import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import './study-item.scss';
import { FcPlus, FcApproval, FcOpenedFolder, FcClock, FcSettings, FcEmptyFilter, FcClearFilters, FcDataEncryption, FcDataProtection, FcSerialTasks, FcDeleteRow } from 'react-icons/fc';
import { BsPauseCircle } from 'react-icons/bs';
import { AiOutlinePauseCircle } from 'react-icons/ai';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Text, Label, Spacer, Button } from '../../atoms';
import { useGetStudyInfoQuery, usePrefetch as usePrefetchStudy } from '../../../api/builders/study.api';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';


type StudyItemProps = {
  studyId?: string | undefined;
  summary?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  acl?: string | undefined;
};

type Props = {
  item: StudyItemProps
  type?: string,
  onClick?: (item: any) => void,
};


interface ActionProps {
  acl: string;
  item: any;
  onOpenDrive: (item: any) => void;
  onOpenRecent: (item: any) => void;
  onOpenSettings: (item: any) => void;
}
const StudyItemActions: React.FC<ActionProps> = (props) => {
  const { acl, item, onOpenDrive, onOpenRecent, onOpenSettings } = props;
  const renderActions = () => {
    switch (acl) {
      case 'admin':
        return (
          <div className='study-item__actions'>
            {/* <div><BsFillPeopleFill onClick={() => onConfig(item)} /></div> */}
            {/* <div><FcApproval onClick={() => onReview(item)} /></div> */}
            {/* <div><FcDataProtection onClick={() => onOpenDrive(item)} /></div> */}
            <div><FcClock onClick={() => onOpenRecent(item)} /></div>
            <div><FcSettings onClick={() => onOpenSettings(item)} /></div>
            {/* <div><AiOutlineAudit onClick={() => onAudit(item)} /></div> */}
          </div>
        );
      case 'SPONSOR':
        return (
          <div className='study-item__actions'>
            <div><FcSettings onClick={() => onOpenSettings(item)} /></div>
            {/* <div><FcApproval onClick={() => onReview(item)} /></div> */}
            {/* <div><FcDataProtection onClick={() => onOpenDrive(item)} /></div> */}
            <div><FcClock onClick={() => onOpenRecent(item)} /></div>
          </div>
        );
      case '(BLINDED)':
        return (
          <div className='study-item__actions'>
            {/* <div><FcSettings onClick={() => onConfig(item)} /></div> */}
            {/* <div><FcApproval onClick={() => onReview(item)} /></div> */}
            {/* <div><FcDataProtection onClick={() => onOpenDrive(item)} /></div> */}
            <div><FcClock onClick={() => onOpenRecent(item)} /></div>
          </div>
        );
      case 'SPONSOR (BLINDED)':
        return (
          <div className='study-item__actions'>
            {/* <div><FcSettings onClick={() => onConfig(item)} /></div> */}
            {/* <div><FcApproval onClick={() => onReview(item)} /></div> */}
            {/* <div><FcDataProtection onClick={() => onOpenDrive(item)} /></div> */}
            <div><FcClock onClick={() => onOpenRecent(item)} /></div>
          </div>
        );
      case 'SITE COORDINATOR':
        return (
          <div className='study-item__actions'>
            {/* <div><FcClock onClick={() => onOpenRecent(item)} /></div> */}
          </div>
        );
      case 'STUDY COORDINATOR':
        return (
          <div className='study-item__actions'>
            <div><FcClock onClick={() => onOpenRecent(item)} /></div>
          </div>
        );
      case 'CORELAB':
        return (
          <div className='study-item__actions'>
            <div><FcClock onClick={() => onOpenRecent(item)} /></div>
          </div>
        );
      default:
        return <div className='study-item__actions' />;
    }
  };

  const memoizedActions = useMemo(() => renderActions(), [acl]);

  return memoizedActions;
};

const StudyItem = ({ item, type, onClick }: Props) => {
  const { id, studyId, description, status, title, acl } = item;
  const navigate = useNavigate();
  const prefetchstudy = usePrefetchStudy('getStudyInfo');
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);

  // const test = useGetStudyInfoQuery({ studyId: studyId || skipToken });

  const addTrialCrumb = () => {
    const newBreadcrumb = {
      label: 'Trials',
      path: '/private/studies',
    };
    setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
  };

  const addStudyCrumb = () => {
    addTrialCrumb();
    const newBreadcrumb = {
      label: title ?? 'Study',
      path: '/private/studies/study/',
      state: { study: item },
    };
    setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
  };

  const onOpen = (_item: StudyItemProps) => {
    if (acl === 'CLR1' || acl === 'CLR2' || acl === 'CLTR') {
      navigate('/private/studies/study/activity', { state: { study: _item, acl } });
    } else {
      navigate('/private/studies/study', { state: { study: _item } });
      addTrialCrumb();
    }
    // navigate('/private/study/view', { state: { study: _item } });
  };


  const onOpenDrive = (_item: StudyItemProps) => {
    addStudyCrumb();
    navigate('/private/studies/study/drive', { state: { study: _item } });
  };
  const onOpenSettings = (_item: StudyItemProps) => {
    addStudyCrumb();
    navigate('/private/studies/study/settings', { state: { study: _item } });
  };

  const onOpenRecent = (_item: StudyItemProps) => {
    addStudyCrumb();
    navigate('/private/studies/study/recent', { state: { study: _item, acl } });
  };

  const onHover = (hoveritem: any) => {
    // eslint-disable-next-line no-console
    // console.info('on hover', { studyId: hoveritem?.id ? hoveritem.id : skipToken });
    prefetchstudy({ studyId: hoveritem?.id ? hoveritem.id : skipToken }, { ifOlderThan: 35 });
  };

  const handleClick = (hiveItem: any) => {
    if (onClick) {
      onClick(hiveItem);
    }
  };

  if (type === 'hive') {
    return (
      <Tile key={`study-item-key-${id}`} onHover={() => onHover(item)} className='study-item__root' shadow='default'>
        <div className='study-item__wrapper' >
          <div className='study-item__body' role="button" tabIndex={0} onClick={() => handleClick(item)}>
            <div className='study-item__wrapper'>
              <div>
                <Label size="h4" weight={600} >{title}</Label>
                <Spacer value={10} />
                <Text size='sm'>{description}</Text>
                <Spacer value={20} />
                <Label size='subtext'>Status: <span className='bold'>{status}</span> </Label>
              </div>
              <div className='pause-wrapper'>
                <AiOutlinePauseCircle color='red' className='pause-btn' size={50} />
              </div>
            </div>
          </div>
        </div>
      </Tile>
    );
  }
  return (
    <Tile key={`study-item-key-${id}`} onHover={() => onHover(item)} className='study-item__root' shadow='default'>
      <div className='study-item__wrapper' >
        <div className='study-item__body' role="button" tabIndex={0} onClick={() => onOpen(item)}>
          <div>
            <Label size="h4" weight={600} >{title}</Label>
            <Spacer value={10} />
            <Text size='sm'>{description}</Text>
            <Spacer value={20} />
          </div>
          <div className='d-flex'>
            <Label size='subtext'>Status: <span className='bold'>{status}</span> </Label>
            <span className='h-space-20' />
            <Label size='subtext'>My Role:  <span className='bold'>{acl}</span>
            </Label>
          </div>
        </div>
        <StudyItemActions acl={acl!} item={item} onOpenDrive={onOpenDrive} onOpenRecent={onOpenRecent} onOpenSettings={onOpenSettings} />
      </div>
    </Tile>
  );
};

StudyItem.defaultProps = {
  type: 'n/a',
  onClick: () => console.info('clicked'),
};

export default StudyItem;
