import React, { useState, useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsCheck, BsX } from 'react-icons/bs';
import { Tile, Spacer, Label, Spinner } from '../../atoms';
import FormItem from '../FormItem/FormItem';
import { useGetSavedCRFSQuery, registriesApi, useAdvancePatientStepMutation, useGetPatientPathwaysQuery, useGetPatientcarepathwayStatsQuery } from '../../../api/builders/registries.api';
import DocumentsDrive from '../DocumentsDrive/DocumentsDrive';
import './conference-crf.scss';

export interface ConferenceCRFProps {
  className?: string,
  patient: any,
  readOnly?: boolean,
  conferenceLabel: string,
}



const ConferenceCRF = (props: ConferenceCRFProps): JSX.Element => {
  const { patient, conferenceLabel } = props;
  const [questions, setQuestions] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState<any | null>(null);
  const { data: pathway } = useGetPatientcarepathwayStatsQuery(patient?.patientcarepathwayId ?? skipToken);
  const [edit, { isLoading: isEdititng, isSuccess, isError, error }] = useAdvancePatientStepMutation();
  const [decision, setDecision] = useState('');


  useEffect(() => {
    if (pathway && Array.isArray(pathway) && pathway[0].carepathway && Array.isArray(pathway[0].carepathway.steps)) {
      const findMatch = pathway[0].carepathway.steps.filter((step: any) => step.description.toLocaleUpperCase() === conferenceLabel.toLocaleUpperCase());
      if (Array.isArray(findMatch)) {
        setCurrentStep(findMatch[0]);
        const parsed = JSON.parse(findMatch[0].crfQuestions);
        setQuestions(parsed);
        if (findMatch[0].conferenceDecision !== 'null') {
          setDecision(findMatch[0].conferenceDecision);
        }
      }
    }
  }, [pathway]);



  const handleSave = (choice: string) => {
    setDecision(choice);
    try {
      const pl = {
        patientcarepathwayId: patient?.patientcarepathwayId,
        body: {
          stepId: patient.steps.id,
          crfQuestions: JSON.stringify(questions),
          status: 'COMPLETED',
          conferenceDecision: choice,
          completionDate: new Date(),
        },
      };
      edit(pl);
    } catch (e) {
      console.error('e', e);
    }
  };


  const handleViewAllAnswer = (ans: any, index: number) => {
    const updatedSurvey = [...questions];
    updatedSurvey[index].answer = ans;
    setQuestions(updatedSurvey);
  };

  const getClass = (indx: any) => {
    let adtnClass = '';
    try {
      if (questions.length === 1) {
        adtnClass = 'span-two';
      } else {
        if (indx > 0 && indx < questions.length - 1) {
          if (
            (questions[indx - 1].type === 'Boolean' || questions[indx - 1].type === 'LIST_SINGLE' || questions[indx - 1].type === 'SECTIONTITLE') &&
            (questions[indx + 1].type === 'Boolean' || questions[indx + 1].type === 'LIST_SINGLE' || questions[indx + 1].type === 'SECTIONTITLE')
          ) {
            adtnClass = 'span-two';
          }
        }
        if (indx === questions.length - 1) {
          if (questions[indx - 1].type === 'Boolean' || questions[indx - 1].type === 'LIST_SINGLE' || questions[indx - 1].type === 'SECTIONTITLE') {
            adtnClass = 'span-two';
          }
        }
      }
    } catch (e) {
      console.error('e', e, indx);
    }
    return adtnClass;
  };


  return (
    <div className='conference-crf__body'>
      <div>
        {currentStep && questions &&
          <div className='conference-crf__body'>
            <div className='conference-crf__section-header'>
              <Label uppercase size='h3' weight={300}>
                {patient?.steps?.description} CRF
              </Label>
            </div>
            <div className='crf-wrapper' >
              {questions?.map((q: any, index: number) => (
                <FormItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`survey-question-${q.id}-${index}`}
                  question={q}
                  onAnswer={(it: any) => handleViewAllAnswer(it, index)}
                  currentIndex={index}
                  editable
                  noNumbers
                  className={getClass(index)}
                />
              ))}
            </div>
          </div>
        }
      </div>

      <div>
        <div className='conference-crf__section-header'>
          <Label uppercase size='h3' weight={300}>
            Patient CarePathway Decision
          </Label>
        </div>
        <Spacer value={20} />
        {isEdititng ?
          <div className='btn-row'>
            <Spinner />
          </div>
          :
          <div className='btn-row'>
            <button disabled={patient?.steps?.patientStatus !== 'COMPLETED' || isEdititng} type='button' className={decision === 'Stop' ? 'conference-crf__no-btn-selected' : 'conference-crf__no-btn'} onClick={() => handleSave('Stop')}>
              <BsX className='red' />
              <Spacer value={5} />
              <Label uppercase size='h5' weight={300}>
                Stop Patient
              </Label>
            </button>
            <button type='button'
              disabled={patient?.steps?.patientStatus !== 'COMPLETED' || isEdititng}
              className={decision === 'Pause' ? 'conference-crf__pause-btn-selected' : 'conference-crf__pause-btn'}
              onClick={() => handleSave('Pause')}>
              <BsX className='yellow' />
              <Spacer value={5} />
              <Label uppercase size='h5' weight={300}>
                Pause Patient
              </Label>
            </button>
            <button type='button'
              disabled={patient?.steps?.patientStatus !== 'COMPLETED' || isEdititng}
              className={decision === 'Continue' ? 'conference-crf__yes-btn-selected' : 'conference-crf__yes-btn'}
              onClick={() => handleSave('Continue')}>
              <BsCheck className='green' />
              <Spacer value={5} />
              <Label uppercase size='h5' weight={300}>
                Continue on Pathway
              </Label>
            </button>
          </div>}

        <Spacer value={30} />
      </div>

    </div >
  );
};

ConferenceCRF.defaultProps = {
  className: '',
  readOnly: false,
};

ConferenceCRF.displayName = 'ConferenceCRF';
export default ConferenceCRF;

