import cohortMenu from './cohortMenu';
import imxMenu from './imxMenu';
import privacyMenu from './privacyMenu';
import trialsMenu from './trialsMenu';
import registryMenu from './registryMenu';

const allMenu = [
  ...cohortMenu,
  ...imxMenu,
  ...privacyMenu,
  ...trialsMenu,
  ...registryMenu,
];

export default allMenu;
