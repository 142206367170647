import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Label, Dropdown as DropdownComponent } from '../../../atoms';
import '../form-item.scss';

export interface DropdownProps {
  answer: any,
  onAnswer: (vals: any) => void,
  editable?: boolean,
  label: string,
  qNumber: number,
  noNumbers: boolean,
  className: string,
  options: string[],
}

const Dropdown = (props: DropdownProps): JSX.Element => {
  const { answer, onAnswer, editable, label, qNumber, noNumbers, className, options } = props;
  const [value, setValue] = useState('');


  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      setValue(answer[0].value);
    }
  }, [answer]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (editable) {
      const val = e.target.value;
      setValue(val);
      onAnswer([{ 'value': val, 'label': val }]);
    }
  };


  return (
    <div className={className}>
      {noNumbers ?
        <Label size="h6" weight={200} >{label}</Label>
        :
        <Label size="h6" weight={200} >{qNumber}: {label}</Label>
      }
      <DropdownComponent
        label=""
        value={value}
        onChange={onChange}
      >
        {options.map((it: any) => <MenuItem value={it.value} >{it.label}</MenuItem>)}
      </DropdownComponent>
    </div>
  );
};

Dropdown.defaultProps = {
  editable: true,
};

Dropdown.displayName = 'Dropdown';
export default Dropdown;
