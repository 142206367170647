/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { MenuItem } from '@mui/material';
import { Tile, Label, Button, Spacer, DatePicker, Dropdown } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import './question-types.scss';
import { useGetUserProfileQuery } from '../../../api/builders/hsg.api';
import TextInput from '../TextInput/TextInput';
import SimpleSelect from '../SimpleSelect/SimpleSelect';
import CountryList from '../../../utils/countryList';
import { States } from '../../../utils/states';
// import States from '../../..utils/states';


export interface UserLocationProps {
  answer: any,
  onAnswer: (vals: any) => void,
  question: any,
}

const initInfo: State = {
  city: '',
  state: '',
  country: '',
  zip: '',
};

interface State {
  city: string;
  state: string;
  country: string;
  zip: string;
}



const UserLocation = (props: UserLocationProps): JSX.Element => {
  const { answer, onAnswer, question } = props;
  const { data: userProfile } = useGetUserProfileQuery(' ');
  const [value, setValue] = useState<State>(initInfo);


  const updateStateFromProps = (state: State, propsArray: { label: keyof State; value: string }[]) => {
    const updatedState: State = { ...state };
    propsArray.forEach((prop) => {
      updatedState[prop.label] = prop.value;
    });
    return updatedState;
  };


  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      // setValue(answer[0].value);
      const test = updateStateFromProps(value, answer);
      if (test) setValue(test);
    } else if (userProfile) {
      const info = {
        zip: userProfile[0].zip?.length > 0 ? userProfile[0].zip[0] : '',
        city: userProfile[0].city?.length > 0 ? userProfile[0].city[0] : '',
        state: userProfile[0].state?.length > 0 ? userProfile[0].state[0] : '',
        country: userProfile[0].country?.length > 0 ? userProfile[0].country[0] : '',
      };

      setValue(info);
    }
  }, [question.id, userProfile]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setValue((prev) => ({ ...prev, [e.target.name]: val }));
    if (e.target.name === 'country' &&  val !== 'United States') {
      setValue((prev) => ({ ...prev, state: '' }));
    }
  };


  useEffect(() => {
    onAnswer([
      { label: 'city', value: value.city },
      { label: 'state', value: value.state },
      { label: 'country', value: value.country },
      { label: 'zip', value: value.zip },
    ]);
  }, [value]);

  return (
    <div className='question-types_text'>

      <Spacer value={15} />

      <TextInput
        name="zip"
        type=""
        value={value.zip}
        label='Zip Code'
        onChange={onChange}
      />
      <Spacer value={10} />

      <TextInput
        name="city"
        type=""
        value={value.city}
        label='City'
        onChange={onChange}
      />
      <Spacer value={10} />

      {value.country === 'United States' &&
        <Dropdown
          label="State"
          name="state"
          value={value.state}
          onChange={onChange} >
          {States.map((countr: string) => (
            <MenuItem value={countr}>{countr}</MenuItem>
          ))}
        </Dropdown>
      }

      <Dropdown
        label='Country'
        name="country"
        value={value.country}
        onChange={onChange}
      >
        {CountryList.map((countr: string) => (
          <MenuItem value={countr}>{countr}</MenuItem>
        ))}
      </Dropdown>
      <Spacer value={10} />


    </div>
  );
};

UserLocation.defaultProps = {
};

UserLocation.displayName = 'UserLocation';
export default UserLocation;
