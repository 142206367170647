import React from 'react';



const Discover = () => {
  const somevar = 'Discover';
  return (
    <div className="discover">{somevar}</div>
  );
};

export default Discover;
