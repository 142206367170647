import React, { useRef, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { Tile, Label, Spacer, Button, Modal, Alert } from '../../atoms';
import { DiagnosisRow, LifeEvents, Medications, Seizures, SurveyScores } from './Rows';
import './epilepsy-table.scss';
import { VisitT } from '../../../types/types';
import sortVisits from '../../../utils/fns/sortVisits';
import SeizureTypeForm from '../SeizureTypeForm/SeizureTypeForm';
import NewMedForm from '../NewMedForm/NewMedForm';
import { useCreateSeizureRecordMutation, useGetSeizureRecordsByPatientQuery, useGetMedicationRecordsByPatientQuery, useGetDiagnosisRecordsByPatientQuery, useCreateMedicationRecordMutation } from '../../../api/builders/epilepsy.api';
import EpilepsyEpicForm from '../EpilepsyEpicForm/EpilepsyEpicForm';

export interface EpilepsyTableProps {
  className?: string,
  visits: VisitT[] | [],
  patient: any,
  patientSurveys: any,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const initialSeizure = {
  patientLabel: '',
  classification: '',
  onset: '',
  awareness: '',
  yearOfOnset: '',
  description: '',
  dateOfLastKnownSeizure: new Date(),
  count: 0,
  other: '',
  historyOfSeizureCount: '',
  NonEpilepticOption: '',
  active: true,
};


const EpilepsyTable = (props: EpilepsyTableProps): JSX.Element => {
  const { visits, patient, patientSurveys } = props;
  const [seizureVals, setSeizureVals] = useState(initialSeizure);
  const [addModal, setAddModal] = useState(false);
  const [formType, setFormType] = useState('choice');
  const [epicModal, setEpicModal] = useState(false);
  const [addMed, { isLoading: isAddingMed, isSuccess: isSuccessAddingMed, isError: isErrorAddingMed, error: isAddMedEr }] = useCreateMedicationRecordMutation();
  const [addSz, { isLoading: isAddingSz, isSuccess: isSuccessAddSz, isError: isErroAddSzr, error: AddSzError }] = useCreateSeizureRecordMutation();
  const { data: seizures } = useGetSeizureRecordsByPatientQuery(patient?.id ?? skipToken);
  const { data: meds } = useGetMedicationRecordsByPatientQuery(patient?.id ?? skipToken);
  const { data: diagnosis } = useGetDiagnosisRecordsByPatientQuery(patient?.id ?? skipToken);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  const openEpicModal = () => {
    setEpicModal(true);
  };

  const closeAddModal = () => {
    if (isAddingSz) {
      return;
    }
    setAddModal(false);
    setFormType('choice');
  };


  React.useEffect(() => {
    if (isErrorAddingMed) {
      const apiError = isAddMedEr as ApiError;
      setAlert({
        open: true,
        message: `Error Adding Medication: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAddingMed]);

  React.useEffect(() => {
    if (isSuccessAddingMed) {
      setAlert({
        open: true,
        message: 'Success Adding Medication',
        kind: 'success',
      });
      closeAddModal();
    }
  }, [isSuccessAddingMed]);

  React.useEffect(() => {
    if (isErroAddSzr) {
      const apiError = AddSzError as ApiError;
      setAlert({
        open: true,
        message: `Error Adding Seizure: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErroAddSzr]);

  React.useEffect(() => {
    if (isSuccessAddSz) {
      setAlert({
        open: true,
        message: 'Success Adding Seizure',
        kind: 'success',
      });
      closeAddModal();
    }
  }, [isSuccessAddSz]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const onAddSz = () => {
    try {
      const calcOrder = Array.isArray(seizures) && seizures.length > 0 ? seizures.length + 1 : 1;
      const payload = {
        ...seizureVals,
        patientId: patient.id,
        order: calcOrder.toString(),
      };
      addSz(payload);
    } catch (e) {
      console.error(e);
    }
  };

  const focus = useRef<HTMLTableHeaderCellElement | null>(null);

  const scrollToBottom = () => {
    focus?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [visits]);



  return (
    <div className="epilepsy-table">
      <Tile className='epilepsy-table__wrapper'>
        <>
          <div className='epilepsy-table__header'>
            <Label size='h4' color='ghost'>Clinic Visits</Label>
            <span className='d-flex'>
              <Button onClick={openEpicModal}>Send to Epic </Button>
              <Button outline onClick={() => setAddModal(true)} >Add New + </Button>
            </span>
          </div>
          <Spacer value={10} />
          <div >
            {Array.isArray(visits) && visits.length > 0 &&
              <table className="epilepsy-table__table" >
                <thead>
                  <tr>
                    <th>Item</th>
                    {sortVisits(visits).map((v, index) => (
                      <th
                        className={index === visits.length - 1 ? 'epilepsy-table__active' : 'n/a'}
                        ref={index === visits.length - 1 ? focus : null}
                        key={v.id}
                      >
                        {moment(v.date).format('L')}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <DiagnosisRow visits={visits} patient={patient} diagnosis={diagnosis} />
                  <Seizures visits={visits} patient={patient} seizures={seizures} />
                  <Medications meds={meds} visits={visits} patient={patient} />
                  <SurveyScores visits={visits} patient={patient} patientSurveys={patientSurveys} />
                  <LifeEvents visits={visits} patient={patient} patientSurveys={patientSurveys} />
                </tbody>
              </table>
            }
          </div>
        </>
      </Tile>

      <Modal
        open={addModal}
        onClose={closeAddModal}
        title="Add New Row to table"
        size={formType === 'choice' ? 'sm' : 'md'}
      >
        <div className="modal-body">
          {formType === 'choice' &&
            <>
              <div role='button' tabIndex={-1} onClick={() => setFormType('sz')} className="epilepsy-table__btn">
                <Label size="h5" weight={600} color="primary"  > Add New Seizure Type</Label>
              </div>
              <Spacer value={15} />
              <div role='button' tabIndex={-1} onClick={() => setFormType('med')} className="epilepsy-table__btn">
                <Label size="h5" weight={600} color="primary"  > Add New Medication</Label>
              </div>
            </>
          }
          {formType === 'sz' &&
            <>
              <SeizureTypeForm
                seizureVals={seizureVals}
                setSeizureVals={setSeizureVals}
              />
              <Spacer value={20} />
              <div className='btn-row'>
                <Button kind='danger' onClick={() => setFormType('choice')}>Cancel</Button>
                <Button onClick={onAddSz} loading={isAddingSz} >Add Seizure</Button>
              </div>
            </>
          }
          {formType === 'med' &&
            <NewMedForm meds={meds} patient={patient} addMed={addMed} isLoading={isAddingMed} />
          }
        </div>
      </Modal>

      <EpilepsyEpicForm
        open={epicModal}
        onClose={() => setEpicModal(false)}
        visit={Array.isArray(visits) && visits.length > 0 ? visits[visits.length - 1] : []}
        diagnosis={diagnosis}
        seizures={seizures}
        meds={meds}
      />

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div >
  );
};

EpilepsyTable.defaultProps = {
  className: '',
};

EpilepsyTable.displayName = 'EpilepsyTable';
export default EpilepsyTable;
