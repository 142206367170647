import React from 'react';
import { Button, Label, Logo, Spacer } from '../../../components/atoms';



const Home = () => {
  const somevar = 'Home';

  return (
    <div className="home">
    <div className="wrapper">
      <div className='panel1'>
        <Logo size="100%" type="registered" />
        <Label size='h1' center >Participate in Research</Label>
      </div>
      <div className='panel2'>
        <Spacer value={30} />
        <Label size='h1' center>
          New studies are continuously added to myHDstory<sup>&reg;</sup> 
        </Label>
        <Spacer value={15} />
        <Label size='h1' center>
          To be alerted to new studies you may qualify for, create your account on the platform.
        </Label>
        <Spacer value={15} />
      </div>
    </div>
  </div>
  );
};

export default Home;
