import React, { useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLocation, useNavigate } from 'react-router-dom';
import { StudyT } from '../../../types/hsgTypes';
import { Modal, Spacer, Label, Button } from '../../../components/atoms';
import { hsgApi, useGetUserProfileQuery, useUpdateConsentMutation, useAddStudyConsentMutation, useGetUserStudyDocsQuery, useGetStudyInfoQuery } from '../../../api/builders/hsg.api';
import { TextInput, StudyConsentForm, StudyDetaisl } from '../../../components/molecules';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { selectAuth } from '../../../store/authentication/slice';

interface LocationState {
  study: StudyT;
}

const underAgeValidate = (birthday: string): boolean => {
  // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
  const optimizedBirthday = birthday.replace(/-/g, '/');

  const myBirthday = new Date(optimizedBirthday);
  const currentDate = new Date();

  // Calculate age in years
  const ageInMilliseconds = currentDate.getTime() - myBirthday.getTime();
  const millisecondsInYear = 31557600000; // 365.25 days
  const myAge = Math.floor(ageInMilliseconds / millisecondsInYear);

  if (myAge < 18) {
    return false;
  }
  return true;
};

const Study = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector(selectAuth);
  const { study } = location?.state as LocationState;
  const { data: userProfile } = useGetUserProfileQuery(' ');
  const [registerPatient, { isLoading }] = useAddStudyConsentMutation();
  const { data: studyInfo } = useGetStudyInfoQuery(study?.id ?? skipToken);
  const [updateConsent, { isLoading: isEditing }] = useUpdateConsentMutation();
  const [revokeConsentModal, setRevokeConsentModal] = useState(false);
  const [reason, setReason] = useState('');
  const [openConsentModal, setOpenConsentModal] = useState(false);
  const [docPl, setPl] = useState<any>(null);
  const { refetch } =
    hsgApi.endpoints.getUserStudyDocs.useQuerySubscription(docPl ?? skipToken);


  const goHome = () => {
    navigate('/research');
  };

  React.useEffect(() => {
    if (Array.isArray(studyInfo)) {
      const pl = {
        'studyId': study.id,
        'filename': studyInfo[0].consentUUID,
        admin: false,
        userId: user,
        'method': 'download',
      };
      setPl(pl);
    }
  }, [studyInfo, user]);

  React.useEffect(() => {
    if (docPl) {
      refetch();
    }
  }, [study, openConsentModal]);



  const handleSubmit = (docId: string) => {
    try {
      const pl = {
        studyId: study.id,
        consentUUID: docId,
      };
      registerPatient(pl);
    } catch (e) {
      console.error(e);
    }
  };


  const reConsenUser = async () => {
    try {
      const payload = {
        'studyId': study.id,
        'status': 'consented',
        'reason': reason,
      };
      await updateConsent(payload);
    } catch (e) {
      console.error(e);
    }
  };


  const confrimRevoke = async () => {
    try {
      const payload = {
        'studyId': study.id,
        'status': 'revoked',
        'reason': reason,
      };
      await updateConsent(payload);
      setRevokeConsentModal(false);
    } catch (e) {
      console.error(e);
    }
  };


  const onChangeReason = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setReason(e.target.value);
  };

  const renderUserStatus = (status: string) => {
    switch (status) {
      case 'consented':
        return (
          <>
            {Array.isArray(studyInfo) && studyInfo[0]?.consentUrl  &&
              <a href={studyInfo[0].consentUrl} style={{ color: 'white' }} target="_blank" download rel="noreferrer">
                <FiDownload size={20} />
              </a>
            }
            <Button disabled={isLoading} onClick={() => setRevokeConsentModal(true)}>
              Enrolled
            </Button>
          </>
        );
      case 'revoked':
        return (
          <>
            {Array.isArray(studyInfo) && studyInfo[0]?.consentUrl  &&
              <a href={studyInfo[0].consentUrl} style={{ color: 'white' }} target="_blank" download rel="noreferrer">
                <FiDownload size={20} />
              </a>
            }
            <Button disabled={isLoading} onClick={reConsenUser}>
              Not Enrolled
            </Button>
          </>
        );
      default:
        return (
          <>
            <Label size="h4" color="default">
              In order to participate in this Study, you will need to sign an Informed Consent Form which explains the Study and seeks your written consent to participate.
            </Label>
            <Spacer value={20} />
            <Label size="h4" color="default">
              If you have any questions, click Help in the top menu bar.
            </Label>
            <Spacer value={20} />
            {
              userProfile && userProfile[0].country[0] === 'United States' && underAgeValidate(userProfile[0].dob[0]) ?
                <div>
                  <div className="d-flex study-choice-item">
                    <Label size="h4" color="default">
                      I think I qualify and am interested
                    </Label>
                    <Spacer value={5} />
                    <Button size="small" onClick={() => setOpenConsentModal(true)}>
                      Go to consent
                    </Button>
                  </div>
                  <Spacer value={10} />
                  <div className="d-flex study-choice-item">
                    <Label size="h4" color="default">
                      I do not qualify or am not interested
                    </Label>
                    <Spacer value={5} />
                    <Button size="small" onClick={goHome}>
                      Skip this study
                    </Button>
                  </div>
                </div>
                :
                <div>
                  <Label size="h4" color="secondary">
                    Based on your information, you do not qualify for this study
                  </Label>
                </div>
            }
          </>
        );
    }
  };

  return (
    <div >
      {/* <Button onClick={() => setOpenConsentModal(true)}>Test</Button> */}
      {studyInfo && (Array.isArray(studyInfo) && studyInfo[0].consentStatus === 'consented' || studyInfo[0].consentStatus === 'revoked') ?
        <StudyDetaisl study={study} userDocuments={Array.isArray(studyInfo) ? studyInfo[0]?.consentUrl : '' } />
        :
        <div className="study">
          <div className="study__tile"  >
            <Label size="h1" color="primary" bold>{study?.title && study.title}</Label>
            <Spacer value={20} />
            {JSON.parse(study.description[0]).map((name: any) => (
              <div className="d-flex-row" key={name}>
                <Label size="h4" color="default"> {name} </Label>
              </div>
            ))}

            <Spacer value={30} />
            <Label size="h4"  > To qualify for this study, you must: </Label>
            <Spacer value={20} />

            <ul>
              {JSON.parse(study.inclusionCriteria[0]).map((name: string) => (
                <div className="d-flex-row" key={name}>
                  <li>
                    <Label size="h4" color="default"> {name} </Label>
                  </li>
                </div>
              ))}
            </ul>

            <Spacer value={30} />

            {study?.status[0] !== 'closed' &&
              <>
                {renderUserStatus(study.consentStatus[0])}
              </>
            }
            <Spacer value={30} />
          </div>
        </div>
      }
      {/* Study Consent Popup */}
      <Modal open={openConsentModal} onClose={() => setOpenConsentModal(false)} >
       {userProfile &&  <StudyConsentForm
          body={study.consentText}
          user={`${userProfile[0].firstname[0]} ${userProfile[0].lastname[0]}`}
          loading={isLoading}
          onSubmit={handleSubmit}
          // handleClose={goHome}
          handleClose={() => setOpenConsentModal(false)}
          study={study}
        /> }
      </Modal>

      {/* Revoke Study Consent Popup */}
      <Modal open={revokeConsentModal} onClose={() => setRevokeConsentModal(false)} title="Revoke your Consent">
        <div>
          <Spacer value={15} />
          <TextInput
            id="reason"
            label="Reason"
            value={reason}
            onChange={onChangeReason}
          />
          <Spacer value={20} />
          <div className="super-centered">
            <Button disabled={isEditing} onClick={confrimRevoke}>
              revoke
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withAuthenticator(Study);
