/* eslint-disable react/no-array-index-key */
import React from 'react';
import { DatePicker, Label, Spacer, Button } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';
import SimpleSelect from '../../SimpleSelect/SimpleSelect';

export interface EmailOpProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleNextStep: () => void,
}

const EmailOp = (props: EmailOpProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleNextStep } = props;

  const answerQuestion = (value: any) => {
    let tmp: any[] = [];
    if (props?.state?.emailOP[0] === value) {
      tmp = [];
    } else {
      tmp = [value];
    }
    handleChange((prev: any) => ({ ...prev, emailOP: tmp }));
  };

  const options = [
    { goto: 'next', label: 'Yes', order: 1, value: 'true' },
    { goto: 'next', label: 'No', order: 2, value: 'false' },
  ];

  const validate = () => {
    if (
      state.emailOP[0] &&
			state.emailOP[0].trim() !== ''
    ) {
      return false;
    } 
    return true;
		
  };

  return (
    <div>
      <Label size="h3" bold color="primary" center >
        Do you agree to receive information by email about future research related to HD?
      </Label>
      <Spacer value={20} />
      {options.map((op, i) => (
        <SimpleSelect
          label={op.label}
          onSelect={answerQuestion}
          answer={state.emailOP}
          withCheck
          multi={false}
          key={i}
        />
      ))}

      <Spacer value={20} />
      <div className='btn-row'>
        <Button onClick={handlePrevStep}>Previous</Button>
        <Button onClick={handleNextStep} disabled={validate()}>Next</Button>
      </div>
    </div>
  );
};

EmailOp.defaultProps = {
};

EmailOp.displayName = 'EmailOp';
export default EmailOp;
