/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tile, Label, Spacer, DatePicker, Button, Modal, Alert } from '../../atoms';
import './care-list-item.scss';
import { useUpdatePatientcarepathwayMutation } from '../../../api/builders/patient.api';

export interface CareListItemProps {
  className?: string,
  path: any,
  handleOpen: (item: any) => void,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const CareListItem = (props: CareListItemProps): JSX.Element => {
  const { path, handleOpen } = props;
  const [selectedPath, setSelectedPath] = useState<any | null>(null);
  const [statusModal, setStatusModal] = useState(false);
  const [editPath, { isLoading, isSuccess, isError, error }] = useUpdatePatientcarepathwayMutation();


  const calculateMonthsSinceStartTime = (startTime: any) => {
    const currentDate = new Date();
    const startDate = new Date(startTime);

    const yearsDiff = currentDate.getUTCFullYear() - startDate.getUTCFullYear();
    const monthsDiff = currentDate.getUTCMonth() - startDate.getUTCMonth();

    // Calculate the total months difference
    const totalMonthsDiff = yearsDiff * 12 + monthsDiff;

    return totalMonthsDiff;
  };


  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const handleStatus = (e: any, itm: any) => {
    e.stopPropagation();
    setSelectedPath(itm);
    setStatusModal(true);
  };


  const resetStatusModal = () => {
    setSelectedPath(null);
    setStatusModal(false);
  };

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Patient Pathway: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Editing Patient Pathway',
        kind: 'success',
      });
      resetStatusModal();
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const handleChangeStatus = () => {
    try {
      const pl = {
        id: path.id,
        body: {
          active: !path.active,
        },
      };
      editPath(pl);
    } catch (e) {
      console.error(e);
    }
  };


  const handleClick = () => {
    if (selectedPath) {
      return;
    }
    handleOpen(path);
  };

  return (
    <div className="care-list-item" tabIndex={-1}
      onClick={handleClick}>
      <div className='care-list-item__body'>
        <div className='w-100'>
          <div key={path.id} className='care-list-item__header'>
            <Label uppercase size='h5' weight={600}>
              {path.name}
            </Label>
            <button type='button' onClick={(e) => handleStatus(e, path)}
              className={path.active ? 'btn-actve' : 'btn-inactive'}
            >
              <Label size='h6' italic color={path.active ? 'success' : 'ghost'}>
                {path.active ? 'Active' : 'Inacative'}
              </Label>
            </button>
          </div>
          <Spacer value={5} />
          <Label size='h6' italic color='ghost'>
            Started on: {moment(path.startTime).format('L')}
          </Label>
          <Spacer value={5} />
          <Label size='h6' italic color='ghost'>
          Time on the Path: {calculateMonthsSinceStartTime(path.startTime)} Months
        </Label>
          <Spacer value={5} />
          <Label size='h6' weight={400}>
            {path.description}
          </Label>
          <Spacer value={10} />
        </div>
      </div>
      <Modal title='Change Pathway Status' open={statusModal} onClose={resetStatusModal}>
        {selectedPath && <div className='w-100 h-100 super-centered'>
          {selectedPath?.active ?
            <Label size='h5' weight={600}>Change Status of {selectedPath.name} to Inactive?</Label>
            :
            <Label size='h5' weight={600}>Change Status of {selectedPath.name}  to Active?</Label>
          }
          <Spacer value={15} />
          <div className='btn-row'>
            <Button kind='danger' onClick={resetStatusModal}>
              Cancel
            </Button>
            <Button onClick={handleChangeStatus} loading={isLoading}>
              Confirm
            </Button>
          </div>
        </div>}
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

CareListItem.defaultProps = {
  className: '',
};

CareListItem.displayName = 'CareListItem';
export default CareListItem;
