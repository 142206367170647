import React, { useContext, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLocation, useNavigate } from 'react-router-dom';
import { SurveyT } from '../../../types/hsgTypes';
import { Alert, Button, Label, Spacer, Spinner, Tile } from '../../../components/atoms';
import { SurveyContext } from '../../../utils/SurveyInProgress/surveyInProgress';
import { Survey } from '../../../components/molecules';
import { useUpdateUserSurveyMutation, useGetUserSurveyDetailsQuery } from '../../../api/builders/hsg.api';
import { AlertState, ApiError } from '../../../types/types';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';


interface LocationState {
  survey: SurveyT;
}

const UserSurvey = () => {
  const { setSurveyInProgress } = useContext(SurveyContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { survey } = location?.state as LocationState;
  const { data: surveyDetials } = useGetUserSurveyDetailsQuery(survey?.id ?? skipToken);
  const [takeSurvey, setTakeSurvey] = useState(false);
  const [questions, setQuestions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateSurvey, { isLoading, isSuccess, isError, error }] = useUpdateUserSurveyMutation();
  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Submitting Survey: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };



  useEffect(() => {
    if (Array.isArray(surveyDetials) && surveyDetials.length > 0 && surveyDetials[0]?.questions) {
      try {
        const tmp = JSON.parse(surveyDetials[0].questions);
        setQuestions(tmp);
      } catch {
        console.error('error parsing sruvey');
      }
    }

  }, [surveyDetials]);

  const onSubmit = async (surv: any) => {
    setLoading(true);
    if (surv && surv.length > 0 && survey && survey.usersurveyId) {
      try {
        const payload = {
          surveyId: survey.usersurveyId,
          body: {
            status: 'complete',
            questions: JSON.stringify(surv),
          },
        };
        await updateSurvey(payload);
        setSurveyInProgress(false);
        setLoading(false);
      } catch (e) {
        console.error('error submitting survey', e);
        setLoading(false);
      }
    }
  };

  const startSurvey = () => {
    setTakeSurvey(true);
    setSurveyInProgress(true);
  };

  const onSkip = async () => {
    setLoading(true);
    try {
      const payload = {
        surveyId: survey.usersurveyId,
        body: {
          status: 'skipped',
          questions: JSON.stringify(surveyDetials[0]?.questions),
        },
      };
      await updateSurvey(payload);
      setLoading(false);
    } catch (e) {
      console.error('error submitting survey', e);
      setLoading(false);
    }
  };

  // console.log('surveyDetials', surveyDetials);

  return (<div>

    {(takeSurvey && survey) ?
      <div className="userSurvey__body">
        <Label center size="h2" bold color="ghost" >
          {survey.surveyLabel ? survey.surveyLabel : survey.label}
        </Label>
        <div className="super-centered">
          {/* <Button center kind="ghost" onClick={backToAllSurveys}> <BsArrowLeft />  Go back</Button> */}
        </div>
        <Spacer value={10} />
        <Tile className="userSurvey__list-container">
          <Survey
            userSurvey={questions}
            setUserSurvey={setQuestions}
            onSubmit={onSubmit}
            saveProgress={onSubmit}
            loading={isLoading}
          />
        </Tile>
      </div>
      :
      <div className="userSurvey__body">
        <Tile className="userSurvey__tile" >
          <>
            <Label size="h1" color="ghost" bold>
              {survey.surveyLabel ? survey.surveyLabel : survey.label}
            </Label>
            <Spacer value={30} />
            <Label size="h3" >
              {survey?.description ? survey?.description : ''}
            </Label>
            <Spacer value={30} />
            <Label size="h3" >
              {survey?.instructions ? survey?.instructions : ''}
            </Label>
            <Spacer value={30} />
            <Label size="h3">
              Estimated time to complete: {survey.timeToComplete ? survey.timeToComplete : 'about 5 minutes'}
            </Label>

            <Spacer value={30} />
            {questions && <Button kind="primary" onClick={startSurvey}> Begin </Button>}
            <Spacer value={30} />
            <button type='button' className="userSurvey__skip" onClick={onSkip}>
              {loading ?
                <span>
                  <Spinner size={20} />
                </span>
                :
                <Label italic bold size="h4" color="secondary" >
                  Decline to complete this survey
                </Label>
              }
            </button>
          </>
        </Tile>

      </div>
    }

    <Alert
      severity={alert.kind}
      open={alert.open}
      onClose={resetAlert}
      message={alert.message}
    />

  </div>
  );
};

export default withAuthenticator(UserSurvey);
