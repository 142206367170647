/* eslint-disable react/no-array-index-key */
import React from 'react';
import { DatePicker, Label, Spacer, Button } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';
import SimpleSelect from '../../SimpleSelect/SimpleSelect';

export interface GenderProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleNextStep: () => void,
}

const Gender = (props: GenderProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleNextStep } = props;

  const answerQuestion = (value: any) => {
    let tmp: any[] = [];
    if (props?.state?.gender[0] === value) {
      tmp = [];
    } else {
      tmp = [value];
    }
    handleChange((prev: any) => ({ ...prev, gender: tmp }));
  };

  const handleOther = (e: any) => {
    handleChange((prev: any) => ({ ...prev, genderOther: e.target.value }));
  };

  const options = [
    { goto: 'next', label: 'Male', order: 1, value: 'Male' },
    { goto: 'next', label: 'Female', order: 2, value: 'Female' },
    { goto: 'next', label: 'Other', order: 3, value: 'Other' },
  ];

  const validate = () => {
    if (
      state.gender[0] === 'Other' &&
      state.genderOther
    ) {
      return false;
    } if (state.gender[0] !== 'Other' &&
      state.gender[0] &&
      state.gender[0].trim() !== '') {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Label size="h3" bold color="primary" center>
        What is your Sex (sex at birth)?
      </Label>
      <Spacer value={20} />
      {options.map((op, i) => (
        <SimpleSelect
          label={op.label}
          onSelect={answerQuestion}
          answer={state.gender}
          withCheck
          multi={false}
          key={i}
        />
      ))}

      <Spacer value={20} />

      {state.gender[0] === 'Other' &&
        <TextInput
          id="genderOther"
          name="genderOther"
          label="Specify:"
          value={state.genderOther}
          placeholder=""
          onChange={handleOther}
        />}

      <Spacer value={20} />
      <div className='btn-row'>
        <Button onClick={handlePrevStep}>Previous</Button>
        <Button onClick={handleNextStep} disabled={validate()}>Next</Button>
      </div>

    </div>
  );
};

Gender.defaultProps = {
};

Gender.displayName = 'Name';
export default Gender;
