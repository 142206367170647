import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import SearchBox from '../SearchBox/SearchBox';
import TextInput from '../TextInput/TextInput';
import ConferenceInfoColumn from '../ConferenceInfoColumn/ConferenceInfoColumn';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import { IconButton, Label, Modal, Alert, Dropdown, DateTimePicker, Button, Spacer } from '../../atoms';
import { useCreateConferenceMutation, useGetConferencesQuery, conferencesApi } from '../../../api/builders/conference.api';
import './conferences-manager.scss';




const containerStyle = {
  width: '100%', // Set the container width to fill its parent's width
  height: '100%', // Set the container height to fill its parent's height
};


dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop: any) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;






export interface ConferencesManagerProps {
  id: string;
  className?: string;
  registry: any,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const initialVals = {
  conferenceDate: '',
  description: '',
  type: '',
  status: 'Pending',
};

const ConferencesManager = (props: ConferencesManagerProps): JSX.Element => {
  const { id, className, registry } = props;
  const { data: conferences, isLoading: isLoadingConferences, isFetching: isFetchingConferences } = useGetConferencesQuery(registry ? registry.id : skipToken);
  const { refetch } = conferencesApi.endpoints.getConferences.useQuerySubscription(' ');
  const [AddConference, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useCreateConferenceMutation();
  const [modal, setModal] = React.useState(false);
  const [filter, setFilter] = React.useState('all');
  const [filteredData, setFilteredData] = React.useState<any | any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { t } = useTranslation();
  const [values, setValues] = useState(initialVals);
  const [gridState, setGridState] = useState('default');
  const [openConference, setOpenConference] = useState<any>(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());

  const handleSelectConf = (item: any) => {
    setGridState('panelOpen');
    setOpenConference(item);
  };

  const handleCloseConf = () => {
    setGridState('default');
    setOpenConference(null);
  };


  const handleChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };
  const handleChangeArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const handleChangeDate = (val: any) => {
    setValues({ ...values, conferenceDate: val });
  };



  const filteredConferences = conferences?.filter((conf: any) => {
    const conferenceDate = dayjs(conf.conferenceDate);
    const startDateOfWeek = dayjs(value).startOf('week'); // Start of the week for the given 'value'
    const endDateOfWeek = dayjs(value).endOf('week'); // End of the week for the given 'value'

    // Check if the conference date is within the week range
    return conferenceDate.isBetween(startDateOfWeek, endDateOfWeek, null, '[]');
  });


  const sortConferencesByDate = (confs: any) => {
    const sortedConferences = [...confs]; // Create a new array with the same elements
    sortedConferences.sort((a, b) => new Date(a.conferenceDate).getTime() - new Date(b.conferenceDate).getTime());
    return sortedConferences;
  };

  const handleChangeCalRange = (newRange: any) => {
    setValue(newRange);
    handleCloseConf();
  };

  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Conference: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding New Conference',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const handleChangeSearch = (val: string) => {
    setSearchTerm(val);
  };

  const openAddModal = () => {
    setModal(true);
  };

  const closeAddModal = () => {
    setModal(false);
    setValues(initialVals);
  };

  const handleCreatConf = async () => {
    try {
      const pl = {
        ...values,
        conferenceLabel: values.type,
        patientRegistryId: registry.id,
      };
      await AddConference(pl);
      closeAddModal();
    } catch (e) {
      console.error(e);
    }
  };


  const renderWeekPickerDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>,
  ) => {
    if (!value) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <PickersDay {...pickersDayProps} />;
    }

    const start = value.startOf('week');
    const end = value.endOf('week');

    const dayIsBetween = date.isBetween(start, end, null, '[]');
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');

    return (
      <CustomPickersDay
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <div key={`ConferencesManager_${id}`} className="conferences-manager">
      <div className="conferences-manager__header">
        <span className="conferences-manager__header-title">
          <Label size="h3" weight={300}>
            {t('Conferences Manager')}
          </Label>
          <IconButton icon="refresh" onClick={refetch} />
        </span>
        <span className="d-flex">
          <SearchBox value={searchTerm} onChange={handleChangeSearch} placeholder={t('search') as string} />
          <IconButton icon="add" onClick={openAddModal} />
        </span>
      </div>

      <div className={`conferences-manager__body ${gridState === 'panelOpen' ? 'show-panel2' : ''}`} >
        <div className="panel1">
          <div className="conferences-manager__calendar">
            <Box style={containerStyle} id='wtf'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  label="Week picker"
                  value={value}
                  onChange={handleChangeCalRange}
                  renderDay={renderWeekPickerDay}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="'Week of' MMM d"
                />
              </LocalizationProvider>
            </Box>
          </div>
          <div className="conferences-manager__list">
            {conferences?.length > 0 ? (
              <ul className='conference-item__list'>
                {sortConferencesByDate(filteredConferences)?.map((conference: any) => (
                  /* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
                  <li className={openConference?.id === conference.id ? 'conference-item-active' : 'conference-item'} role='button' tabIndex={-1} onClick={() => handleSelectConf(conference)} key={conference.id}>
                    <Label size='h4' weight={200} >{conference.conferenceLabel}</Label>
                    <Spacer value={5} />
                    <Label size='h6' weight={400}  >{moment(conference.conferenceDate).format('LLLL')}</Label>
                    <Label size='h6' weight={400} >{conference.description}</Label>
                  </li>
                ))}
              </ul>
            ) : (
              <div className='w-100 h-100 super-centered'>
                <Label size='h6'> No Conferneces scheduled this week</Label>
              </div>
            )}
          </div>
        </div>
        <div className="panel2">
          {openConference &&
            <ConferenceInfoColumn openConference={openConference} handleCloseConf={handleCloseConf} registry={registry} />
          }
        </div>
      </div>


      <Modal title='Add Conference' open={modal} onClose={closeAddModal}>
        <div>
          <DateTimePicker
            label="Date"
            value={values.conferenceDate}
            onChange={handleChangeDate}
          />
          <Dropdown
            name='type'
            label='Type'
            value={values.type}
            onChange={handleChangeArea}
          >
            <MenuItem value="pre-screening conference">Pre-Screening Conference</MenuItem>
            <MenuItem value="pre-MAB conference">Pre-MAB Conference</MenuItem>
          </Dropdown>
          <Spacer value={20} />
          <div className='btn-row'>
            <Button loading={isAdding} onClick={handleCreatConf}>Save</Button>
          </div>
        </div>
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

ConferencesManager.defaultProps = {
  className: '',
};

ConferencesManager.displayName = 'ConferencesManager';
export default ConferencesManager;
