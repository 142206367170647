/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Tile, Label, Text, Button, Spacer, Modal } from '../../atoms';
import FormItem from '../FormItem/FormItem';
import PrintForm from '../ProtocolPrintForm/ProtocolPrintForm';
import SurveyResponseTable from '../SurveyResponseTable/SurveyResponseTable';
import DocumentsDrive from '../DocumentsDrive/DocumentsDrive';
import './crf-forms.scss';
import { Snapshot } from '../../../store/snapshots';
import { useCreateSignedUrlMutation } from '../../../api/api';
import CRFFormAttachement from '../CRFFormAttachement/CRFFormAttachement';

export interface CRFFormsProps {
  userSurvey: any,
  setUserSurvey: (updatedItem: any) => void,
  onSubmit: (updatedItem: any) => void,
  saveProgress: (updatedItem: any) => void,
  editable?: boolean,
  enableAttachements?: boolean,
  info: any,
  item: any,
  path?: string | undefined,
  attachments?: any[];
}

const CRFForms = (props: CRFFormsProps): JSX.Element => {
  const { userSurvey, setUserSurvey, onSubmit, saveProgress, editable, enableAttachements, info, item, path } = props;
  const [printModal, setPrintModal] = useState(false);

  const [attachments, setAttachments] = useState<any>([]);

  const handleViewAllAnswer = (ans: any, index: number) => {
    const updatedSurvey = [...userSurvey];
    updatedSurvey[index].answer = ans;
    setUserSurvey(updatedSurvey);
  };

  const submitSurvey = () => {
    onSubmit(userSurvey);
  };

  const openSignPopup = () => {
    setPrintModal(true);
  };

  const handleSaveParticial = () => {
    saveProgress(userSurvey);
  };

  const addAttachment = () => {
    const updatedSurvey = { ...userSurvey };
    if (!updatedSurvey.attachments) {
      updatedSurvey.attachments = []; // Initialize the attachments array if it doesn't exist
    }
    updatedSurvey.attachments.push({ notes: 'note', filled: false });
    setUserSurvey(updatedSurvey);
  };

  const handleDrop = (e: any) => {
    const updatedattachments = [...attachments];
    updatedattachments.push({ notes: '', filled: true, file: e });
    setAttachments(updatedattachments);
  };

  return (
    <div className='crf-forms'>
      <Spacer value={20} />
      {userSurvey?.length > 0 ?
        <div>
          <div>
            {userSurvey.map((q: any, index: number) => (
              <div className='survey-view-all-item' >
                <FormItem
                  key={`survey-question-${q.id}`}
                  question={q}
                  onAnswer={(it: any) => handleViewAllAnswer(it, index)}
                  currentIndex={index}
                  editable={editable}
                />
                <Spacer value={20} />
              </div>
            ))}
          </div>
          {(editable && enableAttachements) && (
            <div className="attachments-row">
              {attachments?.map((attachment: any, index: number) => (
                <CRFFormAttachement
                  key={`attachment-${index}`}
                  notes={attachment.notes}
                  filled={attachment.filled}
                  file={attachment?.file} id={`crf-attch-${index}`} />
              ))}
              <CRFFormAttachement notes="note" filled={false} onDrop={handleDrop} id="crf-attch-def" />
            </div>
          )}
          <Spacer value={20} />
          {editable ?
            <div className='btn-row'>
              <Button size='wide' onClick={handleSaveParticial}>
                Save Progress
              </Button>
              <Button size='wide' onClick={openSignPopup}>
                Sign & Submit
              </Button>
            </div>
            :
            <div>
              <DocumentsDrive protocolId={item.id} />
            </div>
          }

          <Spacer value={30} />
          <Modal title='Print' size='lg' open={printModal} onClose={() => setPrintModal(false)}>
            <PrintForm
              protocolItem={item}
              info={info}
              path={path}
              submitSurvey={submitSurvey}
            >
              <>
                <SurveyResponseTable questions={userSurvey} noScore />
                <div className="attachments-row">
                  {attachments?.map((attachment: any, index: number) => (
                    <CRFFormAttachement
                      key={`attachment-${index}`}
                      notes={attachment.notes}
                      filled={attachment.filled}
                      className='print'
                      file={attachment?.file} id={`crf-attch-${index}`} />
                  ))}
                </div>
              </>
            </PrintForm>
          </Modal>
        </div>
        :
        <Label size='h4'> There are no questions for this survey </Label>
      }
    </div>
  );
};

CRFForms.defaultProps = {
  editable: true,
  enableAttachements: true,
};

CRFForms.displayName = 'CRFForms';
export default CRFForms;
