import React, { useState } from 'react';
import { Tile, Label, Button, Spacer } from '../../atoms';
import { List, Text, Scale, Number, Date, UserInfo, UserLocation, Height } from '../QuestionTypes';
import './question.scss';

export interface QuestionProps {
  question: any
  onAnswer: (ans: any) => void
  currentIndex: number,
  setListeningActive: (pl: boolean) => void,
  listeningActive: boolean,
}

const Question = (props: QuestionProps): JSX.Element => {
  const { question, onAnswer, currentIndex, setListeningActive, listeningActive } = props;
  const { answer, type, options, instructions, label } = question;
  const [answr, setAnswr] = useState(answer);

  const answerQuestion = (answers: any) => {
    onAnswer(answers);
    setAnswr(answers);
  };

  const renderOptions = (qType: string) => {
    // if (!qOtions || qOtions.length === 0) {
    //   return <div> There are no options for this question </div>;
    // }
    switch (qType) {
      case 'Boolean':
      case 'LIST_SINGLE':
        return options.map((option: any, index: number) =>
          <List
            key={`question-option-${option.order}`}
            option={option}
            onAnswer={answerQuestion}
            answer={answer}
          />,
        );
      case 'LIST_MULTI':
        return options.map((option: any, index: number) =>
          <List
            key={`question-option-${option.order}`}
            option={option}
            onAnswer={answerQuestion}
            answer={answer}
            multi
          />,
        );
      case 'USERINFO':
        return (
          <UserInfo
            key={`question-${currentIndex}`}
            onAnswer={answerQuestion}
            answer={answer}
            question={question}
          />
        );
      case 'HEIGHT':
        return (
          <Height
            key={`question-${currentIndex}`}
            onAnswer={answerQuestion}
            answer={answer}
            question={question}
          />
        );
      case 'USERLOCATION':
        return (
          <UserLocation
            key={`question-${currentIndex}`}
            onAnswer={answerQuestion}
            answer={answer}
            question={question}
          />
        );
      case 'HORIZONTAL_SCALE':
        // const optionHS = options.find((o: any) => o.min);
        return (
          <Scale
            answer={answer}
            key={`question-${currentIndex}`}
            onAnswer={answerQuestion}
          />
        );
      case 'DATE':
        return (
          <Date
            answer={answer}
            key={`question-${currentIndex}`}
            onAnswer={answerQuestion}
          />
        );
      case 'TEXT':
        return <Text
          key={`question-${currentIndex}`}
          answer={answer}
          onAnswer={answerQuestion}
          listeningActive={listeningActive}
          setListeningActive={setListeningActive}
          question={question}
          detect
        />;
      case 'NUMBER':
        return <Number
          key={`question-${currentIndex}`}
          answer={answer}
          onAnswer={answerQuestion}
          min={options[0].min && options[0].min}
          max={options[0].max && options[0].max}
        />;
      default: return <div>Unknown question type: {type}</div>;
    }
  };


  return (
    <div className="question__wrapper">
      <div className="label-container">
        <Label size="h4" center>{instructions} </Label>
        <Spacer value={10} />
        <Label size="h4" center>{label}</Label>
      </div>

      <Spacer value={30} />

      <div className="options-container">
        {renderOptions(type)}
      </div>
    </div>
  );
};

Question.defaultProps = {
};

Question.displayName = 'Question';
export default Question;
