/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import moment from 'moment';
import { Tile, Label, Spacer, Button, Modal } from '../../../atoms';
import { VisitT } from '../../../../types/types';
import sortVisits from '../../../../utils/fns/sortVisits';
import SurveyResponseTable from '../../SurveyResponseTable/SurveyResponseTable';

export interface SurveyScoresProps {
  visits: VisitT[] | [],
  patient: any,
  patientSurveys: any,
}


const SurveyScores = (props: SurveyScoresProps): JSX.Element => {
  const { visits, patient, patientSurveys } = props;
  const [currentSurvey, setCurrentSurvey] = useState<any>(null);
  const [scoreModal, setScoreModal] = useState(false);


  const openScoreModal = (survey: any) => {
    if (survey) {
      setCurrentSurvey(survey);
      setScoreModal(true);
    }
  };

  const closeScoreModal = () => {
    setCurrentSurvey(null);
    setScoreModal(false);
  };

  const renderSurveyScore = (index: number, surveys: any, visit: VisitT, scoreAccessor: string) => {
    try {
      let score: string | number = '';
      let color = '';
      let surveyTMP: any = null;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < surveys.length; i++) {
        if (surveys[i].status === 'completed' && surveys[i].sessionId && surveys[i].sessionId === visit.id) {
          if (surveys[i].score !== '') {
            const parsed = JSON.parse(surveys[i].score);
            // console.log('XXTT', surveys[i].score);
            // const parsed = surveys[i].score.map((el: any) => JSON.parse(el));
            const filtered = parsed.filter((el: any) => el.label && el.label.toLowerCase() === scoreAccessor.toLowerCase())[0];
            if (filtered) {
              score = parseInt(filtered.score, 10);
            }
            surveyTMP = {
              survey: surveys[i].surveyLabel,
              date: moment(surveys[i].showOn).format('L'),
              questions: surveys[i].questions,
              ...surveys[i],
            };
            // Breasi - 0-6 green, 7 - 14 yellow, above 14 = red
            // NNDIE - 0 - 9 green, 10-15 yellow, 16 + red
            // QOLIE - 0 - 39 bad, 40 - 69 yellow, 70 + green
            // PIES - 0- 25 GREEN, 26 - 75 yellow, 76 + red 
            if (typeof score === 'number') {
              if (scoreAccessor === 'total') {
                // NDDIE
                if (score <= 9) {
                  color = 'good';
                } else if (score <= 14) {
                  color = 'medium';
                } else if (score > 14) {
                  color = 'bad';
                }
              } else if (scoreAccessor === 'brEASI SUM') {
                // brEASI
                if (score <= 7) {
                  color = 'good';
                } else if (score <= 14) {
                  color = 'medium';
                } else if (score > 14) {
                  color = 'bad';
                }
              } else if (scoreAccessor === 'average score') {
                // QOLIE
                if (score <= 39) {
                  color = 'bad';
                } else if (score <= 69) {
                  color = 'medium';
                } else if (score > 69) {
                  color = 'good';
                }
              } else if (scoreAccessor === 'final score' ||
                scoreAccessor === 'seizures' ||
                scoreAccessor === 'adverse effects' ||
                scoreAccessor === 'Mood and Social Situation'
              ) {
                // PIES
                if (score <= 25) {
                  color = 'good';
                } else if (score <= 75) {
                  color = 'medium';
                } else if (score > 75) {
                  color = 'bad';
                }
              }
            }
          }
        }
      }
      return <td key={index} className={color} onClick={() => openScoreModal(surveyTMP)} >{score}</td>;
    } catch (e) {
      // console.log('e', e);
      return <td key={index} />;
    }
  };


  return (
    <>
      <tr>
        <th> <span className="seizure-th">NDDIE  (Depression) </span> </th>
        {
          sortVisits(visits).map((visit, index) => renderSurveyScore(index, patientSurveys.NDDIE, visit, 'total'))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">bREASI (Anxiety)</span> </th>
        {
          sortVisits(visits).map((visit, index) => renderSurveyScore(index, patientSurveys.BREASI, visit, 'brEASI SUM'))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">QOLIE  (Qual of Life)</span> </th>
        {
          sortVisits(visits).map((visit, index) => renderSurveyScore(index, patientSurveys.QOLIE, visit, 'average score'))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">PIES Total (Impact) </span> </th>
        {
          sortVisits(visits).map((visit, index) => renderSurveyScore(index, patientSurveys.PIES, visit, 'final score'))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">PIES Seizures (Impact) </span> </th>
        {
          sortVisits(visits).map((visit, index) => renderSurveyScore(index, patientSurveys.PIES, visit, 'seizures'))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">PIES Adv Effcts (Impact) </span> </th>
        {
          sortVisits(visits).map((visit, index) => renderSurveyScore(index, patientSurveys.PIES, visit, 'adverse effects'))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">PIES Mood-Social (Impact) </span> </th>
        {
          sortVisits(visits).map((visit, index) => renderSurveyScore(index, patientSurveys.PIES, visit, 'Mood and Social Situation'))
        }
      </tr>


      <Modal
        open={scoreModal}
        onClose={closeScoreModal}
        title="Survey info"
        size="sm"
      >
        <div className="">
          {currentSurvey ?
            <div>
              <Label size="h5" center weight={600}>{currentSurvey.survey}</Label>
              {/* <Label size="h6" center>Score: {currentSurvey.score}</Label> */}
              <Spacer value={10} />

              {currentSurvey.survey === 'brEASI' &&
                <div className='survey-scores-info'>
                  <Label size="h6" weight={300} uppercase center bold>bRAESI Scoring Color Labels:</Label>
                  <Label size="h6" weight={300} uppercase center>0 - 6 = green, 7 - 14 = yellow, above 14 = red</Label>
                </div>
              }

              {currentSurvey.survey === 'NDDIE' &&
                <div className='survey-scores-info'>
                  <Label size="h6" weight={300} uppercase center bold>NDDIE Scoring Color Labels:</Label>
                  <Label size="h6" weight={300} uppercase center>0 - 9 = green, 10 - 15 =  yellow, above 16 = red</Label>
                </div>
              }

              {currentSurvey.survey === 'QOLIE-10P' &&
                <div className='survey-scores-info'>
                  <Label size="h6" weight={300} uppercase center bold>QOLIE-10P Scoring Color Labels:</Label>
                  <Label size="h6" weight={300} uppercase center> 0 - 39 = red, 40 - 69 yellow, above 70 = green</Label>
                </div>
              }

              {currentSurvey.survey === 'PIES' &&
                <div className='survey-scores-info'>
                  <Label size="h6" weight={300} uppercase center>PIES Scoring Color Labels:</Label>
                  <Label size="h6" weight={300} uppercase center>0 - 25 = green, 26 - 75 = yellow, above 76 = red </Label>
                </div>
              }
            </div>
            :
            <Label size="h5" bold>Error Loading Survey Info</Label>
          }

          <Spacer value={10} />
          {currentSurvey &&
            <SurveyResponseTable
              questions={JSON.parse(currentSurvey?.questions)}
              survey={currentSurvey}
            />}

        </div>
      </Modal>
    </>
  );
};

SurveyScores.defaultProps = {
};

SurveyScores.displayName = 'SurveyScores';
export default SurveyScores;