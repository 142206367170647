/* eslint-disable react/no-array-index-key */
import React from 'react';
import { DatePicker, Label, Spacer, Button } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';
import SimpleSelect from '../../SimpleSelect/SimpleSelect';

export interface AssistantProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleCreateProfile: () => void,
  loading: boolean,
}

const Assistant = (props: AssistantProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleCreateProfile, loading } = props;

  // const answerQuestion = (value: any) => {
  // //add logic to check if the value, an array contains value

  //   handleChange((prev: any) => ({ ...prev, assistance: value }));
  // };


  const answerQuestion = (value: any) => {
    if (Array.isArray(state.assistance) && state.assistance.includes('No assistance needed')) {
      const filtered = value.filter((item: any) => item !== 'No assistance needed');
      handleChange((prev: any) => ({ ...prev, assistance: filtered }));
    } else if (Array.isArray(value) && value.includes('No assistance needed')) {
      handleChange((prev: any) => ({ ...prev, assistance: ['No assistance needed'] }));
    } else {
      handleChange((prev: any) => ({ ...prev, assistance: value }));
    }
  };



  const options = [
    { goto: 'next', label: 'Providing technical assistance such as logging in', order: 1, value: 'Providing technical assistance such as logging in' },
    { goto: 'next', label: 'Providing technical assistance such as keyboard entry of data', order: 2, value: 'Providing technical assistance such as keyboard entry of data' },
    { goto: 'next', label: 'Advising you how to answer questions', order: 2, value: 'Advising you how to answer questions' },
    { goto: 'next', label: 'No assistance needed', order: 2, value: 'No assistance needed', clear: true },
  ];

  const validate = () => {
    if (
      Array.isArray(state.assistance) &&
      state.assistance.length > 0 &&
      state.assistance[0].trim() !== ''
    ) {
      return false;
    }
    return true;
  };


  return (
    <div>
      <Label size="h3" bold color="primary" center>
        Do you have an assistant advising you on how to answer these questions? Select all that apply.
      </Label>
      <Spacer value={20} />
      {options.map((op, i) => (
        <SimpleSelect
          label={op.label}
          onSelect={answerQuestion}
          answer={state.assistance}
          withCheck
          multi
          key={i}
        />
      ))}

      <Spacer value={20} />
      <div className='btn-row'>
        <Button onClick={handlePrevStep} disabled={loading}>Previous</Button>
        <Button loading={loading} kind='secondary' disabled={validate()} onClick={handleCreateProfile}> Create Profile</Button>
      </div>
    </div>
  );
};

Assistant.defaultProps = {
};

Assistant.displayName = 'Assistant';
export default Assistant;
