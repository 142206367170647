/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Label } from '../../../components';



const Maintenance = () => {
  const somevar = 'Maintenance';
  
  return (
    <div className="super-centered h-100 w-100">
      <Label size='h1' color='ghost'>
        We'll be back soon!
      </Label>
      <Label size='h1' color='ghost'>
        Sorry for the inconvenience but we are performing some maintenance at the moment
      </Label>
    </div>
  );
};

export default Maintenance;
