/* eslint-disable import/no-cycle */
import React from 'react';
import './met-manager.scss';
import { Label, Spacer, Button, Modal, Alert, Spinner } from '../../atoms';
import MetItemDetails from '../MetItemDetails/MetItemDetails';
import MetReview from '../MetReview/MetReview';
import MetBaselineMRI from '../MetBaselineMRI/MetBaselineMRI';
import MetTable from '../MetTable/MetTable';
import MetProtocolStatus from '../MetProtocolStatus/MetProtocolStatus';
import { useGetparticipantTumorsQuery, useAddTumorAsMutation, useEditTumorAsMutation, useEditTumorMutation } from '../../../api/builders/met.api';
import MultiPanels from '../MultiPanels/MultiPanels';
import SmartViewer from '../SmartViewer/SmartViewer';
import CRFFormAttachement from '../CRFFormAttachement/CRFFormAttachement';
import { MetT, MetAssessmentT, AlertState, ApiError } from '../../../types/types';


export interface MetManagerProps {
  participant: string | undefined,
  itemId: string | undefined,
  visitId: string | undefined,
  itemStatus: string | undefined,
  handleChangeStatus: (item: string, status: string) => void,
  acl: string,
  clinicalStatus: string,
  corticosteroidUse: string,
  baselineMRI: any,
  handleChangeMetStats: (itemId: string, status: string, steroid: string) => void,
  role: string,
  info: any,
  path: string | undefined,
  protocolItem: any,
  attachments: any,
  setAttachments: (items: any) => void,
}

export interface FlagItem {
  user: string,
  userEmail: string,
  message: string,
  time: string,
  item: string,
}
export interface TypeError {
  status: number,
  data: { message: string },
}


const MetManager = (props: MetManagerProps) => {
  // PROPS
  const { participant, itemId, handleChangeStatus, acl, corticosteroidUse, handleChangeMetStats, clinicalStatus, visitId, baselineMRI, role, protocolItem, info, path, attachments, setAttachments } = props;

  // STATE
  const [current, setCurrent] = React.useState<MetT | null>(null);
  const [reviewMode, setReviewMode] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [error, setError] = React.useState({ open: false, message: '' });
  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'success',
  });

  // API HANDELRS
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetparticipantTumorsQuery({ participantId: participant });
  const [addTumorAs, { isLoading: isAddingTumor, isSuccess: isSuccessAdd, isError: isErrorAddingTumor, error: addErr }] = useAddTumorAsMutation();
  const [editTumor, { isLoading: isEditingTumorAs, isSuccess, isError: isErrorEditingTumorAs, error: editErr }] = useEditTumorMutation();
  const [editTumorAs, { isLoading: isEditingTumor, isSuccess: isSuccessEditAs, isError: isErrorEditingTumor, error: assessmentErr }] = useEditTumorAsMutation();


  const closeEdit = () => {
    setCurrent(null);
    setEditModal(false);
  };


  React.useEffect(() => {
    if (isErrorEditingTumor) {
      const apiError = assessmentErr as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Assessment: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorEditingTumor]);

  React.useEffect(() => {
    if (isErrorEditingTumorAs) {
      const apiError = editErr as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Assessment: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorEditingTumorAs]);

  React.useEffect(() => {
    if (isSuccess || isSuccessEditAs) {
      setAlert({
        open: true,
        message: 'Success Editing Assessment',
        kind: 'success',
      });
      closeEdit();
    }
  }, [isSuccess, isSuccessEditAs]);

  React.useEffect(() => {
    if (isErrorAddingTumor) {
      const apiError = addErr as ApiError;
      setAlert({
        open: true,
        message: `Error Adding Assessment: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAddingTumor]);

  React.useEffect(() => {
    if (isSuccessAdd) {
      setAlert({
        open: true,
        message: 'Success Deleting Assessment',
        kind: 'success',
      });
      closeEdit();
    }
  }, [isSuccessAdd]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const toggleReviewMode = () => {
    setReviewMode(!reviewMode);
  };

  const handleDrop = (e: any) => {
    const updatedattachments = [...attachments];
    updatedattachments.push({ notes: '', filled: true, file: e });
    setAttachments(updatedattachments);
  };


  const cancelReviewMode = () => {
    toggleReviewMode();
  };


  const submit = () => {
    if (itemId) {
      handleChangeStatus(itemId, 'pending review');
    }
  };

  const handleReviewMode = () => {
    toggleReviewMode();
  };


  const onChangeItem = (item: MetT) => {
    setCurrent(item);
    setEditModal(true);
  };

  const addTumorAssessmnt = async (payload: MetAssessmentT) => {
    try {
      const tumorPl = {
        id: current?.id,
        body: {
          isTreated: payload.isTreated,
          isTreatable: payload.isTreatable,
          RLCoord: payload.RLCoord,
          APCoord: payload.APCoord,
          SICoord: payload.SICoord,
          // volume: payload.volume,
        },
      };
      editTumor(tumorPl);
      // }
      if (current && current?.tumorassessments?.length > 0) {
        const findMatch = current?.tumorassessments.filter(it => it.protocolItemId === itemId);
        if (findMatch && findMatch.length > 0) {
          const editVals = {
            notes: payload.notes,
            status: payload.status,
            tumorId: payload.tumorId,
            protocolItemId: payload.protocolItemId,
            diameterAxial: payload.diameterAxial.toString(),
            diameterCoronal: payload.diameterCoronal.toString(),
            diameterSagittal: payload.diameterSagittal.toString(),
          };
          await editTumorAs({
            tumorassessmentId: findMatch[0].id,
            body: editVals,
          });
        } else {
          await addTumorAs(payload);
        }
      } else {
        await addTumorAs(payload);
      }
    } catch (e) {
      const err = e as TypeError;
      if (err?.status === 400) {
        setError({
          open: true,
          message: err.data.message,
        });
      }
    }
  };

  if (isLoadingData || isFetchingData) {
    return (
      <div className='w-100 h-100 super-centered'>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="met-manager">
      <Spacer value={20} />
      {reviewMode ?
        <MetReview
          mets={data}
          itemId={itemId}
          cancelReviewMode={cancelReviewMode}
          onChangeItem={onChangeItem}
          submit={submit}
          role={role}
          info={info}
          path={path}
          protocolItem={protocolItem}
        >
          {attachments?.map((attachment: any, index: number) => (
            <CRFFormAttachement
              // eslint-disable-next-line react/no-array-index-key
              key={`attachment-${index}`}
              notes={attachment.notes}
              filled={attachment.filled}
              fullWidth
              file={attachment?.file} id={`crf-attch-${index}`} />
          ))}
        </MetReview>
        :
        <div className="met-manager">
          <MultiPanels className="met-manager__panels_wrapper" id="met-panels-wrapper">
            <div className="met_panel_1">
              <MetBaselineMRI
                visitId={visitId}
                participant={participant}
                baselineMRI={baselineMRI}
                role={role}
              />
              <Spacer value={20} />
              <MetTable
                itemId={itemId}
                data={data}
                participant={participant}
                acl={acl}
                onChangeItem={onChangeItem}
                visitId={visitId}
                role={role}
                path={path}
                canUpload={protocolItem.item === 'Clinical MET Assessment'}
              />
              <Spacer value={20} />
              <Spacer value={20} />
              {(acl === 'admin' || acl === 'SITE COORDINATOR') &&
                <MetProtocolStatus
                  clinicalStatus={clinicalStatus}
                  corticosteroidUse={corticosteroidUse}
                  id={itemId}
                  handleChangeMetStats={handleChangeMetStats}
                />}
              <Spacer value={20} />
              <div className="attachments-row">
                {attachments?.map((attachment: any, index: number) => (
                  <CRFFormAttachement
                    // eslint-disable-next-line react/no-array-index-key
                    key={`attachment-${index}`}
                    notes={attachment.notes}
                    filled={attachment.filled}
                    file={attachment?.file} id={`crf-attch-${index}`} />
                ))}
                <CRFFormAttachement notes="note" filled={false} onDrop={handleDrop} id="crf-attch-def" />
              </div>
              <Spacer value={20} />
              <Spacer value={20} />
              <div className='btn-row'>
                {data && data.length > 0 && <Button onClick={handleReviewMode}>Review and Sign</Button>}
              </div>
            </div>
            <div className="met_panel_2">
              <SmartViewer id="metsmartv" visitId={visitId} item={itemId} />
            </div>
          </MultiPanels>

        </div>
      }

      <Modal open={editModal} onClose={closeEdit} title={`Edit ${current?.tumorLabel ? current.tumorLabel : 'MET'}`} >
        <div>
          {current != null ?
            <MetItemDetails
              visitId={visitId}
              role={role}
              acl={acl}
              met={current}
              protocolItemId={itemId}
              addTumorAssessmnt={addTumorAssessmnt}
              loading={isEditingTumor || isAddingTumor || isAddingTumor}
              error={error}
              baselineMRI={baselineMRI}
            />
            :
            <Label>Error Loading Met Details</Label>
          }
        </div>
      </Modal>


      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />

    </div >
  );
};

MetManager.defaultProps = {
  // key: '_key',
};

MetManager.displayName = 'MetManager';


export default MetManager;

