import React from 'react';
import './met-image-td.scss';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BsImage } from 'react-icons/bs';
import { IoTrashOutline } from 'react-icons/io5';
import { MetT, MetReportT, AlertState, ApiError } from '../../../types/types';
import { Label, Button, Modal, Alert, Spinner } from '../../atoms';
import { useUploadPicMutation, useDeletePicMutation } from '../../../api/builders/met.api';

export interface MetImageTDProps {
  met: MetT,
  className?: string,
  refetchTumors: () => any,
  path: string | undefined,
  canEdit: boolean,
}

const MetImageTD = (props: MetImageTDProps): JSX.Element => {
  const { className, met, refetchTumors, path, canEdit } = props;
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [loadingIMGS, setLoadingIMGS] = React.useState(false);
  const [filesToPreview, setFilstoPreview] = React.useState<MetReportT[] | null>(null);
  const [imgPrevModal, setImgPrevModal] = React.useState(false);
  const [getPUTSignedUrl, { isSuccess, isError, error }] = useUploadPicMutation();
  const [deleteImg, { isLoading: isDelImg, isError: isErrorDel, isSuccess: isisSuccessDel, error: delError }] = useDeletePicMutation();
  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'success',
  });

  const handleCloseImgPreview = () => {
    setFilstoPreview(null);
    setImgPrevModal(false);
  };


  React.useEffect(() => {
    if (isErrorDel) {
      const apiError = delError as ApiError;
      setAlert({
        open: true,
        message: `Error Deleting Image: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorDel]);

  React.useEffect(() => {
    if (isisSuccessDel) {
      setAlert({
        open: true,
        message: 'Success Deleting image',
        kind: 'success',
      });
      handleCloseImgPreview();
    }
  }, [isisSuccessDel]);



  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Adding Image: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success adding image',
        kind: 'success',
      });
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        refetchTumors()
          .then(() => {
            // Update the loading state once the query is completed
            setLoadingIMGS(false);
          });
      }, 3000); // 3000 milliseconds = 3 seconds

      return () => clearTimeout(timer); // Clear the timer if the component unmounts before 3 seconds
    }
  }, [isSuccess]);


  const uploadToS3 = async (url: string, file: any): Promise<boolean> => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': 'image/png' },
      });
      return response.ok;
    } catch (e2) {
      console.error('E2', e2);
      return false;
    }
  };


  const handleDrop = async (event: React.DragEvent<HTMLDivElement>, metTmp: any) => {
    setLoadingIMGS(true);
    event.preventDefault();
    const { dataTransfer } = event;
    if (dataTransfer.items) {
      // Modern browsers support items
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < dataTransfer.items.length; i++) {
        const item = dataTransfer.items[i];
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file && file.type === 'image/png') {
            const fileReader = new FileReader();
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            fileReader.onload = async () => {
              const fullPath = `${path}/${metTmp.id}/${file.name}`;
              const payload = {
                s3_path: fullPath,
                ContentType: file.type,
              };
              const filetmp = await getPUTSignedUrl({ files: [payload] }).unwrap();
              uploadToS3(filetmp[0].signedUrl, file);
            };
            fileReader.readAsDataURL(file);
            // onFileDrop(file);
            return; // Stop processing other items
          }
        }
      }
    } else {
      // Legacy browsers support files
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < dataTransfer.files.length; i++) {
        const file = dataTransfer.files[i];
        if (file.type === 'image/png') {
          const fileReader = new FileReader();
          fileReader.onload = async () => {
            const fullPath = `${path}/${metTmp.id}/${file.name}`;
            const payload = {
              s3_path: fullPath,
              ContentType: file.type,
            };
            const filetmp = await getPUTSignedUrl({ files: [payload] }).unwrap();
            uploadToS3(filetmp[0].signedUrl, file);
          };
          fileReader.readAsDataURL(file);
          // onFileDrop(file);
          return; // Stop processing other files
        }
      }
    }

  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleOpenImg = (imgs: any) => {
    setFilstoPreview(imgs);
    setImgPrevModal(true);
  };

  const handleDeleteImage = (img: any) => {
    try {
      const deletePl = {
        metId: met.id,
        fileId: img.id,
      };
      deleteImg(deletePl);
    } catch (e) {
      console.error(e);
    }
  };

  const renderTDcontent = () => {
    if (loadingIMGS) {
      return (
        <td className='met-manager__table-td imagetd__td' >
          <Spinner />
        </td>
      );
    }
    if (Array.isArray(met.metReport) && met.metReport.length > 0) {
      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <td className='met-manager__coords-td'
          onClick={() => handleOpenImg(met.metReport)}
          // onDragOver={handleDragOver}
          // onDragLeave={handleDragLeave}
          // onDrop={(event) => handleDrop(event, met)}
        >
          <BsImage />
        </td>
      );
      // eslint-disable-next-line no-else-return
    } else if (canEdit) {
      return (
        <td className='met-manager__table-td imagetd__td' >
          <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className={isDragOver ? 'imagetd__upload imagetd__drag-over' : 'imagetd__upload'}
            onDrop={(event) => handleDrop(event, met)}
          >
            <AiOutlineCloudUpload />
          </div>
        </td>
      );
    } else {
      return (
        <td className='met-manager__table-td' >
          <Label center size='subtext'>No uploads</Label>
        </td>
      );
    }
  };

  return (
    <>
      {renderTDcontent()}
      <Modal
        open={imgPrevModal}
        onClose={handleCloseImgPreview}
        title='Preview'
        size='lg'
      >
        <div className='met-table__image-prev'>
          {Array.isArray(filesToPreview) && filesToPreview.length > 0 ? (
            <div className='img-preview'>
              {filesToPreview.map((item: any, index) => (
                <div className='image-container' key={item.id}>
                  <img src={item.backgroundUrl} alt='scan-preview' />
                  {canEdit && <div className='overlay'>
                    <Button loading={isDelImg} kind='ghost' outline onClick={() => handleDeleteImage(item)}>
                      <IoTrashOutline />
                    </Button>
                  </div>}
                </div>
              ))}
            </div>
          ) : (
            <div className='btn-row'>
              <Label size='h5' color='ghost'>
                Nothing to Preview
              </Label>
            </div>
          )}
        </div>
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </>
  );
};

MetImageTD.defaultProps = {
  className: '',
};

MetImageTD.displayName = 'MetImageTD';
export default MetImageTD;
