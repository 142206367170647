import QueryString from 'qs';
import api from '../api';

export const patientScans = api.injectEndpoints({
  endpoints: (builder) => ({
    getImagesByPatient: builder.query({
      query: (patientId) => `/files/images/patient/${patientId}`,
      providesTags: ['PATIENTSCANS'],
      transformResponse: (response: any) => response.items,
    }),
    getPatientColdFilesArray: builder.query({
      query: (seriesId) => `/files/patient/cold/files/${seriesId}`,
      providesTags: ['PATIENTSCANS'],
    }),
  }),
});

export const {
  usePrefetch,
  useGetImagesByPatientQuery,
  useGetPatientColdFilesArrayQuery,
} = patientScans;
