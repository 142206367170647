import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Label, Logo, Spacer } from '../../../components/atoms';
import { TextInput } from '../../../components/molecules';
import { Credentials, selectAuth, loginUser, logout } from '../../../store/authentication/slice';
import api from '../../../api/api';
import { AlertState } from '../../../types/types';


const Login = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'error',
  });

  const [state, setState] = React.useState({
    username: '',
    password: '',
    code: '',
    status: '',
  });

  const { token, error } = useSelector(selectAuth);



  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'error',
    });
  };


  React.useEffect(() => {
    if (error) {
      setAlert({
        open: true,
        message: `Error Signing in: ${error}`,
        kind: 'error',
      });
    }
  }, [error]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  useEffect(() => {
    if (token) {
      navigate('/research');
    }
  }, [token]);

  useEffect(() => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
  }, []);

  const onSubmit = async (credentials: Credentials) => {
    setState((prev) => ({ ...prev, 'status': 'loading' }));
    await dispatch(loginUser(credentials));
    // navigate(-2);
    setState((prev) => ({ ...prev, 'status': 'loggedin' }));
    // add timeout
  };


  const goToForgot = () => {
    navigate('/forgot-password');
  };

  const goToResend = () => {
    navigate('/re-verify');
  };

  const goToCreate = () => {
    navigate('/create-account');
  };


  return (
    <div className="hsg-login">
      <Logo size='350px' />
      <Spacer value={20} />
      <div className='hsg-login__form-wrapper'>
        <Label center size='h4'>Please Sign In</Label>
        <Spacer value={10} />
        <TextInput
          id="username"
          type="Email"
          autocomplete="Email"
          value={state.username}
          placeholder='Email'
          label='Email'
          onChange={onChange}
        />
        <Spacer value={10} />
        <TextInput
          id="password"
          type="password"
          value={state.password}
          autocomplete="current-password"
          placeholder='Password'
          label='Password'
          onChange={onChange}
        />
        <Spacer value={20} />
        <div className='btn-row'>
          <Button loading={state.status === 'loading'} outline kind='secondary' onClick={() => onSubmit(state)} >Sign In</Button>
        </div>
        <Spacer value={30} />
        <div className='btn-row'>
          <Button kind='ghost' onClick={goToForgot} >
            FORGOT PASSWORD
          </Button>
        </div>
        <Spacer value={5} />
        <div className='btn-row'>
          <Button kind='ghost' onClick={goToResend} >
            PLEASE SEND ME ANOTHER VERIFICATION CODE
          </Button>
        </div>
        <Spacer value={5} />
        <div className='btn-row'>
          <Button kind='ghost' onClick={goToCreate} >
            Create Account
          </Button>
        </div>
      </div>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />

    </div>
  );
};

export default Login;
