/* eslint-disable no-nested-ternary */
// RegistryPatient.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Label } from '../../atoms';
import './registry-patient.scss';
import { CarePathway, PathwayAnalysis, analyzePatientPathway } from '../../../utils/pathways/patientPathwayUtils';

export interface RegistryPatientProps {
  className?: string;
  patient: any; // Replace 'any' with the proper type for your patient data
  registry: any; // Replace 'any' with the proper type for your registry data
}

const RegistryPatient: React.FC<RegistryPatientProps> = ({ patient, registry }) => {
  const navigate = useNavigate();
  const { mrn, firstname, lastname } = patient;

  const onOpen = () => {
    navigate('/private/registries/registry/patient/overview', { state: { patient, registry } });
  };

  // Initialize pathway analysis
  let pathwayAnalysis = null;
  if (Array.isArray(patient.patientcarepathway) && patient.patientcarepathway.length > 0) {
    const carePathwayData: CarePathway = patient.patientcarepathway[0].carepathway;
    pathwayAnalysis = analyzePatientPathway(carePathwayData);
  }

  return (
    <tr role="button" tabIndex={-1} onClick={onOpen} className="care-pathway-tracker__table__row">
      {/* Other cells of the row */}
      {/* Display patient information and analysis results here */}
      <td>
        <Label size="h5" center weight={200}>
          {mrn}
        </Label>
      </td>
      <td>
        <Label size="h5" center weight={200}>
          {firstname} {lastname}
        </Label>
      </td>
      <td>
        <Label size="h5" weight={200} center>
          {(pathwayAnalysis && pathwayAnalysis?.name) || 'None'}
        </Label>
      </td>
      <td>
        <Label size="h5" weight={200} center>
          {pathwayAnalysis
            ? pathwayAnalysis?.firstStepStartDate !== 'Not started'
              ? moment(pathwayAnalysis?.firstStepStartDate, 'MM/DD/YYYY').format('L')
              : pathwayAnalysis?.firstStepStartDate
            : '-'}
        </Label>
      </td>
      <td>
        <Label size="h5" weight={200} center>
          {pathwayAnalysis && pathwayAnalysis ? `${pathwayAnalysis?.weeksSinceStart}` : '-'}
        </Label>
      </td>
      <td>
        <Label size="h5" weight={200} center>
          {pathwayAnalysis ? `${pathwayAnalysis?.progress.toFixed(2)} %` : '-'}
        </Label>
      </td>
      <td>
        <Label size="h5" weight={200} center>
          {pathwayAnalysis &&  pathwayAnalysis?.nextStep?.description || 'None'}
        </Label>
      </td>
      <td>
        {((pathwayAnalysis && pathwayAnalysis?.nextStepStatus) === 'Not scheduled' ) ? (
          <Label size="h5" weight={600} center color="warning" bold>
            {pathwayAnalysis && pathwayAnalysis?.nextStepStatus}
          </Label>
        ) : (
          <Label size="h5" weight={300} center color="success">
            {pathwayAnalysis && pathwayAnalysis?.nextStepStatus || 'Pending'}
          </Label>
        )}
      </td>
    </tr>
  );
};

RegistryPatient.defaultProps = {
  className: '',
};

export default RegistryPatient;
