/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { MenuItem } from '@mui/material';
import { Tile, Label, Button, Spacer, Dropdown } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import './question-types.scss';


export interface HeightProps {
  answer: any,
  onAnswer: (vals: any) => void,
  question: any,
}

const Height = (props: HeightProps): JSX.Element => {
  const { answer, onAnswer, question } = props;
  const [feet, setFeet] = useState(0);
  const [inches, setInches] = useState(0);


  const onChangeFt = (event: any) => {
    setFeet(event.target.value);
    const ans = `${event.target.value}'${inches}`;
    onAnswer([{ value: ans, label: ans }]);
  };

  const onChangeIn = (event: any) => {
    setInches(event.target.value);
    const ans = `${feet}'${event.target.value}`;
    onAnswer([{ value: ans, label: ans }]);
  };

  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {

      const tmp = props?.answer[0].value.split("'");
      setFeet(tmp[0]);
      setInches(tmp[1]);
    }
  }, [question.id]);

  const ftOps = ['3', '4', '5', '6', '7'];
  const inOps = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];

  return (
    <div className='question__height'>
      <Spacer value={15} />

      <span className='w-100'>
        <Label className="input__label" size="h5" weight={600} color='primary'>
          Feet
        </Label>
        <Dropdown
          onChange={onChangeFt}
          value={feet}
        >
          {ftOps.map((it: string) => <MenuItem value={it} >{it} </MenuItem>)}
        </Dropdown>
      </span>

      <Spacer value={10} />

      <span className='w-100'>
        <Label className="input__label" size="h5" weight={600} color='primary'>
          Inches
        </Label>
        <Dropdown
          onChange={onChangeIn}
          value={inches}
        >
          {inOps.map((it: string) => <MenuItem value={it} >{it} </MenuItem>)}
        </Dropdown>
      </span>

    </div>
  );
};

Height.defaultProps = {
};

Height.displayName = 'Height';
export default Height;
