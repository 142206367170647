import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Label, Spacer } from '../../../atoms';

export interface ThankYouProps {
  state: any,
}

const ThankYou = (props: ThankYouProps): JSX.Element => {
  const { state } = props;
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/profile');
  };
  const goToHome = () => {
    navigate('/research');
  };


  return (
    <div>
      <Label size="h1" center color="primary" >Thank You for Registering</Label>
      <Spacer value={30} />
      <Label size="h4" color="primary" center>Your profile has been created and you’re all set to start participating!</Label>
      <Spacer value={30} />
      <div className="d-flex">
        <Label size="text" bold>First Name:</Label>
        <Spacer value={10} />
        <Label size="text">{state.firstname}</Label>
      </div>
      <div className="d-flex">
        <Label size="text" bold>Last Name:</Label>
        <Spacer value={10} />
        <Label size="text">{state.surname}</Label>
      </div>
      <div className="d-flex">
        <Label size="text" bold>Last Name at Birth:</Label>
        <Spacer value={10} />
        <Label size="text">{state.lastNameAtBirth}</Label>
      </div>
      <div className="d-flex">
        <Label size="text" bold>Sex at birth:</Label>
        <Spacer value={10} />
        <Label size="text">{state.gender ? state.gender[0] : state.genderOther}</Label>
      </div>
      <div className="d-flex">
        <Label size="text" bold>Mothers Maiden last name:</Label>
        <Spacer value={10} />
        <Label size="text">{state.mother}</Label>
      </div>
      <div className="d-flex">
        <Label size="text" bold>Date of Birth:</Label>
        <Spacer value={10} />
        <Label size="text">{state.dob}</Label>
      </div>
      <div className="d-flex">
        <Label size="text" bold>Zip Code:</Label>
        <Spacer value={10} />
        <Label size="text">{state.zip}</Label>
      </div>
      <div className="d-flex">
        <Label size="text" bold>Country:</Label>
        <Spacer value={10} />
        <Label size="text">{state.country}</Label>
      </div>
      <Spacer value={30} />
      <div className='btn-row'>
        <Button onClick={goToProfile} kind="secondary" >Edit Profile</Button>
        <Spacer value={10} />
        <Button onClick={goToHome} kind="secondary" >Get Started</Button>
      </div>
    </div>
  );
};

ThankYou.defaultProps = {
};

ThankYou.displayThankYou = 'ThankYou';
export default ThankYou;
