/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsExclamationCircle } from 'react-icons/bs';
import { IconButton, Modal, Alert } from '../../../atoms';
import { VisitT, SeizureT } from '../../../../types/types';
import sortVisits from '../../../../utils/fns/sortVisits';
import { useDeleteSeizureRecordMutation } from '../../../../api/builders/epilepsy.api';
import getSZstring from '../../../../utils/fns/getSZstring';
import SeizureFrequencyForm from '../../SeizureFrequencyForm/SeizureFrequencyForm';
import DeleteVerification from '../../DeleteVerification/DeleteVerification';

export interface SeizuresProps {
  visits: VisitT[] | [],
  patient: any,
  seizures: SeizureT[] | [],
}

const sortByDate = (data: SeizureT[]) => {
  try {
    const clone = JSON.parse(JSON.stringify(data));

    const sorted = clone.sort((a: any, b: any )=> {
      if (a.active === 'null' || a.active === true) {
        if (b.active === 'null' || b.active === true) {
          return new Date(b.createdon).getTime() - new Date(a.createdon).getTime();
        }
        return -1; // 'a' comes first as 'b' is false
      } if (b.active === 'null' || b.active === true) {
        return 1; // 'b' comes first as 'a' is false
      }
      return new Date(b.createdon).getTime() - new Date(a.createdon).getTime();
    });
    return sorted;
  } catch (e) {
    console.error(e);
    return data; // Return the original data if an error occurs
  }
};


type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}



const Seizures = (props: SeizuresProps): JSX.Element => {
  const { visits, patient, seizures } = props;
  const [visit, setVisit] = useState<VisitT | null>(null);
  const [openSeizure, setOpenSeizure] = useState<SeizureT | null>(null);
  const [seizureFreqModal, setSeizureFreqModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<SeizureT | null>(null);
  const [deleteSz, { isLoading, isSuccess, isError, error }] = useDeleteSeizureRecordMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });



  const closeDelete = () => {
    setDeleteModal(false);
    setItemToDelete(null);
  };

  const openDeleteSz = (sz: SeizureT) => {
    setDeleteModal(true);
    setItemToDelete(sz);
  };

  const handleDeleteSz = (item: SeizureT) => {
    deleteSz(item.id);
  };


  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Deleting Seizure: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Deleting Seizure',
        kind: 'success',
      });
      closeDelete();
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };



  const handleShowSeizure = (vst: VisitT, sz: SeizureT) => {
    setSeizureFreqModal(true);
    setOpenSeizure(sz);
    setVisit(vst);
  };

  const handleCloseSeizure = () => {
    setSeizureFreqModal(false);
    setOpenSeizure(null);
    setVisit(null);
  };


  const renderSeizureTd = (index: number, sz: SeizureT, vst: VisitT) => {
    try {
      let count = '';
      let color = '';
      if (sz.historyOfSeizureCount && sz.historyOfSeizureCount.length > 0) {
        const tmp = JSON.parse(sz.historyOfSeizureCount);
        // console.log("tmp", tmp)
        const bingo = tmp.filter((el: any) => el.visitDate === vst.date)[0];
        if (bingo) {
          // count = bingo.
          if (bingo.uknownNumber || bingo.unknownNumber) {
            count = '> 0';
          } else if (bingo.timeUnit === 'Day') {
            count = (bingo.count * 30).toString();
          } else if (bingo.timeUnit === 'Week') {
            count = (bingo.count * 4).toString();
          } else if (bingo.timeUnit === 'Month') {
            count = bingo.count;
          } else if (bingo.timeUnit === 'Year') {
            count = (bingo.count / 12).toFixed(4);
          } else if (bingo.timeUnit === 'Since last visit') {
            let num = 0;
            if (visits?.length > 1) {
              const idx = visits.findIndex(el => el.date[0] === vst.date[0]);
              const previous = visits[idx - 1];
              if (previous && previous !== undefined) {
                const date1 = new Date(vst.date[0]);
                const date2 = new Date(previous.date[0]);
                const differenceInTime = date1.getTime() - date2.getTime();
                const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
                num = differenceInDays / 30;
              }
            }
            if (num !== 0) {
              count = (bingo.count / num).toFixed(2);
            } else {
              count = '0';
            }
          }
        }
      }
      if (count === '') {
        color = '';
      } else if (Number(count) > 1) {
        color = 'bad';
      } else if (Number(count) > 0 || count === '> 0') {
        color = 'medium';
      } else {
        color = 'good';
      }
      const latest = visits.length - 1;
      const isMostRecent = visits[latest];

      // console.log('isMostRecent', isMostRecent);
      if (sz.active === false && isMostRecent.id === vst.id) {
        return <td key={index} className="exclamation" onClick={() => handleShowSeizure(vst, sz)}>
          Inactive
        </td>;
      }
      if (count === '') {
        return <td key={index} className="exclamation" onClick={() => handleShowSeizure(vst, sz)}>
          <BsExclamationCircle />
        </td>;
      }
      return <td key={index} className={color} onClick={() => handleShowSeizure(vst, sz)}>
        {count}
      </td>;
    } catch (e) {
      console.error(e);
    }
    return <td key={index} >poop</td>;
  };

  return (
    <>
      {
        seizures && seizures.length > 0 && (
          sortByDate(seizures).map((el: any) => <tr key={el.id}
          >
            <th> <span className="seizure-th">
              <span>
                {el.patientLabel}: {' '}
                {console.log('patientLabel', el.patientLabel)}
                {getSZstring(el.classification + el.onset + el.awareness + el.field)}
              </span>
              <IconButton icon='trash' onClick={() => openDeleteSz(el)} />

            </span> </th>
            {
              sortVisits(visits).map((vis, indx) => renderSeizureTd(indx, el, vis))
            }
          </tr>)
        )
      }

      <Modal open={seizureFreqModal} title='open sz' onClose={handleCloseSeizure}>
        <SeizureFrequencyForm
          visit={visit}
          visits={visits}
          openSeizure={openSeizure}
          patient={patient}
          onClose={handleCloseSeizure}
        />
      </Modal>

      <Modal title="Delete Medication" open={deleteModal} onClose={() => setDeleteModal(false)} >
        <DeleteVerification
          item={itemToDelete}
          onDelete={handleDeleteSz}
          loading={isLoading}
          name={`Seizure ${itemToDelete?.patientLabel}`}
          onCancel={closeDelete}
        />
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </>
  );
};

Seizures.defaultProps = {
};

Seizures.displayName = 'Seizures';
export default Seizures;
