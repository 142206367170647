import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import './seizure-type-form.scss';
import TextInput from '../TextInput/TextInput';
import { Spacer, Label, Dropdown, Checkbox, Button } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';

export interface SeizureTypeFormProps {
  className?: string,
  seizureVals: any,
  setSeizureVals: any,
}

const initialSeizure = {
  patientLabel: '',
  classification: '',
  onset: '',
  awareness: '',
  yearOfOnset: '',
  description: '',
  dateOfLastKnownSeizure: new Date(),
  count: 0,
  other: '',
  historyOfSeizureCount: '',
  NonEpilepticOption: '',
  active: true,
};


const SeizureTypeForm = (props: SeizureTypeFormProps): JSX.Element => {
  const { seizureVals, setSeizureVals } = props;
  // const [seizureVals, setSeizureVals] = useState(initialSeizure);

  const handleValChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeizureVals({
      ...seizureVals,
      [event.target.name]: event.target.value,
    });
  };
  const handleAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSeizureVals({
      ...seizureVals,
      [event.target.name]: event.target.value,
    });
  };

  const handleActiveChange = () => {
    setSeizureVals({
      ...seizureVals,
      active: !seizureVals.active,
    });
  };

  const setEpileptic = () => {
    setSeizureVals({
      ...seizureVals,
      classification: 'Epileptic',
      NonEpilepticOption: '',
      other: '',
    });
  };

  const setNonEpileptic = () => {
    setSeizureVals({
      ...seizureVals,
      classification: 'NonEpileptic',
      onset: '',
      awareness: '',
    });
  };

  const setunknown = () => {
    setSeizureVals({
      ...seizureVals,
      classification: 'unknown',
      NonEpilepticOption: '',
      other: '',
      onset: '',
      awareness: '',
    });
  };

  const getTypeArray = (onset: string) => {
    switch (onset) {
      case 'Focal Onset':
        return ['Aware', 'Impaired awareness', 'Focal to Bilateral Tonic Clonic'];
      case 'Generalized Onset':
        return ['Absence', 'Motor', 'Epileptic Spasm', 'Tonic-Clonic'];
      case 'Unknown':
        return ['Motor', 'Non Motor', 'Tonic-Clonic'];
      default:
        return [];
    }
  };

  const onsetOps = ['Focal Onset', 'Generalized Onset', 'Unknown'];


  return (
    <div className='seizure-type-form'>
      <Label
        size='subtext'
        uppercase
      >
        Patient Label
      </Label>
      <TextInput
        name="patientLabel"
        id="label"
        value={seizureVals.patientLabel}
        onChange={handleValChange}
      />
      <Spacer value={15} />

      <Label size='subtext'
        uppercase >Classification</Label>
      <Spacer value={5} />
      <div className="_make-row">
        <div
          role='button'
          tabIndex={-1}
          className={
            seizureVals.classification === 'Epileptic'
              ? 'left-toggle-btn view-toggle-btn-active'
              : 'left-toggle-btn view-toggle-btn'
          }
          onClick={setEpileptic}
        >
          <Label
            size="subtext"
            color={seizureVals.classification === 'Epileptic' ? 'primary' : 'ghost'}
            uppercase
          >
            Epileptic
          </Label>
        </div>
        <div
          role='button'
          tabIndex={-1}
          className={
            seizureVals.classification === 'NonEpileptic'
              ? 'mid-toggle-btn view-toggle-btn-active'
              : 'mid-toggle-btn view-toggle-btn'
          }
          onClick={setNonEpileptic}
        >
          <Label
            size="subtext"
            color={seizureVals.classification === 'NonEpileptic' ? 'primary' : 'ghost'}
            uppercase
          >
            NonEpileptic
          </Label>
        </div>
        <div
          role='button'
          tabIndex={-1}
          className={
            seizureVals.classification === 'unknown'
              ? 'right-toggle-btn view-toggle-btn-active'
              : 'right-toggle-btn view-toggle-btn'
          }
          onClick={setunknown}
        >
          <Label
            size="subtext"
            color={seizureVals.classification === 'unknown' ? 'primary' : 'ghost'}
            uppercase
          >
            unknown
          </Label>
        </div>
      </div>
      <div>
        <Spacer value={10} />
        {seizureVals.classification === 'Epileptic' && (
          <div className="_make-row">
            <span className='col-50'>
              <Label
                size='subtext'
                uppercase
              >
                Onset Category
              </Label>
              <Dropdown
                name="onset"
                label=''
                value={seizureVals.onset}
                onChange={handleValChange}
              >
                {onsetOps.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Dropdown>
            </span>
            <span className='col-50'>
              <Label
                size='subtext'
                uppercase
              >
                Type
              </Label>
              <Dropdown
                name="awareness"
                label=''
                value={seizureVals.awareness}
                onChange={handleValChange}
              >
                {getTypeArray(seizureVals.onset).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Dropdown>
            </span>
          </div>
        )}

        {seizureVals.classification === 'NonEpileptic' && (
          <div>
            <Dropdown
              name="NonEpilepticOption"
              value={seizureVals.NonEpilepticOption}
              onChange={handleValChange}
            >
              <MenuItem value="Psychogenic - VEEG confirmed">Psychogenic - VEEG confirmed</MenuItem>
              <MenuItem value="Psychogenic - Not VEEG confirmed">Psychogenic - Not VEEG confirmed</MenuItem>
              <MenuItem value="Sleep">Sleep</MenuItem>
              <MenuItem value="Syncope">Syncope</MenuItem>
              <MenuItem value="Unknown">Unknown</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
              <MenuItem value="Behavioral Event">Behavioral Event</MenuItem>
              <MenuItem value="Migraine">Migraine</MenuItem>
            </Dropdown>

            {seizureVals.NonEpilepticOption === 'Other' && (
              <>
                <Label
                  size='subtext'
                  uppercase
                >
                  other
                </Label>
                <TextInput
                  name=""
                  id="other"
                  placeholder="Please describe Other"
                  value={seizureVals.other}
                  onChange={handleValChange}
                />
              </>
            )}
          </div>
        )}
      </div>
      <Spacer value={15} />
      <Label
        size='subtext'
        uppercase
      >
        Year of Onset
      </Label>
      <TextInput
        label=""
        name="yearOfOnset"
        id="yearOfOnset"
        value={seizureVals.yearOfOnset}
        onChange={handleValChange}
      />
      <Spacer value={15} />
      <Label
        size='subtext'
        uppercase
      >
        description
      </Label>
      <TextAreaInput
        name="description"
        label=""
        id="description"
        value={seizureVals.description}
        onChange={handleAreaChange}
        rows={3}
      />
      <Spacer value={15} />
      <span className='_make-row'>
        <Checkbox checked={seizureVals.active} onChange={handleActiveChange} />
        <Label
          size='subtext'
          uppercase
        >
          active
        </Label>
      </span>
    </div>
  );
};

SeizureTypeForm.defaultProps = {
  className: '',
};

SeizureTypeForm.displayName = 'SeizureTypeForm';
export default SeizureTypeForm;
