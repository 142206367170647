import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { FiDownload } from 'react-icons/fi';
import { FaArrowRightLong } from 'react-icons/fa6';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import './study-detaisl.scss';
import { StudyT, SurveyNewT } from '../../../types/hsgTypes';
import { Label, Button, Modal, Spacer, Spinner } from '../../atoms';
import { useGetUsersurveyFeedQuery, useUpdateConsentMutation } from '../../../api/builders/hsg.api';
import TextInput from '../TextInput/TextInput';
import UserSurveyItem from '../UserSurveyItem/UserSurveyItem';
import copyAndSortSurveys from '../../../utils/fns/copyAndSortSurveys';
import isSurveyClickable from '../../../utils/fns/isSurveyClickable';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

export interface StudyDetaislProps {
  study: StudyT,
  className?: string,
  userDocuments: string;
}

const surveysToComplete = (_surveys: SurveyNewT[]) => {
  let tmp = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < _surveys.length; i++) {
    if (_surveys[i].status && _surveys[i].status === 'pending') {
      tmp += 1;
    }
  }
  return tmp;
};


const StudyDetaisl = (props: StudyDetaislProps): JSX.Element => {
  const { study, userDocuments } = props;
  const [revokeConsentModal, setRevokeConsentModal] = useState(false);
  const [updateConsent, { isLoading: isEditing }] = useUpdateConsentMutation();
  const [reason, setReason] = useState('');
  const [watch, setWatch] = useState(false);
  const { data: surveys, isLoading } = useGetUsersurveyFeedQuery(study?.id ?? skipToken);
  const [showConfetti, setShowConfetti] = useState(false);


  const reConsenUser = async () => {
    try {
      const payload = {
        'studyId': study.id,
        'status': 'consented',
        'reason': reason,
      };
      await updateConsent(payload);
    } catch (e) {
      console.error(e);
    }
  };


  const confrimRevoke = async () => {
    try {
      const payload = {
        'studyId': study.id,
        'status': 'revoked',
        'reason': reason,
      };
      await updateConsent(payload);
      setRevokeConsentModal(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onChangeReason = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setReason(e.target.value);
  };



  const renderUserStatus = (status: string) => {
    switch (status) {
      case 'consented':
        return (
          <span className='d-flex'>
            {userDocuments && userDocuments !== '' &&
              <a href={userDocuments} target="_blank" download rel="noreferrer">
                <FiDownload size={20} className='study-details___btn' />
              </a>
            }
            <Button className='study-details__btn' loading={isEditing} kind='ghost' onClick={() => setRevokeConsentModal(true)}>
              Enrolled
            </Button>
          </span>
        );
      case 'revoked':
        return (
          <span className='d-flex'>
            {userDocuments && userDocuments !== '' &&
              <a href={userDocuments} target="_blank" download rel="noreferrer">
                <FiDownload size={20} className='study-details___btn' />
              </a>
            }
            <Button className='study-details__btn' loading={isEditing} kind='ghost' onClick={reConsenUser}>
              Not Enrolled
            </Button>
          </span>
        );
      default:
        return (<div />);
    }
  };

  const handleSurveyCompletion = () => {
    setShowConfetti(true);
    // You might also perform any other actions related to survey completion here
  };
  const onClose = () => {
    setShowConfetti(false);
    // You might also perform any other actions related to survey completion here
  };

  useEffect(() => {
    if (surveys && surveys.length > 0) {
      const check = surveysToComplete(surveys);
      if (check === 0) {
        handleSurveyCompletion();
      }
    }
  }, [surveys]);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (showConfetti) {
      timer = setTimeout(() => {
        onClose();
      }, 5000); // Close after 4 seconds
    }
    return () => clearTimeout(timer);
  }, [showConfetti]);


  const confettiProps = {
    width: window.innerWidth,
    height: window.innerHeight,
    gravity: 0.2, // Increase gravity for faster fall
    numberOfPieces: 500, // Increase number of particles
    recycle: false, // Do not recycle particles
  };


  return (
    <div className='study-details'>
      <div className='study-details__header'>
        <span>
          <Label size="h1" weight={200} color="primary"  >
            {study?.title && study.title}
          </Label>
          <Spacer value={20} />
          {JSON.parse(study.description[0]).map((name: any) => (
            <div className="d-flex-row" key={name}>
              <Label size="h4" color="default"> {name} </Label>
            </div>
          ))}
          <Spacer value={30} />

          {study?.status[0] !== 'closed' &&
            <>
              {renderUserStatus(study.consentStatus[0])}
            </>
          }
        </span>

        {Array.isArray(study?.media) && study?.media.length > 0 && study?.media[0].length > 0 && <span className='aside'>
          <Button size='small' onClick={() => setWatch(true)}>Watch Intro <FaArrowRightLong color='white' className='research__icon' /></Button>
        </span>}
      </div>

      {showConfetti && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999 }}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }} />
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1001 }}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Confetti {...confettiProps} />
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1002 }}>
              <Label size="h1" center color="white" weight={600}>Congratulations!</Label>
              <Spacer value={10} />
              <Label size="h4" center color="white" weight={600}>
                You have completed the  {study?.title && study.title} Study!
              </Label>
            </div>
          </div>
        </div>
      )}

      <div className='study-details__body'>
        {isLoading ?
          <div className='super-centered'>
            <Spinner />
          </div>
          :
          <div>
            {Array.isArray(surveys) && surveys.length > 0 &&
              <>
                <div className='d-flex'>
                  <Spacer value={15} />
                  <Label italic color="primary" >Please select each survey in the order they appear.</Label>
                </div>
                {copyAndSortSurveys(surveys).map((survey: SurveyNewT) => (
                  <UserSurveyItem
                    key={survey.id}
                    survey={survey}
                    disabled={isSurveyClickable(survey, copyAndSortSurveys(surveys))} // Pass whether the survey is clickable
                  />
                ))}
              </>
            }
          </div>
        }
      </div>

      <Modal open={revokeConsentModal} onClose={() => setRevokeConsentModal(false)} title="Revoke your Consent">
        <div>
          <Spacer value={15} />
          <TextInput
            id="reason"
            label="Reason"
            value={reason}
            onChange={onChangeReason}
          />
          <Spacer value={20} />
          <div className="super-centered">
            <Button disabled={isEditing} onClick={confrimRevoke}>
              revoke
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={watch} onClose={() => setWatch(false)} title="Study Guide">
        <VideoPlayer url={Array.isArray(study?.media) && study?.media.length > 0 ? study.media[0] : ''} />
      </Modal>
    </div>
  );
};

StudyDetaisl.defaultProps = {
  className: '',
};

StudyDetaisl.displayName = 'StudyDetaisl';
export default StudyDetaisl;
