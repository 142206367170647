/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable  @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
// import classnames from 'classnames';
import './audit-dicom.scss';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import { IconBaseProps } from 'react-icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import DicomViewer2 from '../DicomViewer2/DicomViewer2';
import { Label, Text, Spacer, Tile, Button, Checkbox, Spinner } from '../../atoms';
import { useGetAuditColdFileArrayQuery } from '../../../api/builders/participantFiles.api';
import { useCreateFindingMutation, useGetFindingByImageQuery, useUpdateImageReviewMutation } from '../../../api/builders/hives.api';
import DicomTable from '../DicomTable/DicomTable';
import AuditRadioButtons from '../AuditRadioButtons/AuditRadioButtons';
import DicomAuditActionBar from '../DicomAuditActionBar/DicomAuditActionBar';

export interface AuditDicomProps {
  series: any,
  audit: any,
  readOnly: boolean,
  auditStarted: () => void,
}
//        

const initStack: IStack = {
  currentImageIdIndex: 0,
  currentImageIndex: 0,
  imageIds: [],
};

interface ISerie {
  id: string | undefined;
}

interface IStack {
  currentImageIdIndex: number;
  currentImageIndex: number;
  imageIds: string[];
}


const getPayload = (st: any) => {
  const variable = 'filler';
  return st.replace(/\./g, '_');
};



const AuditDicom = (props: AuditDicomProps): JSX.Element => {
  const { series, audit, readOnly, auditStarted } = props;
  const [currentStack, setCurrentStack] = useState(initStack);
  const [dicomFile, setDicomFile] = useState<string>();
  const [addFinding] = useCreateFindingMutation();
  const [currentSerie, setCurrentSerie] = useState<ISerie>();
  const coldfiles = useGetAuditColdFileArrayQuery({ id: currentSerie ? getPayload(currentSerie.id) : skipToken });


  useEffect(() => {
    if (series) {
      setCurrentSerie(series);
    }
  }, [series]);

  useEffect(() => {
    if (coldfiles && coldfiles?.data && coldfiles?.data?.Keys) {
      const midFrame = Math.floor(coldfiles.data.Keys.length / 2);
      const headerfile = coldfiles.data.Keys[0];

      const stack = {
        currentImageIdIndex: midFrame,
        currentImageIndex: midFrame,
        imageIds: coldfiles.data.Keys.map((file: any) => `wadouri:${file}`),
      };
      // const file = `wadouri:${coldfiles.data.Keys[0]}`;
      setDicomFile(headerfile);
      setCurrentStack(stack);
    }
  }, [coldfiles.data]);

  const check = () => {
    if (audit.status === 'pending') {
      auditStarted();
    }
  };

  const handleSubmitFinding = (values: any) => {
    try {
      check();
      const payload = {
        ...values,
        auditId: audit.id,
        seriesId: series.id,
      };
      addFinding(payload);
    } catch (e) {
      console.error('err saving findings');
    }
  };


  return (
    <div className="audit-dicom">
      <Tile className="audit-dicom__top-panel">
        <>
          <div className="audit-dicom__pannel1">
            <DicomViewer2 id='smart-dicom-v' viewerId="dicom-audit-viewer" stack={currentStack} prefix={`audit-${series?.id}`} series={series} />
            <div className="audit-dicom__img-action">
              {(series.auditStatus === 'incomplete' || readOnly) &&
                <>
                  <Label className="audit-dicom__text">Image Audit : </Label>
                  <AuditRadioButtons onsubmit={handleSubmitFinding} status={0} id="dicomauditbuttons" />
                </>
              }
            </div>
          </div>
          <div className="audit-dicom__pannel2">
            <DicomTable
              id="dicomheadertable"
              dicomFileUrl={dicomFile}
              handleSubmitFinding={handleSubmitFinding}
              readOnly={series?.auditStatus !== 'incomplete' || readOnly}
             />
          </div>
        </>
      </Tile>


      <Tile className='audit-dicom__action-row'>
        <DicomAuditActionBar               check={check}
 audit={audit.id} series={series} readOnly={readOnly} />
      </Tile>
    </div>
  );
};

AuditDicom.defaultProps = {
};

AuditDicom.displayName = 'AuditDicom';
export default AuditDicom;
