import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPatientColdFilesArrayQuery } from '../../../api/builders/patientScans.api';
import './patient-scan-preview.scss';
import DicomViewer2 from '../DicomViewer2/DicomViewer2';
import DicomViewer from '../DicomViewer/DicomViewer';
import { Spinner } from '../../atoms';

export interface PatientScanPreviewProps {
  series: any,
  className?: string,
}

const initStack: IStack = {
  currentImageIdIndex: 0,
  currentImageIndex: 0,
  imageIds: [],
};

interface ISerie {
  id: string | undefined;
}

interface IStack {
  currentImageIdIndex: number;
  currentImageIndex: number;
  imageIds: string[];
}


const getPayload = (st: any) => {
  const variable = 'filler';
  return st.replace(/\./g, '_');
};


const PatientScanPreview = (props: PatientScanPreviewProps): JSX.Element => {
  const { series } = props;
  const [currentStack, setCurrentStack] = useState(initStack);
  const [currentSerie, setCurrentSerie] = useState<any>(null);
  const { data: coldfiles, isLoading, isFetching } = useGetPatientColdFilesArrayQuery(currentSerie ? getPayload(currentSerie.ScanSeries[0].id) : skipToken);


  useEffect(() => {
    if (series) {
      setCurrentSerie(series);
    }
  }, [series]);


  useEffect(() => {
    if (coldfiles && coldfiles?.Keys) {
      const midFrame = Math.floor(coldfiles.Keys.length / 2);
      const headerfile = coldfiles.Keys[0];

      const stack = {
        currentImageIdIndex: midFrame,
        currentImageIndex: midFrame,
        imageIds: coldfiles.Keys.map((file: any) => `wadouri:${file}`),
      };
      // const file = `wadouri:${coldfiles.data.Keys[0]}`;
      setCurrentStack(stack);
    }
  }, [coldfiles]);

  return (
    <div className="card">
      {isLoading || isFetching ?
        <div className='patient-scan-preview__center'>
          <Spinner />
        </div>
        :
        <DicomViewer2 id='smart-dicom-v' viewerId="dicom-audit-viewer" stack={currentStack} prefix={`audit-${series?.id}`} series={series.ScanSeries[0]} />
      }
    </div>
  );
};

PatientScanPreview.defaultProps = {
  className: '',
};

PatientScanPreview.displayName = 'PatientScanPreview';
export default PatientScanPreview;
