import { SurveyNewT } from '../../types/hsgTypes';

const copyAndSortSurveys = (array: SurveyNewT[]): SurveyNewT[] => {
  // Deep copy the array
  const copiedArray = JSON.parse(JSON.stringify(array));

  // Sort the copied array
  copiedArray.sort((a: SurveyNewT, b: SurveyNewT) => parseInt(a.order, 10) - parseInt(b.order, 10));

  return copiedArray;
};

export default copyAndSortSurveys;