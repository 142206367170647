import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Modal, Label, Spacer, Button } from '../../atoms';
import './epilepsy-epic-form.scss';
import getSZstring from '../../../utils/fns/getSZstring';
import trim from '../../../utils/fns/trim';

export interface EpilepsyEpicFormProps {
  className?: string,
  open: boolean,
  onClose: () => void,
  diagnosis: any,
  seizures: any,
  meds: any,
  visit: any,
}

interface DiagnosticCategory {
  date: string;
  diagnostic_category: string;
}

interface SeizureCount {
  visitDate: string[];
  count: number;
  timeUnit: string;
  uknownNumber: boolean;
}

interface MedicationHistory {
  date: string;
  TDADescription?: string;
  BNO: boolean;
  compliance: string;
  longActing: boolean;
  LA: boolean;
  amount: string;
  frequency: string;
  notes: string;
}

const EpilepsyEpicForm = (props: EpilepsyEpicFormProps): JSX.Element => {
  const { open, onClose, diagnosis, seizures, visit, meds } = props;
  const [info, setInfo] = useState<Record<string, string | null>>({});

  useEffect(() => {
    try {
      const arr: Record<string, string | null> = {};

      if (Array.isArray(diagnosis?.history) && diagnosis?.history.length > 0 && visit) {
        const tmp: DiagnosticCategory[] = JSON.parse(diagnosis.history);
        const index = tmp.findIndex((el) => el.date === visit.date);

        if (index !== -1) {
          arr.Diagnosis = tmp[index].diagnostic_category.toString();
        }
      }

      if (Array.isArray(seizures) && seizures.length > 0 && visit) {
        // Sort seizures by order
        const sortedSeizures = seizures.slice().sort((a, b) => a.order - b.order);

        sortedSeizures.forEach((seizure, i) => {
          if (seizure?.historyOfSeizureCount && seizure?.historyOfSeizureCount.length > 0) {
            const tmp: SeizureCount[] = JSON.parse(seizure.historyOfSeizureCount);
            const bingo = tmp.find((el) => el.visitDate === visit.date);

            if (bingo) {
              const label = `Seizure${i + 1}`;
              if (bingo.uknownNumber) {
                const string = `${seizure.classification} ${seizure.onset} ${seizure.awareness} ${seizure.field}`;
                const shorthand = getSZstring(string);
                const stringg = `'${seizure.patientLabel}' ${shorthand}. ${seizure.description}. : >0`;
                arr[label] = stringg;
              } else {
                const string = `${seizure.classification} ${seizure.onset} ${seizure.awareness} ${seizure.field}`;
                const shorthand = getSZstring(string);
                const stringg = `'${seizure.patientLabel}' ${shorthand}. ${bingo.count} / ${bingo.timeUnit}. ${seizure.description}`;
                arr[label] = stringg;
              }
            } else {
              const label = `Seizure${i + 1}`;
              // If there is no information for this visit date, show an error message
              arr[label] = 'Missing';
            }
          }
        });
      }

      if (Array.isArray(meds) && meds.length > 0 && visit) {
        // Sort medications by order
        const sortedMeds = meds.slice().sort((a, b) => a.order - b.order);

        sortedMeds.forEach((medication, i) => {
          if (medication?.active && medication?.history && medication?.history.length > 0) {
            const tmp: MedicationHistory[] = JSON.parse(medication.history);
            const bingo = tmp.find((el) => el.date === visit.date);

            if (bingo) {
              const label = `Medication${i + 1}`;
              if (bingo.frequency === 'Total Daily Amount') {
                const tda = bingo.TDADescription ? bingo.TDADescription : '';
                const bno = bingo.BNO ? ' BNO' : '';
                const compliance = (bingo.compliance && bingo?.compliance !== 'null') ? `Compliance: ${bingo.compliance}` : '';
                const long = bingo.longActing || bingo.LA ? ' LA' : '';
                arr[label] = `${meds[i].substance}: ${tda}${bno}${long} ${bingo.notes} ${compliance}`;
              } else {
                const bno = bingo.BNO ? ' BNO' : '';
                const long = bingo.longActing || bingo.LA ? ' LA' : '';
                const compliance = meds[i].compliance && meds[i].compliance !== 'null' ? `Compliance: ${meds[i].compliance}` : '';
                arr[label] = `${meds[i].substance}: ${bingo.amount}mg ${bingo.frequency}${bno}${long} ${bingo.notes} ${compliance}`;
              }
            } else {
              const label = `Medication${i + 1}`;
              // If there is no information for this visit date, show an error message
              arr[label] = 'Missing';
            }
          }
        });
      }

      setInfo(arr);
    } catch (e) {
      console.error('CLINICEPILEPSYRENDERERROR::', e);
    }
  }, [open]);


  const submit = async () => {
    const questions = [
      {
        id: '1f619d05-e574-4f29-aa7a-43da6b5af114',
        label: 'Diagnosis:',
        answer: info?.Diagnosis ? trim(info.Diagnosis, 1072) : '',
      },
      {
        id: 'b6a34985-b7a7-453b-b97b-22df9f583e29',
        label: 'Seizure 1:',
        answer: info?.Seizure1 ? trim(info.Seizure1, 1072) : '',
      },
      {
        id: 'eae95949-c8b8-4358-b498-cffc27850735',
        label: 'Seizure 2:',
        answer: info?.Seizure2 ? trim(info.Seizure2, 1072) : '',
      },
      {
        id: '40634f22-8c30-4650-a7a3-7739a4a10881',
        label: 'Seizure 3:',
        answer: info?.Seizure3 ? trim(info.Seizure3, 1072) : '',
      },
      {
        id: '830969c4-4541-477d-ade8-e9234df4981b',
        label: 'Seizure 4:',
        answer: info?.Seizure4 ? trim(info.Seizure4, 1072) : '',
      },
      {
        id: '557fbfb0-5e86-4143-971e-1c98f2d19977',
        label: 'Seizure 5:',
        answer: info?.Seizure5 ? trim(info.Seizure5, 1072) : '',
      },
      {
        id: 'a11a09f1-7b28-4321-ab35-c34a27273ab2',
        label: 'Seizure 6:',
        answer: info?.Seizure6 ? trim(info.Seizure6, 1072) : '',
      },
      {
        id: 'fef327c6-1b09-49c1-a02b-e967554ad17c',
        label: 'Seizure 7:',
        answer: info?.Seizure7 ? trim(info.Seizure7, 1072) : '',
      },
      {
        id: 'e7dc990b-8c78-4dd2-9148-e11a17ff3e9a',
        label: 'Seizure 8:',
        answer: info?.Seizure8 ? trim(info.Seizure8, 1072) : '',
      },
      {
        id: 'be3f638b-319a-4645-a06b-3c759a123b59',
        label: 'Seizure 9:',
        answer: info?.Seizure9 ? trim(info.Seizure9, 1072) : '',
      },
      {
        id: 'ce91b183-6a38-4d90-aa88-58f1eb31d8d3',
        label: 'Seizure 10:',
        answer: info?.Seizure10 ? trim(info.Seizure10, 1072) : '',
      },
      {
        id: 'a44309f0-a31a-431a-816a-ebf1f3016752',
        label: 'Medication 1:',
        answer: info?.Medication1 ? trim(info.Medication1, 1072) : '',
      },
      {
        id: 'ccb9c5e4-0e21-4c1f-922f-368bfd72b27d',
        label: 'Medication 2:',
        answer: info?.Medication2 ? trim(info.Medication2, 1072) : '',
      },
      {
        id: '3e323d32-53e7-49d2-b9b9-0d4304aad9e6',
        label: 'Medication 3:',
        answer: info?.Medication3 ? trim(info.Medication3, 1072) : '',
      },
      {
        id: '58d45441-8473-40e4-8d62-2b89185b82b2',
        label: 'Medication 4:',
        answer: info?.Medication4 ? trim(info.Medication4, 1072) : '',
      },
      {
        id: '849b19b1-1045-42fc-835e-8dd93ce0630e',
        label: 'Medication 5:',
        answer: info?.Medication5 ? trim(info.Medication5, 1072) : '',
      },
      {
        id: '846f22d7-a4b3-4cc7-84be-b2adec30c7ac',
        label: 'Medication 6:',
        answer: info?.Medication6 ? trim(info.Medication6, 1072) : '',
      },
      {
        id: '88084bfb-9f95-431e-9492-f8c066a6317f',
        label: 'Medication 7:',
        answer: info?.Medication7 ? trim(info.Medication7, 1072) : '',
      },
      {
        id: 'e6ac5942-fce6-475a-aad5-72821ad3b59c',
        label: 'Medication 8:',
        answer: info?.Medication8 ? trim(info.Medication8, 1072) : '',
      },
      {
        id: '071d95f9-c255-47a3-88d9-6848a5e67a54',
        label: 'Medication 9:',
        answer: info?.Medication9 ? trim(info.Medication9, 1072) : '',
      },
      {
        id: '97c7ee93-562c-42ba-80dd-887d34b591e0',
        label: 'Medication 10:',
        answer: info?.Medication10 ? trim(info.Medication10, 1072) : '',
      },
      {
        id: 'afd3b652-3067-44e9-b084-f6886c3d7008',
        label: 'Medication 11:',
        answer: info?.Medication11 ? trim(info.Medication11, 1072) : '',
      },
      {
        id: '8cc515c0-727e-4f32-b184-1b7622ee320e',
        label: 'Medication 12:',
        answer: info?.Medication12 ? trim(info.Medication12, 1072) : '',
      },
      {
        id: 'f6b63770-3fdb-4cb6-ae8d-b8b5e74967c1',
        label: 'Medication 13:',
        answer: info?.Medication13 ? trim(info.Medication13, 1072) : '',
      },
      {
        id: '2297fdd8-20a5-408e-bf6f-c693122eaae7',
        label: 'Medication 14:',
        answer: info?.Medication14 ? trim(info.Medication14, 1072) : '',
      },
      {
        id: '97cc7bae-0755-45ed-a2cc-2a083f0e95d0',
        label: 'Medication 15:',
        answer: info?.Medication15 ? trim(info.Medication15, 1072) : '',
      },
    ];

  };


  const handleCheck = () => {
    const todayTMP = new Date();
    const today = moment(todayTMP).format('L');
    if (moment(visit.date).format('L') === today) {
      return false;
    }
    return true;
  };


  return (
    <Modal open={open} onClose={onClose} title='Send Vist to Epic'>
      {info && Object.keys(info).length !== 0 ?
        <div>

          {Object.keys(info).map((keyName, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>
              <div className='d-flex' >
                <Label size='h6' bold> {`${keyName}: `} </Label>
                <Spacer value={5} />
                {info[keyName] === 'Missing' ?
                  <Label size='h6' color='danger'> <AiOutlineExclamationCircle color='red' /> Missing Information for this visit</Label>
                  :
                  <Label size='h6'> {info[keyName]} </Label>
                }
              </div>
              <Spacer value={15} />
            </div>

          ))}
          <div className='text-center'>
            <Button disabled={handleCheck()} onClick={submit}>Submit</Button>
          </div>
        </div>
        :
        <div>
          <Spacer value={20} />
          <Label center size='h6' bold> No information available for most recent visit date </Label>
        </div>
      }

    </Modal >
  );
};

EpilepsyEpicForm.defaultProps = {
  className: '',
};

EpilepsyEpicForm.displayName = 'EpilepsyEpicForm';
export default EpilepsyEpicForm;
