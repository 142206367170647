// ********* PRODUCTION VARIABLES ************ //
// surveys
const DVPRSsurvey = 'eb1f4900-2207-41d1-aa27-e141d705bd6f';
const PAINMAPSURVEY = 'a1613b4b-de93-4527-81fe-a559b76ec35e';
const PASTORTREATMENT = 'cb3940d9-8a49-4d8c-a2b6-d87f9aaf7817';
const PASTORACTIVIES = 'a163f78a-2862-429a-8ceb-31c9fcb1e118';
const PASTORPTSD = '447f20c0-7f90-4588-8ec6-6710aba20c41';
const PASTORNEUROPATH = 'e39b07f9-5ccf-4b4d-bcd6-e04f0d71041a';
const PASTORPCS = '7ab99e5c-3431-4073-8737-18288e98f786';
const SUBSTANCECRAVING = '0294513b-d545-4986-bc5c-a38d9fe7c9d6';
const ALCOHOL = '';
const EQ5D5L = '2459feea-edea-4c46-92e3-d4d52fc50436';
const ORTHOSTATICSV3 = '47069880-7677-4bdc-abf9-65581e8e67d3';
const BIOMUSEVITALSIGNS = '842dcef1-78f5-4ab2-a20d-7e07020096d3';
const HARTFORDPREVISIT = 'b98b1556-6a46-4864-a683-f2f0ec93e3dc';
const NDDIE = '3f4701da-3c35-45ad-a5d8-ea48f7c72a1f';
const GAD7 = '50d19a20-ea02-478c-ba17-6062d551f187';

// survey questions
const PAINMAPQUESTION = '869152cb-636c-4210-a8d6-cd7965bb9188';

// dvprs survey questions
const SLEEP = 'a95b2c1f-31c1-4ca0-9431-9629ada1ab8c';
const ACTIVITY = '27ee226c-6e47-412e-a739-4ad4f6c7bbab';
const PAIN = '81c7ce27-c825-462c-9f01-3e9411c3f5d0';
const MOOD = '16ea93fc-d0e9-4059-9ab4-2bc2b7e4f5fe';
const STRESS = '8d5c5ec2-44a2-46dc-ac94-db14b0f29a88';

// treatment survey questions
const injectionsWhen = 'd2e40d7a-dac5-40b3-8bec-df66a5bfee47';
const injectionsEffectiveness = '43c60217-4261-4497-8571-039830355be2';
const orthopedicWhen = 'f0bce600-3535-4332-b5e4-030edefb1b27';
const orthopedicEffectiveness = '4a5b20d3-98a6-4c24-b440-0009a9a18f92';
const behavioralEffectiveness = 'dac75ac7-cc20-48be-ac6e-f6434a087218';
const behavioralWhen = '385f5428-46f8-4515-b2d8-e5d9e67ba8da';
const physicalEffectiveness = '5034c582-0ead-453c-8c45-32a8e004ef09';
const physicalWhen = '2f3400f0-3f3e-4b0e-8b62-8564fdab45c1';
const physTheraphyWhen = 'c8536b80-c250-482e-bdf9-590ecfab80d1';
const physTheraphyEffectiveness = 'f36685d9-170e-470e-8134-6d69f2f7d407';
const integrativeWhen = '11f82ed3-f4a2-47dc-aa55-9e1bfccfdd6a';
const integrativeEffectiveness = '5baf4941-2cf6-4f8e-bb1d-78eb0a51f704';
const muscleRelEffectiveness = 'c2977984-51a7-4c6c-959b-bc0332375379';
const muscleRelWhen = 'e70e3fbf-0bcf-487c-90ed-4d94a8ca610e';
const opioidMedEffectiveness = '8d7b78e8-ab33-4fc1-a0de-0b0826ee4554';
const opioidMedWhen = 'c18e6c68-484c-446f-a1b6-888ef9af1dc4';
const painMedsEffectiveness = '74f37997-e082-4cf4-bdcb-a57647a37e6c';
const painMedsWhen = '325dc033-d508-41b6-9d7b-492710203aef';
const antiInflamWhen = '5dc1446e-ab89-4e07-91c9-6521263a203e';
const antiInflamEffectiveness = 'e0834f78-ff27-4e48-98b1-23626d60ca54';

// HARTFORD PRE VISIT SURVEY QUESTIONS
const hhcWorking = 'b087bb88-09d3-45e5-a1c9-bf51736b5581';
const hhcEducationLevel = 'df58318a-509a-4e72-8fc0-4acea93a0c9b';
const hhcStudentStatus = '23238f6d-4f89-4b4c-9511-59824db4042d';
const hhcLivingSituation = '79648b28-1cc9-4704-a0de-f3498348de20';
const hhcDriversLicense = 'e071aea8-e0bc-473c-a2a3-9a3834ba5dd6';
const hhcActivelyDrives = '3ca2b55d-6395-48db-98b9-24061232dd4d';
const hhcVisitConcern = '9852ed59-c6b0-4c27-9a3f-3b3fb3600c00';

const PROD = {
  SLEEP,
  ACTIVITY,
  PAIN,
  MOOD,
  STRESS,
  DVPRSsurvey,
  PAINMAPSURVEY,
  PASTORTREATMENT,
  PAINMAPQUESTION,
  injectionsWhen,
  orthopedicWhen,
  behavioralEffectiveness,
  physicalEffectiveness,
  physicalWhen,
  integrativeWhen,
  painMedsEffectiveness,
  muscleRelWhen,
  integrativeEffectiveness,
  physTheraphyWhen,
  behavioralWhen,
  injectionsEffectiveness,
  antiInflamWhen,
  opioidMedEffectiveness,
  antiInflamEffectiveness,
  physTheraphyEffectiveness,
  painMedsWhen,
  opioidMedWhen,
  muscleRelEffectiveness,
  orthopedicEffectiveness,
  PASTORACTIVIES,
  PASTORPTSD,
  PASTORNEUROPATH,
  PASTORPCS,
  SUBSTANCECRAVING,
  ALCOHOL,
  EQ5D5L,
  ORTHOSTATICSV3,
  BIOMUSEVITALSIGNS,
  HARTFORDPREVISIT,
  NDDIE,
  GAD7,
  hhcWorking, hhcEducationLevel, hhcStudentStatus, hhcLivingSituation, hhcDriversLicense, hhcActivelyDrives, hhcVisitConcern,
};


// ********* DEVELOPMENT VARIABLES ************ //
// surveys
const DVPRSsurveyDEV = '5f3162e2-3d8f-4ba5-812a-316632899af7';
const PAINMAPSURVEYDEV = 'ecaff507-fb93-4eab-b207-8406b285093d';
const PASTORTREATMENTDEV = '1c95c0e0-50c1-4ac5-b6c1-34ed2dc02c16';
const PASTORACTIVIESDEV = '959f74eb-3434-475f-ac6b-36604a32b13e';
const PASTORPTSDDEV = '';
const PASTORNEUROPATHDEV = '';
const PASTORPCSDEV = '';
const SUBSTANCECRAVINGDEV = '';
const ALCOHOLDEV = 'b9f944f2-a720-4418-babd-9658f640997b';
const EQ5D5LDEV = '';

// survey questions
const PAINMAPQUESTIONDEV = '68af4e1a-f3ca-4d89-910c-d399cfbbb111';

// dvprs survey questions
const SLEEPDEV = 'b7406427-bad5-4fcd-96fe-718e143671a8';
const ACTIVITYDEV = '83b2efb3-57b8-4155-8cbd-2191662314c0';
const PAINDEV = '2f27a9cc-ab54-4a9f-a601-94800120c903';
const MOODDEV = 'a5b7014a-d24c-4d62-801f-243f092cdfb5';
const STRESSDEV = '9d3135ce-7d1f-412e-b09f-b875d330e64c';

// treatment survey questions  -- don't need these anymore, changed the way treatment card is rendered 1/8/21
const injectionsWhenDEV = 'a723c146-8962-48c9-920e-13a0cebd88c3';
const injectionsEffectivenessDEV = '717adf91-8a47-4ad7-8363-75de2fecf06c';
const orthopedicWhenDEV = '96e8ce90-fc82-4593-b407-29c0d5b1b826';
const orthopedicEffectivenessDEV = 'c41ff415-cfa3-4305-a45c-96da12836713';
const behavioralEffectivenessDEV = 'b992c940-faa1-433b-98d1-481201308123';
const behavioralWhenDEV = 'ae98133b-54a6-482d-a3c2-3bbeabeb661e';
const physicalEffectivenessDEV = '35915d18-0906-4a70-92db-cd196168a8da';
const physicalWhenDEV = '16738454-b23a-487e-b1cf-e20e0c13ad7e';
const physTheraphyWhenDEV = '8ea4fa59-8869-40b1-b398-998cbd4129a0';
const physTheraphyEffectivenessDEV = 'b780c55e-fa01-4b57-8de0-75b57da0e0bc';
const integrativeWhenDEV = 'b0d173db-5377-47be-ba8f-4c396f2bd160';
const integrativeEffectivenessDEV = 'f234e24f-8f3c-4279-9617-950103bcfe75';
const muscleRelEffectivenessDEV = '8f1bc561-a1cf-4e2a-87e2-b7ceff3bbc22';
const muscleRelWhenDEV = '85f79742-91c1-4fe1-a4dd-bf0d7c285102';
const opioidMedEffectivenessDEV = '17e6d279-afaa-4421-8009-ef6e207c536d';
const opioidMedWhenDEV = 'ad031caf-b9a3-4b03-9cb6-e03aabf56e32';
const painMedsEffectivenessDEV = '75aef871-fd29-4896-92a8-b4a0ea407e36';
const painMedsWhenDEV = '392c6f70-bff2-42c9-af1e-cdc04486a5c4';
const antiInflamWhenDEV = 'b29e12c8-8fd6-49d0-9e81-f8390c286b08';
const antiInflamEffectivenessDEV = 'c4e16149-1a8f-4d7a-aaca-e525d7821e46';

const DEV = {
  SLEEPDEV,
  ACTIVITYDEV,
  PAINDEV,
  MOODDEV,
  STRESSDEV,
  DVPRSsurveyDEV,
  PAINMAPSURVEYDEV,
  PASTORTREATMENTDEV,
  PAINMAPQUESTIONDEV,
  injectionsWhenDEV,
  orthopedicWhenDEV,
  behavioralEffectivenessDEV,
  physicalEffectivenessDEV,
  physicalWhenDEV,
  integrativeWhenDEV,
  painMedsEffectivenessDEV,
  muscleRelWhenDEV,
  integrativeEffectivenessDEV,
  physTheraphyWhenDEV,
  behavioralWhenDEV,
  injectionsEffectivenessDEV,
  antiInflamWhenDEV,
  opioidMedEffectivenessDEV,
  antiInflamEffectivenessDEV,
  physTheraphyEffectivenessDEV,
  painMedsWhenDEV,
  opioidMedWhenDEV,
  muscleRelEffectivenessDEV,
  orthopedicEffectivenessDEV,
  PASTORACTIVIESDEV,
  PASTORPTSDDEV,
  PASTORNEUROPATHDEV,
  PASTORPCSDEV,
  SUBSTANCECRAVINGDEV,
  ALCOHOLDEV,
  EQ5D5LDEV,
};


const epilepsyMeds = ['Brivaracetam', 'Cannabidiol oral solution', 'Carbamazepine', 'Cenobamate', 'Clobazam', 'Clonazepam', 'Diazepam Nasal', 'Diazepam Rectal', 'Divalproex Sodium', 'Eslicarbazepine', 'Ethosuximide', 'Ezogabine', 'Felbamate', 'Fenfluramine', 'Gabapentin', 'Lacosamide', 'Lamotrigine', 'Levetiracetam', 'Lorazepam', 'Midazolam Nasal', 'Oxcarbazepine', 'Perampanel', 'Phenobarbital', 'Phenytoin', 'Pregabalin', 'Primidone', 'Rufinamide', 'Stiripentol', 'Tiagabine', 'Topiramate', 'Valproic Acid', 'Vigabatrin', 'Zonisamide'];

const medFrequencyOps = [
  'PRN', 'Once Daily', 'BID', 'TID', 'QID', 'Total Daily Amount',
];


const EpilepsySurgeryOps = ['Resection', 'Ablation', 'VNS - Vagus nerve stimulator', 'DBS - Deep Brain stimulator', 'RNS - Responsive neurostimulator', 'Stereotactic Radiosurgery (Gamma Knife)', 'Corpus Callosotomy', 'Hemispherectomy', 'Multiple Subpial transections', 'Arachnoid Cyst fenestration/ removal'];

const ResectionPathologyOps = ['Focal Cortical Dysplasia', 'Mild microdysgenesis', 'Heterotopic cortex', 'Tumor', 'Gliosis', 'Vascular malformation'];


export {
  PROD,
  DEV,
  epilepsyMeds,
  medFrequencyOps,
  ResectionPathologyOps,
  EpilepsySurgeryOps,
};



