/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { BiRefresh } from 'react-icons/bi';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import './epilepsy-alerts.scss';
import moment from 'moment';
import { Tile, Label, Spacer, Button } from '../../atoms';
import { useGetAllPatientSurveysQuery, epilepsyApi } from '../../../api/builders/epilepsy.api';

interface EpilepsyAlertsProps {
  preVisit: any;
  previous: any;
  visits: any;
  nddieSurveys: any;
  breasiSurveys: any;
  piesSurveys: any;
  patient: any,
  isRefetchingSurveys: boolean,
}
const deadID = 'adcd93a1-8d62-47c8-bc2c-330b3cf3fb98';


const EpilepsyAlerts: React.FC<EpilepsyAlertsProps> = (props) => {
  const { preVisit, previous, visits, nddieSurveys, breasiSurveys, piesSurveys, patient, isRefetchingSurveys } = props;
  const [preVisitQs, setPreVisitQs] = useState<any>(null);
  const [previousQs, setPreviousQs] = useState<any>(null);
  const [nddieAlert, setNDDIEalert] = useState<boolean>(false);
  const [breasiAlert, setBreasiAlert] = useState<boolean>(false);
  const [deadAlert, setDeadAlert] = useState<{ alert: boolean; message: string }>({
    alert: false,
    message: '',
  });
  const [piesAlert, setPiesAlert] = useState<{ alert: boolean; message: string }>({
    alert: false,
    message: '',
  });
  const { refetch: refetchSurveys } =
    epilepsyApi.endpoints.getAllPatientSurveys.useQuerySubscription(patient?.id ?? skipToken);

  useEffect(() => {
    try {
      if (preVisit?.status[0] === 'compconsted' && preVisit?.questions?.length > 0) {
        const tmp = JSON.parse(preVisit.questions[0]);
        setPreVisitQs(tmp);
      }
      if (Array.isArray(previous) && previous[0]?.status[0] === 'compconsted' && previous[0]?.questions?.length > 0) {
        const tmp = JSON.parse(previous[0].questions[0]);
        setPreviousQs(tmp);
      }
    } catch {
      console.error('error');
    }
  }, [preVisit]);

  useEffect(() => {
    try {
      if (Array.isArray(visits) && visits.length > 1 && Array.isArray(piesSurveys)) {
        const survey1 = piesSurveys.filter(s => s.sessionId[0] === visits[visits.length - 1].id);
        const survey2 = piesSurveys.filter(s => s.sessionId[0] === visits[visits.length - 2].id);
        if (survey1.length > 0 && survey2.length > 0 && survey1[0].status[0] === 'compconsted' && survey2[0].status[0] === 'compconsted') {
          const score1TMP = survey1[0].score.map((el: any) => JSON.parse(el))[0];
          const score2TMP = survey2[0].score.map((el: any) => JSON.parse(el))[0];

          if (Array.isArray(score1TMP) && Array.isArray(score2TMP)) {
            const score1 = score1TMP.filter(el => el.label && el.label.toLowerCase() === 'final score')[0];
            const score2 = score2TMP.filter(el => el.label && el.label.toLowerCase() === 'final score')[0];

            if (score1.score && score2.score) {
              const check = score1.score - score2.score;
              if (Math.abs(check) > 8 && score1.score > score2.score) {
                setPiesAlert({
                  alert: true,
                  message: 'Pies Score Worsening',
                });
              } else if (Math.abs(check) > 8 && score2.score > score1.score) {
                setPiesAlert({
                  alert: true,
                  message: 'Pies Score Improvement',
                });
              }
            }
          }
        }
      }
    } catch {
      console.error('error');
    }
  }, [visits, piesSurveys]);

  useEffect(() => {
    try {
      if (Array.isArray(visits) && visits.length > 0 && Array.isArray(nddieSurveys)) {
        const survey1 = nddieSurveys.filter(s => s.sessionId[0] === visits[visits.length - 1].id);
        if (survey1.length > 0 && survey1[0].status[0] === 'compconsted') {
          const score1 = survey1[0].score.map((el: any) => JSON.parse(el))[0];
          if (Array.isArray(score1) && score1[0].score && score1[0].score > 14) {
            setNDDIEalert(true);
          }

          const qs = JSON.parse(survey1[0].questions);
          const filtered = qs.filter((el: any) => el.id === deadID);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < filtered[0].answer.length; i++) {
            const answer = filtered[0].answer[i].label;
            if (answer.toLowerCase() !== 'never') {
              setDeadAlert({
                alert: true,
                message: answer,
              });
            }
          }
        }
      }
    } catch {
      console.error('error');
    }
  }, [visits, nddieSurveys]);

  useEffect(() => {
    try {
      if (Array.isArray(visits) && visits.length > 1 && Array.isArray(breasiSurveys)) {
        const survey1 = breasiSurveys.filter(s => s.sessionId[0] === visits[visits.length - 1].id);
        if (survey1.length > 0 && survey1[0].status[0] === 'compconsted') {
          const score1 = survey1[0].score.map((el: any) => JSON.parse(el))[0];
          if (Array.isArray(score1) && score1[0].score > 6) {
            setBreasiAlert(true);
          }
        }
      }
    } catch {
      console.error('error');
    }
  }, [visits, breasiSurveys]);

  const renderAlert = (allQuestions: any[], questionId: string, answerId: string, text: string) => {
    if (Array.isArray(allQuestions) && allQuestions.length > 0) {
      try {
        const q = allQuestions.filter((qu: any) => qu.id === questionId)[0];
        if (Array.isArray(q.answer) && q.answer.length > 0 && q.answer[0].label === 'Yes') {
          if (answerId) {
            const ansQ = allQuestions.filter((qu: any) => qu.id === answerId)[0];
            let ans = '';
            if (Array.isArray(ansQ.answer) && ansQ.answer.length > 0) {
              ans = ansQ.answer[0].label;
            }
            return (
              <div>
                <Label size='h6' bold uppercase>{text}</Label>
                <Label size='h6'>{ans}</Label>
                <Spacer value={15} />
              </div>
            );
          }
          return null;
        }
      } catch (e) {
        console.error('renderAlert error', e);
        return null;
      }
    }
    return null;
  };

  const renderLongAlert = (allQuestions: any[], questionId: string, answerId: string, answer2ID: string, text: string) => {
    if (Array.isArray(allQuestions) && allQuestions.length > 0) {
      try {
        const q = allQuestions.filter((qu: any) => qu.id === questionId)[0];
        if (Array.isArray(q.answer) && q.answer.length > 0 && q.answer[0].label === 'Yes') {
          if (answerId) {
            const ansQ = allQuestions.filter((qu: any) => qu.id === answerId)[0];
            let ans = '';
            if (Array.isArray(ansQ.answer) && ansQ.answer.length > 0) {
              for (let i = 0; i < ansQ.answer.length; i++) {
                if (ansQ.answer[i] && ansQ.answer[i].label) {
                  ans += `${ansQ.answer[i].label},  `;
                }
              }
            }
            const ans2Q = allQuestions.filter((qu: any) => qu.id === answer2ID)[0];
            if (Array.isArray(ans2Q.answer) && ans2Q.answer.length > 0) {
              for (let i = 0; i < ans2Q.answer.length; i++) {
                if (ans2Q.answer[i].label) {
                  ans += `${ans2Q.answer[i].label},  `;
                }
              }
            }
            return (
              <div>
                <Label size='h6' bold uppercase>{text}</Label>
                <Label size='h6'>{ans}</Label>
                <Spacer value={15} />
              </div>
            );
          }
        }
      } catch (e) {
        console.error('renderAlert error', e);
      }
    }
    return null;
  };

  const renderCompareAlert = (allQuestions: any[], prevQuestions: any[], questionId: string, text: string) => {
    if (Array.isArray(allQuestions) && allQuestions.length > 0 && Array.isArray(prevQuestions) && prevQuestions.length > 0) {
      try {
        const q1 = allQuestions.filter(q => q.id === questionId)[0];
        const q2 = prevQuestions.filter(q => q.id === questionId)[0];
        if (Array.isArray(q1.answer) && q1.answer.length > 0 && Array.isArray(q2.answer) && q2.answer.length > 0) {
          const check1 = q1.answer[0].label;
          const check2 = q2.answer[0].label;
          if (check1 !== check2) {
            return (
              <div>
                <Label size='h6' bold uppercase>{text}</Label>
                <Label size='h6'>{`${q1.label}: ${check1}`}</Label>
                <Spacer value={15} />
              </div>
            );
          }
        }
      } catch (e) {
        console.error('renderAlert error', e);
      }
    }
    return null;
  };

  const renderNoneAlert = (allQuestions: any[], questionId: string, check: string, text: string, sub: string) => {
    if (Array.isArray(allQuestions) && allQuestions.length > 0) {
      try {
        const q = allQuestions.filter((qu: any) => qu.id === questionId)[0];
        if (Array.isArray(q.answer) && q.answer.length > 0 && q.answer[0].label === check) {
          return (
            <div>
              <Label size='h6' bold uppercase>{text}</Label>
              <Label size='h6'>{sub}</Label>
              <Spacer value={15} />
            </div>
          );
        }
      } catch (e) {
        console.error('renderAlert error', e);
      }
    }
    return null;
  };

  const renderPregnantAlert = (allQuestions: any, questionId: string, check: string, dateQ: string, text: string) => {
    if (Array.isArray(allQuestions) && allQuestions.length > 0) {
      try {
        const q = allQuestions.find((qu: any) => qu.id === questionId);
        if (Array.isArray(q.answer) && q.answer.length > 0 && q.answer[0].label === 'Yes') {
          const checkq = allQuestions.find((qu: any) => qu.id === check);
          if (Array.isArray(checkq.answer) && checkq.answer.length > 0 && checkq.answer[0].label === 'No') {
            const due = allQuestions.find((qu: any) => qu.id === dateQ);
            let ans = '';
            if (Array.isArray(due.answer) && due.answer.length > 0) {
              ans = due.answer[0].label;
            }
            return (
              <div>
                <Label size='h6' bold uppercase>{text}</Label>
                <Label size='h6'>Due date: {ans}</Label>
                <Spacer value={15} />
              </div>
            );
          }
        }
      } catch (e) {
        console.error('renderAlert error', e);
      }
    }
    return null;
  };


  return (
    <div className='epilepsy-alerts'>
      <Tile className='epilepsy-alerts__tile'>
        <>
          <div className='d-flex-row'>
            <Label size='h4' color='ghost'>Alerts</Label>
            <button className='icon-btn' type='button' onClick={refetchSurveys}><BiRefresh /> </button>
          </div>
          <Spacer value={20} />
          {isRefetchingSurveys ? <div>loading ...</div>
            :
            <div>

              {deadAlert.alert &&
                <div>
                  <Label size='h6' bold uppercase> better off dead</Label>
                  <Label size='h6'>Indicated {deadAlert.message} for NDDIE question better off dead</Label>
                  <Spacer value={15} />
                </div>
              }

              {preVisitQs &&
                <div>
                  {/* seizures ongoing, q 2 - 3 */}
                  {renderAlert(preVisitQs, 'ca6508e3-81e6-435e-9d9f-3a4fdd30022a', '2cc26ac4-2831-4908-a3f3-ea28dc81604c', 'seizures ongoing:')}

                  {/* medication problems, q 4 - 5 */}
                  {renderAlert(preVisitQs, 'afa97e1f-b6a1-4565-8429-7f0067d1137e', '0b99a4bf-2f16-4128-830e-920935f0b0a3', 'medication problems')}

                  {/* medication problems 2, q 6 - 7 */}
                  {renderAlert(preVisitQs, 'e6cd1c23-7ec4-4979-86ea-381299952ee3', 'cfb8e816-a273-4eec-a082-0e73cf7dce09', 'medication problems')}

                  {/* q 8 - 10 */}
                  {renderLongAlert(preVisitQs, 'e55672f8-5586-4432-9fe7-7a7bf4859bad', '7894b50c-a3c6-4f0b-a450-cc5ed8c9cf8a', '3e46e28f-238b-410e-b00d-488345c0abe7', 'ED for seizure')}

                  {/* Q 11 - 12 */}
                  {renderAlert(preVisitQs, '42e6eccf-19f9-49ca-bdc2-af1fa1759b71', '512e31a2-07ea-4892-bf87-1030c78c7e2c', 'recent surgery')}

                  {/* Q 13 - 14 */}
                  {renderLongAlert(preVisitQs, '71f27b4f-9af9-4a53-ba57-e20a3de11949', 'ca83c0ae-5352-494f-a0e2-384bb8d2991e', '5aa7c5c3-e401-4ae6-b59d-470044b5ccf3', 'tests to discuss')}

                  {/*  Q23  */}
                  {renderNoneAlert(preVisitQs, '3e271fce-2c21-41cc-b720-fff3d868aa20', 'No', 'seizure safety,', 'No support person for acute seizure care at home')}

                  {/*  Q24  */}
                  {renderPregnantAlert(preVisitQs, '77de7614-903b-4c69-94ef-7045d55d49d9', '88f361cb-574d-4f5b-b174-2d99e9364e97', '135f1f3d-7883-4541-bf6e-f69e2f58d48e', 'Pregnant: no folic acid')}
                </div>
              }

              {preVisitQs && previousQs &&
                <div>
                  {/* Q16, */}
                  {renderCompareAlert(preVisitQs, previousQs, '3ca2b55d-6395-48db-98b9-24061232dd4d', 'Driving Status Change')}

                  {/* Q20 */}
                  {renderCompareAlert(preVisitQs, previousQs, 'df58318a-509a-4e72-8fc0-4acea93a0c9b', 'Education Status Change')}
                </div>
              }
              {nddieAlert &&
                <div>
                  <Label size='h6' bold uppercase>NDDIE SCORE ALERT</Label>
                  <Label size='h6'>Possible Depression</Label>
                  <Spacer value={15} />
                </div>
              }

              {breasiAlert &&
                <div>
                  <Label size='h6' bold uppercase>bREASI SCORE ALERT</Label>
                  <Label size='h6'>Possible Anxiety</Label>
                  <Spacer value={15} />
                </div>
              }

              {piesAlert.alert &&
                <div>
                  <Label size='h6' bold uppercase>{piesAlert.message}</Label>
                  <Spacer value={15} />
                </div>
              }

              <Spacer value={20} />

              {preVisit && preVisit.showOn &&
                <Label size='subtext' color='ghost' >
                  ** As per visit:
                  {moment(preVisit.showOn[0]).format('L')}
                </Label>
              }
            </div>
          }
        </>
      </Tile>

    </div>
  );
};


// EpilepsyAlerts.defaultProps = {
// };

export default EpilepsyAlerts;
