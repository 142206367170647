import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetUsersurveyStatsByIdQuery, useEditSurveyMutation, useEditT1Mutation, surveysApi } from '../../../api/builders/surveys.api';
import { useEditProtocolMutation } from '../../../api/builders/participantProtocol.api';
import { Spacer, Button } from '../../atoms';
import CRFForms from '../CRFForms/CRFForms';
import SurveyResponseTable from '../SurveyResponseTable/SurveyResponseTable';
import DocumentsDrive from '../DocumentsDrive/DocumentsDrive';
import './crf-manager.scss';
import SmartCRF from '../SmartCRF/SmartCRF';

export interface CRFManagerProps {
  id: string,
  item: any,
  visitId?: string,
  itemStatus: string,
  readOnly?: boolean,
  participant: string | undefined,
  info: any,
  path: string,
}

const CRFManager = (props: CRFManagerProps): JSX.Element => {
  const { id, item, visitId, itemStatus, readOnly, participant, info, path } = props;
  const { data } = useGetUsersurveyStatsByIdQuery(item?.id ?? skipToken);
  const { refetch } =
    surveysApi.endpoints.getUsersurveyStatsById.useQuerySubscription(item?.id ?? skipToken);
  const [editSurvey] = useEditT1Mutation();
  const [editing, setEditing] = useState(false);
  const [questionsToTake, setQuestionsToTake] = useState([]);
  const [changeStatus, { isLoading: isEditing }] = useEditProtocolMutation();



  useEffect(() => {
    try {
      if (Array.isArray(data) && data[0].questions) {
        const surveyQuestions = JSON.parse(data[0].questions);
        const sorted = surveyQuestions.sort((a: any, b: any) => a.order - b.order);
        setQuestionsToTake(sorted);
        const stat = data[0]?.status;
        if (stat === 'not started' ||  stat === 'started') {
          setEditing(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [data, item]);

  const handleChangeStatus = () => {
    try {
      const pL = {
        id: participant ?? 'n/a',
        body: {
          itemId: item.id,
          itemStatus: 'pending review',
        },
      };
      changeStatus(pL);
    } catch {
      console.error('er');
    }
  };

  const handleSubmitSurvey = (surv: any) => {
    try {
      const payload = {
        id: data[0].id,
        body: {
          status: 'not started',
          questions: JSON.stringify(surv),
          score: '',
        },
      };
      // console.log('t1', payload);
      editSurvey(payload);
      handleChangeStatus();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSaveProgress = (surv: any) => {
    try {
      const payload = {
        id: data[0].id,
        body: {
          status: 'started',
          questions: JSON.stringify(surv),
          score: '',
        },
      };
      // console.log('t2', payload);
      editSurvey(payload);
    } catch (e) {
      console.error(e);
    }
  };

  // console.log('questionsToTake', questionsToTake);

  return (
    <div className="card">
      {readOnly ?
        <div>
          <SurveyResponseTable questions={questionsToTake} noScore />
          <div>
            <DocumentsDrive protocolId={item.id} />
          </div>
          {/* <CRFForms
            userSurvey={questionsToTake}
            setUserSurvey={setQuestionsToTake}
            onSubmit={handleSubmitSurvey}
            saveProgress={handleSaveProgress}
            editable={false}
            info={info}
            item={item}
            path={path}
          /> */}
        </div>
        :
        <div>
          {Array.isArray(data) &&
            <div>
              {editing ?
                <>
                  <SmartCRF id="smart" item={item} visitId={visitId ?? 'missing visit info'} >
                    <CRFForms
                      userSurvey={questionsToTake}
                      setUserSurvey={setQuestionsToTake}
                      onSubmit={handleSubmitSurvey}
                      saveProgress={handleSaveProgress}
                      editable={editing}
                      info={info}
                      item={item}
                      path={path}
                    />
                  </SmartCRF>
                </>
                :
                <div>
                  {/* <CRFForms
                    userSurvey={questionsToTake}
                    setUserSurvey={setQuestionsToTake}
                    onSubmit={handleSubmitSurvey}
                    saveProgress={handleSaveProgress}
                    editable={false}
                    info={info}
                    item={item}
                    path={path}
                  /> */}
                  <>
                    <SurveyResponseTable questions={questionsToTake} noScore />
                    <div>
                      <DocumentsDrive protocolId={item.id} />
                    </div>
                    {/* <div className="attachments-row">
                      {attachments?.map((attachment: any, index: number) => (
                        <CRFFormAttachement
                          key={`attachment-${index}`}
                          notes={attachment.notes}
                          filled={attachment.filled}
                          className='print'
                          file={attachment?.file} id={`crf-attch-${index}`} />
                      ))}
                    </div> */}
                  </>
                  <Spacer value={20} />
                  {itemStatus !== 'accepted' && <Button onClick={() => setEditing(true)}>Edit</Button>}
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  );
};

CRFManager.defaultProps = {
  readOnly: false,
  visitId: null,
};

CRFManager.displayName = 'CRFManager';
export default CRFManager;