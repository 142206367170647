import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsPeople, BsFillFolderFill } from 'react-icons/bs';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { IoStatsChart } from 'react-icons/io5';
import './clinic-router.scss';
import { Label } from '../../atoms';

export interface ClinicRouterProps {
  className?: string;
  activePage: string;
  registry: any; 
}

const ClinicRouter = (props: ClinicRouterProps): JSX.Element => {
  const { activePage, registry } = props;
  const navigate = useNavigate();

  const handleChangeTab = (newTab: string) => {
    navigate(`/private/registries/registry/${newTab}`, { state: { registry } });
  };

  return (
    <div className="clinic-router">
      <button type='button' onClick={() => handleChangeTab('overview')}
        className={`clinic-router__tab ${activePage === 'overview' ? 'active' : ''}`}>
        <AiOutlineDashboard className={`clinic-router__icon ${activePage === 'overview' ? 'active' : ''}`} />
        <Label size='h5' uppercase color={activePage === 'overview' ? 'primary' : 'default'}>Overview</Label>
      </button>
      <button type='button' onClick={() => handleChangeTab('settings')}
        className={`clinic-router__tab ${activePage === 'settings' ? 'active' : ''}`}>
        <FiSettings className={`clinic-router__icon ${activePage === 'settings' ? 'active' : ''}`} />
        <Label size='h5' uppercase color={activePage === 'settings' ? 'primary' : 'default'}>Settings</Label>
      </button>
      <button type='button' onClick={() => handleChangeTab('patients')}
        className={`clinic-router__tab ${activePage === 'patients' ? 'active' : ''}`}>
        <BsPeople className={`clinic-router__icon ${activePage === 'patients' ? 'active' : ''}`} />
        <Label size='h5' uppercase color={activePage === 'patients' ? 'primary' : 'default'}>All Patients</Label>
      </button>
      <button type='button' onClick={() => handleChangeTab('tracker')}
        className={`clinic-router__tab ${activePage === 'tracker' ? 'active' : ''}`}>
        <IoStatsChart className={`clinic-router__icon ${activePage === 'tracker' ? 'active' : ''}`} />
        <Label size='h5' uppercase color={activePage === 'tracker' ? 'primary' : 'default'}>Patient Tracker</Label>
      </button>
      <button type='button' onClick={() => handleChangeTab('conferences')}
        className={`clinic-router__tab ${activePage === 'conferences' ? 'active' : ''}`}>
        <FaClipboardList className={`clinic-router__icon ${activePage === 'conferences' ? 'active' : ''}`} />
        <Label size='h5' uppercase color={activePage === 'conferences' ? 'primary' : 'default'}>Conferences</Label>
      </button>
      <button type='button' onClick={() => handleChangeTab('files')}
        className={`clinic-router__tab ${activePage === 'files' ? 'active' : ''}`}>
        <BsFillFolderFill className={`clinic-router__icon ${activePage === 'files' ? 'active' : ''}`} />
        <Label size='h5' uppercase color={activePage === 'files' ? 'primary' : 'default'}>Clinic Files</Label>
      </button>
    </div>
  );
};

ClinicRouter.defaultProps = {
  className: '',
};

ClinicRouter.displayName = 'ClinicRouter';
export default ClinicRouter;
