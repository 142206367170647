/* eslint-disable no-else-return */
import React, { useState } from 'react';
// import classnames from 'classnames';
import { Tile, Label, Spinner, Button, Spacer } from '../../atoms';
import Question from '../Question/Question';
import './survey.scss';

export interface SurveyProps {
  userSurvey: any,
  setUserSurvey: (updatedItem: any) => void,
  onSubmit: (updatedItem: any) => void,
  saveProgress: (updatedItem: any) => void,
  viewAll?: boolean,
  loading?: boolean,
}

const Survey = (props: SurveyProps): JSX.Element => {
  const { userSurvey, setUserSurvey, onSubmit, viewAll, saveProgress, loading } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [listeningActive, setListeningActive] = useState(false);

  const next = async () => {
    let goTo = 'next';
    if (Array.isArray(userSurvey[currentIndex].answer) && userSurvey[currentIndex].answer.length > 0) {
      const hasPriorityGoTo = userSurvey[currentIndex].answer.some((answer: any) => answer.priority_goto);
      if (hasPriorityGoTo) {
        const priorityGoToAnswer = userSurvey[currentIndex].answer.find((answer: any) => answer.priority_goto);
        goTo = priorityGoToAnswer.goto;
      } else {
        const hasGoTo = userSurvey[currentIndex].answer.some((answer: any) => answer.goto);
        if (hasGoTo) {
          const goToAnswer = userSurvey[currentIndex].answer.find((answer: any) => answer.goto);
          goTo = goToAnswer.goto;
        }
      }
    }

    if (goTo) {
      if (goTo === 'next') {
        setCurrentIndex(prev => prev + 1);
      } else if (goTo === 'end') {
        setCurrentIndex(userSurvey.length + 1);
      } else if (goTo !== 'next' && goTo !== 'end' && Number(goTo)) {
        setCurrentIndex(Number(goTo) - 1);
      } else {
        setCurrentIndex(prev => prev + 1);
      }
    }
  };

  const prev = () => {
    if (userSurvey[currentIndex] && userSurvey[currentIndex].prev) {
      const prevs = userSurvey[currentIndex].prev;
      setCurrentIndex(prevs);
    } else {
      setCurrentIndex(previous => previous - 1);
    }
  };

  const handleViewAllAnswer = (ans: any, index: number) => {
    const updatedSurvey = [...userSurvey];
    updatedSurvey[index].answer = ans;
    setUserSurvey(updatedSurvey);
  };

  const onAnswer = (answers: any) => {
    const updatedSurvey = [...userSurvey];
    updatedSurvey[currentIndex].answer = answers;

    if (Array.isArray(answers) && answers.length === 1 && answers[0].goto) {

      if (typeof (answers[0].goto) === 'number') {
        const goTo: number = answers[0]?.goto ? answers[0].goto - 1 : 0;

        updatedSurvey[goTo].prev = currentIndex;

      } else {
        let goTo = -1;
        switch (answers[0].goto) {
          case 'next':
            goTo = currentIndex + 1;
            break;
          default:
            goTo = currentIndex + 1;
        }

        if (goTo < updatedSurvey.length && updatedSurvey[goTo].prev) {
          delete updatedSurvey[goTo].prev;
        }

      }

    } else {
      const goTo = currentIndex + 1;

      if (goTo < updatedSurvey.length && updatedSurvey[goTo].prev) {
        delete updatedSurvey[goTo].prev;
      }
    }

    setUserSurvey(updatedSurvey);
  };

  const decline = () => {
    setCurrentIndex(old => old + 1);
  };

  const skipQuestion = (goTo: any) => {
    if (goTo === 'next') {
      setCurrentIndex(prevv => prevv + 1);
    } else if (goTo === 'end') {
      setCurrentIndex(userSurvey.length + 1);
    } else if (goTo !== 'next' && goTo !== 'end' && Number(goTo)) {
      setCurrentIndex(Number(goTo) - 1);
    } else {
      setCurrentIndex(prevv => prevv + 1);
    }
  };

  const submitSurvey = () => {
    onSubmit(userSurvey);
  };

  const handleSaveParticial = () => {
    saveProgress(userSurvey);
  };

  const validate = () => {
    if (Array.isArray(userSurvey[currentIndex].answer) && userSurvey[currentIndex].answer.length === 0) {
      return true;
    } else if (Array.isArray(userSurvey[currentIndex].answer) && userSurvey[currentIndex].answer[0]?.value === '') {
      return true;
    } else if (Array.isArray(userSurvey[currentIndex].answer) && userSurvey[currentIndex].answer[0]?.value === 'invalidValue') {
      return true;
    } else if (listeningActive) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className='survey'>
      <Spacer value={20} />
      {userSurvey?.length > 0 ?
        <>
          {viewAll ?
            <div>
              {userSurvey.map((q: any, index: number) => (
                <div className='survey-view-all-item'>
                  <Question
                    key={`survey-question-${q.id}`}
                    question={q}
                    onAnswer={(it) => handleViewAllAnswer(it, index)}
                    currentIndex={index}
                    listeningActive={listeningActive}
                    setListeningActive={setListeningActive}
                  />
                  <Spacer value={20} />
                </div>
              ))}
              <Spacer value={20} />
              <div className='btn-row'>
                {loading ?
                  <Spinner />
                  :
                  <>
                    <Button size='wide' onClick={handleSaveParticial}>
                      Save Progress
                    </Button>
                    <Button size='wide' onClick={submitSurvey}>
                      Submit
                    </Button>
                  </>
                }
              </div>
              <Spacer value={30} />
            </div>
            :
            <>
              {currentIndex + 1 > userSurvey.length ? (
                <div className="survey-finished-content">
                  <Spacer value={10} />
                  <Label size="h3" color="primary" bold center >
                    Good work. You have completed this section.
                  </Label>
                  <Spacer value={30} />
                  <Button loading={loading} onClick={submitSurvey} >
                    Submit Survey
                  </Button>
                  <Spacer value={30} />
                </div>
              )
                : (
                  <div className='survey-content-container'>
                    <div className='survey-nav-container'>
                      <Button onClick={prev} size='small' kind='secondary' disabled={currentIndex === 0} >Prev</Button>
                      <Label center size='h5'>{`Question ${currentIndex + 1}/${userSurvey.length}`}</Label>
                      <Button disabled={validate()} onClick={next} kind='secondary' size='small' >Next</Button>
                    </div>

                    <Spacer value={30} />

                    <Question
                      key={`survey-question-${currentIndex}`}
                      question={userSurvey[currentIndex]}
                      onAnswer={onAnswer}
                      currentIndex={currentIndex}
                      listeningActive={listeningActive}
                      setListeningActive={setListeningActive}
                    />

                    {
                      userSurvey[currentIndex].skippable &&
                      <div className='userSurvey__skip' role='button' tabIndex={-1} onClick={decline}><Label italic bold size='h6' color='secondary' >
                        Decline to answer
                      </Label></div>
                    }
                    {
                      userSurvey[currentIndex].skipto &&
                      <div className='userSurvey__skip' role='button' tabIndex={-1} onClick={() => skipQuestion(userSurvey[currentIndex].skipto)}><Label italic bold size='h6' color='secondary' >
                        Decline to answer
                      </Label></div>
                    }
                    <Spacer value={30} />
                  </div>
                )
              }
            </>
          }
        </>
        :
        <Label size='h4'> There are no questions for this survey </Label>
      }
    </div>
  );
};

Survey.defaultProps = {
  viewAll: false,
  loading: false,
};

Survey.displayName = 'Survey';
export default Survey;
