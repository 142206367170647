import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillStar } from 'react-icons/ai';
import './user-study-item.scss';
import { StudyT } from '../../../types/hsgTypes';
import { Label, Spacer, Button } from '../../atoms';

export interface UserStudyItemProps {
  className?: string,
  trial: StudyT
}

const UserStudyItem = (props: UserStudyItemProps): JSX.Element => {
  const { trial } = props;
  const navigate = useNavigate();

  const openStudy = (t: StudyT) => {
    navigate('/study', { state: { study: t } });
  };


  const renderFooter = (status: string) => {
    switch (status) {
      case 'closed':
        return (
          <Label size="h6" uppercase color="white" italic  > Enrollment Closed </Label>
        );
      default:
        return (
          <>
            {trial?.consentStatus?.length > 0 && trial.consentStatus[0] === 'consented' ?
              <Label size="h6" uppercase color="white" italic  > <AiFillStar /> Enrolled </Label>
              :
              <Button outline onClick={() => openStudy(trial)}>Participate</Button>
            }
          </>
        );
    }
  };

  return (
    <div className="user-study-item"  role='button' tabIndex={-1} onClick={() => openStudy(trial)} >
      <Label size="h3" color="white" bold >  {trial?.title && trial.title} </Label>
      <Spacer value={20} />
      <Label size="h5" color="white"  > {trial?.description[0] && JSON.parse(trial.description[0])[0].substring(0, 94)} ... </Label>
      <Spacer value={30} />
      {renderFooter(trial?.status[0])}
      <Spacer value={20} />
    </div>
  );
};

UserStudyItem.defaultProps = {
  className: '',
};

UserStudyItem.displayName = 'UserStudyItem';
export default UserStudyItem;
