import React from 'react';
import { Label, IconButton, Modal, DatePicker, Spacer, Button } from '../../atoms';
import './session-survey-item.scss';

export interface SessionSurveyItemProps {
  className?: string,
  survey: any,
  onClick: (item: any) => void,
  isActive?: boolean,
}



const SessionSurveyItem = (props: SessionSurveyItemProps): JSX.Element => {
  const { survey, onClick, isActive } = props;
  const { surveyLabel, status } = survey;


  const getColorForStatus = (_status: string) => {
    switch (_status) {
      case 'not started':
        return (
          <Label uppercase size='subtext' color='danger'>
            {_status}
          </Label>
        );
      case 'completed':
        return (
          <Label uppercase size='subtext' color='success'>
            {_status}
          </Label>
        );
      default:
        return (
          <Label uppercase size='subtext' color='primary'>
            {_status}
          </Label>
        );
    }
  };


  return (
    <div className={isActive ? 'session-survey-item-active' : 'session-survey-item'} role='button' tabIndex={-1} onClick={() => onClick(survey)}>
      <Label uppercase size='h5' weight={600} color='ghost'>
        {surveyLabel}
      </Label>
      <Spacer value={10} />
      {getColorForStatus(status)}
    </div>
  );
};

SessionSurveyItem.defaultProps = {
  className: '',
  isActive: false,
};

SessionSurveyItem.displayName = 'SessionSurveyItem';
export default SessionSurveyItem;
