import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './simple-select.scss';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';

export interface SimpleSelectProps {
  className?: string,
  withCheck: boolean,
  multi: boolean,
  answer: any,
  label: any,
  onSelect: (ans: any) => void,
}

const SimpleSelect = (props: SimpleSelectProps): JSX.Element => {
  const { withCheck, multi, answer, label, onSelect } = props;
  const [selected, setSelected] = useState(false);

  const classes = {
    'question': true,
    'question__list': true,
    [`question__list--${selected}`]: true,
    [`question__list--${withCheck ? 'with-check' : 'no-check'}`]: true,
  };


  useEffect(() => {
    if (!multi && answer !== label) {
      setSelected(false);
    }
    if ((typeof answer === 'number') && answer === label) {
      setSelected(true);
    } else if ((typeof answer !== 'number') && answer && answer.includes(label)) {
      setSelected(true);
    } else {
      setSelected(false);
    }

  }, [answer, multi, label]);


  const onClick = () => {
    if (multi) {
      const idx = answer.findIndex((it: any) => it === label);
      if (idx === -1) {
        onSelect?.([...answer, label]);
      } else {
        const selection = [...answer];
        selection.splice(idx, 1);
        onSelect(selection);
      }
    } else {
      onSelect(label);
    }
    setSelected(!selected);
  };



  return (
    <div role='button' tabIndex={-1} onClick={onClick} className={classnames(classes)}>
      {withCheck && (selected ? <BsCheckCircle className="selected-icon" /> : <BsCircle />)}
      <div className='question__list-label'>{label}</div>
    </div>
  );
};

SimpleSelect.defaultProps = {
  className: '',
};

SimpleSelect.displayName = 'SimpleSelect';
export default SimpleSelect;
