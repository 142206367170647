import api from '../api';
import updatedApi from '../updatedApi';


export const hsgApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudies: builder.query({
      query: () => '/studies/me',
      providesTags: ['STUDIES'],
    }),
    getAllStudies: builder.query({
      query: () => '/studies/all',
      providesTags: ['STUDIES'],
    }),
    getUserProfile: builder.query({
      query: () => '/users/me',
      providesTags: ['USER'],
    }),
    getSurveys2: builder.query({
      query: () => '/usersurvey/me',
      providesTags: ['USERSURVEYS'],
    }),
    getUserDocuments: builder.query({
      query: () => '/s3/consentform',
      providesTags: ['USER'],
    }),
    getUserDocuments3: builder.query({
      query: (pl) => ({
        url: '/s3/consentform',
        method: 'POST',
        data: pl,
      }),
      providesTags: ['USER'],
    }),
    getUserDocuments32: builder.mutation({
      query: (pl) => ({
        url: '/s3/consentform',
        method: 'POST',
        data: pl,
      }),
    }),
    getUserStudyDocs: builder.query({
      query: (pl) => ({
        url: `s3/consentform/${pl.filename}/${pl.method}/${pl.userId}/${pl.admin}/${pl.studyId}`,
      }),
    }),
    consentToStudy: builder.mutation({
      query: (payload) => ({ url: '/studyConsent', method: 'POST', body: payload }),
      invalidatesTags: ['STUDIES', 'USERSURVEYS'],
    }),
    uploadConsent: builder.mutation({
      query: (payload) => ({ url: '/s3/consentform', method: 'POST', body: payload, transformResponse: (response: any) => response.data  }),
    }),
    updateConsent: builder.mutation({
      query: (pl) => ({
        url: '/studyConsent',
        method: 'PATCH',
        body: pl,
      }),
      invalidatesTags: ['STUDIES'],
    }),
    updateUserProfile: builder.mutation({
      query: (payload) => ({
        url: '/users/me',
        method: 'PATCH',
        body: payload,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['USER'],
    }),
    createUserProfile: builder.mutation({
      query: (payload) => ({ url: '/upstream/addUserProfile', method: 'POST', body: payload }),
      invalidatesTags: ['USER'],
    }),

  }),
});

export const hsgNewApi = updatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersurveyFeed: builder.query({
      query: (studyId) => ({ url: `/usersurvey/study/feed/${studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['USERSURVEYS', 'STUDIES'],
    }),
    getStudyInfo: builder.query({
      query: (studyId) => ({ url: `/study/${studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['STUDIES'],
    }),
    getUserSurveyDetails: builder.query({
      query: (surveyId) => ({ url: `/usersurvey/${surveyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['USERSURVEYS'],
    }),
    generateS3ConsentUrl: builder.mutation({
      query: (payload) => ({ url: '/study/s3/consentform/', method: 'POST', body: payload }),
      invalidatesTags: ['STUDIES'],
    }),
    addStudyConsent: builder.mutation({
      query: (payload) => ({ url: '/study/consent/', method: 'POST', body: payload }),
      invalidatesTags: ['STUDIES', 'USERSURVEYS'],
    }),
    updateUserSurvey: builder.mutation({
      query: (payload) => ({
        url: `/usersurvey/${payload.surveyId}`,
        method: 'PUT',
        body: payload.body,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['USERSURVEYS'],
    }),
    addUserToCognitoGroup: builder.mutation({
      query: (payload) => ({ url: '/user/cognito/group', method: 'POST', body: payload }),
    }),
  }),
});

export const {
  usePrefetch,
  useGetStudiesQuery,
  useGetSurveys2Query,
  useGetAllStudiesQuery,
  useGetUserProfileQuery,
  useGetUserDocumentsQuery,
  useUpdateUserProfileMutation,
  useCreateUserProfileMutation,
  useGetUserDocuments3Query,
  useGetUserDocuments32Mutation,
  useConsentToStudyMutation,
  useUpdateConsentMutation,
  useUploadConsentMutation,
  useGetUserStudyDocsQuery,
} = hsgApi;

export const {
  useGetUsersurveyFeedQuery,
  useGetUserSurveyDetailsQuery,
  useUpdateUserSurveyMutation,
  useGenerateS3ConsentUrlMutation,
  useAddStudyConsentMutation,
  useGetStudyInfoQuery,
  useAddUserToCognitoGroupMutation,
} = hsgNewApi;
