/* eslint-disable @typescript-eslint/quotes */

// eslint-disable-next-line import/prefer-default-export
export const FAQbody = [
  {
    title: "How do I sign up to participate in the myHDstory<sup>®</sup> research study?",
    body: [
      "If you are interested in participating in the myHDstory<sup>®</sup> Making HD Voices Heard pilot study, you need to register on the HSG online platform.  Here is how to get started.",
      "1. Visit the study homepage, www.myhdstory.org",
      "2. Click the orange “Create Account” button to create your own account.",
      "3. You will enter your account information and a verification code will be sent to the email address you provide.  If you do not receive the email, please check your spam folder.",
      "4.  After you enter the verification code from your email, select the “Submit” button.   You will then be directed to select “Create Your Profile”.",
      "5.  You will enter your profile information by answering a series of questions.  When complete, select the “Create Profile” button.",
      "6. You will then be directed to the study page where you can select the “Participate” button for the Making HD Voices Heard pilot study.",
      "7.  Please read about the study and then select the “Go to consent” button. To participate in the study, you must read, understand, agree to, and sign the Informed Consent Form electronically.",
      "8. You will then answer surveys about how you feel and what you experience in your own words about your HD, general health, and personal well-being.",
    ],
  },
  {
    title: "What if I forgot my password?",
    body: [
      'If you forgot your password or would like to change your password, follow the below steps:',
      '1. Go to “Sign In”.',
      '2. Select “Forgot Password”.',
      '3. Enter your email address that you used at the time of registration to receive a password reset code.',
      '4. Select “Submit”.',
      '5. Enter the code that was sent to your email that you provided and your new password.',
      '6.Select “Reset”.',
      'Remember that your user ID is the email address or cell phone number you used to register.',
    ],
  },
  {
    title: "How can I change my account information?",
    body: [
      'If you would like to update your Profile, you can do so by selecting the circle with your initials at the top menu bar. Then follow the below steps:',
      '1. Edit the field you would like to change.',
      '2. Scroll to the bottom of the page and press the "Save Changes" button.',
    ],
  },
  {
    title: "How do I print out the Terms and Conditions of Use/User Agreement, so I have a copy for my records?",
    body: [
      '1. Select “Terms of Use” on the bottom footer bar of any screen.',
      '2.Windows/PC Users: To print, while viewing the document, press Control key [Ctrl] and [P] on your keyboard.',
      '3. Mac Users: To print, while viewing the document, press Command key [CMD] and [P] on your keyboard.',
    ],
  },
  {
    title: "What should I do if I don’t want to be in the study any longer?",
    body: [
      'If you want to stop participating in myHDstory<sup>®</sup> or any study on the platform, you may choose to:',
      '1. Revoke your consent by selecting the study from the study description page.',
      '2. Select “Revoke”.',
      '3. Or you may email the study team by selecting the “Help” button on the top menu bar. Someone from the study team will contact you by the email address you used to login.',
    ],
  },
  {
    title: "How do I delete my account?",
    body: [
      "You may email the study team by selecting the “Help” button on the top menu bar. Someone from the study team will contact you by the email address you used to login to confirm that your account has been deleted.",
    ],
  },
  {
    title: "Do I have to pay to participate in the myHDstory<sup>®</sup> research study?",
    body: [
      'There will be no cost to you for participating in this study, except for the time that you spend answering questions and providing data. There is no fee for using the myHDstory<sup>®</sup> platform to participate in Making HD Voices Heard Pilot Study.',
    ],
  },
  {
    title: "Will I be paid for my participation in the myHDstory<sup>®</sup> research study?",
    body: [
      'You will not be paid to participate in this study.',
    ],
  },
  {
    title: "Who can take part in the LEAD-HD study?",
    body: [
      'Individuals who can participate in the study must:',
      '1. Be 18 years of age or older;',
      '2. Be willing and able to provide informed consent electronically;',
      '3. Self-report, when answering as a participant, that you have been diagnosed with HD by a doctor, or have undergone genetic testing and been found to carry the gene mutation responsible for HD but have not been clinically diagnosed with HD (prodromal HD);',
      '4. Have the ability to answer online questions or direct someone else to enter answers for them; ',
      '5. Have the ability to ambulate independently and take care of some of his or her personal needs; ',
      '6. Have the ability to read and understand English;',
      '7. Be willing to create a unique identifier based on personal demographic information;',
      '8. Reside in the United States or its territories; ',
      '9. Own or have access to an electronic device and secure internet connectivity',
    ],
  },
  {
    title: "What can I expect to see in the LEAD-HD study? ",
    body: [
      'The LEAD-HD study utilizes myHDstory<sup>®</sup>, an online observational research platform that enables consenting adults who are affected by HD (e.g. patients, HD gene carriers, family members, care givers) to report how they are impacted by HD, including how they feel and function.',
      'Upon enrolling in the study, you will be asked to reply to a series of questions, including demographic questions and questions about your HD symptoms. You will be asked to answer open-ended questions that will be transcribed by voice (microphone voice-to-text transcription) or keyboard-entered text to record what you experience in your own words.',
      'There are five questionnaires to review and complete: ',
      '1. Demographics – General information about you',
      '2. More About You - A questionnaire about you and your family.',
      '3. Tell us about your HD - A questionnaire about your HD diagnosis and care. ',
      '4. Have you had HD genetic testing? - A questionnaire about your experience with HD genetic testing.',
      '5. Huntington Disease Health Index (HD-HI) – A questionnaire about how HD impacts your life.',
      '6. HD-PROP - A questionnaire about your HD to be answered in your own words.',
      '7. TFC-self report - A questionnaire about what you are able to do.',
      '8. HLS<sub>19</sub>-Q12 – A questionnaire about your understanding of health information.',
      'Once you begin your questionnaires, you will have 1 week to complete all of them. It is estimated that it will take approximately 45 minutes to complete the questionnaires in your first visit. It is estimated that it will take approximately 30 minutes to complete the questionnaires at 6, 12, 18, and 24 months. Once you begin a questionnaire, you must complete that section until the system indicates that you have completed the section; partial completion in the middle of a section will not be saved.  Once you begin the HD-HI questionnaire, you will have 48 hrs to complete all subsections of the HD-HI.',
      'You will receive automated emails through myHDstory<sup>®</sup> at 6, 12, 18, and 24 months to complete your questionnaires. You will have a 14-day window to complete the questionnaires (for example, 1 week before and 1 week after the 6-month mark to complete 6-month visit). You will receive an email 7 days ahead of your visit reminding you to complete your questionnaires within the time window. You will receive up to three emails reminding you to complete your questionnaires at each time point.',
    ],
  },
  {
    title: "What are the minimum system requirements for the web based version or the mobile app?",
    body: [
      'You must use a recent version of a modern web browser, such a Chrome or Safari (Mac). When using the mobile app, you must use a modern iPhone or Android device running a recent version. Unsupported versions may not be fully functional.',
    ],
  },
  // {
  // 	title: "Who can take part in the Making HD Voices Heard pilot study?",
  // 	body: [
  // 		'Individuals can participate in the study who:',
  // 		'1. Are age 18 years or older, who live in the US and have been diagnosed with HD',
  // 		'2. Are able to read and understand English.',
  // 		'3. Are able to take care of their own daily needs.',
  // 		'4. Have access to the internet.',
  // 		'5. Have someone to assist them, if needed, with data entry.'
  // 	]
  // },
  // {
  // 	title: "What can I expect to see in the Making HD Voices Heard pilot study?  ",
  // 	body: [
  // 		'When you participate in this study, you will be asked to think about the problems you face.  Some of these problems may be hard to think about or might bring up uncomfortable feelings.  We ask you these questions because understanding your personal experience living with HD helps us make research more focused on you',
  // 		'The questionnaires will ask you to reply in your own words about the problems you now face due to your HD, General Health, and Personal Well Being.',
  // 		'You will be asked first about your current HD problems and how they bother you.  Most people will have more than one problem. You are asked to think about your HD problems and order them in priority beginning with the most bothersome.',
  // 		'Upon enrolling in the study, there are eight questionnaires to review and complete:',
  // 		'1. HD Patient Report of Problems - A questionnaire about your HD to be answered in your own words.',
  // 		'2. General Health - A questionnaire about your general health to be answered in your own words.',
  // 		'3. Well-Being - A questionnaire about your personal well-being, to be answered in your own words.',
  // 		'Each of the first three (3) questionnaires will have five (5) parts:',
  // 		'	a. Describe your problem.',
  // 		'	b. Tell us how the problem bothers you.',
  // 		'c. Rate how serious the problem is.',
  // 		'd. What makes the problem better?',
  // 		'e. What makes the problem worse?',
  // 		'4. What You Are Capable Of Doing - A questionnaire about what you are able to do.',
  // 		'5. More About You - A questionnaire about you and your family.			',
  // 		'6. Tell us about your HD - A questionnaire about your HD diagnosis and care, to be answered in your own words. ',
  // 		'7. Have you had HD genetic testing? - A questionnaire about your experience with HD genetic testing, to be answered in your own words.			',
  // 		'8. What do you think of myHDstory? - An opportunity to share your experience about completing the questionnaires, to be answered in your own words.'
  // 	]
  // },
  // {
  // 	title: "How do I participate in the Making HD Voices Heard pilot study?",
  // 	body: [
  // 		'1. Select the “Participate” button in the Making HD Voices Heard Pilot Study box on the Research home page.',
  // 		'2. Read about the study.  If you think you qualify for the study, select the “Go to consent” button.',
  // 		'3. Read and understand the Study Information Sheet (Informed Consent Form) and sign the form electronically.'
  // 	]
  // },
  // {
  // 	title: "I am a care partner, can I complete the Making HD Voices Heard pilot study?",
  // 	body: [
  // 		'If you are a care partner for someone with HD, you can work together to complete the study surveys. Just make sure that you only enter information for the research participant (not yourself).'
  // 	]
  // },
  {
    title: "How do I print the Study Information Sheet (Informed Consent Form), so I have a copy for my records?",
    body: [
      '1. Before you sign the Study Information Sheet (Informed Consent Form), you may:',
      'a. Windows/PC Users: To print, while viewing the document, press Control key [Ctrl] and [P] on your keyboard.',
      'b. Mac Users: To print, while viewing the document, press Command key [CMD] and [P] on your keyboard.',
      '2. After you sign the Study Information Sheet (Informed Consent Form), you may select the “Print Document” button.',
    ],
  },
  {
    title: "How do I print out the FAQ sheet?",
    body: [
      'Windows/PC Users: To print an FAQ sheet, while viewing the document, press Control key [Ctrl] and [P] on your keyboard.',
      'Mac Users: To print an FAQ sheet, while viewing the document, press Command key [CMD] and [P] on your keyboard.',
    ],
  },
  {
    title: "May I stop completing a survey or skip it if I want to?		",
    body: [
      'Yes, you may stop completing any survey at any time and for any reason once you are registered for the platform.			',
      '1. You may select the “Research” button on the top menu bar to exit a survey.',
      '2. You will be asked “Are you sure you want to exit this survey?”',
      '3. Select “Yes”.',
      '4. You may return at a later time to take the survey.',
      'Or you may also skip a survey completely by selecting “Decline to complete this survey” on the description page of the survey.',
    ],
  },
  {
    title: " How do I see my answers to prior questions? 		",
    body: [
      'Above each question there is a "Previous" [PREV] button.  Just click on the "Previous"[PREV] button to see what you answered for prior questions.			',
    ],
  },
  {
    title: 'Will I lose everything I entered if I hit the "Previous" [PREV] button?',
    body: [
      'No, everything you have entered will be saved. 			',
    ],
  },
  {
    title: "How do I use the microphone to speak my answer instead of typing?",
    body: [
      'Some survey questions have a microphone button so you can speak words instead of typing them.  To activate this feature:',
      '1. Press the microphone button on the screen.',
      '2. Speak your answer.',
      '3. Click on the microphone button a second time once you finish speaking each answer.',
      '4. You may also use your keyboard to enter or edit your text.',
    ],
  },
  {
    title: "May I change my past survey responses?",
    body: [
      'You are not able to edit your answers on completed surveys.  You can select the “PREV” button on a survey that you are working on to go back and change an answer. ',
    ],
  },
  {
    title: "Crisis (Suicide/SOS helpline)",
    body: [
      'During the study, if you experience any medical problems or psychiatric problems, please contact your personal physician or call 911.  If you have thoughts of harming yourself, or you or someone you know is in crisis, Call the National Suicide Prevention Lifeline (Lifeline) at 1-800-273-TALK (8255), or text the Crisis Text Line (text HELLO to 741741).',
    ],
  },
];


