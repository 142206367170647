import React, { useState, useEffect } from 'react';
import { Label, Alert, Modal, DatePicker, Spacer, Button } from '../../atoms';
import './patient-session-survey.scss';
import computeScore from '../../../utils/scoring/FormulaParser';
import Survey from '../Survey/Survey';
import SurveyResponseTable from '../SurveyResponseTable/SurveyResponseTable';
import { useEditSurveyMutation } from '../../../api/builders/patientSessions.api';

export interface PatientSessionSurveyProps {
  className?: string,
  survey: any,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const PatientSessionSurvey = (props: PatientSessionSurveyProps): JSX.Element => {
  const { survey } = props;
  const [questionsToTake, setQuestionsToTake] = useState([]);
  const [editing, setEditing] = useState(false);
  const [update, { isLoading: isEditing, isError: isErrorEditing, isSuccess: isSuccessEditing, error: editError }] = useEditSurveyMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isErrorEditing) {
      const apiError = editError as ApiError;
      setAlert({
        open: true,
        message: `Error Updating Survey: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorEditing]);

  React.useEffect(() => {
    if (isSuccessEditing) {
      setAlert({
        open: true,
        message: 'Success Updating Survey!',
        kind: 'success',
      });
    }
  }, [isSuccessEditing]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  useEffect(() => {
    try {
      if (survey && survey.questions) {
        const surveyQuestions = JSON.parse(survey.questions);
        const sorted = surveyQuestions.sort((a: any, b: any) => a.order - b.order);
        setQuestionsToTake(sorted);
        if (survey.status === 'not started' || survey.status === 'started') {
          setEditing(true);
        } else {
          setEditing(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [survey]);

  const handleSubmitSurvey = async (updatedItem: any) => {
    try {
      const calcScore = computeScore({
        ...survey,
        questions: JSON.stringify(updatedItem),
      });


      const payload = {
        id: survey.id,
        body: {
          status: 'completed',
          questions: JSON.stringify(updatedItem),
          score: Array.isArray(calcScore) && calcScore.length > 0 ? JSON.stringify(calcScore) : '',
        },
      };
      await update(payload);
      setEditing(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSaveProgress = (updatedItem: any) => {
    try {
      const payload = {
        id: survey.id,
        body: {
          status: 'started',
          questions: JSON.stringify(updatedItem),
          score: '',
        },
      };
      update(payload);
    } catch (e) {
      console.error(e);
    }
  };



  return (
    <div className="card">
      <Label uppercase size="h3" weight={600}>
        {survey.surveyLabel}
      </Label>
      <Label uppercase size="h6" weight={400}>Status: {survey.status}</Label>
      <div className='patient-session-survey'>
        {editing ?
          <Survey
            userSurvey={questionsToTake}
            setUserSurvey={setQuestionsToTake}
            onSubmit={handleSubmitSurvey}
            saveProgress={handleSaveProgress}
            viewAll
            loading={isEditing}
          />
          :
          <SurveyResponseTable
            questions={questionsToTake}
          />
        }
      </div>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

PatientSessionSurvey.defaultProps = {
  className: '',
};

PatientSessionSurvey.displayName = 'PatientSessionSurvey';
export default PatientSessionSurvey;
