/* eslint-disable import/no-named-as-default */
import React from 'react';
import { MenuItem } from '@mui/material';
import { Dropdown, Label, Spacer, Button } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';
import CountryList from '../../../../utils/countryList';
import States from '../../../../utils/states';

export interface BornProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleNextStep: () => void,
}

const Born = (props: BornProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleNextStep } = props;

  const handleChangeLocation = (e: any) => {
    const { name, value } = e.target;
    handleChange((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    if (
      state.birthCity &&
			state.birthCity.trim() !== '' &&
			state.birthCountry &&
			state.birthCountry.trim() !== ''
    ) {
      return false;
    } 
    return true;
  };


  return (
    <div>
      <Label size="h3" bold color="primary" center >
        Where were you born?
      </Label>
      <Spacer value={20} />

      <TextInput
        name="birthCity"
        label="City of Birth"
        value={state.birthCity}
        onChange={handleChangeLocation}
      />
      <Spacer value={10} />
      <Dropdown
        label="Country of Birth"
        name="birthCountry"
        value={state.birthCountry}
        onChange={handleChangeLocation} >
        {CountryList.map((countr: string) => (
          <MenuItem value={countr}>{countr}</MenuItem>
        ))}
      </Dropdown>

      <Spacer value={5} />

      {state.birthCountry === 'United States' &&
        <Dropdown
          label="State of Birth"
          name="birthState"
          value={state.birthState}
          onChange={handleChangeLocation} >
          {States.map((countr: string) => (
            <MenuItem value={countr}>{countr}</MenuItem>
          ))}
        </Dropdown>
      }

      <Spacer value={20} />
      <div className='btn-row'>
        <Button onClick={handlePrevStep}>Previous</Button>
        <Button onClick={handleNextStep} disabled={validate()}>Next</Button>
      </div>
    </div>
  );
};

Born.defaultProps = {
};

Born.displayCountry = 'Born';
export default Born;
