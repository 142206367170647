import React from 'react';
import { FcCheckmark } from 'react-icons/fc';
import { AiFillEdit } from 'react-icons/ai';
import { BsCheckCircleFill, BsFillXCircleFill, BsImage } from 'react-icons/bs';
import { Spacer, Button, Checkbox, Label, Modal } from '../../atoms';
import PrintForm from '../ProtocolPrintForm/ProtocolPrintForm';
import MetImageTD from '../MetImageTD/MetImageTD';
import './met-review.scss';
import { MetT } from '../../../types/types';

export interface MetReviewProps {
  mets: MetT[],
  itemId: string | undefined,
  cancelReviewMode: () => void,
  onChangeItem: (item: MetT, indx: number) => void,
  submit: () => void,
  role: string,
  info: any,
  path: string | undefined,
  protocolItem: any,
  children: React.ReactNode;
}


const MetReview = (props: MetReviewProps) => {
  const { mets, itemId, cancelReviewMode, onChangeItem, submit, role, path, info, protocolItem, children } = props;
  const [checked, setChecked] = React.useState<Array<string>>([]);
  const [printModal, setPrintModal] = React.useState(false);
  const expanded = role === 'SITE COORDINATOR';


  const handleSubmit = () => {
    submit();
    // cancelReviewMode();
  };

  const handleChecked = (met: string) => {
    const found = checked.includes(met);
    let newArr: string[] = [];
    if (found) {
      newArr = checked.filter((item) => item !== met);
    } else {
      newArr = checked.concat(met);
    }
    setChecked(newArr);
  };


  const renderRow = (item: MetT, index: number, nonEditable: boolean) => {
    const hasTumorAssessments = item?.tumorassessments?.length > 0;
    const findMatch = hasTumorAssessments
      ? item.tumorassessments.find((it) => it.protocolItemId === itemId)
      : null;

    const labelWithFallback = (value: string | number | undefined) => (
      <Label size='h6'>{value !== undefined ? String(value) : <span>-</span>}</Label>
    );

    return (
      <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1}>
        <td className='met-manager__table-td'>{labelWithFallback(item.tumorLabel)}</td>
        <td className='met-manager__table-td'>{labelWithFallback(item.lobe)}</td>
        <td className='met-manager__table-td'>{labelWithFallback(item.laterality)}</td>
        <td>
          <Label center size='subtext'>RL / AP / SI</Label>
          <Label size='h6'>
            <p className='d-flex-row-center'>
              {labelWithFallback(item.RLCoord)} / {labelWithFallback(item.APCoord)} / {labelWithFallback(item.SICoord)}
            </p>
          </Label>
        </td>
        <td>
          <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
          <Label size='h6'>
            <p className='d-flex-row-center'>
              {findMatch?.diameterAxial || <span>-</span>} / {findMatch?.diameterCoronal || <span>-</span>} / {findMatch?.diameterSagittal || <span>-</span>}
            </p>
          </Label>
        </td>
        {expanded && <td className='met-manager__table-td'><Label size='h6'>{item.isTreated ? <span>Yes</span> : <span>No</span>}</Label></td>}
        {expanded && <td className='met-manager__table-td'><Label size='h6'>{item.isTreatable ? <span>Yes</span> : <span>No</span>}</Label></td>}
        {expanded && <td className='met-manager__table-td'>{item?.visit && item.visit === 'Screening and Baseline' ? <BsCheckCircleFill /> : <BsFillXCircleFill />}</td>}
        <td className='met-manager__table-td'><button type='button' aria-label='edit' className='icon-btn' onClick={() => onChangeItem(item, index)}><AiFillEdit /></button></td>
        <MetImageTD
          refetchTumors={() => console.info('YOU SHOULDNT BE ABLE TO UPLOAD SO IDK HOW YOU TRIGGERED THIS FUNCTION')}
          met={item}
          path={'NO'}
          canEdit={false}
        />
        {!nonEditable && <td className='met-manager__table-td'><Checkbox label='' checked={checked.includes(item.id)} onChange={() => handleChecked(item.id)} /></td>}
      </tr>
    );
  };


  const renderImages = (item: any) => {
    if (Array.isArray(item.metReport) && item.metReport.length > 0) {
      return (
        < div className='img-preview'>
          {item.metReport.map((itm: any) => (
            <>
              <div className='report-img'>
                <Label size='h5'>{item.tumorLabel}</Label>
              </div>
              <img src={itm.backgroundUrl} alt='scan-preview' />
              <Spacer value={10} />
            </>
          ),
          )}
        </div>
      );
    }
    return (<div />);
  };


  const sort = (items: MetT[]) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => a.tumorLabel.localeCompare(b.tumorLabel));
      return test;
    } catch {
      console.error('failed to sort MET data');
      return items;
    }
  };


  return (
    <div className="card">
      <div className='border-heading'>
        <Label size='h4' weight={600}>Mets:</Label>
      </div>
      <table className='met-manager__table'>
        <thead className='met-manager__table-thead'>
          <th className='met-manager__table-th'>
            <Label size='h6' uppercase>MET</Label>
          </th>
          <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Lobe</Label>
          </th>
          <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Laterality</Label>
          </th>
          <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Coordinates</Label>
          </th>
          <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Dimensions</Label>
          </th>
          {expanded && <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Treated</Label>
          </th>}
          {expanded && <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Treatable</Label>
          </th>}
          {expanded && <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Added At Baseline</Label>
          </th>}
          <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Actions</Label>
          </th>
          <th className='met-manager__table-th'>
            <Label size='h6' uppercase>Images</Label>
          </th>
          <th className='met-manager__table-th'> <FcCheckmark /></th>
        </thead>

        <tbody>
          {Array.isArray(mets) && mets.length > 0 && sort(mets).map((item: MetT, index) => (
            renderRow(item, index, false)
          ),
          )}
        </tbody>
      </table>
      <div className="attachments">{children}</div>
      <Spacer value={10} />
      <div className='btn-row'>
        <Button kind='danger' onClick={cancelReviewMode}>Cancel</Button>
        <Button disabled={mets?.length !== checked?.length} onClick={() => setPrintModal(true)}>Sign and Submit</Button>
      </div>
      <Spacer value={20} />

      <Modal title='Print' size='lg' open={printModal} onClose={() => setPrintModal(false)}>
        <PrintForm
          protocolItem={protocolItem}
          info={info}
          path={path}
          submitSurvey={handleSubmit}
        >
          <>
            <table className='met-manager__table'>
              <thead className='met-manager__table-thead'>
                <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>MET</Label>
                </th>
                <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Lobe</Label>
                </th>
                <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Laterality</Label>
                </th>
                <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Coordinates</Label>
                </th>
                <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Dimensions</Label>
                </th>
                {expanded && <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Treated</Label>
                </th>}
                {expanded && <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Treatable</Label>
                </th>}
                {expanded && <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Added At Baseline</Label>
                </th>}
                <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Actions</Label>
                </th>
                <th className='met-manager__table-th'>
                  <Label size='h6' uppercase>Images</Label>
                </th>
              </thead>

              <tbody>
                {Array.isArray(mets) && mets.length > 0 && sort(mets).map((item: MetT, index) => (
                  renderRow(item, index, true)
                ),
                )}
              </tbody>
            </table>
            {/* {Array.isArray(mets) && mets.length > 0 && sort(mets).map((item: Met, index) => (
              renderImages(item)
            ),
            )} */}
            <div className="attachments">{children}</div>
          </>
        </PrintForm>
      </Modal>

    </div>
  );
};

MetReview.defaultProps = {
  // key: '_key',
};

MetReview.displayName = 'MetReview';


export default MetReview;
