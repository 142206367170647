import React from 'react';
// import classnames from 'classnames';
import './participant-list-item.scss';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { BsClipboardCheck } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Spacer, Label, Button, Modal, Badge, Alert } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import ConsentManager from '../ConsentManager/ConsentManager';
import { usePrefetch } from '../../../api/builders/participantProtocol.api';

export enum ParticipantOpenModes {
  DEFAULT = 'DEFAULT',
  AUDIT = 'AUDIT',
  ANALYSIS = 'ANALYSIS',
  INSIGHTS = 'INSIGHTS',
  LOGS = 'LOGS',
}

type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  type: string | undefined;
  id: string;
  consentStatus: string;
  group?: string,
};

export interface ParticipantListItemProps {
  participant: ParticipantItem,
  prefix: string,
  sitePath: string,
  binderPath: string,
  convertCandidate: (payload: { id: string, participantId: string }) => void,
  onUpdateConsent: (payload: { id: string, status: string }) => void,
  onUpdateTx: (payload: { id: string, treatment: string }) => void,
  acl: string,
  consentModal: any,
  setConsentModal(arg: any): any;
  txModal: any,
  setTxModal(arg: any): any;
  siteId: string,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const renderConsentTd = (cStatus: string) => {
  switch (cStatus) {
    case 'not consented':
      return (
        // <Tooltip arrow title="Not Consented" placement="right">
        <AiOutlineCloseCircle className="red-text" size={20} />
        // </Tooltip>
      );
    case 'consented':
      return (
        // <Tooltip arrow title="Consented" placement="right">
        <BsClipboardCheck className="green-text" size={20} />
        // </Tooltip>
      );
    case 'revoked':
      return (
        // <Tooltip arrow title="Revoked" placement="right">
        <RiErrorWarningLine className="yellow-text" size={20} />
        // </Tooltip>
      );
    default:
      return (
        <AiOutlineCloseCircle className="red-text" size={20} />
      );
  }
};

const RenderParticipant = ({ participant, onOpen, acl, handleOpenTx, handleChangeTx, setConsentModal, prefix, onHover }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popid = open ? 'simple-popover' : undefined;
  const { id, type, participantId, screeningId, consentStatus, group, secondaryId } = participant;

  const [openError, setOpenError] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openConsent = () => {
    handleClose();
    setConsentModal(true);
  };

  const messageTimer = () => {
    const timer = setTimeout(() => setOpenError(false), 2000);
    return () => clearTimeout(timer);
  };

  React.useEffect(() => {
    if (openError) {
      messageTimer();
    }
  }, [openError]);

  const handleOpen = (participantTMP: any) => {
    onOpen(participantTMP);
    // if (consentStatus === 'consented') {
    //   onOpen(participantTMP);
    // } else {
    //   setOpenError(true);
    // }
  };
  const handleOpenAx = (participantTMP: any) => {
    console.info('analysis');
  };



  const renderActions = () => {
    switch (acl) {
      case 'admin':
      case 'SITE COORDINATOR':
        return (<Grid xs={1} className='participant-list-item__action-wrapper'>
          <button type='button' className='icon-btn' aria-describedby={popid} onClick={handleClick}>
            <BiDotsHorizontalRounded size={24} />
          </button>
        </Grid>);
      case '(BLINDED)':
      case 'STUDY COORDINATOR':
      case 'SPONSOR (BLINDED)':
      case 'CORELAB':
        return (<div />);
      default:
        return (<div />);
    }
  };

  return (
    <>
      <div onMouseEnter={() => onHover(participant)} className='participant-list-item__tile'>
        <Grid columns={13} container spacing={2} >
          <Grid xs={2} onClick={() => handleOpen(participant)} className='participant-list-item__col'>
            <Badge label={type} color='blue' />
          </Grid>
          <Grid xs={3} onClick={() => handleOpen(participant)} className='participant-list-item__col'>
            <Label size="h5" center>{participantId}</Label>
          </Grid>
          <Grid xs={3} onClick={() => handleOpen(participant)} className='participant-list-item__col'>
            <Label size="h5" center>{secondaryId}</Label>
          </Grid>
          <Grid xs={2} onClick={() => handleOpen(participant)} className='participant-list-item__col'>
            <Label size="h5" center>{renderConsentTd(consentStatus)}</Label>
          </Grid>
          <Grid xs={2} onClick={() => handleOpen(participant)} className='participant-list-item__col'>
            <Label size="h5" center>{group && group !== 'null' ? group : 'N/A'}</Label>
          </Grid>
          {renderActions()}
        </Grid>
      </div>
      <Popover
        id={popid}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='participant-list-item__popover-body' >
          <Button kind='ghost' onClick={openConsent}>Update Consent</Button>
          <Button kind='ghost' onClick={handleOpenTx}>Change Treatment</Button>
          <Button kind='ghost' onClick={() => onOpen(participant, ParticipantOpenModes.ANALYSIS)}>Analyze</Button>
        </div>
      </Popover>
    </>
  );
};

const RenderPhantom = ({ participant, onOpen, acl, handleOpenTx, handleChangeTx, setConsentModal, prefix, onHover }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popid = open ? 'simple-popover' : undefined;
  const { id, type, participantId, screeningId, consentStatus, group, secondaryId } = participant;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div onMouseEnter={() => onHover(participant)} className='participant-list-item__tile'>
      <Grid container columns={13}  spacing={2} onClick={() => onOpen(participant)}>
        <Grid xs={2} className='participant-list-item__col'>
          <Badge label={type} color='gray' />
        </Grid>
        <Grid xs={3} className='participant-list-item__col'>
          <Label center size="h5">{participantId}</Label>
        </Grid>
        <Grid xs={3} className='participant-list-item__col'>
          <Label size="h5" center>{secondaryId}</Label>
        </Grid>
        <Grid xs={4} />
      </Grid>
    </div>
  );
};
const RenderScreening = ({ participant, convertCandidate, onOpen, acl, handleChangeTx, setConsentModal, prefix, onHover }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popid = open ? 'simple-popover' : undefined;
  const [modal, setModal] = React.useState(false);
  const [newPat, setNewPat] = React.useState('');
  const { id, type, participantId, screeningId, consentStatus, secondaryId } = participant;

  const handleChangenewParticipantVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPat(event.target.value);
  };


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setModal(false);
    setNewPat('');
  };

  const openModal = () => {
    setModal(true);
    handleClose();
    setNewPat(`${prefix}-`);
  };
  const openConsent = () => {
    handleClose();
    setConsentModal(true);
  };

  const updateParticipant = async () => {
    const payload = {
      id: participant.id,
      participantId: newPat,
    };
    await convertCandidate(payload);
    closeModal();
  };

  const renderActions = () => {
    switch (acl) {
      case 'admin':
      case 'SITE COORDINATOR':
        return (<Grid xs={1} className='participant-list-item__action-wrapper'>
          <button type='button' className='icon-btn' aria-describedby={popid} onClick={handleClick}>
            <BiDotsHorizontalRounded size={24} />
          </button>
        </Grid>);
      case '(BLINDED)':
      case 'STUDY COORDINATOR':
      case 'SPONSOR (BLINDED)':
      case 'CORELAB':
        return (<div />);
      default:
        return (<div />);
    }
  };

  return (
    <>
      <div onMouseEnter={() => onHover(participant)} className='participant-list-item__tile'>
        <Grid container spacing={2} columns={13}>
          <Grid xs={2} className='participant-list-item__col'>
            <Badge label={type} color='dark-blue' />
          </Grid>
          <Grid xs={3} className='participant-list-item__col'>
            <Label size="h5" center>{screeningId}</Label>
          </Grid>
          <Grid xs={3} className='participant-list-item__col'>
            <Label size="h5" center>{secondaryId}</Label>
          </Grid>
          <Grid xs={2} className='participant-list-item__col'>
            <Label size="h5" center>{renderConsentTd(consentStatus)}</Label>
          </Grid>
          <Grid xs={2} className='participant-list-item__col'>
            <Label size="h5" center>N/A</Label>
          </Grid>
          {renderActions()}
        </Grid>
      </div>
      <Popover
        id={popid}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='participant-list-item__popover-body' >
          <Button kind='ghost' onClick={openModal}>Convert to Participant </Button>
          <Button kind='ghost' onClick={openConsent}>Update Consent</Button>
        </div>
      </Popover>
      <Modal size='sm' open={modal} onClose={closeModal} title='Change to Participant'>
        <div>
          <div className='d-flex'>
            <Label className="input__label" size="h5" weight={600} color="primary" >Screening ID: </Label>
            <Spacer value={5} />
            <Label className="input__label" size="h5" weight={300} color="primary" >{screeningId}</Label>
          </div>
          <Spacer value={10} />
          <TextInput
            id='newpat'
            value={newPat}
            label='Participant ID:'
            onChange={handleChangenewParticipantVals}
          />
          <Spacer value={20} />
          <div className='d-flex-row-center'>
            <Button outline kind="danger" onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={updateParticipant}  >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
const ParticipantFactory = (props: ParticipantListItemProps) => {
  const { participant, convertCandidate, prefix, sitePath, binderPath, onUpdateTx, acl, consentModal, setConsentModal, txModal, setTxModal, siteId } = props;
  const { id, type, participantId, screeningId, consentStatus, group } = participant;
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'error',
  });


  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'error',
    });
  };


  const [newTx, setNewTx] = React.useState(group && group !== 'null' ? group : '');

  const handleOpenTx = () => {
    setTxModal(true);
  };

  const handleSubmitTx = async () => {
    const payload = {
      id: participant.id,
      treatment: newTx,
    };
    await onUpdateTx(payload);
    setTxModal(false);
  };

  const handleChangeTx = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewTx(event.target.value);
  };

  const navigate = useNavigate();

  const onOpen = (_item: ParticipantItem, _mode?: ParticipantOpenModes) => {
    const mode = _mode || ParticipantOpenModes.DEFAULT;
    switch (mode) {
      case ParticipantOpenModes.AUDIT:
        // Handle AUDIT mode logic here
        navigate('/private/study/participant/audit', { state: { participant: _item, sitePath, acl, siteId } });
        break;
      case ParticipantOpenModes.ANALYSIS:
        // Handle ANALYSIS mode logic here
        navigate('/private/study/participant/analysis', { state: { participant: _item, sitePath, acl, siteId } });
        break;
      case ParticipantOpenModes.INSIGHTS:
        // Handle INSIGHTS mode logic here
        navigate('/private/studies/study/site/participant/insights', { state: { participant: _item, sitePath, acl, siteId } });
        break;
      case ParticipantOpenModes.LOGS:
        // Handle LOGS mode logic here
        break;
      case ParticipantOpenModes.DEFAULT:
      default:
        if (_item.consentStatus === 'consented' || _item.type === 'phantom') {
          navigate('/private/studies/study/site/participant', { state: { participant: _item, sitePath, acl, siteId } });
        }
        setAlert({
          open: true,
          message: 'Participant must be consented to access',
          kind: 'error',
        });
    }
    setAlert({
      open: true,
      message: 'Participant must be consented to access',
      kind: 'error',
    });
  };

  const prefetchpatient = usePrefetch('getparticipantInfo');

  const onHover = (hoveritem: any) => {
    prefetchpatient({ participantId: hoveritem?.id ? hoveritem.id : skipToken });
  };



  const switchType = (_type: string | undefined) => {
    switch (_type) {
      case 'phantom':
        return <RenderPhantom onHover={onHover} prefix={prefix} onOpen={onOpen} participant={participant} acl={acl} handleChangeTx={handleChangeTx} setConsentModal={setConsentModal} />;
      case 'candidate':
        return <RenderScreening convertCandidate={convertCandidate} onHover={onHover} prefix={prefix} onOpen={onOpen} participant={participant} acl={acl} handleChangeTx={handleChangeTx} setConsentModal={setConsentModal} />;
      case 'participant':
        return <RenderParticipant handleOpenTx={handleOpenTx} onHover={onHover} prefix={prefix} onOpen={onOpen} participant={participant} acl={acl} handleChangeTx={handleChangeTx} setConsentModal={setConsentModal} />;
      default:
        return (
          <div>
            <Label>Error Rendering Participant</Label>
          </div>);
    }
  };

  return <div className='participant-list-item__wrapper'>
    {switchType(type)}
    <Alert
      severity={alert.kind}
      open={alert.open}
      onClose={resetAlert}
      message={alert.message}
    />
  </div>;
};

ParticipantFactory.defaultProps = {
  // key: '_key',
};

ParticipantFactory.displayName = 'ParticipantFactory';


export default ParticipantFactory;
