import React from 'react';
import classnames from 'classnames';
import './text-area-input.scss';
import { Label } from '../../atoms';

export interface TextAreaInputProps  {
  kind?: 'primary' | 'secondary' | 'ghost' | 'invalid',
  disabled?: boolean,
  invalid?: boolean,
  label?: string | undefined,
  name?: string,
  value?: string,
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
  id?: string,
  placeholder?: string,
  type?: string,
  autocomplete?: string,
  rows?: number,
  className?: string;
}

const TextAreaInput = (props: TextAreaInputProps) => {
  const { kind, disabled, invalid, label, name, value, onChange, id, placeholder, className, autocomplete, rows } = props;
  
  const classes = {
    'text-input': true,
    [`text-input--${kind}`]: true,
    'text-input--disabled': disabled,
    'text-input--invalid': invalid,
    [`${className}`]: true,
  };

  return (
		<div>
			{label && <Label className="input__label" size="h5" weight={600}  color="primary" >{label}</Label>}
			<textarea
				id={id}
				aria-label={id}
				onChange={onChange}
				className={classnames(classes)}
				value={value}
				disabled={disabled}
				autoComplete={autocomplete}
				// defaultValue={props.defaultValue}
				placeholder={placeholder}
				name={name}
        rows={rows}
			/>
		</div>
  ); 
};

TextAreaInput.defaultProps = {
  id:'randomidhere',
  kind: 'grey',
  disabled: false,
  invalid: false,
  type: 'text',
  name: '',
  value:'',
  onChange:()=>{},
  placeholder:null,
  autocomplete:'off',
  rows: 3,
  label: '',
  className: 'custom-text-area-input',
};

TextAreaInput.displayName = 'TextAreaInput';


export default TextAreaInput;
