/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
// import classnames from 'classnames';
import './question-types.scss';
import { Label, Spacer } from '../../atoms';
import TextInput from '../TextInput/TextInput';

export interface NumberProps {
  className?: string,
  answer: any,
  onAnswer: (vals: any) => void,
  min?: number,
  max?: number,
}

const Number = (props: NumberProps): JSX.Element => {
  const { answer, onAnswer, className, min, max } = props;
  const [value, setValue] = useState<any>();
  const [invalidResponse, setInvalidResponse] = useState(false);

  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value && answer[0].value !== 'invalidValue') {
      setValue(answer[0].value);
    }
  }, [answer]);


  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(event.target.value);

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(val)) {
      if ((min !== undefined && val < min) || (max !== undefined && val > max)) {
        setInvalidResponse(true);
        onAnswer?.([{ value: 'invalidValue', label: 'invalidValue' }]);
      } else {
        setInvalidResponse(false);
        onAnswer?.([{ value: val.toString(), label: val.toString() }]);
      }
    } else {
      // Handle case when the input value is not a valid number
      setInvalidResponse(true);
      onAnswer?.([{ value: 'invalidValue', label: 'invalidValue' }]);
    }
    setValue(val);

  };



  return (
    <div className='question-types_text'>
      <Spacer value={20} />
      <TextInput
        type='number'
        value={value}
        onChange={onChange}
      />
      {(invalidResponse) &&
        <>
          {min === 0 ?
            <Label size="subtext" color="danger">
              Does not allow negative numbers
            </Label>
            :
            <Label size="subtext" color="danger">Value must be between {min} and {max}</Label>
          }
        </>
      }
    </div>
  );
};

Number.defaultProps = {
  className: '',
};

Number.displayName = 'Number';
export default Number;
