import React, { useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import { Tile, Label, Spacer, Button, Modal } from '../../atoms';
import { useCreateSignedUrlMutation } from '../../../api/api';
import './care-pathway-print-form.scss';


export interface CarePathwayPrintFormProps {
  children: JSX.Element,
  info: any,
  path: string | undefined,
  submitSurvey: () => void;
  className?: string,
}

const CarePathwayPrintForm = (props: CarePathwayPrintFormProps): JSX.Element => {
  const { info, path, submitSurvey, children } = props;
  const [sigValidate, setSigValidate] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [signerName, setSignerName] = React.useState('');

  const [getPUTSignedUrl] = useCreateSignedUrlMutation();


  const componentRef = React.useRef(null);
  const sigCanvas = React.useRef<SignatureCanvas>(null);

  const finishSigning = () => {
    setSigValidate(false);
  };

  const handleAfterPrint = React.useCallback(() => {
    console.info('after'); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    const someVar = '';
    return componentRef.current;
  }, [componentRef.current]);


  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: info ?? 'CRF',
    onBeforeGetContent: () => console.info('loading'),
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const uploadToS3 = async (url: string, file: any): Promise<boolean> => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': 'application/pdf' },
      });
      return response.ok;
    } catch (e2) {
      console.error('E2', e2);
      return false;
    }
  };

  const uploadPdfToS3 = async (pdfBlob: Blob) => {
    try {
      const fullPath = `${path}/${info}_${moment().format('YYYY-MM-DD LTS')}.pdf`;
      const payload = {
        s3_path: fullPath,
        ContentType: 'application/pdf',
      };
      const data = await getPUTSignedUrl({ files: [payload] }).unwrap();
      const uploaded = await uploadToS3(data[0].signedUrl, pdfBlob);
      if (uploaded) {
        submitSurvey();
      } else {
        console.error('File upload to S3 failed.');
      }
      setLoading(false);
    } catch (e3) {
      console.error('e3', e3);
    }
  };

  const handleUploadClick = () => {
    setLoading(true);
    const element = componentRef.current;
    const options = {
      margin: [0, 0, 0, 0],
      filename: 'crf.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf()
      .set(options)
      .from(element)
      .output('blob')
      .then((pdfBlob: Blob) => {
        uploadPdfToS3(pdfBlob);
      });
  };


  return (
    <div>
      <div className='protocol-print-form' id='divToPrint' ref={componentRef}>
        <div className='protocol-print-form__header'>
          <Label size='h5' uppercase center>{` CRF / ${info}  `}</Label>
        </div>

        <Spacer value={20} />

        {children}

        <Spacer value={20} />

        <div className='protocol-print-form__signature-wrapper'>
          <Label size='h6'>Completed on: {moment().format('LLL')}</Label>
          <Spacer value={5} />
          <Label size='h6'>Name:</Label>
          <input type='text' className='protocol-print-form__sig-input' value={signerName} onChange={(e) => setSignerName(e.target.value)} />
          <Spacer value={20} />
          <Label size='h6'>Signature:</Label>
          <SignatureCanvas
            penColor='black'
            ref={sigCanvas}
            onEnd={finishSigning}
            maxWidth={1}
            canvasProps={{ width: 500, height: 150, className: 'protocol-print-form__signature' }}
          />
        </div>
      </div>
      <div className='btn-row'>
        <Button disabled={sigValidate} onClick={handlePrint}> Print</Button>
        <Button disabled={sigValidate} loading={loading} onClick={handleUploadClick}>Save & Close</Button>
      </div>
    </div>
  );
};

CarePathwayPrintForm.defaultProps = {
  className: '',
};

CarePathwayPrintForm.displayName = 'CarePathwayPrintForm';
export default CarePathwayPrintForm;
