import React from 'react';
import { MenuItem } from '@mui/material';
import { Dropdown, Label, Spacer, Button, Alert } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';
import CountryList from '../../../../utils/countryList';
import { AlertState } from '../../../../types/types';

export interface LocationProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleNextStep: () => void,
}

const Location = (props: LocationProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleNextStep } = props;
  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: 'You must currently reside in the United States to participate',
    kind: 'error',
  });

  const resetAlert = () => {
    setAlert({
      open: false,
      message: 'You must currently reside in the United States to participate',
      kind: 'error',
    });
  };

  const handleChangeCountry = (e: any) => {
    const { name, value } = e.target;
    handleChange((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    if (e.target.name === 'country' && e.target.value !== 'United States') {
      setAlert({ open: true, message: 'You must currently reside in the United States to participate', kind: 'error' });
    }
  };

  const isValidUSZip = (sZip: string) => /^\d{5}(-\d{4})?$/.test(sZip);

  const validate = () => {
    if (
      state.zip &&
      state.zip.trim() !== '' &&
      isValidUSZip(state.zip) &&
      state.country &&
      state.country.trim() !== '' &&
      state.country.trim() === 'United States'
    ) {
      return false;
    }
    return true;
  };


  return (
    <div>
      <Label size="h3" bold color="primary" center >
        Where do you live?
      </Label>
      <Spacer value={20} />

      <TextInput
        name="zip"
        label="Zip Code"
        type=""
        value={state.zip}
        onChange={handleChangeCountry}
      />
      <Spacer value={10} />
      <Dropdown
        label="Country"
        name="country"
        id="country"
        value={state.country}
        onChange={handleChangeCountry} >
        {CountryList.map((countr: string) => (
          <MenuItem value={countr}>{countr}</MenuItem>
        ))}
      </Dropdown>

      <Spacer value={20} />
      <div className='btn-row'>
        <Button onClick={handlePrevStep}>Previous</Button>
        <Button onClick={handleNextStep} disabled={validate()}>Next</Button>
      </div>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

Location.defaultProps = {
};

Location.displayCountry = 'Location';
export default Location;
