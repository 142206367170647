import React from 'react';
import './app-bar.scss';
import HSGBar from './HSGBar';
import NormalBar from './NormalBar';


type Props = {
  navigation?: { title: string, url: string, private?: boolean, submenu?: { title: string, url: string, }[] }[],
  userMenuItems?: { title: string, url: string, private?: boolean }[],
  token: string | undefined,
  logout: () => void,
};



const ResponsiveAppBar = (props: Props) => {
  const { navigation, userMenuItems, token, logout } = props;
  const systemType = process.env.REACT_APP_SYSTEM;


  return (
    <>
      {systemType === 'HSG' ?
        <HSGBar navigation={navigation} userMenuItems={userMenuItems} token={token} logout={logout} />
        :
        <NormalBar navigation={navigation} userMenuItems={userMenuItems} token={token} logout={logout} />
      }
    </>
  );
};

ResponsiveAppBar.defaultProps = {
  navigation: [{ title: '', url: '' }],
  userMenuItems: [{ title: '', url: '' }],
};

export default ResponsiveAppBar;
