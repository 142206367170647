import React from 'react';
import moment from 'moment';
import { DatePicker, Label, Spacer, Button } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';

export interface DOBProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleNextStep: () => void,
}

const DOB = (props: DOBProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleNextStep } = props;

  const isValidBirthday = (date: string) => {
    const val = moment(date).format('MM/DD/YYYY');

    const value = new Date(date);

    const currYear = new Date();
    const minYear = new Date().getFullYear() - 100;
    const minDate = new Date(`01/01/${minYear}`);

    if (value < minDate || value > currYear || val === 'Invalid date') {
      return false;
    }
    return true;

  };

  const validate = () => {
    if (
      isValidBirthday(state.dob) &&
      state.dob
    ) {
      return false;
    } 
    return true;
  };



  return (
    <div>
      <Label size="h3" bold color="primary" center >What is your Date of Birth?</Label>
      <Spacer value={20} />
      <DatePicker
        value={state.dob}
        onChange={handleChange}
        id="dob"
        label='Date of Birth'
      />

      <Spacer value={20} />
      <div className='btn-row'>
        <Button onClick={handlePrevStep}>Previous</Button>
        <Button onClick={handleNextStep} disabled={validate()}>Next</Button>
      </div>
    </div>
  );
};

DOB.defaultProps = {
};

DOB.displayName = 'Name';
export default DOB;
