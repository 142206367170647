import { SurveyNewT } from '../../types/hsgTypes';

const isSurveyClickable = (currentSurvey: SurveyNewT, sortedSurveys: SurveyNewT[]): boolean => {
  // Filter out completed surveys and find the lowest order among pending surveys
  const pendingSurveys = sortedSurveys.filter(survey => survey.status === 'pending');
  const lowestPendingOrder = pendingSurveys.length > 0 ?
    Math.min(...pendingSurveys.map(survey => parseInt(survey.order, 10))) : 1;

  // Check if the current survey's order is the lowest pending order
  // and if the current survey is the first survey in the list
  return (
    parseInt(currentSurvey.order, 10) === lowestPendingOrder
  );
};

export default isSurveyClickable;