import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Name, DOB, Gender, Mother, Location, Born, EmailOp, HowHear, Assistant, ThankYou } from './FormStates';
import './create-profile.scss';
import { Alert, Button, Logo, Spacer } from '../../atoms';
import { useCreateUserProfileMutation } from '../../../api/builders/hsg.api';
import { AlertState, ApiError } from '../../../types/types';
import { selectAuth } from '../../../store/authentication/slice';

export interface CreateProfileProps {
  className?: string,
}

const CreateProfile = (props: CreateProfileProps): JSX.Element => {
  const { user } = useSelector(selectAuth);
  const [createProfile, { isLoading, isError, isSuccess, error }] = useCreateUserProfileMutation();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstname: '',
    surname: '',
    lastNameAtBirth: '',
    gender: [],
    genderOther: '',
    mother: '',
    phone_number: '+',
    emailOP: [],
    dob: '',
    country: 'United States',
    zip: '',
    city: '-',
    state: '-',
    birthCity: '',
    birthState: '',
    birthCountry: 'United States',
    assistance: [],
    hear: '',
  });

  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'success',
  });


  const handleNextStep = () => {
    setStep((prevStep: any) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep: any) => prevStep - 1);
  };

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Creating Profile: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Creating Profile',
        kind: 'success',
      });
      setStep(10);
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleChangeDate = (e: any) => {
    if (e) {
      const val = moment(e.$d).format('L');
      setFormData((prevData: any) => ({
        ...prevData,
        dob: val,
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        dob: '',
      }));
    }
  };

  const handleCreateProfile = () => {
    try {
      const payload = {
        cognitoId: user,
        firstname: formData.firstname,
        lastname: formData.surname,
        lastnameAtBirth: formData.lastNameAtBirth,
        dob: formData.dob,
        gender: formData.gender[0] === 'Other' ? formData.genderOther : formData.gender[0],
        motherMaidenname: formData.mother,
        receiveEmail: formData.emailOP[0],
        assistant: formData.assistance,
        zip: formData.zip,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        cityOfbirth: formData.birthCity,
        stateOfbirth: formData.birthState,
        countryOfbirth: formData.birthCountry,
        hear_myHDstory: formData.hear[0],
      };
      createProfile(payload);
    } catch {
      console.error('err creating profile');
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Name state={formData} handleChange={handleChange} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />;
      case 2:
        return <DOB state={formData} handleChange={handleChangeDate} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />;
      case 3:
        return <Gender state={formData} handleChange={setFormData} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />;
      case 4:
        return <Mother state={formData} handleChange={handleChange} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />;
      case 5:
        return <Location state={formData} handleChange={setFormData} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />;
      case 6:
        return <Born state={formData} handleChange={setFormData} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />;
      case 7:
        return <EmailOp state={formData} handleChange={setFormData} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />;
      case 8:
        return <HowHear state={formData} handleChange={setFormData} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} />;
      case 9:
        return <Assistant state={formData} handleChange={setFormData} handlePrevStep={handlePrevStep} handleCreateProfile={handleCreateProfile} loading={isLoading} />;
      case 10:
      case 11:
        return <ThankYou state={formData} />;
      default:
        return null;
    }
  };


  return (
    <div className='create-profile-form'>
      <div className='super-centered'>
        <Logo size='75px' type='small' />
      </div>
      {renderStep()}

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

CreateProfile.defaultProps = {
  className: '',
};

CreateProfile.displayName = 'CreateProfile';
export default CreateProfile;
