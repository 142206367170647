/* eslint-disable no-else-return */
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Logo, Button, Modal, Label, Spacer } from '../../atoms';
import './app-bar.scss';
import { logout, selectAuth } from '../../../store/authentication/slice';
import stringAvatar from '../../../utils/fns/stringAvatar';
import api from '../../../api/api';
import { useGetUserProfileQuery } from '../../../api/builders/hsg.api';
import { SurveyContext } from '../../../utils/SurveyInProgress/surveyInProgress';


type Props = {
  navigation?: { title: string, url: string, private?: boolean, submenu?: { title: string, url: string, }[] }[],
  userMenuItems?: { title: string, url: string, private?: boolean }[],
  token: string | undefined,
  logout: () => void,
};

const defaultDropdownMenu = [
  {
    title: '',
    url: '',
  },
];



const UserProfileEnd = () => {
  const { data: userProfile } = useGetUserProfileQuery(' ');
  const navigate = useNavigate();

  const handleOpenUserMenu = () => {
    navigate('/profile');
  };
  const handleGoToCreate = () => {
    navigate('/create-profile');
  };

  const renderer = () => {
    if (!userProfile || !userProfile[0]) {
      return (
        <Button kind='secondary' size='wide' onClick={handleGoToCreate} >CREATE PROFILE</Button>
      );
    } else if (!userProfile[0].country) {
      return (
        <Button kind='secondary' size='wide' onClick={handleGoToCreate} >CREATE PROFILE</Button>
      );
    }
    return (
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar className="user-avatar" >{stringAvatar(`${userProfile[0].firstname} ${userProfile[0].lastname}`)}</Avatar>
      </IconButton>
    );
  };


  return (
    <>
      {renderer()}
    </>
  );

};





const HSGBar = (props: Props) => {
  const { surveyInProgress, setSurveyInProgress } = useContext(SurveyContext);
  const navigate = useNavigate();
  const { navigation, userMenuItems } = props;
  const dispatch = useDispatch<any>();
  const { token } = useSelector(selectAuth);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [route, newRoute] = React.useState('');
  const [popup, setPopup] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(defaultDropdownMenu);


  const location = useLocation();
  const isPageActive = (path: string): boolean => location.pathname === path;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, page: { title: string, url: string, }[]) => {
    setAnchorEl(event.currentTarget);
    setDropdown(page);
  };


  const confirmNaviagte = () => {
    setPopup(false);
    setSurveyInProgress(false);
    navigate(route);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  const handleLinkClick = (toGo: any) => {
    if (surveyInProgress && location.pathname === '/survey') {
      newRoute(toGo);
      setPopup(true);
    } else {
      navigate(toGo);
    }
    handleCloseNavMenu();
    // setBreadcrumbs([]);
  };

  const handleCancelNav = () => {
    newRoute('');
    setPopup(false);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const leaveMenu = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorElNav(event.currentTarget);
    }
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogout = () => {
    // console.log('LOGOUT 1');
    dispatch(logout());
    dispatch(api.util.resetApiState());
    // navigate('/home');
    // handleCloseUserMenu();
  };


  return (
    <AppBar position='static' className='app-bar' color='inherit' >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Raleway',
              fontWeight: 800,
              letterSpacing: '.2rem',
              color: '#0C6EF8',
              textDecoration: 'none',
              mt: 1,
              mb: 1,
            }}
          >
            <Logo size={200} type='text' />
          </Typography>
          {token && token != null &&
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {navigation?.map((page) => (
                  <div key={page.title}>
                    <button onClick={() => handleLinkClick(page.url)} className={isPageActive(page.url) ? 'nav-link nav-link__active' : 'nav-link'} type='button' >{page.title}</button>
                  </div>
                ))}
                <button onClick={() => handleLinkClick('/profile')} className={isPageActive('/profile') ? 'nav-link nav-link__active' : 'nav-link'} type='button' >Profile</button>

                <MenuItem key='logout' onClick={onLogout}>
                  <NavLink to='#' onClick={(e) => { e.preventDefault(); onLogout(); }}>Sign Out</NavLink>
                </MenuItem>
              </Menu>
            </Box>}
          <Typography
            variant='h5'
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Raleway',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '5B7EDD',
              textDecoration: 'none',
            }}
          >
            <Logo size={200} type='text' />
          </Typography>
          <Box className='hsg-menu' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} >

            {token && navigation?.map((page) => (
              <div key={page.title}>
                {page?.submenu ?
                  <div>
                    <Typography
                      aria-owns={open ? 'menu-appbar-2' : undefined}
                      aria-haspopup='true'
                      onMouseEnter={(e) => handlePopoverOpen(e, page?.submenu ? page.submenu : [])}
                    >
                      <MenuItem key={page?.url}>
                        <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={page.url}>{page?.title}</NavLink>
                      </MenuItem>
                    </Typography>
                    <Menu
                      sx={{ mt: '45px' }}
                      id='menu-appbar-2'
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(open)}
                      onClose={handlePopoverClose}
                    >
                      <div onMouseLeave={leaveMenu}>
                        {dropdown.map((sub) => (
                          <MenuItem key={sub.title} onClick={handlePopoverClose}>
                            <NavLink onClick={handleLinkClick} to={sub.url} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} >
                              {sub?.title}
                            </NavLink>
                          </MenuItem>
                        ))}
                      </div>
                    </Menu>
                  </div>
                  :
                  <MenuItem key={page?.title}>
                    <button onClick={() => handleLinkClick(page.url)} className={isPageActive(page.url) ? 'nav-link nav-link__active' : 'nav-link'} type='button' >{page.title}</button>
                  </MenuItem>
                }
              </div>
            ))}
            {token && token != null &&
              <MenuItem key='logout' onClick={onLogout}>
                <NavLink to='#' onClick={(e) => { e.preventDefault(); onLogout(); }}>Sign Out</NavLink>
              </MenuItem>
            }
          </Box>

          {token && token != null ?
            <Box sx={{ flexGrow: 0 }} className='header__end-profile'  >
              <UserProfileEnd />
            </Box>
            :
            <Box sx={{ flexGrow: 0 }}>
              <MenuItem onClick={handleCloseUserMenu} >
                <NavLink to='/login' >Log in </NavLink>
              </MenuItem>
            </Box>
          }
        </Toolbar>
      </Container >

      <Modal open={popup} onClose={handleCancelNav} title='Confirm Exit' size='sm'>
        <div>
          <Label size="h4">Are you sure you want to exit this survey?</Label>
          <Spacer value={20} />
          <Label size="h4">Your progress so far will not be saved.</Label>
          <Spacer value={30} />
          <div className="btn-row">
            <Button onClick={handleCancelNav} kind="ghost">No I don&apos;t want to exit</Button>
            <Button onClick={confirmNaviagte} >Yes</Button>
          </div>
        </div>
      </Modal>
    </AppBar >
  );
};

HSGBar.defaultProps = {
  navigation: [{ title: '', url: '' }],
  userMenuItems: [{ title: '', url: '' }],
};

export default HSGBar;
