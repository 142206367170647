import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import './epilepsy-dashboard.scss';
import { Tile, Label, Spacer, Text } from '../../atoms';
import { useGetAllPatientSurveysQuery } from '../../../api/builders/epilepsy.api';
import { useGetSessionsQuery } from '../../../api/builders/patientSessions.api';
import PatientSurveySwitch from '../PatientSurveySwitch/PatientSurveySwitch';
import EpilepsyAlerts from '../EpilepsyAlerts/EpilepsyAlerts';
import EpilepsySurgery from '../EpilepsySurgery/EpilepsySurgery';
import EpilepsyTable from '../EpilepsyTable/EpilepsyTable';

export interface EpilepsyDashboardProps {
  patient: any,
  className?: string,
}

interface Survey {
  id: string;
  surveyLabel?: string;
  sessionId?: string;
  status?: string;
  questions?: string;
}

const EpilepsyDashboard = (props: EpilepsyDashboardProps): JSX.Element => {
  const { className, patient } = props;
  const { data: surveys, isLoading } = useGetAllPatientSurveysQuery(patient?.id ?? skipToken);
  const { data: patSessions } = useGetSessionsQuery(patient?.id ?? skipToken);
  const [sortedSessions, setSortedSessions] = useState<any>(null);
  const [dashboardSurveys, setDashboardSurveys] = useState({
    NDDIE: [],
    PIES: [],
    QOLIE: [],
    BREASI: [],
    PREVISIT: [],
    PREVISITALL: [],
    PREVISTPREV: [],
    TOPIC: 'Unknown',
  });


  useEffect(() => {
    if (Array.isArray(patSessions) && patSessions.length > 0) {
      const sortedData = [...patSessions].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime(); // Compare date timestamps
      });
      setSortedSessions(sortedData);
    }
  }, [patSessions]);


  useEffect(() => {
    if (surveys && surveys.length > 0 && sortedSessions && sortedSessions.length > 0) {
      const nddie = surveys.filter((s: Survey) => s.surveyLabel && s.surveyLabel === 'NDDIE');
      const pies = surveys.filter((s: Survey) => s.surveyLabel && s.surveyLabel === 'PIES');
      const qolie = surveys.filter((s: Survey) => s.surveyLabel && s.surveyLabel === 'QOLIE-10P');
      const breasi = surveys.filter((s: Survey) => s.surveyLabel && s.surveyLabel === 'brEASI');
      const preVisitAll = surveys.filter(
        (s: Survey) => s.surveyLabel && s.surveyLabel === 'Hartford Pre-Visit Questionnaire',
      );
      const preVisit = surveys.filter(
        (s: Survey) =>
          s.surveyLabel &&
          s.surveyLabel === 'Hartford Pre-Visit Questionnaire' &&
          s?.sessionId === sortedSessions[sortedSessions.length - 1].id,
      );

      let topic = 'Unknown';
      if (Array.isArray(preVisit) && preVisit.length > 0 && preVisit[0]?.status === 'completed') {
        const tmp = JSON.parse(preVisit[0]?.questions);
        if (Array.isArray(tmp) && Array.isArray(tmp[0].answer) && tmp[0].answer[0].label) {
          topic = tmp[0].answer[0].label;
        }
      }


      let prevPrev: [] = [];
      if (preVisitAll && preVisitAll.length > 1 && sortedSessions.length > 1) {
        prevPrev = preVisitAll.filter((s: Survey) => s.sessionId?.[0] === sortedSessions[sortedSessions.length - 2]?.id);
      }

      setDashboardSurveys({
        NDDIE: nddie,
        PIES: pies,
        QOLIE: qolie,
        BREASI: breasi,
        PREVISIT: preVisit,
        PREVISITALL: preVisitAll,
        PREVISTPREV: prevPrev ?? [],
        TOPIC: topic,
      });
    }
  }, [surveys, sortedSessions]);


  return (
    <div className="card">
      <div className='epilepsy-dashboard'>
        <div className='epilepsy-dashboard__panel1' >
          <Tile className="height-100">
            <>
              <Label size="h4" color="ghost">{`${patient?.firstname[0]} ${patient?.lastname[0]} Most Recent Visit:`}</Label>
              <Spacer value={10} />
              <span className='epilepsy-dashboard__list-item'>
                <Label size="h5" weight={600} >Visit Date:</Label>
                <Label size="h5" weight={200} >{Array.isArray(sortedSessions) && sortedSessions?.length > 0 && moment(sortedSessions[sortedSessions.length - 1].date[0]).format('L')}</Label>
              </span>
              <span className='epilepsy-dashboard__list-item'>
                <Label size="h5" weight={600} >Chief Complaint:</Label>
                <Label size="h5" weight={200} >
                  {dashboardSurveys.TOPIC}
                </Label>
              </span>
              <PatientSurveySwitch patient={patient} />
            </>
          </Tile>
          <EpilepsyAlerts
            preVisit={dashboardSurveys.PREVISIT[0]}
            previous={dashboardSurveys.PREVISTPREV}
            nddieSurveys={dashboardSurveys.NDDIE}
            piesSurveys={dashboardSurveys.PIES}
            // qolie10Surveys={dashboardSurveys.QOLIE}
            breasiSurveys={dashboardSurveys.BREASI}
            visits={sortedSessions}
            patient={patient}
            isRefetchingSurveys={isLoading}
          />
          <EpilepsySurgery
            patient={patient}
          />
        </div>
        <div className='epilepsy-dashboard__panel2' >
          <EpilepsyTable
            visits={sortedSessions}
            patient={patient}
            patientSurveys={dashboardSurveys}
          />
        </div>
      </div>
    </div >
  );
};

EpilepsyDashboard.defaultProps = {
  className: '',
};

EpilepsyDashboard.displayName = 'EpilepsyDashboard';
export default EpilepsyDashboard;
