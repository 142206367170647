import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
// import { JsxElement } from 'typescript';
import { PrivateLayout } from '../../components';
import { fetchUser, selectAuth } from '../../store/authentication/slice';

const withAuthenticator = (Component: React.FunctionComponent) => {
  const AppWithAuthenticator = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch = useDispatch<any>();
    const { token, status } = useSelector(selectAuth);

    useEffect(() => {
      if (!token) dispatch(fetchUser());
    }, []);

    if (['loading', 'idle'].includes(status)) return <div>loading</div>;

    return token ? (
      <PrivateLayout>
        <Component  />
      </PrivateLayout>
    ) : (
      <Navigate to="/home" />
    );
  };

  return AppWithAuthenticator;
};

export default withAuthenticator;
