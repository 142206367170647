import React from 'react';
// import classnames from 'classnames';
import { Label, Spacer } from '../../atoms';
import './conference-ready-patient.scss';

export interface ConferenceReadyPatientProps {
  item: any,
  className?: string,
  onOpen?: (pat: any) => void,
  draggable?: boolean,
}

const ConferenceReadyPatient = (props: ConferenceReadyPatientProps): JSX.Element => {
  const { item, className, onOpen, draggable } = props;
  const { id, mrn, lastname, firstname, steps } = item;

  const dragStart = (event: any, pat: any) => {
    try {
      event.dataTransfer.setData('patient', JSON.stringify(pat));
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = () => {
    if (onOpen) {
      onOpen(item);
    }
  };




  return (
    <div
      role='button'
      tabIndex={-1}
      onClick={handleClick}
      className='conference-ready-patient'
      draggable
      onDragStart={(e) => dragStart(e, item)}
    >
      <div className="conference-ready-patient__header">
        <Label size='h5' color='primary'>{firstname}, {lastname}</Label>
        <Label size='h5' color='primary'>{mrn}</Label>
      </div>
      <Spacer value={5} />
      <Label weight={200} size='h6'>Target Conference:</Label>
      <Label weight={200} size='h6' italic >{steps.description}</Label>
      <Label weight={200} size='h6' italic >{steps.scheduledDate}</Label>
    </div>
  );
};

ConferenceReadyPatient.defaultProps = {
  className: '',
  draggable: true,
  onOpen: () => console.info('click'),
};

ConferenceReadyPatient.displayName = 'ConferenceReadyPatient';
export default ConferenceReadyPatient;
