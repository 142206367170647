import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import './year-picker.scss';
import TextInput from '../TextInput/TextInput';
import { Label } from '../../atoms';

export interface YearPickerProps {
  value: string,
  onAnswer: (vals: any) => void,
  pastOnly?: boolean,
}

const YearPicker = (props: YearPickerProps): JSX.Element => {
  const { value, onAnswer, pastOnly } = props;
  const [state, setState] = React.useState<any>();
  const [invalid, setInvalid] = React.useState(false);

  // const datePicker = React.createRef()

  const currYear = new Date().getFullYear();
  const minYear = new Date().getFullYear() - 100;

  React.useEffect(() => {
    if (value && value !== 'Invalid date') {
      const date = new Date(value);
      const n = date.getFullYear();
      setState(n);
    }
  }, [value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Check if the input value is a valid year (four-digit number)
    const isValidYear = /^\d{4}$/.test(val);

    if (!isValidYear) {
      setInvalid(true);
      onAnswer('invalidValue');
      return;
    }

    const year = parseInt(val, 10);
    if (year < minYear || (pastOnly && year > currYear)) {
      setInvalid(true);
      onAnswer('invalidValue');
    } else {
      setInvalid(false);
      onAnswer(val);
    }

    setState(val);
  };





  return (
    <div className="date-picker__outer-wrapper">
      <TextInput
        value={state}
        onChange={onChange}
        placeholder={"Year format 'YYYY'"}
        invalid={invalid}
      />
      {invalid  &&
        <Label size="subtext" color="danger">
          <FiAlertCircle />  Please enter valid year  between {minYear} and {currYear}
        </Label>
      }
    </div>
  );
};

YearPicker.defaultProps = {
  pastOnly: true,
};

YearPicker.displayName = 'YearPicker';
export default YearPicker;
