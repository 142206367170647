import React, { useState, useEffect } from 'react';
import { Tile, Label } from '../../../atoms';
import TextAreaInput from '../../TextAreaInput/TextAreaInput';
import '../form-item.scss';

export interface TextProps {
  answer: any,
  onAnswer: (vals: any) => void,
  editable?: boolean,
  label: string,
  qNumber: number,
  noNumbers: boolean,
  className: string,
}

const Text = (props: TextProps): JSX.Element => {
  const { answer, onAnswer, editable, label, qNumber, noNumbers, className } = props;
  const [value, setValue] = useState('');
  const [state, setState] = React.useState({
    wordCount: 0,
    charCount: 0,
  });
  const [invalid, setInvalid] = useState(false);
  const [rows, setRows] = useState(5); 

  // UPDATE TO USE CHARS INSTEAD OF WORD COUNT?? 
  const countWords = (count: string) => {
    if (count.length === 0) {
      return 0;
    }
    let countN = '';
    countN = count.replace(/(^\s*)|(\s*$)/gi, '');
    countN = count.replace(/[ ]{2,}/gi, ' ');
    countN = count.replace(/\n /, '\n');
    return countN.split(' ').length;
  };

  const updateRows = (text: string) => {
    const words = countWords(text);
    const rowsNeeded = Math.ceil(words / 10); 
    setRows(Math.max(rowsNeeded, 5));
  };

  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      setValue(answer[0].value);

      if (state.wordCount === 0 && answer[0].value) {
        const count = answer[0].value;
        setState({
          wordCount: countWords(count),
          charCount: count.length,
        });

        if (countWords(count) > 300) {
          setInvalid(true);
        }
      }
    }
  }, [answer, state.wordCount]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const txt = event.target.value;

    if (countWords(txt) < 300) {
      setState({
        wordCount: countWords(txt),
        charCount: txt.length,
      });

      setValue(event.target.value);
      setInvalid(false);
      onAnswer([{ 'value': event.target.value, 'label': event.target.value }]);
      updateRows(event.target.value);
    } else {
      setInvalid(true);
    }
  };

  return (
    <div className={`question-types__text ${className}`}>
      {noNumbers ?
        <Label size="h6" weight={200} >{label}</Label>
        :
        <Label size="h6" weight={200} >{qNumber}: {label}</Label>
      }
      <TextAreaInput
        value={value}
        onChange={onChange}
        rows={rows} // Set the number of rows dynamically
        disabled={!editable}
      />
    </div>
  );
};

Text.defaultProps = {
  editable: true,
};

Text.displayName = 'Text';
export default Text;
