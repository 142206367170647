/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Label, Spacer } from '../components/atoms';


const TermsBody = () => (
  < >
    <Label size="h1" bold center >
      Terms and Conditions of Use and Web and Mobile App End User License Agreement
    </Label>
    <Spacer value={20} />
    <Label size="h4" center bold >
      Please read this User Agreement before using this website or mobile application.
    </Label>
    <Spacer value={30} />

    <div >
      {/* header */}
      <Label size="h4" bold >
        1. Welcome to The Huntington Study Group’s online research platform, myHDstory<sup>&reg;</sup>
      </Label>
      <Spacer value={20} />

      {/* body */}
      <Label size="h6" >
        Please carefully review these Terms and Conditions and Web and Mobile App End User License Agreement and any policies referenced in it, all of which are part of the myHDstory<sup>&reg;</sup> User Agreement and Web and Mobile App End User License Agreement (`&quot;` User Agreement `&quot;`). By using myHDstory<sup>&reg;</sup>, you agree to the terms and conditions in this User Agreement.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        If you do not agree to abide by all of the provisions contained in this User Agreement, you must not use or access myHDstory<sup>&reg;</sup>.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        We reserve the right to change any terms of this User Agreement or to modify any features of myHDstory<sup>&reg;</sup> at any time, and, if you continue to use myHDstory<sup>&reg;</sup> either on the web or mobile device you agree to be bound by such changes. You agree to periodically review this User Agreement to determine whether you agree to any new terms, conditions and policies. Any changes to this User Agreement (including the policies) shall become a part of this User Agreement upon publication and shall apply as soon as they are posted. Any new features or functionality that augment or enhance myHDstory<sup>&reg;</sup> shall be subject to this User Agreement upon activation.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        This User Agreement and policies were last updated on December 8, 2021.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold >
        2. In General
      </Label>
      <Spacer value={20} />


      <Label size="h6" >
        myHDstory<sup>&reg;</sup> is the website/platform or mobile application (“Application”) through which individuals participate in online clinical research studies. It is also a means by which visitors may learn about the Huntington Study Group (HSG). By visiting the website or mobile application (“Application”), providing us with your contact information and/or registering on the myHDstory<sup>&reg;</sup> platform, you agree that myHDstory<sup>&reg;</sup> and HSG may use information about you as set forth in our Privacy Policy. By providing your email address or otherwise consenting to receive additional information from myHDstory<sup>&reg;</sup> and HSG, you agree that myHDstory<sup>&reg;</sup> and/or HSG may send you information about myHDstory<sup>&reg;</sup>, other studies and/or other HSG programs and activities. 					</Label>
      <Spacer value={20} />

      <Label size="h6" >
        Nothing about myHDstory<sup>&reg;</sup> website or mobile application should be used to suggest a course of treatment for any patient. Visiting or using the myHDstory<sup>&reg;</sup> website or mobile application will not benefit individual patients. myHDstory<sup>&reg;</sup> is not a health care provider and is no substitute for treatment by a physician or other health care provider.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        myHDstory<sup>&reg;</sup> depends on its users to input accurate and truthful information and does not independently verify the accuracy or truth of such information unless the myHDstory<sup>&reg;</sup> administrative team is specifically notified about a potential problem. You hereby agree to input accurate and truthful information when you use the myHDstory<sup>&reg;</sup> website or mobile application and verify the accuracy of your information if we contact you.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        You may not use any device, software or routine to interfere with the proper working of this website or mobile application. You may not take any action that imposes an unreasonable burden upon the infrastructure used to support the efficient operation of this website or mobile application.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Any individual who uses myHDstory<sup>&reg;</sup> in any way contrary to these Terms and Conditions will have his or her access immediately canceled. If you become aware of any such violation, please contact the myHDstory<sup>&reg;</sup> administrator at: info@myhdstory.org

      </Label>

      <Spacer value={20} />

      <Label size="h6" >
        Use of myHDstory<sup>&reg;</sup> website or mobile application is at the discretion of HSG. HSG reserves the right to discontinue myHDstory<sup>&reg;</sup> at any time, limit its use, or prohibit certain individuals or institutions from using it, and HSG shall not be liable to anyone for any claim of loss or damage related to such decisions.
      </Label>
      <Spacer value={20} />


      <Label size="h4" bold >
        3. Visiting Users And Registered Users
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Visitors to myHDstory<sup>&reg;</sup> may use the website or mobile application for purposes of reviewing publicly available materials. myHDstory<sup>&reg;</sup> tracks information about visitors but does not retain individually identifiable information about them unless they provide this information to myHDstory<sup>&reg;</sup> by giving an email address and/or registering on myHDstory<sup>&reg;</sup>. Visitors are not permitted to view any individually identifiable information or any proprietary information about the myHDstory<sup>&reg;</sup>or registrants.
      </Label>
      <Spacer value={20} />


      <Label size="h6" >
        To become a registered user on the myHDstory<sup>&reg;</sup> platform, you provide myHDstory<sup>&reg;</sup> with certain personal information. The personal information provided by you is subject to our Privacy Policy, which can be found <a target="_blank" href="https://huntingtonstudygroup.org/privacy-statement/" rel="noreferrer"> here </a>.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        During registration you will assign yourself a user ID and password. Only a registered user or a person explicitly authorized by a registered user to utilize myHDstory<sup>&reg;</sup> on their behalf, may use their user ID and password. You are responsible for keeping your password confidential and agree not to allow anyone else to use your user ID or password to access this website or mobile application. You are fully responsible for all activities that occur under your user ID and password. If you become aware of unauthorized use of your user ID and password, you agree to immediately notify myHDstory<sup>&reg;</sup> at info@myhdstory.org.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold>
        4. License Grant
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Subject to the terms of this User Agreement, HSG grants you a limited, non-exclusive, and nontransferable license to:
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        (a)            register, download, install, and use the Application for your personal, non-commercial use on a single Mobile Device or Computer owned or otherwise controlled by you strictly in accordance with the Application`&apos;`s documentation, and
      </Label>
      <Spacer value={20} />


      <Label size="h6" >
        (b)            access, stream, download, and use on such Mobile Device or Computer the content and services made available in or otherwise accessible through the Application, strictly in accordance with this User Agreement and the HSG Privacy Policy and any applicable informed consent related to your participation in a study.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold >
        5.  License Restrictions
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        (a) You as Licensee shall not copy the Application, except as expressly permitted by this license;
      </Label>
      <Spacer value={20} />


      <Label size="h6" >
        (b)            modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Application;

      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        (c)            reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Application or any part thereof;
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        (d)            remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Application, including any copy thereof;

      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        (e)            rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Application, or any features or functionality of the Application, to any third party for any reason, including by making the Application available on a network where it is capable of being accessed by more than one device at any time; or

      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        (f)             remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management, or security features in or protecting the Application; or

      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        (g)            use the Application in, or in association with, the design, construction, maintenance, or operation of any hazardous environments or systems, including any power generation systems; aircraft navigation or communication systems, air traffic control systems or any other transport management systems; safety-critical applications, including medical or life-support systems, vehicle operation applications or any police, fire, or other safety response systems; and military or aerospace applications, weapons systems, or environments.
      </Label>
      <Spacer value={20} />


      <Label size="h4" bold >
        6.               Reservation of Rights
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        You acknowledge and agree that the Application is provided under license, and not sold, to you. You do not acquire any ownership interest in the Application under this Agreement, or any other rights thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions, and restrictions, under this Agreement. HSG and its licensors and service providers reserves and shall retain their entire right, title, and interest in and to the Application, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold >
        7.               Collection and Use of Your Information
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        You acknowledge that when you download, install, or use the Application, HSG may use automatic means (including, for example, cookies and web beacons) to collect information about your Mobile Device or Computer and about your use of the Application. You also may be required to provide certain information about yourself as a condition to downloading, installing, or using the Application or certain of its features or functionality, and the Application may provide you with opportunities to share information about yourself with others. All information we collect through or in connection with this Application is subject to our Privacy Policy. By downloading, installing, using, and providing information to or through this Application, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.					</Label>
      <Spacer value={20} />


      <Label size="h4" bold >
        8. Ownership of Information myHDstory<sup>&reg;</sup>
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Except for your personal data and personal health information (`&quot;`Personal Information`&quot;`), HSG or myHDstory<sup>&reg;</sup> owns all information appearing on this website or any mobile application sponsored by HSG or myHDstory<sup>&reg;</sup>.  HSG is working with third parties to provide services, including cloud services, to administer the myHDstory platform and to collect and store certain data from Users, including, without limitation, a developer, Neurotargeting, LLC, which owns certain software and proprietary intellectual property relative to myHDstory<sup>&reg;</sup>. You will not use this website or mobile application to obtain any information about any other user of this website or mobile application, or for any commercial purpose, including, but not limited to, soliciting, offering to sell or selling any product or service to any user of this website or mobile application. You hereby provide HSG with permission to use your Personal Information for any purpose permissible by law that is within HSG`&apos;`s mission.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold>
        9. Links or Referrals to Other Web Sites
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Through this website or other communications from myHDstory<sup>&reg;</sup> or HSG, myHDstory<sup>&reg;</sup> may refer you to other websites or mobile applications. HSG and myHDstory<sup>&reg;</sup> does not have control over websites and mobile applications not sponsored by HSG or myHDstory<sup>&reg;</sup>, and HSG and myHDstory<sup>&reg;</sup> do not assume any responsibility for them. You agree that you will not seek to hold HSG or myHDstory<sup>&reg;</sup> responsible for any material related to those websites or mobile applications not sponsored by HSG or myHDstory<sup>&reg;</sup>.
      </Label>
      <Spacer value={20} />


      <Label size="h4" bold >
        10. Indemnification
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Users of this website or related mobile applications for any purpose shall and hereby do indemnify, defend and hold harmless HSG, myHDstory<sup>&reg;</sup>, Neurotargeting, LLC., their affiliates, and their respective members, directors, officers, managers, employees, agents, and licensors, from and against all losses, expenses, damages and costs, including reasonable attorneys`&apos;` fees, resulting from such use. We reserve the right to assume the exclusive defense of any claim for which we are entitled to indemnification under this Section. In such event, you shall provide us with such cooperation as is reasonably requested by us.
      </Label>
      <Spacer value={20} />


      <Label size="h4" bold >
        11. Disclaimers of Liability
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG, myHDstory<sup>&reg;</sup>, their affiliates, and their respective members, directors, officers, managers, employees, shareholders, agents and licensors are not liable for any damages in any way related to use of myHDstory<sup>&reg;</sup>, including incidental, direct, indirect, consequential, special, punitive, or exemplary damages of any kind, lost revenues or profits, loss of business or loss of data, or for any claim, loss or injury based on errors, omissions, interruptions or other inaccuracies, including, without limitation, as a result of breach of any warranty or other term of this User Agreement.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Any and all information provided through myHDstory<sup>&reg;</sup> is `&quot;`as is,`&quot;` and HSG and myHDstory<sup>&reg;</sup> do not warrant or represent that any information provided by them or any third party through myHDstory<sup>&reg;</sup> is accurate or truthful. Although we will make a good faith effort to correct any errors of which we become aware, we do not make any warranties, express or implied, with respect to myHDstory<sup>&reg;</sup> or any information offered through it. We do not make any representations, nor do we endorse the accuracy, completeness, timeliness or reliability of any material or data displayed, uploaded or distributed through myHDstory<sup>&reg;</sup> or the Study, or available through links in this website or through mobile applications. Each user must independently verify the accuracy and truthfulness of the information received through myHDstory<sup>&reg;</sup>.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG and myHDstory<sup>&reg;</sup> do not warrant that myHDstory<sup>&reg;</sup> will be uninterrupted or error-free. We reserve the right to correct any errors or omissions on the website or mobile application.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Although we intend to and will take all reasonable steps to prevent the introduction of viruses, worms, `&quot;`trojan horses`&quot;` or other destructive materials that could affect myHDstory<sup>&reg;</sup>, we do not guarantee or warrant that the website or the materials that may be downloaded from this website or mobile applications do not contain such destructive features. We are not liable for any damages or harm attributable to such features. Your use of myHDstory<sup>&reg;</sup> and/or download of information from this website or related mobile applications is at your own risk.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        You are responsible for complying with any applicable laws that may apply to you in using myHDstory<sup>&reg;</sup>.
      </Label>
      <Spacer value={20} />


      <Label size="h4" bold >
        12. Release
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        You hereby release HSG from any claim that HSG`&apos;`s financial support for myHDstory<sup>&reg;</sup> or your use of the website, related mobile applications or any information contained thereon caused you harm in any way.
      </Label>
      <Spacer value={20} />


      <Label size="h4" bold>
        13. Use of myHDstory<sup>&reg;</sup> and The Huntington Study Group brands
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        myHDstory<sup>&reg;</sup> is a product managed by HSG. We are eager to have HD investigators, clinicians, doctors, health care providers and others publicize the existence of the myHDstory<sup>&reg;</sup> website or related mobile applications. Anyone is encouraged to include a link in a manner that does not imply endorsement by HSG or myHDstory<sup>&reg;</sup>. The appearance, position and other aspects of the link, URL, host website, or other item in which the link or URL is included may not be such as to damage or dilute the goodwill associated with myHDstory<sup>&reg;</sup> and the name and trademarks of HSG. The appearance, position and other aspects of the link, URL, host website, or other item in which the link or URL is included may not create the false appearance that any other entity is associated with or sponsored by HSG or myHDstory<sup>&reg;</sup>. If the web link is listed on a website, the link, when activated by a user, must display this website full-screen and not with a `&quot;`frame`&quot;` on the linked website. Any statement or materials that use the myHDstory<sup>&reg;</sup> name, brand or trademark in any manner other than providing a web link require approval by HSG before being made publicly available.
      </Label>
      <Spacer value={20} />

      <Label uppercase size="h6" >
        The owner of any website, mobile application, and/or any other materials with a hyperlink or URL to myHDstory<sup>&reg;</sup> agrees to the foregoing terms, agrees to be fully responsible for all of its content and links (including full indemnification of HSG and myHDstory<sup>&reg;</sup> as set forth in Section 6), and agrees to delete any such link upon notice from HSG that such permission has been revoked, which notice may be given at any time and for any reason. HSG reserves the right to suggest altered text or positioning and to deny requests for use of name, brand or trademark. Use of `&quot;`Huntington Study Group`&quot;` name, brand or trademark is not permitted unless separate written permission has been granted by HSG. Individuals seeking approval of use of the myHDstory<sup>&reg;</sup> name can email the item using name, brand or trademark to: info@myhdstory.org.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold>
        14. Miscellaneous
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        This User Agreement may be terminated by either party for any reason at any time. Any individual user of myHDstory<sup>&reg;</sup> wishing to terminate the applicability of this User Agreement to them shall not access the website or mobile application or in any other way use myHDstory<sup>&reg;</sup>. The terms and conditions of this User Agreement shall survive such termination with respect to any information collected by myHDstory<sup>&reg;</sup> during the time that the User Agreement was in effect and for any disputes relating to it.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Any portion of this User Agreement or published policy found by a court of competent jurisdiction to be unenforceable shall be severable and all other portions of this Agreement and published policies shall remain in effect.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        This User Agreement shall be governed by the laws of the United States and the State of New York, applicable to agreements made and to be performed therein without regard to conflict of laws principles, and you agree that the federal or state courts in New York, New York shall have exclusive jurisdiction over any dispute relating to this User Agreement. You acknowledge that you may not be able to access all or some of the content and services on the Application outside of the United States and that access thereto may not be legal by certain persons or in certain countries. If you access the Application from outside the United States, you are responsible for compliance with local laws.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        BY ACCESSING AND/OR USING THIS WEBSITE OR THE MOBILE APPLICATION, YOU AGREE TO ABIDE BY THE TERMS OF THIS USER AGREEMENT.
      </Label>

    </div>
  </>
);

export default TermsBody;
