import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';

import { Logo } from '../../atoms';
import './app-bar.scss';


type Props = {
  navigation?: { title: string, url: string, private?: boolean, submenu?: { title: string, url: string, }[] }[],
  userMenuItems?: { title: string, url: string, private?: boolean }[],
  token: string | undefined,
  logout: () => void,
};

const defaultDropdownMenu = [
  {
    title: '',
    url: '',
  },
];


const NormalBar = (props: Props) => {
  const { navigation, userMenuItems, token, logout } = props;
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [dropdown, setDropdown] = React.useState(defaultDropdownMenu);
  const { setBreadcrumbs } = React.useContext(BreadcrumbsContext);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, page: { title: string, url: string, }[]) => {
    setAnchorEl(event.currentTarget);
    setDropdown(page);
  };

  const handleLinkClick = () => {
    setBreadcrumbs([]);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const leaveMenu = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const userOpen = Boolean(anchorElUser);


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorElNav(event.currentTarget);
    }
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElUser !== event.currentTarget) {
      setAnchorElUser(event.currentTarget);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogout = () => {
    logout();
    handleCloseUserMenu();
  };

  const systemType = process.env.REACT_APP_SYSTEM;


  return (
    <AppBar position='static' className='app-bar' color='inherit' >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            // component='a'
            // href='/home'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Raleway',
              fontWeight: 800,
              letterSpacing: '.2rem',
              color: '#0C6EF8',
              textDecoration: 'none',
              mt: 1,
              mb: 1,
            }}
          >
            {systemType === 'INS' ?
              <Logo size={70} />
              :
              <Logo size={200} type='text' />
            }
          </Typography>

          {/* <div className='appbar-links'> */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {navigation?.map((page) => (
                  // <NavLink to={page.url}>{page?.title}</NavLink>
                  <div key={page.title}>
                    {page?.submenu ?
                      <div>
                        {page?.submenu?.map((sub) => (
                          <MenuItem key={sub?.title}>
                            <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={sub.url}>{sub?.title}</NavLink>
                          </MenuItem>
                        ))}
                      </div>
                      :
                      <MenuItem key={page?.title}>
                        <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={page.url}>{page?.title}</NavLink>
                      </MenuItem>
                    }
                  </div>
                ))}

              </Menu>
            </Box>
            <Typography
              variant='h5'
              noWrap
              // component='a'
              // href=''
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'Raleway',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: '5B7EDD',
                textDecoration: 'none',
              }}
            >
              <Logo size={60} type='text' />
              {/* <span className='app-bar__logo'>UPSTREAM</span> */}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} >

              {navigation?.filter((obj) => !obj.private).map((page) => (
                <div key={page.title}>
                  {page?.submenu ?
                    <div >
                      <Typography
                        aria-owns={open ? 'menu-appbar-2' : undefined}
                        aria-haspopup='true'
                        onMouseEnter={(e) => handlePopoverOpen(e, page?.submenu ? page.submenu : [])}
                      >
                        <MenuItem key={page?.url}>
                          <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={page.url}>{page?.title}</NavLink>
                        </MenuItem>
                      </Typography>
                      <Menu
                        onMouseLeave={leaveMenu}
                        sx={{ mt: '45px' }}
                        id='menu-appbar-2'
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(open)}
                        onClose={handlePopoverClose}
                      >
                        <div className='MENU-WRAPPER' onMouseLeave={leaveMenu}>
                          {dropdown.map((sub) => (
                            <MenuItem onClick={handlePopoverClose} key={sub.title}>
                              <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={sub.url}>
                                {sub?.title}
                              </NavLink>
                            </MenuItem>
                          ))}
                        </div>
                      </Menu>
                    </div>
                    :
                    <MenuItem key={page?.title}>
                      <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={page.url}>{page?.title}</NavLink>
                    </MenuItem>
                  }
                </div>
              ))}

              {token && navigation?.filter((obj) => obj.private).map((page) => (
                <div key={page.title}>
                  {page?.submenu ?
                    <div>
                      <Typography
                        aria-owns={open ? 'menu-appbar-2' : undefined}
                        aria-haspopup='true'
                        onMouseEnter={(e) => handlePopoverOpen(e, page?.submenu ? page.submenu : [])}
                      >
                        <MenuItem key={page?.url}>
                          <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={page.url}>{page?.title}</NavLink>
                        </MenuItem>
                      </Typography>
                      <Menu
                        sx={{ mt: '45px' }}
                        id='menu-appbar-2'
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(open)}
                        onClose={handlePopoverClose}
                      >
                        <div onMouseLeave={leaveMenu}>
                          {dropdown.map((sub) => (
                            <MenuItem key={sub.title} onClick={handlePopoverClose}>
                              <NavLink onClick={handleLinkClick} to={sub.url} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} >
                                {sub?.title}
                              </NavLink>
                            </MenuItem>
                          ))}
                        </div>
                      </Menu>
                    </div>
                    :
                    <MenuItem key={page?.title}>
                      <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={page.url}>{page?.title}</NavLink>
                    </MenuItem>
                  }
                </div>
              ))}
            </Box>
          {/* </div> */}

          {token && token != null ?
            <Box sx={{ flexGrow: 0 }}  >
              {userMenuItems?.map((setting) => (
                <MenuItem key={setting?.title} onClick={onLogout}>
                  <NavLink onClick={handleLinkClick} className={({ isActive }) => (isActive ? 'selected' : 'inactive')} to={setting.url}>{setting?.title}</NavLink>
                </MenuItem>
              ))}
            </Box>
            :
            <Box sx={{ flexGrow: 0 }}>
              <MenuItem onClick={handleCloseUserMenu} >
                <NavLink to='/login' >Log in </NavLink>
              </MenuItem>
            </Box>
          }
        </Toolbar>
      </Container >
    </AppBar >
  );
};

NormalBar.defaultProps = {
  navigation: [{ title: '', url: '' }],
  userMenuItems: [{ title: '', url: '' }],
};

export default NormalBar;
