import React, { useState, useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { MenuItem } from '@mui/material';
import { Label, Button, Spacer, Modal, Dropdown, DatePicker, Tile } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import './patient-survey-switch.scss';

export interface PatientSurveySwitchProps {
  patient: any,
  className?: string,
}

const permaOps = ['Patient Nonverbal', 'Intellectual Limitation', 'Unable to complete surveys in available languages'];

type SurveySwitchState = {
  status: boolean;
  reason: string;
  date: string;
  language: string,
};

const PatientSurveySwitch = (props: PatientSurveySwitchProps): JSX.Element => {
  const { patient } = props;
  const [screen, setScreen] = useState('choice');
  const [surveyState, setSurveyState] = useState({
    status: true,
    reason: '',
    date: '',
    language: '',
  });
  const [modal, setModal] = useState(false);
  const [dateErr, setDateErr] = useState(false);


  const handleSurveySwitch = () => {
    setSurveyState({ ...surveyState, status: !surveyState.status });
    setModal(true);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const closeModal = () => {
    setScreen('choice');
    setDateErr(false);
    setSurveyState({
      ...surveyState,
      reason: '',
      date: '',
      language: '',
    });
    setModal(false);
  };

  const handleReasonChange = (event: any) => {
    const { value } = event.target;
    setSurveyState({ ...surveyState, reason: value });
  };

  const handleDateChange = (event: any) => {
    // const selectedDate = new Date(event.target.value);
    // console.log('selectedDate', selectedDate);
    // const sixMonthsFromToday = new Date();
    // sixMonthsFromToday.setMonth(sixMonthsFromToday.getMonth() + 6);

    // if (selectedDate > sixMonthsFromToday) {
    //   setDateErr(true);
    //   return;
    // }

    // setSurveyState({ ...surveyState, date: event.target.value });
    // setDateErr(false);

    const selectedDate = event.$d;
    const sixMonthsFromToday = new Date();
    sixMonthsFromToday.setMonth(sixMonthsFromToday.getMonth() + 6);

    if (selectedDate > sixMonthsFromToday) {
      setDateErr(true);
      return;
    }

    setSurveyState({ ...surveyState, date: selectedDate });
    setDateErr(false);

  };

  const handleUpdateSurvey = async () => {
    try {
      if (screen === 'temp') {
        const payload = {
          subjectId: patient.subjectId[0],
          surveyStatus: JSON.stringify({
            status: false,
            date: surveyState.date,
          }),
        };
        // await updateStatus(payload);
        closeModal();
      } else if (screen === 'perm') {
        const payload = {
          subjectId: patient.subjectId[0],
          surveyStatus: JSON.stringify({
            status: false,
            reason: surveyState.reason,
          }),
        };
        // if (surveyState.reason === permaOps[2]) {
        //   payload.surveyStatus.language = surveyState.language;
        // }
        // await updateStatus(payload);
        closeModal();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleResumeSurvey = async () => {
    try {
      const payload = {
        subjectId: patient.subjectId[0],
        surveyStatus: JSON.stringify({
          status: true,
          date: '',
          reason: '',
          language: '',
        }),
      };
      // await updateStatus(payload);
      closeModal();
    } catch (e) {
      console.error(e);
    }
  };

  const getStatusButton = () => {
    if (surveyState.reason !== '') {
      return <Button onClick={handleSurveySwitch} kind="ghost">Inactive</Button>;
    } if (surveyState.date !== '') {
      return <Button onClick={handleSurveySwitch} kind="danger">Paused</Button>;
    }
    return <Button onClick={handleSurveySwitch}>Active</Button>;
  };

  return (
    <div>
      <div className='patient-survey-switch__item'>
        <Label size='h5' weight={600} >Send Surveys to patient:</Label>
        {getStatusButton()}
      </div>

      <Modal title='Stop Patient from receiving surveys' size='sm' open={modal} onClose={closeModal}>
        <> {
          surveyState.status === false ?
            <>
              {
                screen === 'choice' &&
                <div>
                  <div role='button' tabIndex={-1} onClick={() => setScreen('temp')} className="patient-survey-switch__btn">
                    <Label size="h5" weight={600} color="primary"  >
                      Temporarily Pause Surveys
                    </Label>
                  </div>
                  <div role='button' tabIndex={-1} onClick={() => setScreen('perm')} className="patient-survey-switch__btn">
                    <Label size="h5" weight={600} color="primary"  >
                    Permanently Discontinue Surveys
                    </Label>
                  </div>
                </div>
              }
              {
                screen === 'temp' &&
                <div>
                  <Spacer value={20} />
                  <Label size='h5'>
                    Pause Surveys until:
                  </Label>
                  <DatePicker
                    id='date'
                    label=''
                    value={surveyState.date}
                    onChange={handleDateChange}
                  />
                  <Spacer value={10} />
                  {dateErr && <Label size='h6' color='danger'>Please select a date within 6 months from today.</Label>}
                  <Spacer value={30} />
                  <div className='btn-row'>
                    <Button kind='danger' onClick={() => setScreen('choice')}>
                      {/* <Button disabled={isUpdatingSurv} kind='danger' onClick={() => setScreen('choice')}> */}
                      Cancel
                    </Button>
                    <Button disabled={dateErr} onClick={handleUpdateSurvey}>
                      {/* <Button loading={isUpdatingSurv} disabled={dateErr} onClick={handleUpdateSurvey}> */}
                      Confirm
                    </Button>
                  </div>
                </div>
              }
              {
                screen === 'perm' &&
                <div>
                  <Spacer value={20} />
                  <Label size='h5'>
                    Reason for Permanently Discontinuing Surveys:
                  </Label>
                  <Dropdown
                    name='sideEffects'
                    id='sideEffects'
                    label=''
                    value={surveyState.reason}
                    onChange={handleReasonChange}
                  >
                    {permaOps.map((option, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Dropdown>
                  {surveyState.reason === permaOps[2] &&
                    <TextInput
                      name='language'
                      id='language'
                      value={surveyState.language}
                      label="Patient's Language:"
                      onChange={(e) => setSurveyState({ ...surveyState, language: e.target.value })}
                    />
                  }
                  <Spacer value={30} />
                  <div className='btn-row'>
                    <Button kind='danger' onClick={() => setScreen('choice')}>
                      {/* <Button disabled={isUpdatingSurv} kind='danger' onClick={() => setScreen('choice')}> */}
                      Cancel
                    </Button>
                    <Button onClick={handleUpdateSurvey}>
                      {/* <Button loading={isUpdatingSurv} onClick={handleUpdateSurvey}> */}
                      Confirm
                    </Button>
                  </div>
                </div>
              }
            </>
            :
            <div className='modal-body'>
              <Label size='h5'>
                Surveys Paused
                {surveyState.date !== '' && ` Until ${moment(surveyState.date).format('L')}`}
                {surveyState.reason !== '' && `: ${surveyState.reason}`}
              </Label>
              <Spacer value={30} />
              <div className='btn-row'>
                {/* <Button disabled={isUpdatingSurv} kind='danger' onClick={closeModal}>Cancel</Button>
                <Button loading={isUpdatingSurv} onClick={handleResumeSurvey}>Resume Surveys</Button> */}
              </div>
            </div>
        }
        </>
      </Modal>
    </div>
  );
};

PatientSurveySwitch.defaultProps = {
  className: '',
};

PatientSurveySwitch.displayName = 'PatientSurveySwitch';
export default PatientSurveySwitch;
