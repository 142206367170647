import React from 'react';
import { BiLock, BiCheck, BiCalendar } from 'react-icons/bi';
import { BsCheck, BsX } from 'react-icons/bs';
import { Tile, Label, Spacer, DatePicker, Button, Modal, Alert, Spinner } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import CRFForms from '../CRFForms/CRFForms';
import './care-item-factory.scss';
import CRFManager from './CRFManager';
import CarePathwayVisitAttachement from '../CarePathwayVisitAttachement/CarePathwayVisitAttachement';
import { useUpdateReadyForConfMutation } from '../../../api/builders/registries.api';
import Tab from '../Tab/Tab';
import Tabs from '../Tabs/Tabs';

export interface CareItemFactoryProps {
  className?: string,
  activeStep: any,
  currentStep: any,
  advanceStep: (pl: any) => void,
  isLoading: boolean,
  patientPathInfo: any,
  filePath: string,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const CareItemFactory = (props: CareItemFactoryProps): JSX.Element => {
  const { className, activeStep, advanceStep, isLoading, currentStep, patientPathInfo, filePath } = props;
  const [visitDate, setVisitDate] = React.useState<any>('');
  const [completionDate, setCompletionDate] = React.useState<any>('');
  const [status, setStatus] = React.useState('');
  const [notes, setNotes] = React.useState('');
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<number | undefined>(undefined); // Use number or boolean for activeTab state
  const [updateItem, { isLoading: isEdititng, isSuccess, isError, error }] = useUpdateReadyForConfMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  React.useEffect(() => {
    setCompletionDate('');
    setVisitDate('');
  }, []);


  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error ${patientPathInfo.status === 'Registered' ? 'Removing Patient from Conference list' : 'Adding Patient to Conference List'}: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: `Success ${patientPathInfo.status === 'Registered' ? 'Adding Patient to Conference List' : 'Removing Patient from Conference list'}`,
        kind: 'success',
      });
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };



  React.useEffect(() => {
    setVisitDate(currentStep.scheduledDate === 'null' ? '' : new Date(currentStep.scheduledDate));
    setCompletionDate(currentStep.completionDate === 'null' ? '' : new Date(currentStep.completionDate));
    setStatus(currentStep.patientStatus === 'null' ? 'NOTSCHEDULED' : currentStep.patientStatus);
    setNotes(currentStep?.notes !== 'null' ? currentStep?.notes : '');
  }, [currentStep]);

  const isCompleted = currentStep.patientStatus === 'COMPLETED';
  const isCurrent = activeStep.id === currentStep.id;


  const onChangeDate = (e: any) => {
    const formattedDate = new Date(e).toISOString().split('T')[0];
    // console.log('e', e);
    setVisitDate(e.$d);
    if (status !== 'COMPLETED') {
      setStatus('SCHEDULED');
    }
  };

  const onChangeCompletionDate = (e: any) => {
    const formattedDate = new Date(e).toISOString().split('T')[0];
    setCompletionDate(e.$d);
    setStatus('COMPLETED');
  };


  const onChangeNotes = (e: any) => {
    setNotes(e.target.value);
  };

  const handleAdvance = () => {
    try {
      const pl = {
        stepId: currentStep.id,
        notes,
        status,
        ...(completionDate !== 'null' && { completionDate }),
        ...(visitDate !== 'null' && { scheduledDate: visitDate }),
      };
      advanceStep(pl);
    } catch (e) {
      console.error('e', e);
    }
  };
  const handleStatusControl = () => {
    try {
      const pl = {
        stepId: currentStep.id,
        status,
      };
      advanceStep(pl);
      setConfirmModal(false);
    } catch (e) {
      console.error('e', e);
    }
  };

  const handleReadyForConf = () => {
    if (currentStep?.patientStatus !== 'COMPLETED') {
      try {
        const pl = {
          id: patientPathInfo.id,
          body: {
            status: 'Ready for Conference',
            stepId: currentStep.id,
          },
        };
        updateItem(pl);
      } catch (e) {
        console.error('e', e);
      }
    }
  };
  const handleRmFromConf = () => {
    if (currentStep?.patientStatus !== 'COMPLETED') {
      try {
        const pl = {
          id: patientPathInfo.id,
          body: {
            status: 'Registered',
            stepId: currentStep.id,
          },
        };
        updateItem(pl);
      } catch (e) {
        console.error('e', e);
      }
    }
  };

  const renderInfo = () => (
    <div>
      <div className='care-item-factory__dates'>
        <span className='w-100'>
          <DatePicker
            label="Sheduled Date:"
            value={visitDate}
            key={`${currentStep.id}scheduledate`}
            onChange={onChangeDate}
          />
        </span>
        <Spacer value={30} />
        <span className='w-100'>
          <DatePicker
            label="Completed Date:"
            key={`${currentStep.id}completiondate`}
            value={completionDate}
            onChange={onChangeCompletionDate}
          />
        </span>
      </div>
      <Spacer value={5} />
      <TextAreaInput
        value={notes}
        label='Notes'
        rows={5}
        onChange={onChangeNotes}
      />
      <div className='btn-row'>
        {currentStep?.patientStatus !== 'COMPLETED' &&
          <Button loading={isLoading} onClick={handleAdvance}>Save Changes</Button>}
      </div>
    </div>
  );


  const renderConference = () => (
    <div>
      {/* <div className='conference-controls'>
        <Label color='primary' size='h4' weight={600}>
          Patient Ready for Conference?
        </Label>
      </div> */}
      <Spacer value={10} />
      {patientPathInfo.status === 'Ready for Conference' ?
        <div className='super-centered'>
          <Label size='h4' weight={600} center color='ghost'>Patient Awating Conference</Label>
          <Spacer value={5} />
          {isEdititng ?
            <Spinner />
            :
            <button type='button' className='no-btn' onClick={handleRmFromConf}>
              <BsX className='red' />
              <Spacer value={5} />
              <Label uppercase size='h5' weight={300}>
                Remove Patient from Conference
              </Label>
            </button>
          }
        </div>
        :
        <div className='super-centered'>
          {isEdititng ?
            <Spinner />
            :
            <button type='button' className='yes-btn' onClick={handleReadyForConf}>
              <BsCheck className='green' />
              <Spacer value={5} />
              <Label uppercase size='h5' weight={300}>
                mark patient ready for confernce
              </Label>
            </button>
          }
        </div>}
    </div>
  );


  // eslint-disable-next-line react/no-unstable-nested-components
  const CareItemBody = ({ item }: any): JSX.Element => {
    // console.log('item type', item.type);
    if (item.type === 'Conference') {
      return (
        <Tabs >
          {/* <Tab title="Schedule Info">
            {renderInfo()}
          </Tab> */}
          <Tab title="Conference">
            {renderConference()}
          </Tab>
          <Tab title="EHR Sync">
            <CarePathwayVisitAttachement currentStep={currentStep} handleLink={advanceStep} />
          </Tab>
        </Tabs>
      );
    }
    if (item.type === 'Radiology') {
      return (
        <Tabs >
          <Tab title="Schedule Info">
            {renderInfo()}
          </Tab>
          <Tab title="EHR Sync">
            <CarePathwayVisitAttachement currentStep={currentStep} handleLink={advanceStep} />
          </Tab>
          <Tab title="Preview Image">
            <div className='super-centered'>
              <Spacer value={30} />
              <Spacer value={30} />
              <Spacer value={30} />
              <Label size='h6' color='ghost' weight={600}>No Images Available</Label>
            </div>
          </Tab>
        </Tabs>
      );
    }
    if (item.crfQuestions && item.crfQuestions !== 'null') {
      return (
        <Tabs >
          <Tab title="Schedule Info">
            {renderInfo()}
          </Tab>
          <Tab title="EHR Sync">
            <CarePathwayVisitAttachement currentStep={currentStep} handleLink={advanceStep} />
          </Tab>
          <Tab title={`${item.description} Form`}>
            <CRFManager readOnly={isCompleted} carePathwayId={patientPathInfo.id} item={currentStep} filePath={filePath} updateItem={advanceStep} />
          </Tab>
        </Tabs>
      );
    }
    return (
      <Tabs>
        <Tab title="Schedule Info">
          {renderInfo()}
        </Tab>
        <Tab title="EHR Sync">
          <CarePathwayVisitAttachement currentStep={currentStep} handleLink={advanceStep} />
        </Tab>
      </Tabs>
    );
  };


  const handleButtonClick = (newStatus: string) => {
    setStatus(newStatus);
    setConfirmModal(true);
  };

  const handleCancelStatus = () => {
    setStatus(currentStep.patientStatus === 'null' ? 'NOTSCHEDULED' : currentStep.patientStatus);
    setConfirmModal(false);
  };

  const StatusButtons = (stepStatus: string) => (
    <div className="status-buttons">
      {stepStatus !== 'COMPLETED' && (
        <button
          type='button'
          className={`status-button ${stepStatus === 'SCHEDULED' ? 'active' : ''}`}
          onClick={() => handleButtonClick('SCHEDULED')}
        >
          <BiCalendar color='white' />
        </button>
      )}

      <button
        type='button'
        className={`status-button ${stepStatus === 'COMPLETED' ? 'active' : ''}`}
        onClick={() => handleButtonClick('NOT SCHEDULED')}
      // disabled={stepStatus !== 'COMPLETED'}
      >
        <BiLock color='white' />
      </button>

      {stepStatus !== 'COMPLETED' && (
        <button
          type='button'
          className={`status-button ${stepStatus === 'IN_PROGRESS' ? 'active' : ''}`}
          onClick={() => handleButtonClick('COMPLETED')}
        >
          <BiCheck color='white' />
        </button>
      )}
    </div>
  );


  return (
    <Tile className={className}>
      <div>
        <div className='row__apart'>
          <span>
            <Label uppercase size="h3" weight={600}>
              {currentStep.description}
            </Label>
            <Label uppercase size="h5" color='success' weight={600}>
              {currentStep.patientStatus === 'null' ? 'NOT SCHEDULED' : currentStep.patientStatus}
            </Label>
          </span>
          {StatusButtons(status.toUpperCase())}
        </div>
        <Spacer value={10} />
        <CareItemBody item={currentStep} />
        <Modal size='sm' title='Change Status' open={confirmModal} onClose={handleCancelStatus} >
          <div>
            <Label size='h4' >Would you like to change the status of this item to {status}?</Label>
            <Spacer value={20} />
            <div className='btn-row'>
              <Button kind='danger' onClick={handleCancelStatus}>
                Cancel
              </Button>
              <Button onClick={handleStatusControl}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
        <Alert
          severity={alert.kind}
          open={alert.open}
          onClose={resetAlert}
          message={alert.message}
        />
      </div>
    </Tile >
  );
};

CareItemFactory.defaultProps = {
  className: '',
};

CareItemFactory.displayName = 'CareItemFactory';
export default CareItemFactory;
