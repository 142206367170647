/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import useDebounce from '../../../hooks/useDebounce';
import './search-box.scss';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  placeholder?: string;
  className?: string;
  withDebounce?: boolean,
};

const SearchBox = ({ value = '', onChange, onSubmit, placeholder = 'Enter text to search', className, withDebounce = false }: Props) => {
  const [inputValue, setInputValue] = useState(value);
  
  // Effect for prop `value` change
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Handler for input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  // Debounced onChange handler
  const debouncedChange = useDebounce(onChange, 500);

  // Effect for debounced change
  useEffect(() => {
    if (withDebounce && inputValue !== value) {
      debouncedChange(inputValue);
    }
  }, [inputValue, debouncedChange, withDebounce, value]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) onSubmit(inputValue);
  };

  const showSubmitButton = onSubmit !== undefined;
  const localclassname = `search-box ${className || ''}`;

  return (
    <div className={localclassname}>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          placeholder={placeholder} 
          value={inputValue} 
          onChange={handleChange}
        />
        {showSubmitButton && (
          <button type="submit" className="icon-btn">
            <FaSearch className="icon" color="#20caf9" />
          </button>
        )}
      </form>
    </div>
  );
};

export default SearchBox;
