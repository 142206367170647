import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Label, Logo, Spacer } from '../../../components/atoms';
import { selectAuth } from '../../../store/authentication/slice';



const Landing = () => {
  const navigate = useNavigate();
  const { token } = useSelector(selectAuth);

  const goToLogin = () => {
    navigate('/login');
  };

  const goToCreate = () => {
    navigate('/create-account');
  };

  return (
    <div className="landing">
      <div className="wrapper">
        <div className='panel1'>
          <Logo size="100%" type="registered" />
          <Label size='h1' center >Participate in Research</Label>
        </div>
        <div className='panel2'>
          <Spacer value={30} />
          <Label size='h1' center>
            New studies are continuously added to myHDstory<sup>&reg;</sup>
          </Label>
          <Spacer value={15} />
          <Label size='h1' center>
            To be alerted to new studies you may qualify for, create your account on the platform.
          </Label>
          <Spacer value={15} />
          {!token && <div className='btn-row'>
            <Button onClick={goToCreate} kind="secondary">Create Account</Button>
            <Spacer value={10} />
            <Button onClick={goToLogin} kind="ghost" >Sign in</Button>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Landing;
