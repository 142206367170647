/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { Tile, Label, Text, Button, Spacer } from '../../atoms';
import './analysis-reports.scss';

export interface AnalysisReportsProps {
  volumetricData: any;
  isLoading?: boolean;
}

const BrainSegVolumeReport = (props: AnalysisReportsProps): JSX.Element => {
  const { volumetricData, isLoading } = props;
  const [ventricleSum, setVentricalSum] = React.useState(0);
  const [seachResults, setSearchResults] = React.useState<any[] | null>(null);
  const [filter, setFilter] = useState('All');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (volumetricData) {
      const ventricles = volumetricData.filter((d: any) => d.anatomy_label.includes('Ventricle'));
      const ventricleVals: any[] = [];
      let sum = 0;
      if (ventricles.length > 0) {
        ventricles.map((v: any) => ventricleVals.push(v.vol_mm3));
        sum = ventricleVals.reduce((a, b) => Number(a) + Number(b));
      }
      setVentricalSum(sum);
    }
  });

  const renderVolume = (volume: any) => {
    const str = volume.toString().split('.');
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  React.useEffect(() => {
    if (filter) {
      switch (filter) {
        case 'All':
          setSearchResults(volumetricData);
          break;
        case '5%':
          setSearchResults(
            volumetricData.filter(
              (d: any) =>
                (d.asymmetry && Number(d.asymmetry) * 100 >= Number('5')) ||
                (d.asymmetry && Number(d.asymmetry) * 100 <= Number('-5')),
            ),
          );
          break;
        case '10%':
          setSearchResults(
            volumetricData.filter(
              (d: any) =>
                (d.asymmetry && Number(d.asymmetry) * 100 >= Number('10')) ||
                (d.asymmetry && Number(d.asymmetry) * 100 <= Number('-10')),
            ),
          );
          break;
        case '20%':
          setSearchResults(
            volumetricData.filter(
              (d: any) =>
                (d.asymmetry && Number(d.asymmetry) * 100 >= Number('20')) ||
                (d.asymmetry && Number(d.asymmetry) * 100 <= Number('-20')),
            ),
          );
          break;
        default:
          break;
      }
    }
  }, [filter]);

  const handleFilterClick = (newFilter: string) => {
    setFilter(newFilter);
  };

  return (
    <div className="card">
      {Array.isArray(volumetricData) && volumetricData.length > 0 ? (
        <div>
          <div className="report__header">
            <div className="report__header__title"><Label uppercase size="h6">{t('Anatomy analysis')} </Label></div>
            <div className="report__header__wrapper">
              <div className="report__item">
                <Label uppercase size="h6">
                  {t('Atrophy')} (%)
                </Label>
                <Label center size="h6">{(((volumetricData[0].skull_vol - volumetricData[0].brain_vol) / volumetricData[0].skull_vol) * 100).toFixed(2)}</Label>
              </div>
              <div className="report__item">
                <Label uppercase size="h6">
                  {t('Csf/Skull ratio')} (%)
                </Label>
                
                  <Label center size="h6">
                    {( 100 * (volumetricData[0].skull_vol - volumetricData[0].brain_vol + ventricleSum) / volumetricData[0].skull_vol).toFixed(2)}
                  </Label>
                
              </div>
              <div className="report__item">
                <Label uppercase size="h6">
                  {t('Brain Vol')}(cm3)
                </Label>
               
                  <Label center size="h6">
                    {renderVolume((volumetricData[0].brain_vol / 1000).toFixed(1))}
                  </Label>
                
              </div>
              <div className="report__item">
                <Label uppercase size="h6">
                  {t('Skull Vol')}(cm3)
                </Label>
               
                  <Label center size="h6">
                  {renderVolume((volumetricData[0].skull_vol / 1000).toFixed(1))}
                  </Label>
                
              </div>
            </div>
          </div>
          <Spacer value={20} />
          <div className="filter__wrapper">
            <span className="filter__spacer">Filter: </span>
            <button
              type="button"
              onClick={() => handleFilterClick('All')}
              className={filter === 'All' ? 'filter__active' : 'filter__btn'}
            >
              
              All
            </button>
            <span className="filter__spacer">{' / '}</span>
            <button
              type="button"
              onClick={() => handleFilterClick('5%')}
              className={filter === '5%' ? 'filter__active' : 'filter__btn'}
            >
              
              5%
            </button>
            <span className="filter__spacer">{' / '}</span>
            <button
              type="button"
              onClick={() => handleFilterClick('10%')}
              className={filter === '10%' ? 'filter__active' : 'filter__btn'}
            >
              
              10%
            </button>
            <span className="filter__spacer">{' / '}</span>
            <button
              type="button"
              onClick={() => handleFilterClick('20%')}
              className={filter === '20%' ? 'filter__active' : 'filter__btn'}
            >
              
              20%
            </button>
          </div>
          <table className="report-table">
            <thead>
              <tr>
                <th>
                  <Label size="h6" uppercase >
                    Label
                  </Label>
                </th>
                <th>
                  <Label size="h6" uppercase >
                    Side
                  </Label>
                </th>
                <th>
                  <Label size="h6" className="text-center">
                    Volume (cm3)
                  </Label>
                </th>
                <th>
                  <Label size="h6" uppercase className="text-center">
                    asymmetry
                  </Label>
                </th>
                <th>
                  <Label size="h6" uppercase className="text-center">Ratio</Label>
                </th>
              </tr>
            </thead>
            {Array.isArray(seachResults) && seachResults.length > 0 ? (
              <tbody>
                {seachResults?.map((item: any) => (
                  <tr className="report-table__row" key={item?.id}>
                    <td>
                      
                      <Label size="h6">{item?.anatomy_label}</Label>
                    </td>
                    <td>
                      
                      <Label size="h6" >{item?.side}</Label>
                    </td>
                    <td>
                      
                      <Label size="h6" className="text-center">`${renderVolume(( Number(item.vol_mm3) / 1000 ).toFixed(2))}` </Label>
                    </td>
                    <td>
                      
                      <Label size="h6" className="text-center">`${(Number(item.asymmetry) * 100).toFixed(2)}%`</Label>
                    </td>
                    <td>
                      
                      <Label size="h6" className="text-center">`${(Number(item.struct2brain_ratio).toFixed(5))}%`</Label>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tr className="text-center">
                <td colSpan={5} rowSpan={10}>
                  <Spacer value={30} />
                  {isLoading ? (
                    <Label size="h6" center>
                      Loading ...
                    </Label>
                  ) : (
                    <Label size="h6" center>
                      Data not available
                    </Label>
                  )}
                </td>
              </tr>
            )}
          </table>
        </div>
      ) : (
        <div className="text-center">
          <Spacer value={30} />
          {isLoading ? (
            <Label size="h6" center>
              Loading ...
            </Label>
          ) : (
            <Label size="h6" center>
              Data not available
            </Label>
          )}
        </div>
      )}
      
    </div>
  );
};

BrainSegVolumeReport.defaultProps = {};

BrainSegVolumeReport.displayName = 'BrainSegVolumeReport';
export default BrainSegVolumeReport;
