/* eslint-disable */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
// import classnames from 'classnames';
import './viewer-tool-bar.scss';
import cornerstoneTools, {
  WwwcTool,
  LengthTool,
  PanTool,
  MagnifyTool, EraserTool,
  WwwcRegionTool,
  ScaleOverlayTool,
  BidirectionalTool,
  ArrowAnnotateTool,
  AngleTool,
  RectangleRoiTool,
  FreehandScissorsTool,
  TextMarkerTool,
  ZoomTool,
  StackScrollMouseWheelTool,
} from 'cornerstone-tools';
import { METTool } from '../../tools';

import { IconButton, Toolbar as MuiToolbar, Divider } from '@mui/material';
import { MdInvertColors, MdOutlineRectangle, MdOutlinePinDrop, MdDraw, MdSearch, MdZoomOutMap, MdStraighten, MdPanTool, MdLandscape } from 'react-icons/md';
import { TfiRulerAlt2, TfiRulerAlt, TfiArrow, TfiTag, TfiEraser } from 'react-icons/tfi';
import { TiAdjustContrast } from 'react-icons/ti';
import { GiTireIronCross } from 'react-icons/gi';
import { FiCamera, FiCopy, FiTarget } from 'react-icons/fi';
import cornerstone from 'cornerstone-core';
import { BiPointer } from 'react-icons/bi';
import { IStack } from '../DicomViewer/DicomViewer';
import ImageRating from '../ImageRating/ImageRating';

export interface ViewerToolBarProps {
  elementId: string,
  id: string,
  className?: string,
  onCaptureSnapshot?: () => void,
  onCopyMeasurement?: (arg: any) => void,
  stack: IStack | undefined,
  series: any,
  // activeTool: string;
  // setActiveTool: (toolName: string) => void;
}
const Separator = () => (
  <Divider orientation="vertical" sx={{ height: 24, mx: 1 }} />
);

const toolsList = [
  {
    name: 'StackScrollMouseWheel',
    tag: 'StackScrollMouseWheel',
    icon: BiPointer,
    tool: StackScrollMouseWheelTool,
    mouseButtonMask: 1,
  },
  {
    name: 'Wwwc',
    tag: 'Wwwc',
    icon: TiAdjustContrast,
    tool: WwwcTool,
    mouseButtonMask: 1,
  },
  {
    name: 'Zoom',
    icon: MdSearch,
    tool: ZoomTool,
    mouseButtonMask: 1,
    configuration: {
      invert: false,
      preventZoomOutsideImage: false,
      minScale: 0.1,
      maxScale: 20.0,
    },
  },
  {
    name: 'Pan',
    icon: TfiArrow,
    tool: PanTool,
    mouseButtonMask: 1,
  },
  {
    name: 'separator',
    type: 'separator',
    icon: Separator,
  },
  {
    name: 'Length',
    icon: TfiRulerAlt,
    tool: LengthTool,
    mouseButtonMask: 1,
  },
  {
    name: 'Angle',
    icon: TfiRulerAlt2,
    tool: AngleTool,
    mouseButtonMask: 1,
  },
  {
    name: 'Bidirectional',
    icon: GiTireIronCross,
    tool: BidirectionalTool,
    mouseButtonMask: 1,
  },
  {
    name: 'RectangleRoi',
    icon: MdOutlineRectangle,
    tool: RectangleRoiTool,
    mouseButtonMask: 1,
  },
  {
    name: 'ArrowAnnotate',
    icon: TfiTag,
    tool: ArrowAnnotateTool,
    mouseButtonMask: 1,
  }, {
    name: 'IdentifyMET',
    tag: 'IdentifyMET',
    icon: FiTarget,
    tool: METTool,
    mouseButtonMask: 1,
  },
  {
    name: 'separator2',
    type: 'separator',
    icon: Separator,
  },
  // {
  //   name: 'TextMarker',
  //   icon: MdOutlinePinDrop,
  //   tool: TextMarkerTool,
  //   mouseButtonMask: 1,
  //   configuration: {
  //     markers: ['MET-1', 'MET-2', 'MET-3', 'MET-4', 'MET-5'],
  //     current: 'MET-1',
  //     ascending: true,
  //     loop: true,
  //   },
  // },
  {
    name: 'Eraser',
    icon: TfiEraser,
    tool: EraserTool,
    mouseButtonMask: 1,
  },
  // {
  //   name: 'separator3',
  //   type: 'separator',
  //   icon: Separator,
  // },
];
const initTools = (elementId: any) => {
  const element = document.getElementById(elementId);

  // const test = cornerstoneTools

  toolsList.forEach((tool: any) => {
    if (tool.type !== 'separator') {
      const enabledTool = cornerstoneTools.getToolForElement(element, tool.name);
      if (!enabledTool) {
        cornerstoneTools.addToolForElement(element, tool.tool, tool.configuration);
      }
    }
  });

  const stackScrollMouseWheelCallback = (event: any) => {
    const stackData = cornerstoneTools.getToolState(element, 'stack');
    const currentImageIdIndex = stackData?.data[0]?.currentImageIdIndex;

    if (currentImageIdIndex !== undefined) {
      const imageId = stackData?.data[0]?.imageIds[currentImageIdIndex];
    }
  };




  // const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool
  // cornerstoneTools.addToolForElement(element, StackScrollMouseWheelTool, {});

  // cornerstoneTools.setToolActive('StackScrollMouseWheel', { })

  cornerstoneTools.toolColors.setToolColor('rgb(255, 255, 0)');
  cornerstoneTools.toolColors.setActiveColor('rgb(0, 255, 0)');
};

interface Point {
  x: number;
  y: number;
}

const calculateIntersection = (line1: { start: Point; end: Point }, line2: { start: Point; end: Point }): Point | null => {
  const { start: p1, end: p2 } = line1;
  const { start: p3, end: p4 } = line2;

  const denominator = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);

  // Check if the lines are parallel (denominator equals 0)
  if (denominator === 0) {
    return null;
  }

  const ua = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denominator;
  const ub = ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) / denominator;

  // Check if the intersection point is within the line segments
  if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
    const intersectionX = p1.x + ua * (p2.x - p1.x);
    const intersectionY = p1.y + ua * (p2.y - p1.y);

    return { x: intersectionX, y: intersectionY };
  }

  return null;
}
const ViewerToolBar = (props: ViewerToolBarProps): JSX.Element => {
  const { elementId, onCaptureSnapshot, onCopyMeasurement, id, className, series } = props;
  const [activeTool, setActiveTool] = useState('');

  useEffect(() => {
    if (!elementId) return;

    cornerstoneTools.init({
      showSVGCursors: true,
      mouseEnabled: true,
      globalToolSyncEnabled: true,
      touchEnabled: true,
      moduleName: 'globalConfiguration',
      // configuration: {
      //   mouseEnabled: true,
      //   touchEnabled: true,
      //   showSVGCursors: true,
      //   globalToolSyncEnabled: true,
      // },
    });
    const element = document.getElementById(elementId);

    initTools(elementId);

  }, []);

  // useEffect(()=>{
  //   if (elementId && stack){
  //     try{
  //       const element = document.getElementById(elementId);

  //       const mouseMoveHandler = (event: cornerstone.) => {
  //         const coords = cornerstone.pageToPixel(element, event.detail.currentPoints.page);
  //       };

  //       element.addEventListener('cornerstonetoolsmousemove', mouseMoveHandler);

  //       return () => {
  //         element.removeEventListener('cornerstonetoolsmousemove', mouseMoveHandler);
  //       };

  //     }
  //     catch(e){
  //       console.error('Error stack in bar');
  //     }
  //   }
  // },[elementId, stack]);

  const toggleTool = (tool: any) => {
    const element = document.getElementById(elementId);
    console.info('ACTIVATING tool', tool.name);

    if (tool.name === activeTool) {
      console.info('removing active tool', tool.name, activeTool);
      cornerstoneTools.setToolDisabledForElement(element, cornerstoneTools[tool.name]);
      setActiveTool('');
    } else {
      if (activeTool !== '') {
        console.warn('disabling active tool', activeTool);
        cornerstoneTools.setToolDisabledForElement(element, cornerstoneTools[activeTool]);
      }
      if (!tool.name) {
        console.error('Tool is missing:', tool);
        return;
      } else {
        const enabledTool = cornerstoneTools.getToolForElement(element, tool.name);
        if (!enabledTool) {
          cornerstoneTools.addTool(tool.tool, tool.configuration);
        }
        cornerstoneTools.setToolActiveForElement(element, tool.name, { mouseButtonMask: 1 });
        setActiveTool(tool.name);
      }

    }

  };
  const captureMeasurement = () => {
    const element = document.getElementById(elementId);
    const toolType = 'Bidirectional';
    const toolData = cornerstoneTools.getToolState(element, toolType);
    captureSnapshot('measurement');

    const measurements = toolData?.data?.map(
      (measurement: { handles: any; longestDiameter: any; shortestDiameter: any }) => {
        const st = measurement.handles.start as Point;
        const end = measurement.handles.end as Point;

        const per_st = measurement.handles.perpendicularStart as Point;
        const per_end = measurement.handles.perpendicularEnd as Point;

        const intersect = calculateIntersection({ start: st, end: end }, { start: per_st, end: per_end });
        return {
          longestDiameter: measurement.longestDiameter,
          shortestDiameter: measurement.shortestDiameter,
          start: { x: measurement.handles.start.x, y: measurement.handles.start.y },
          end: { x: measurement.handles.end.x, y: measurement.handles.end.y },
          perpendicularStart: {
            x: measurement.handles.perpendicularStart.x,
            y: measurement.handles.perpendicularStart.y
          },
          perpendicularEnd: { x: measurement.handles.perpendicularEnd.x, y: measurement.handles.perpendicularEnd.y },
          intersect: intersect,
        };
      }
    );


    if (onCopyMeasurement) onCopyMeasurement(measurements);
  };

  const captureSnapshot = (snapshotName: string) => {
    const element = document.getElementById(elementId); // Replace with your container ID
    const canvas = cornerstone.getEnabledElement(element!).canvas;
    const image = cornerstone.getEnabledElement(element!).image;
    if (image) {
      // Create a temporary canvas to draw the snapshot
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = image.width!;
      tempCanvas.height = image.height!;
      const tempContext = tempCanvas.getContext('2d');
      if (tempContext) {
        tempContext.drawImage(canvas!, 0, 0);
      }
      // Convert the snapshot to an image URL
      const imageURL = tempCanvas.toDataURL();

      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = `${snapshotName}.png`; // Specify the desired file name and format

      // Trigger the download programmatically
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  };


  return (
    <div className="toolbar">
      {/* <div>Active tool: {activeTool}</div> */}
      {toolsList.map((tool) => (
        <IconButton
          key={tool.name}
          onClick={() => toggleTool(tool)}
          sx={{ color: activeTool === tool.name ? '#fff' : '#bbb' }}
          size='small'
        >
          <tool.icon className={activeTool === tool.name ? 'icon-primary' : 'icon-secondary'} />
        </IconButton>
      ))}
      <IconButton
        key={'captureMeasurement'}
        onClick={captureMeasurement}
        size='small'
      >
        <FiCopy className={'icon-secondary'} />
      </IconButton>
      <Separator />

      <IconButton
        key={'captureSnapshot'}
        onClick={() => {
          if (onCaptureSnapshot) {
            onCaptureSnapshot();
          } else {
            captureSnapshot('snapshot');
          }
        }}
        size='small'
      >
        <FiCamera className={'icon-secondary'} />
      </IconButton>
      <Separator />
      <ImageRating series={series} />
    </div>
  );
};

ViewerToolBar.defaultProps = {
  className: '',
};

ViewerToolBar.displayName = 'ViewerToolBar';
interface MemoizedViewerToolBarProps {
  elementId: string;
  onCaptureSnapshot?: () => void;
  onCopyMeasurement?: (arg: any) => void,
  stack: IStack | undefined,
  series: any,
}

// const MemoizedViewerToolBar = React.memo(() => <ViewerToolBar id="viewertoolbar" elementId="dicomImage" />);
// export default MemoizedViewerToolBar;

const MemoizedViewerToolBar: React.FC<MemoizedViewerToolBarProps> = ({ elementId, onCaptureSnapshot, onCopyMeasurement, stack, series }) => (
  <ViewerToolBar stack={stack} id="viewertoolbar" elementId={elementId} onCaptureSnapshot={onCaptureSnapshot} onCopyMeasurement={onCopyMeasurement} series={series} />
);
export default React.memo(MemoizedViewerToolBar);
