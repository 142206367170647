import React, { useState } from 'react';
import { BsChevronCompactRight } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { Label, IconButton, Modal, DatePicker, Spacer, Button, Alert } from '../../atoms';
import DeleteVerification from '../DeleteVerification/DeleteVerification';
import TextInput from '../TextInput/TextInput';
import './session-list-item.scss';
import { useEditSessionMutation, useDeleteSessionMutation, useCreateSessionSurveyEmailMutation } from '../../../api/builders/patientSessions.api';
import { useGetPatientEditableQuery } from '../../../api/builders/patient.api';


export interface SessionListItemProps {
  className?: string,
  session: any,
  isActive?: boolean,
  onClick: (item: any) => void,
  patient: any,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const SessionListItem = (props: SessionListItemProps): JSX.Element => {
  const { session, isActive, onClick, patient } = props;
  const { data: patientInfo } = useGetPatientEditableQuery(patient?.id ?? skipToken);
  const [editModal, setEditModal] = useState(false);
  const [deleteConfrimModal, setDeleteConfirmModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [editedSession, setEditedSession] = useState({ ...session });
  const [onEdit, { isLoading: isEdititng, isSuccess, isError, error }] = useEditSessionMutation();
  const [sendEmail, { isLoading: isSending, isSuccess: isSuccessSending, isError: isErrorSending, error: sendError }] = useCreateSessionSurveyEmailMutation();
  const [onDelete, { isLoading: isDeleting, isSuccess: isSuccessDeleting, isError: isErrorDeleting, error: deleteError }] = useDeleteSessionMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  React.useEffect(() => {
    if (isErrorSending) {
      const apiError = sendError as ApiError;
      setAlert({
        open: true,
        message: `Error Sending Email: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorSending]);

  React.useEffect(() => {
    if (isSuccessSending) {
      setAlert({
        open: true,
        message: 'Success ending Email',
        kind: 'success',
      });
    }
  }, [isSuccessSending]);

  React.useEffect(() => {
    if (isErrorDeleting) {
      const apiError = deleteError as ApiError;
      setAlert({
        open: true,
        message: `Error Deleting: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorDeleting]);

  React.useEffect(() => {
    if (isSuccessDeleting) {
      setAlert({
        open: true,
        message: 'Success Deleting',
        kind: 'success',
      });
    }
  }, [isSuccessDeleting]);


  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Saving Edits: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Saving Edits',
        kind: 'success',
      });
    }
  }, [isSuccess]);



  const email = async () => {
    try {
      const payload = {
        source: 'patient-coordinator@upstream.vision',
        template: 'alabama_surveys_link',
        email: patientInfo[0].email,
        variables: JSON.stringify({
          hash_id: session.hashId ? session.hashId : 'nullID',
          visit_date: moment(session.date).format('MM/DD/YYYY'),
        }),
      };
      await sendEmail(payload);
      setEmailModal(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = () => {
    try {
      onDelete(session);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancelEdit = () => {
    setEditModal(false);
    setEditedSession({ ...session });
  };

  const handleSaveEdit = async () => {
    try {
      const payload = {
        id: session.id,
        body: {
          sessionLabel: editedSession.sessionLabel,
          date: editedSession.date,
        },
      };
      await onEdit(payload);
      setEditModal(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="session-list-item">
      {isActive ?
        <>
          <div className='session-list-item__body-active'>
            <Label uppercase size='h5' weight={600} color='ghost'>
              {session.date ? moment(session.date).format('MMM Do YYYY') : 'date'}
            </Label>
            <div>
              <IconButton customIcon={<HiOutlineMail size={16} />} onClick={() => setEmailModal(true)} />
              <IconButton icon='edit' onClick={() => setEditModal(true)} />
              <IconButton icon='trash' onClick={() => setDeleteConfirmModal(true)} />
            </div>
          </div>
          <div className='session-list-item__indicator'>
            <BsChevronCompactRight />
          </div>
        </>
        :
        <div className='session-list-item__body' role='button' tabIndex={-1} onClick={() => onClick(session)}>
          <Label uppercase size='h5' weight={600} color='ghost'>
            {session.date ? moment(session.date).format('MMM Do YYYY') : 'date'}
          </Label>
        </div>
      }

      {/* delete */}
      <Modal title='delete session' open={deleteConfrimModal} onClose={() => setDeleteConfirmModal(false)} >
        <DeleteVerification
          name='session'
          item={session}
          onCancel={() => setDeleteConfirmModal(false)}
          onDelete={handleDelete}
        />
      </Modal>

      {/* email */}
      <Modal title='Email Patient' open={emailModal} onClose={() => setEmailModal(false)} >
        <div>
          {Array.isArray(patientInfo) && validateEmail(patientInfo[0].email)
            ?
            <>
              <Label center size='h5'>Alert patient at: {patientInfo[0].email} about surveys?</Label>
              <Spacer value={30} />
              <div className='btn-row'>
                <Button outline onClick={email} loading={isSending}>
                  Send
                </Button>
                <Button outline kind='danger' onClick={() => setEmailModal(false)}>
                  Cancel
                </Button>
              </div>
            </>
            :
            <>
              <Label center size='h5'>Patient does not have valid email address, cannot send email</Label>
              <Spacer value={30} />
              <div className='btn-row'>
                <Button outline kind='danger' onClick={() => setEmailModal(false)}>
                  Cancel
                </Button>
              </div>
            </>
          }
        </div>
      </Modal>

      {/* edit */}
      <Modal title='edit session' open={editModal} onClose={() => setEditModal(false)} >
        <>
          <TextInput
            label='Label'
            value={editedSession.sessionLabel}
            onChange={(e) => setEditedSession({ ...editedSession, sessionLabel: e.target.value })}
          />
          <DatePicker
            label='Date'
            value={moment(editedSession.date).format('L')}
            onChange={(e: any) => setEditedSession({ ...editedSession, date: e.$d })}
          />
          <Spacer value={20} />
          <div className='btn-row'>
            <Button onClick={handleSaveEdit} loading={isEdititng}>Confrim</Button>
            <Button kind='danger' onClick={handleCancelEdit}>Cancel</Button>
          </div>
        </>
      </Modal>
      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

SessionListItem.defaultProps = {
  className: '',
  isActive: false,
};

SessionListItem.displayName = 'SessionListItem';
export default SessionListItem;
