/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Tile, Label, Button, Spacer, DatePicker } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import './question-types.scss';
import { useGetUserProfileQuery } from '../../../api/builders/hsg.api';
import TextInput from '../TextInput/TextInput';
import SimpleSelect from '../SimpleSelect/SimpleSelect';


export interface UserInfoProps {
  answer: any,
  onAnswer: (vals: any) => void,
  question: any,
}

const initInfo: State = {
  firstname: '',
  lastname: '',
  lastnameAtBirth: '',
  motherMaidenname: '',
  gender: '',
  genderOther: '',
  dob: '',
};

interface State {
  firstname: string;
  lastname: string;
  lastnameAtBirth: string;
  motherMaidenname: string;
  gender: string;
  genderOther: string;
  dob: string;
}


const options = [
  { goto: 'next', label: 'Male', order: 1, value: 'Male' },
  { goto: 'next', label: 'Female', order: 2, value: 'Female' },
  { goto: 'next', label: 'Other', order: 3, value: 'Other' },
];



const UserInfo = (props: UserInfoProps): JSX.Element => {
  const { answer, onAnswer, question } = props;
  const { data: userProfile } = useGetUserProfileQuery(' ');
  const [value, setValue] = useState<State>(initInfo);

  const getGender = (tmp: string) => {
    if (tmp === 'Male' || tmp === 'Female') {
      return tmp;
    }
    return 'Other';
  };

  const getGenderOther = (tmp: string) => {
    if (tmp === 'Male' || tmp === 'Female') {
      return '';
    }
    return tmp;
  };

  const updateStateFromProps = (state: State, propsArray: { label: keyof State; value: string }[]) => {
    const updatedState: State = { ...state };
    // propsArray.forEach((prop) => {
    //   updatedState[prop.label] = prop.value;
    // });
    // return updatedState;
    propsArray.forEach((prop) => {
      if (prop.label === 'gender') {
        if (prop.value === 'Male' || prop.value === 'Female') {
          updatedState.gender = prop.value;
          updatedState.genderOther = ''; // Reset genderOther if Male or Female
        } else {
          updatedState.gender = 'Other';
          updatedState.genderOther = prop.value;
        }
      } else {
        updatedState[prop.label] = prop.value;
      }
    });
    return updatedState;
  };


  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      // setValue(answer[0].value);
      const test = updateStateFromProps(value, answer);

      if (test) setValue(test);
    } else if (userProfile) {
      const info = {
        firstname: userProfile[0].firstname?.length > 0 ? userProfile[0].firstname[0] : '',
        lastname: userProfile[0].lastname?.length > 0 ? userProfile[0].lastname[0] : '',
        lastnameAtBirth: userProfile[0].lastnameAtBirth?.length > 0 ? userProfile[0].lastnameAtBirth[0] : '',
        dob: userProfile[0].dob?.length > 0 ? userProfile[0].dob[0] : '',
        gender: userProfile[0].gender?.length > 0 ? getGender(userProfile[0].gender[0]) : '',
        motherMaidenname: userProfile[0].motherMaidenname?.length > 0 ? userProfile[0].motherMaidenname[0] : '',
        genderOther: userProfile[0].gender?.length > 0 ? getGenderOther(userProfile[0].gender[0]) : '',
      };
      setValue(info);
    }
  }, [question.id, userProfile]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setValue((prev) => ({ ...prev, [e.target.id]: val }));
  };

  const answerQuestion = (gender: string) => {
    setValue((prev) => ({ ...prev, gender }));
  };

  const handleDOBchange = (newVal: any) => {
    const val = moment(newVal.$d).format('MM/DD/YYYY');
    setValue((prev) => ({ ...prev, dob: val.toString() }));
  };

  useEffect(() => {
    onAnswer([
      { label: 'firstname', value: value.firstname },
      { label: 'lastname', value: value.lastname },
      { label: 'lastnameAtBirth', value: value.lastnameAtBirth },
      { label: 'motherMaidenname', value: value.motherMaidenname },
      { label: 'gender', value: value.gender === 'Other' ? value.genderOther : value.gender },
      { label: 'dob', value: value.dob },
    ]);
  }, [value]);

  return (
    <div className='question-types_text'>

      <Spacer value={15} />


      <TextInput
        id="firstname"
        value={value.firstname}
        label='First Name'
        onChange={onChange}
      />
      <Spacer value={10} />
      <TextInput
        id="lastname"
        value={value.lastname}
        label='Last Name'
        onChange={onChange}
      />
      <Spacer value={10} />

      <TextInput
        id="lastnameAtBirth"
        value={value.lastnameAtBirth}
        label='Last Name at Birth'
        onChange={onChange}
      />

      <TextInput
        id="motherMaidenname"
        type=""
        value={value.motherMaidenname}
        label="Mother's Maiden Name"
        onChange={onChange}
      />
      <Spacer value={10} />

      <Label className="input__label" size="h5" weight={600} color="primary" >Sex at Birth</Label>
      <Spacer value={10} />
      {options.map((op, i) => (
        <SimpleSelect
          label={op.label}
          onSelect={answerQuestion}
          answer={value.gender}
          multi={false}
          key={i}
          withCheck
        />
      ))}

      {value.gender === 'Other' &&
        <TextInput
          id="genderOther"
          label="Specify:"
          value={value.genderOther}
          placeholder=""
          onChange={onChange}
        />}

      <Spacer value={10} />
      <DatePicker
        id="dob"
        label='Date of Birth'
        value={value.dob}
        onChange={handleDOBchange}
      />
      <Spacer value={10} />


    </div>
  );
};

UserInfo.defaultProps = {
};

UserInfo.displayName = 'UserInfo';
export default UserInfo;
