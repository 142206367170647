import React, { useState } from 'react';
import { Tile, Label, Button, Spacer } from '../../atoms';
import { List, Text, Number, Date, Dropdown, Title, Checklist } from './ItemTypes';
import './form-item.scss';

export interface FormItemProps {
  question: any
  onAnswer: (ans: any) => void
  currentIndex: number,
  editable?: boolean,
  noNumbers?: boolean,
  className?: string,
}

const FormItem = (props: FormItemProps): JSX.Element => {
  const { question, onAnswer, currentIndex, editable, noNumbers, className } = props;
  const { answer, type, options, instructions, label } = question;
  const [answr, setAnswr] = useState(answer);

  const answerQuestion = (answers: any) => {
    onAnswer(answers);
    setAnswr(answers);
  };


  const renderOptions = (qType: string, qOtions: any) => {
    if (!qOtions || qOtions.length === 0) {
      return <div> There are no options for this question </div>;
    }
    
    switch (type) {
      case 'Boolean':
      case 'LIST_SINGLE':
        return (
          <div className='span-two'>
            {noNumbers ? (
              <Label size="h6" weight={200} >{label}</Label>
            ) : (
              <Label size="h6" weight={200} >{currentIndex + 1}: {label}</Label>
            )}
            {options.map((option: any, index: number) => (
              <List
                key={`question-option-${option.order}`}
                option={option}
                onAnswer={answerQuestion}
                answer={answer}
                editable={editable}
              />
            ))}
          </div>
        );
      case 'LIST_MULTI':
        return options.map((option: any, index: number) =>
          <List
            key={`question-option-${option.order}`}
            option={option}
            onAnswer={answerQuestion}
            answer={answer}
            multi
            editable={editable}
          />,
        );
      case 'CHECKLIST':
        return <Checklist
          key={`question-${currentIndex}`}
          label={label}
          onAnswer={answerQuestion}
          answer={answer}
          multi
          editable={editable}
        />;
      case 'TEXT':
        return <Text
          key={`question-${currentIndex}`}
          answer={answer}
          onAnswer={answerQuestion}
          editable={editable}
          label={label}
          qNumber={currentIndex + 1}
          noNumbers={noNumbers ?? false}
          className={className ?? ''}
        />;
      case 'NUMBER':
        return <Number
          key={`question-${currentIndex}`}
          answer={answer}
          onAnswer={answerQuestion}
          editable={editable}
          label={label}
          qNumber={currentIndex + 1}
          noNumbers={noNumbers ?? false}
          className={className ?? ''}
        />;
      case 'DATE':
        return <Date
          key={`question-${currentIndex}`}
          answer={answer}
          onAnswer={answerQuestion}
          editable={editable}
          label={label}
          qNumber={currentIndex + 1}
          noNumbers={noNumbers ?? false}
          className={className ?? ''}
        />;
      case 'DROPDOWN':
        return <Dropdown
          key={`question-${currentIndex}`}
          answer={answer}
          options={options}
          onAnswer={answerQuestion}
          editable={editable}
          label={label}
          qNumber={currentIndex + 1}
          noNumbers={noNumbers ?? false}
          className={className ?? ''}
        />;
      case 'SECTIONTITLE':
        return <Title
          key={`question-${currentIndex}`}
          label={label}
        />;
      default: return <div>Unknown form type: {type}</div>;
    }
  };

  return (
    <>
      {renderOptions(type, options)}
    </>
  );
};

FormItem.defaultProps = {
  editable: true,
  noNumbers: true,
  className: '',
};

FormItem.displayName = 'FormItem';
export default FormItem;
