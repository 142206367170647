/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import './add-met.scss';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { IconButton } from '@mui/material';
import { FaPaste } from 'react-icons/fa';
import { Dropdown, Label, Spacer, Button, Checkbox } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import { useGetAllVisitImagesQuery } from '../../../api/builders/participantFiles.api';
import { RootState } from '../../../store/store';


export interface AddMetProps {
  onClose: () => void,
  participant: string | undefined,
  itemId: string | undefined,
  addMET: (payload: any) => void,
  isAddingMet: boolean,
  visitId: string | undefined,
  label: string,
}

export interface TypeError {
  status: number,
  data: { message: string },
}

// const initialMet = {
//   tumorLabel: '',
//   lobe: '',
//   laterality: '',
//   rlCoord: 0,
//   apCoord: 0,
//   siCoord: 0,
//   diameterAxial: 0,
//   diameterCoronal: 0,
//   diameterSagittal: 0,
//   isTreated: false,
//   isTreatable: false,
// };

const AddMet = (props: AddMetProps): JSX.Element => {
  const { onClose, participant, itemId, addMET, isAddingMet, visitId, label } = props;
  const initialMet = {
    tumorLabel: label,
    lobe: '',
    laterality: '',
    rlCoord: 0,
    apCoord: 0,
    siCoord: 0,
    diameterAxial: 0,
    diameterCoronal: 0,
    diameterSagittal: 0,
    isTreated: false,
    isTreatable: false,
  };
  const [newMetVals, setNewMetVals] = React.useState(initialMet);
  const [error, setError] = React.useState({ open: false, message: '' });
  const { data, isLoading: isLoadingData } = useGetAllVisitImagesQuery(visitId);
  const [selectedImage, setSelectedImage] = React.useState<any | null>(null);
  const copiedItem = useSelector((state: RootState) => state.clipboard.copiedItem);


  const closeError = () => {
    setError({ open: false, message: '' });
  };

  const closeAddModal = () => {
    onClose();
    closeError();
  };

  const handleChangeImage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedImage(event.target.value);
  };
  // const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewMetVals({ ...newMetVals, isTreated: !newMetVals.isTreated });
  // };
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (newMetVals.isTreatable && newMetVals.isTreated) {
      setNewMetVals({ ...newMetVals, isTreated: false });
    } else if (newMetVals.isTreatable && !newMetVals.isTreated) {
      setNewMetVals({ ...newMetVals, isTreated: true });
    } else if (newMetVals.isTreated) {
      setNewMetVals({ ...newMetVals, isTreated: false });
    }
  };
  const handleCheckTreatable = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (newMetVals.isTreatable && newMetVals.isTreated) {
      setNewMetVals({ ...newMetVals, isTreatable: false, isTreated: false });
    } else {
      setNewMetVals({ ...newMetVals, isTreatable: !newMetVals.isTreatable });
    } 
  };
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewMetVals({ ...newMetVals, [event.target.name]: event.target.value });
  };
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMetVals({ ...newMetVals, [event.target.name]: event.target.value });
  };


  const addNewMET = async () => {
    try {
      if (newMetVals.rlCoord === 0 || newMetVals.apCoord === 0 || newMetVals.siCoord === 0) {
        setError({
          open: true,
          message: 'Coordinates is Required',
        });
        return;
      }
      const payload = {
        participantId: participant,
        protocolItemId: itemId,
        tumorLabel: newMetVals.tumorLabel,
        type: 'MET',
        lobe: newMetVals.lobe,
        laterality: newMetVals.laterality,
        RLCoord: newMetVals.rlCoord,
        APCoord: newMetVals.apCoord,
        SICoord: newMetVals.siCoord,
        diameterAxial: newMetVals.diameterAxial.toString(),
        diameterCoronal: newMetVals.diameterCoronal.toString(),
        diameterSagittal: newMetVals.diameterSagittal.toString(),
        isTreated: newMetVals.isTreated,
        isTreatable: newMetVals.isTreatable,
        metReferenceImageId: selectedImage ? selectedImage.ScanSeries[0].id : '',
      };
      const what = await addMET(payload);
      setNewMetVals(initialMet);
      closeAddModal();
    } catch (e) {
      console.error('e', e);
      const err = e as TypeError;
      if (err?.status === 400) {
        setError({
          open: true,
          message: err.data.message,
        });
      }
    }
  };

  const onPaste = () => {
    const measurement = copiedItem?.meta;
    if (Array.isArray(measurement)) {
      setNewMetVals({
        ...newMetVals,
        rlCoord : measurement[0].intersect.x.toFixed(2),
        apCoord : measurement[0].intersect.y.toFixed(2),
        siCoord : measurement[0].intersect.z.toFixed(2),
        diameterAxial: Number(Number(measurement[0].longestDiameter).toFixed(2)),
        diameterCoronal: Number(Number(measurement[0].shortestDiameter).toFixed(2)),
      });
      const img = data.find((item: {
        [x: string]: any; id: any; 
      }) => item.ScanSeries[0].SeriesDescription === measurement[0].series);
      setSelectedImage(img);
    }
  };

  return (
    <div className="card">
      <div>
        <TextInput
          name='tumorLabel'
          label='MET Label'
          value={newMetVals.tumorLabel}
          onChange={handleChangeInput}
        />
       
          <div className='add-met__paste' >
          <IconButton
          key="paste"
          onClick={onPaste}
          size='small'
        >
           <FaPaste className="icon-secondary" />
        </IconButton>
        
        <Label size='h5'>Paste Measurement   </Label>
        </div>
        <Dropdown
          name='lobe'
          label='Lobe'
          value={newMetVals.lobe}
          onChange={handleChange}
        >
          <MenuItem value="Frontal">Frontal</MenuItem>
          <MenuItem value="Temporal">Temporal</MenuItem>
          <MenuItem value="Parietal">Parietal</MenuItem>
          <MenuItem value="Occipital">Occipital</MenuItem>
        </Dropdown>

        <Dropdown
          name='laterality'
          label='Laterality'
          value={newMetVals.laterality}
          onChange={handleChange}
        >
          <MenuItem value="Left">Left</MenuItem>
          <MenuItem value="Right">Right</MenuItem>
          <MenuItem value="Both">Both</MenuItem>
          <MenuItem value="Unknown">Unknown</MenuItem>
        </Dropdown>

        <div>
          {data && data?.length > 0 &&
            <Dropdown
              name='Reference Image'
              label='Reference Image'
              value={selectedImage}
              onChange={handleChangeImage}
            >
              {data.map((it: any) => <MenuItem value={it} >{`${it.ScanSeries[0].Modality} ${it.ScanSeries[0].SeriesDescription} `} </MenuItem>)}
            </Dropdown>}
        </div>

        <Grid container spacing={2}>
          <Grid md={4}>
            <div>
              <TextInput
                type='number'
                label='R-L Coord'
                name='rlCoord'
                onChange={handleChangeInput}
                value={newMetVals.rlCoord}
              // addFlag={addFlag}
              />
            </div>
          </Grid>
          <Grid md={4}>
            <div>
              <TextInput
                type='number'
                label='A-P Coord'
                name='apCoord'
                onChange={handleChangeInput}
                value={newMetVals.apCoord}
              // addFlag={addFlag}
              />
            </div>
          </Grid>
          <Grid md={4}>
            <div>
              <TextInput
                type='number'
                label='S-I Coord'
                name='siCoord'
                onChange={handleChangeInput}
                value={newMetVals.siCoord}
              // addFlag={addFlag}
              />
            </div>
          </Grid>
          <Grid xs={12}>
            <div className='met-manager__assessment-header'>
              <Label size="h4" color='primary' weight={600}>Met Assessment</Label>
            </div>
          </Grid>
          <Grid sm={6}>
            <TextInput
              type='number'
              label='Longest Diameter in mm'
              name='diameterAxial'
              onChange={handleChangeInput}
              value={newMetVals.diameterAxial}
            />
          </Grid>
          <Grid sm={6}>
            <TextInput
              type='number'
              label='Short Diameter (in plane) in mm'
              name='diameterCoronal'
              onChange={handleChangeInput}
              value={newMetVals.diameterCoronal}
            />
          </Grid>
          {/* <Grid sm={4}>
            <TextInput
              type='number'
              label='Third Diameter in mm'
              name='diameterSagittal'
              onChange={handleChangeInput}
              value={newMetVals.diameterSagittal}
            />
          </Grid> */}
          <Grid xs={12}>
            <Checkbox checked={newMetVals.isTreatable} onChange={handleCheckTreatable} label='hey'>
              <Label size='h5' color='ghost' >Is treatable</Label>
            </Checkbox>
          </Grid>
          <Grid xs={12}>
            <Checkbox checked={newMetVals.isTreated} onChange={handleCheck} label='hey'>
              <Label size='h5' color='ghost' >Is/Will be part of treatment plan</Label>
            </Checkbox>
          </Grid>
        </Grid >
        <Spacer value={20} />
        <div className='super-centered'>
          {error.open && <Label size='h6' color='danger'>{error.message}</Label>}
        </div>
        <Button onClick={addNewMET} loading={isAddingMet} >Add</Button>
      </div>    </div>
  );
};

AddMet.defaultProps = {
};

AddMet.displayName = 'AddMet';
export default AddMet;
