import QueryString from 'qs';
import api from '../api';

export const epilepsyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllPatientSurveys: builder.query({
      query: (patientId) => `/usersurvey/patient/${patientId}`,
      providesTags: ['EPILEPSY'],
      transformResponse: (response: any) => response.items,
    }),

    createDiagnosisRecord: builder.mutation({
      query: (payload) => ({
        url: '/epilepsy/diagnosis/record',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['EPILEPSY'], // Add appropriate tags
    }),

    getDiagnosisRecordsByPatient: builder.query({
      query: (patientId) => `/epilepsy/diagnosis/record/patient/${patientId}`,
      providesTags: ['EPILEPSY'],
      transformResponse: (response: any) => response.items,
    }),

    updateDiagnosisRecord: builder.mutation({
      query: (payload) => ({
        url: `/epilepsy/diagnosis/record/${payload.diagnosisRecordId}`,
        method: 'PUT',
        body: payload,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['EPILEPSY'], // Add appropriate tags
    }),

    deleteDiagnosisRecord: builder.mutation({
      query: (diagnosisRecordId) => ({
        url: `/epilepsy/diagnosis/record/${diagnosisRecordId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EPILEPSY'], // Add appropriate tags
    }),

    createSurgeryRecord: builder.mutation({
      query: (payload) => ({
        url: '/epilepsy/surgery/record',
        method: 'POST',
        body: payload,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['EPILEPSY'], // Add appropriate tags
    }),

    getSurgeryRecordsByPatient: builder.query({
      query: (patientId) => `/epilepsy/surgery/record/patient/${patientId}`,
      providesTags: ['EPILEPSY'],
      transformResponse: (response: any) => response.items,
    }),

    updateSurgeryRecord: builder.mutation({
      query: (payload) => ({
        url: `/epilepsy/surgery/record/${payload.surgeryRecordId}`,
        method: 'PUT',
        body: payload,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['EPILEPSY'], // Add appropriate tags
    }),

    deleteSurgeryRecord: builder.mutation({
      query: (surgeryRecordId) => ({
        url: `/epilepsy/surgery/record/${surgeryRecordId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EPILEPSY'], // Add appropriate tags
    }),

    createSeizureRecord: builder.mutation({
      query: (payload) => ({
        url: '/epilepsy/seizure/record',
        method: 'POST',
        body: payload,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['EPILEPSY'], // Add appropriate tags
    }),

    getSeizureRecordsByPatient: builder.query({
      query: (patientId) => `/epilepsy/seizure/record/patient/${patientId}`,
      providesTags: ['EPILEPSY'],
      transformResponse: (response: any) => response.items,
    }),

    updateSeizureRecord: builder.mutation({
      query: (payload) => ({
        url: `/epilepsy/seizure/record/${payload.seizureRecordId}`,
        method: 'PUT',
        body: payload,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['EPILEPSY'], // Add appropriate tags
    }),

    deleteSeizureRecord: builder.mutation({
      query: (seizureRecordId) => ({
        url: `/epilepsy/seizure/record/${seizureRecordId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EPILEPSY'],
    }),

    createMedicationRecord: builder.mutation({
      query: (payload) => ({
        url: '/epilepsy/medication/record',
        method: 'POST',
        body: payload,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['EPILEPSY'],
    }),

    getMedicationRecordsByPatient: builder.query({
      query: (patientId) => `/epilepsy/medication/record/patient/${patientId}`,
      providesTags: ['EPILEPSY'],
      transformResponse: (response: any) => response.items,
    }),

    updateMedicationRecord: builder.mutation({
      query: (payload) => ({
        url: `/epilepsy/medication/record/${payload.medicationRecordId}`,
        method: 'PUT',
        body: payload,
        transformResponse: (response: any) => response,
      }),
      invalidatesTags: ['EPILEPSY'],
    }),

    deleteMedicationRecord: builder.mutation({
      query: (medicationRecordId) => ({
        url: `/epilepsy/medication/record/${medicationRecordId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EPILEPSY'],
    }),
  }),
});

export const {
  usePrefetch,
  useGetAllPatientSurveysQuery,
  useCreateDiagnosisRecordMutation,
  useGetDiagnosisRecordsByPatientQuery,
  useUpdateDiagnosisRecordMutation,
  useDeleteDiagnosisRecordMutation,
  useCreateSurgeryRecordMutation,
  useGetSurgeryRecordsByPatientQuery,
  useUpdateSurgeryRecordMutation,
  useDeleteSurgeryRecordMutation,
  useCreateSeizureRecordMutation,
  useGetSeizureRecordsByPatientQuery,
  useUpdateSeizureRecordMutation,
  useDeleteSeizureRecordMutation,
  useCreateMedicationRecordMutation,
  useGetMedicationRecordsByPatientQuery,
  useUpdateMedicationRecordMutation,
  useDeleteMedicationRecordMutation,
} = epilepsyApi;
