/* eslint-disable no-nested-ternary */
import React from 'react';
import classnames from 'classnames';
import { BsChevronCompactRight, BsFillCheckCircleFill, BsFillFlagFill, BsPersonBoundingBox } from 'react-icons/bs';
import { AiFillEdit } from 'react-icons/ai';
import moment from 'moment';
import './protocol-visit-item.scss';
import { Label, Spacer, DatePicker, Modal, Button, Badge } from '../../atoms';
import { IProtocolTimePoint } from '../ProtocolTimePoint/ProtocolTimePoint';

type Props = {
  item: IProtocolTimePoint | IProtocolTimePoint,
  active: boolean,
  current: boolean | false;
  index: number,
  onChangeItem: (item: IProtocolTimePoint, index: number) => void
  handleChangeDate?: (payload: { visitId: string, visitDate: string }) => void | null
  readOnly: boolean,
  acl?: string,
  condensed: boolean | false;
  // isUploading?: boolean, 
};

// type IProtocolTimePoint = {
//   date: string | undefined,
//   id: string | undefined,
//   label: string | undefined,
//   order: number | undefined,
//   studyDay: string | undefined,
//   updatedAt: number | undefined,
//   visit: string | undefined,
//   visitType: string | undefined,
//   userProtocolItems: ProtocolItemType[],
// };

export type ProtocolItemType = {
  file: string | undefined,
  id: string | undefined,
  item: string | undefined,
  label: string | undefined,
  messages: string | undefined,
  order: number,
  createdAt: number | undefined,
  updatedAt: number | undefined,
  role: string | undefined,
  status: string,
  surveyId: string | undefined,
  type: string | undefined,
  userId: string | undefined,
  deviation: boolean | null;
};
const formatLabel = (inputString: string | undefined, _condensed: boolean) => {
  if (inputString && _condensed) {
    // const regex = /(CYCLE\s)(\d+)(\s?(PRE|POST|(\d+-\d+HR\s\w+))|$)/;
    const regex = /(CYCLE\s)(\d+)(\s?(PRE|POST|(\d+-\d+HR\s\w+))|$)|(LONG TERM FOLLOW UP\s)(\d+)/;

    const match = inputString.toUpperCase().match(regex);

    if (match) {
      if (match[1]) {
        const cycle = match[2].replace(/^0+/, ''); // Remove leading zeroes if any
        const timepoint = match[4] ? match[4] : match[5] ? match[5] : '';

        if (timepoint) {
          return `C${cycle} ${timepoint}`;
        }
        return `C${cycle}`;

      } if (match[6]) {
        const followUp = match[7];
        return `FOLLOW UP ${followUp}`;
      }
    }

  }
  return inputString;
};

const ProtocolVisitItem = (props: Props) => {
  const { current = false, active, item, index, onChangeItem, handleChangeDate, acl, condensed } = props;
  const [visitDate, setVisitDate] = React.useState(item.date ? item?.date : '');
  const [editDate, setEditDate] = React.useState(false);

  React.useEffect(() => {
    if (item.date) {
      setVisitDate(item.date);
    }
  }, [item]);

  const classes = {
    'visit-item': true,
    'visit-item__card': true,
    'visit-item__card-selected': active,
    'condensed': condensed,
  };

  const onCancelChangeDate = () => {
    setEditDate(false);
    setVisitDate(item.date ? item.date : '');
  };

  const confirmDate = () => {
    if (item.id !== undefined && item.date !== undefined && handleChangeDate) {
      const pL = {
        visitId: item.id,
        visitDate: moment(visitDate).format('MM/DD/YYYY'),
      };
      handleChangeDate(pL);
      onCancelChangeDate();
    }
  };


  const onChangeDate = (e: any) => {
    setVisitDate(e.$d);
  };

  const getcompleted = (el: any) => {
    let completed = 0;
    let all = 0;
    if (Array.isArray(el.userProtocolItems)) {
      const filtered = el.userProtocolItems.filter((pItem: any) => pItem.mandatory && pItem.mandatory !== false);
      all = filtered.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < el.userProtocolItems.length; i++) {
        if (el.userProtocolItems[i].status.toLowerCase() === 'pending review' || el.userProtocolItems[i].status.toLowerCase() === 'approved' && el.userProtocolItems[i].mandatory !== false) {
          completed += 1;
        }
      }
    }
    if (completed === all) {
      return 'complete';
    }
    return `${completed}/${all} Submitted`;
  };

  const getrejected = (el: IProtocolTimePoint) => {
    let rejected = 0;
    const all = 0;
    if (Array.isArray(el.userProtocolItems)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < el.userProtocolItems.length; i++) {
        if (el && el.userProtocolItems[i] && el.userProtocolItems[i].status !== undefined && el.userProtocolItems[i].status!.toLowerCase() === 'rejected') {
          rejected += 1;
        }
      }
    }
    if (rejected > 0) {
      return true;
    }
    return false;
  };


  return (
    <div className='visit-item__wrapper'>
      <div className='patient-indicator'>{current ? <BsPersonBoundingBox /> : ''}</div>
      <div className={classnames(classes)} key={item.id} onClick={() => onChangeItem(item, index)} role="button" tabIndex={index}>
        <div className='visit-item__body'>
          {/* <Label size='h8'>visit id {props?.item.id}</Label> */}
          <div className={`visit-item__header ${condensed ? 'condensed' : ''}`}>
            {
              active ?
                <Label uppercase size={condensed ? 'h6' : 'h5'} weight={600} color="primary">
                  {formatLabel(item.visit, condensed)}
                </Label>
                :
                <Label uppercase size={condensed ? 'h6' : 'h5'} weight={600}>
                  {formatLabel(item.visit, condensed)}
                </Label>
            }

            {getrejected(item) &&
              <div className='visit-item__check-wrapper'>
                <BsFillFlagFill size={15} className='visit-item__flag' />
              </div>}

            {getcompleted(item) === 'complete' &&
              <div className='visit-item__check-wrapper'>
                <BsFillCheckCircleFill size={15} className='visit-item__check' />
              </div>}
          </div>

          <div className={`status ${condensed ? 'condensed' : ''}`}>
            <Label uppercase size="subtext">
              {getcompleted(item)}
            </Label>
          </div>
          {/* <div className={`status ${condensed ? 'condensed' : ''}`}>
            <Label size='subtext'>{visitDate !== '' ? moment(visitDate).format('MM/DD/YYYY') : 'No Visit Date'}</Label>
          </div> */}
        </div>


        {/* {(!active && acl === 'CORELAB') &&
          <>
            <Spacer value={10} />
            <div className='visit-item__date'>
              <Label size='h6'>{visitDate !== '' ? moment(visitDate).format('MM/DD/YYYY') : 'No Visit Date'}</Label>
              <button className='icon-btn' type='button' aria-label='pick date' onClick={() => setEditDate(true)}><AiFillEdit /></button>
            </div>
          </>
        } */}

        <Modal open={editDate} onClose={onCancelChangeDate} title='Change Visit Date' size='sm'>
          <div>
            <DatePicker
              label="Visit Date:"
              value={visitDate}
              onChange={onChangeDate}
            />
            <Spacer value={15} />
            <div className='btn-row'>
              <Button onClick={onCancelChangeDate} >Cancel</Button>
              <Button onClick={confirmDate} >Save Changes</Button>
            </div>
          </div>
        </Modal>
      </div>
      <div className='indicator'>{active ? <BsChevronCompactRight /> : ''}</div>
    </div>
  );
};

ProtocolVisitItem.defaultProps = {
  // key: '_key',
  handleChangeDate: null,
  acl: 'null',
  // isUploading: false,
};

export default ProtocolVisitItem;
