import React, { useEffect, useRef } from 'react';
// import classnames from 'classnames';
import './video-player.scss';

export interface VideoPlayerProps {
  className?: string,
  url: string,
}

const VideoPlayer = (props: VideoPlayerProps): JSX.Element => {
  const { url } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      // Additional resets or configurations can be added here
    }
  }, [url]);


  return (
    <div className="card">
      <video className="video-player" ref={videoRef} controls key={url}>
        <source src={url} type="video/mp4" />
        <track kind="captions" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

VideoPlayer.defaultProps = {
  className: '',
};

VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
