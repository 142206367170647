/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import QueryString from 'qs';
import api from '../api';

type Series = {
  id: number;
  alignment: string;
  SeriesDescription: string;
  Modality: string;
  SpacingBetweenSlices: string;
  SliceThickness: string;
  PixelSpacing: string;
  qc_score: string;
  bucket: string;
};
type AlignmentRequest = {
  seriesId: any;
  userProtocolItemId: string | undefined;
  participantId: string;
};

export const participantFilesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProtocolFiles: builder.query({
      query: (payload) => ({ url: `/files/${payload.protocolItemId}`, method: 'GET' }),
      transformResponse: (response: any) => response,
    }),

    getProtocolSeries: builder.query<Series[], string | undefined>({
      query: (payload) => ({ url: `/files/images/${payload}`, method: 'GET' }),
      transformResponse: (
        response: any,
        meta,
        arg) => response.items,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'REQUIREMENT_IMAGES' as const, id })),
            { type: 'REQUIREMENT_IMAGES', id: 'LIST' },
          ]
          : [{ type: 'REQUIREMENT_IMAGES', id: 'LIST' }],
      // providesTags: ['REQUIREMENT_IMAGES'],
      // providesTags: (result, error, id) => [{ type: 'REQUIREMENT_IMAGES', id }],
    }),
    createAlignmentJob: builder.mutation({
      query: (payload) =>{
        console.info('createAlignmentJob', payload);
        return  ({ url: '/analysis/job/alignment', method: 'POST', body: payload });
      },
      // invalidatesTags: (result, error, { id }) => {
      //   console.log('invalidates', id);
      //   return [{ type: 'REQUIREMENT_IMAGES', id }];
      // },
        
      // async onQueryStarted(arg,
      //   { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry },
      // ) {
      //   console.log('onQueryStarted', arg);
      //   // dispatch(api.util.updateQueryData('getProtocolSeries', arg, (draft) => {
      //   //   // modify the draft
      //   // }));
      // },
    }),
    // createAlignmentJob: builder.mutation<AlignmentRequest, Partial<Series> & Pick<Series, 'id'>>({
    //   query: (payload) =>{
    //     console.info('createAlignmentJob', payload);
    //     return  ({ url: '/analysis/job/alignment', method: 'POST', body: payload });
    //   },
    //   invalidatesTags: (result, error, { id }) => {
    //     console.log('invalidates', id);
    //     return [{ type: 'REQUIREMENT_IMAGES', id }];
    //   },
        
    //   async onQueryStarted(arg,
    //     { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry },
    //   ) {
    //     console.log('onQueryStarted', arg);
    //     // dispatch(api.util.updateQueryData('getProtocolSeries', arg, (draft) => {
    //     //   // modify the draft
    //     // }));
    //   },
    // }),
    downloadSeries: builder.mutation({
      query: (payload) => ({ url: '/files/s3zip/series', method: 'POST', body: payload }),
    }),
    getAllScans: builder.query({
      query: (payload) => ({ url: `/files/images/participant/${payload.id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
    }),
    getAllVisitImages: builder.query({
      query: (payload) => ({ url: `/files/images/timepoint/${payload}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['REQUIREMENT_IMAGES'],
    }),
    creatFileConnection: builder.mutation({
      query: (payload) => ({ url: '/files/images/connect/', method: 'POST', body: payload }),
      invalidatesTags: ['REQUIREMENT_IMAGES'],
    }),
    removeImage: builder.mutation({
      query: (payload) => ({ url: '/files/images/connect/', method: 'DELETE', body: payload }),
      invalidatesTags: ['REQUIREMENT_IMAGES'],
    }),
    getImgCount: builder.query({
      query: (payload) => ({ url: `/files/hot/filecount/${payload}`, method: 'GET' }),
      providesTags: ['REQUIREMENT_IMAGES'],
      // transformResponse: (response: any) => response,
    }),
    getDocuments: builder.query({
      query: (itemId) => ({ url: `/files/generic/${itemId}`, method: 'GET' }),
      providesTags: ['REQUIREMENT_DOCUMENTS'],
      transformResponse: (response: any) => response.items,
    }),
    getAllParticipantScans: builder.query({
      query: (participantId) => ({ url: `/files/images/all/${participantId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
    }),
    getAllParticipantFiles: builder.query({
      query: (participantId) => ({ url: `/files/generic/all/${participantId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
    }),
    getColdFileArray: builder.query({
      query: (payload) => {
        console.info('getColdFileArray', payload);
        if (payload.aligned) {
          return { url: `/files/cold/files/${payload.id}/?alignment=aligned&registrationJobId=${payload.job}`, method: 'GET' };
        }
        return { url: `/files/cold/files/${payload.id}`, method: 'GET' };
      },
    }),
    updateImgRating: builder.mutation({
      query: (payload) => ({ url: `/files/images/${payload.seriesId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['REQUIREMENT_IMAGES'],
    }),
    // getColdFileArray: builder.query({
    //   query: ({ id }) => ({ url: `/files/cold/files/${id}`, method: 'GET' }),
    //   // providesTags: ['IMAGES'],
    // }),
    getAuditColdFileArray: builder.query({
      query: ({ id }) => ({ url: `/files/cold/files/audit/${id}`, method: 'GET' }),
      // providesTags: ['IMAGES'],
    }),
    downLoadFile: builder.mutation({ query: (s3_path) => ({ url: '/files/drive/getobject/', method: 'POST', body: s3_path }) }),
    createZip: builder.mutation({ query: (payload) => ({ url: '/files/s3zip/', method: 'POST', body: payload }) }),
  }),
});

export const {
  usePrefetch,
  useGetProtocolFilesQuery,
  useGetProtocolSeriesQuery,
  useDownloadSeriesMutation,
  useCreatFileConnectionMutation,
  useGetAllVisitImagesQuery,
  useGetAllScansQuery,
  useRemoveImageMutation,
  useGetImgCountQuery,
  useDownLoadFileMutation,
  useGetDocumentsQuery,
  useGetAllParticipantFilesQuery,
  useGetAllParticipantScansQuery,
  useGetColdFileArrayQuery,
  useGetAuditColdFileArrayQuery,
  useCreateAlignmentJobMutation,
  useUpdateImgRatingMutation,
} = participantFilesApi;
