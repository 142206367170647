
const hsgMenu = [
  {
    title: 'Home',
    url: '/home',
    private: true,
  },
  {
    title: 'Research',
    url: '/research',
    private: true,
  },
  {
    title: 'Help',
    url: '/help',
    private: true,
  },
];

export default hsgMenu;

