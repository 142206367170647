/* eslint-disable react/require-default-props */
import React from 'react';
import classnames from 'classnames';
import './phone-input.scss';
import { Label } from '../../atoms';


export interface PhoneInputProps {
  disabled?: boolean,
  invalid?: boolean,
  label?: string | undefined,
  name?: string,
  value?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
  autocomplete?: string,
  className?: string;
  error?: string;
  id?: string;
}


// eslint-disable-next-line consistent-return
const normalizeInput = (value: string, previousValue: string | undefined): string => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }

  return currentValue;
};


const PhoneInput = (props: PhoneInputProps): JSX.Element => {
  const { disabled, invalid, label, name, value, onChange, placeholder, autocomplete, className, id, error } = props;

  const classes = {
    'text-input': true,
    'text-input--disabled': disabled,
    'text-input--invalid': invalid,
    className: true,
  };

  // const formatPhoneNumber = (input: string): string => {
  //   const cleaned = input.replace(/\D/g, ''); // Remove non-numeric characters
  //   const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  //   if (!match) return input;

  //   return `(${match[1]})${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
  // };



  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = normalizeInput(event.target.value, value);
    if (onChange) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = formattedValue;
      onChange(event);
    }
  };

  // eslint-disable-next-line consistent-return

  


  return (
    <div className="phone-input-wrapper">
      {label && <Label className="input__label" size="h5" weight={600} color="primary" >{label}</Label>}
      <input
        aria-label={id}
        onChange={handleInputChange}
        className={classnames(classes)}
        value={value}
        disabled={disabled}
        type='tel' // Use type 'tel' for phone numbers
        placeholder={placeholder}
        name={name}
        id={id}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
};

PhoneInput.defaultProps = {
  id: 'phoneNumber',
  disabled: false,
  invalid: false,
  name: '',
  value: '',
  onChange: () => { },
  placeholder: null,
  autocomplete: 'off',
  error: '',
  className: 'text-input',
};

PhoneInput.displayName = 'PhoneInput';
export default PhoneInput;
