import React from 'react';
import { Label, Spacer } from '../../../components';



const Privacy = () => {
  const somevar = 'Privacy';

  return (
    <div className="privacy">
      {/* {`\u2122`}  */}
      <Spacer value={20} />
      <Label size="h1" bold center>Privacy Statement </Label>
      <Spacer value={20} />

      <Label size="h6" >
        Huntington Study Group is committed to protecting your privacy and developing technology that gives you a powerful and safe online experience. This Statement of Privacy applies to the Huntington Study Group (HSG) website, and any website(s) or mobile application(s) owned and/or controlled by or on behalf of the HSG, and governs data collection and usage. By visiting the HSG website(s), or by using HSG mobile application(s), you consent to the terms and conditions described in this statement.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold>
        Collection of your Personal Information
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG may collect personally identifiable information, such as your e-mail address, name, home or work address or telephone number. HSG may also collect user-submitted health-related information such as symptoms, diagnosis, and medical condition (collectively, “Personal Information”). HSG may also collect demographic information such as your ZIP code, age, gender, preferences, interests and favorites.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG may also automatically collect information about your computer or mobile device hardware and software when you access the HSG website(s) or mobile application(s). This information can include: your IP address, browser type, domain names, operating system, access times and referring website addresses. This information is used by HSG for the operation of the services it provides, to maintain quality of HSG’s services, and to provide general statistics regarding use of the HSG website(s) and mobile application(s).
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG encourages you to review the privacy statements of websites you choose to link to from HSG’s website so that you can understand how those websites collect, use and share your information. HSG is not responsible for the privacy statements or other content on websites outside of the HSG owned and/or controlled websites and applications.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold >
        Use of your Personal Information
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG may collect and use your Personal Information to operate the HSG website and deliver the services you have requested. HSG may also use your Personal Information to inform you of other products or services available from HSG and its affiliates. HSG may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered.  In the event you participate in any clinical research with the HSG, the applicable informed consent and other documentation governing the research will address how your study data will be used.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG does not sell, rent or lease its customer lists to third parties. HSG may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your Personal Information is not transferred to the third party. In addition, HSG may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your Personal Information except to provide these services to HSG, and they are required to maintain the confidentiality of your Personal Information.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG does not use or disclose sensitive personal information, such as race, without your explicit consent.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG keeps track of the HSG websites and pages our customers visit in order to determine what HSG services are the most popular. This data is used to deliver customized content within HSG to customers whose behavior indicates that they are interested in a particular subject area.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG will disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) comply with applicable law or comply with legal process served on HSG; (b) protect and defend the rights or property of HSG; or (c) act under exigent circumstances to protect the personal safety of users of HSG, or the public.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold >
        Use of Cookies
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG websites may use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your computer hard drive or mobile device memory. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        One of the primary purposes of cookies is to provide a convenience feature to save you time. One purpose of a cookie is to tell a web server that you have returned to a specific page. For example, if you personalize HSG pages, or register with the HSG site or services, a cookie helps HSG to recall your specific information on subsequent visits. This simplifies the process of recording information such as billing addresses, shipping addresses, and so on. When you return to the same HSG web site, the information you previously provided can be retrieved, so you can easily use the HSG features that you customized.
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the HSG services or websites you visit.
      </Label>
      <Spacer value={20} />

      <Label size="h4" bold >
        Security of your Personal Information
      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG considers the protection of Personal Information a very high priority. HSG maintains appropriate administrative, technical and physical safeguards to protect and secure your Personal Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction, both during transmission and once it is received. Please understand, however, that no security system is perfect. We cannot guarantee the security of our databases, nor can we guarantee that the information you supply will not be intercepted while being transmitted to or from us over the Internet.
      </Label>
      <Spacer value={20} />




      <Label size="h4" bold >
        Changes to this Statement

      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG will occasionally update this Statement of Privacy. HSG encourages you to periodically review this Statement.
      </Label>
      <Spacer value={20} />


      <Label size="h4" bold >
        Contact Information

      </Label>
      <Spacer value={20} />

      <Label size="h6" >
        HSG welcomes your comments regarding this Statement of Privacy. If you have questions or concerns regarding this Statement, please contact Huntington Study Group at info@hsglimited.org .
      </Label>
      <Spacer value={20} />

    </div>
  );
};

export default Privacy;
