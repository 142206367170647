/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsExclamationCircle } from 'react-icons/bs';
import { IconButton, Modal, Alert } from '../../../atoms';
import { MedicationT, VisitT } from '../../../../types/types';
import sortVisits from '../../../../utils/fns/sortVisits';
import { useDeleteMedicationRecordMutation } from '../../../../api/builders/epilepsy.api';
import EditMedicationForm from '../../EditMedicationForm/EditMedicationForm';
import DeleteVerification from '../../DeleteVerification/DeleteVerification';

export interface MedicationsProps {
  visits: VisitT[] | [],
  patient: any,
  meds: any,
}

const sortByDate = (data: any) => {
  try {
    const clone = JSON.parse(JSON.stringify(data));

    const sorted = clone.sort((a: any, b: any) => {
      if (a.active === 'null' || a.active === true) {
        if (b.active === 'null' || b.active === true) {
          return new Date(b.createdon).getTime() - new Date(a.createdon).getTime();
        }
        return -1; // 'a' comes first as 'b' is false
      } if (b.active === 'null' || b.active === true) {
        return 1; // 'b' comes first as 'a' is false
      }
      return new Date(b.createdon).getTime() - new Date(a.createdon).getTime();
    });
    return sorted;
  } catch (e) {
    console.error(e);
    return data; // Return the original data if an error occurs
  }
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const Medications = (props: MedicationsProps): JSX.Element => {
  const { visits, patient, meds } = props;
  const [selectedVisit, setVisit] = useState<VisitT | null>(null);
  const [openMed, setOpenMed] = useState<any | null>(null);
  const [showMedChangeForm, setShowChangeMedForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<any | null>(null);
  const [deleteMed, { isLoading, isSuccess, isError, error }] = useDeleteMedicationRecordMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  const handleCloseMedForm = () => {
    setShowChangeMedForm(false);
    setVisit(null);
    setOpenMed(null);
  };


  const closeDelete = () => {
    setDeleteModal(false);
    setItemToDelete(null);
  };

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Deleting Substance: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Deleting Substance',
        kind: 'success',
      });
      closeDelete();
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  const openDeleteMed = (med: any) => {
    setDeleteModal(true);
    setItemToDelete(med);
  };

  const handleShowMed = (med: any, visit: VisitT) => {
    setShowChangeMedForm(true);
    setVisit(visit);
    setOpenMed(med);
  };

  const handleDeleteMed = (med: MedicationT) => {
    deleteMed(med.id);
  };

  const renderMedsTd = (index: number, med: any, visit: VisitT) => {
    const vIndex = visits.length - 1;
    const check = visits[vIndex].id;

    try {
      let result = '';
      let color = '';
      if (med?.history) {
        const tmp = JSON.parse(med.history);
        const entry = tmp.filter((el: any) => el.date === visit.date)[0];
        if (entry?.active === false) {
          return <td key={index} className="exclamation" onClick={() => handleShowMed(med, visit)}>Inactive</td>;
        } if (entry) {
          const bno = entry?.BNO ? ' BNO' : '';
          const long = entry?.longActing ? ' LA' : '';
          result = `${entry.amount}mg ${entry.frequency}${bno}${long}`;
          if (entry.sideEffects === 'Mild') {
            color = 'medium';
          } else if (entry.sideEffects === 'Significant') {
            color = 'bad';
          } else {
            color = 'hover-td';
          }
        }
      }
      const latest = visits.length - 1;
      const isMostRecent = visits[latest];


      if (med?.active === false && isMostRecent.id === visit.id) {
        return <td key={index} className="exclamation" onClick={() => handleShowMed(med, visit)}>Inactive</td>;
      } if (result === '' && visit.id === check) {
        return <td key={index} className="exclamation" onClick={() => handleShowMed(med, visit)}><BsExclamationCircle /></td>;
      }
      return <td key={index} className={color} onClick={() => handleShowMed(med, visit)}>{result}</td>;

    } catch (e) {
      console.error('MED E', e);
    }
    return <td key={index} />;
  };

  return (
    <>
      {
        meds && meds.length > 0 && (
          sortByDate(meds).map((el: any) => <tr key={el.id}>
            <th> <span className="seizure-th">
              {el?.substance}{' '}{el.BNO === true && 'BNO'}{' '}{el.LA === true && 'LA'}{' '}
              <IconButton icon='trash' onClick={() => openDeleteMed(el)} />
            </span> </th>
            {
              sortVisits(visits).map((visit, index) => renderMedsTd(index, el, visit))
            }
          </tr>)
        )
      }

      <Modal open={showMedChangeForm} title='open sz' onClose={handleCloseMedForm}>
        <EditMedicationForm
          visit={selectedVisit}
          med={openMed}
          handleCloseMedForm={handleCloseMedForm}
          patient={patient}
        // patient={patient}
        />
      </Modal>

      <Modal title="Delete Medication" open={deleteModal} onClose={() => setDeleteModal(false)} >
        <DeleteVerification
          item={itemToDelete}
          onDelete={handleDeleteMed}
          loading={isLoading}
          name={itemToDelete?.substance ?? ''}
          onCancel={closeDelete}
        />
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />

    </>
  );
};

Medications.defaultProps = {
};

Medications.displayName = 'Medications';
export default Medications;
