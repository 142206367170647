import React from 'react';
import TermsBody from '../../../utils/TermsBody';



const Terms = () => {
  const somevar = 'Terms';
  return (
    <div className="terms">
      <TermsBody />
    </div>
  );
};

export default Terms;
