/* eslint-disable react/no-array-index-key */
import React from 'react';
import axios from 'axios';
import { MenuItem, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { MdOutlineExpandMore } from 'react-icons/md';
import { Button, Dropdown, Label, Spacer } from '../../../components/atoms';
import { FAQbody } from '../../../utils/FaqBody';
import { TextAreaInput, TextInput } from '../../../components/molecules';
import { AlertState } from '../../../types/types';
import formatFAQ from '../../../utils/fns/formatFAQ';


const initialVals = {
  type: '',
  title: '',
  body: '',
  newEmail: '',
};

const typeOps = [
  'I need to change my email',
  'I accidently skipped a survey',
  'I have a technical question',
  'Other',
];


const Help = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [formVals, setFormVal] = React.useState(initialVals);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'success',
  });

  const handleFAQChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  const formatText = (text: string): JSX.Element[] => text.split('®').map((part, i, array) =>
    i === array.length - 1 ? <React.Fragment key={i}>{part}</React.Fragment> : (
      <React.Fragment key={i}>
        {part}<sup>®</sup>
      </React.Fragment>
    ),
  );

  const handleChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormVal((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const handleChangeBody = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormVal((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const validateEmail = (username: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(username).toLowerCase());
  };

  const validate = () => {
    if (
      validateEmail(formVals.newEmail) &&
      formVals.type &&
      formVals.type.trim() !== '' &&
      formVals.title &&
      formVals.title.trim() !== '' &&
      formVals.body &&
      formVals.body.trim() !== ''
    ) {
      return false;
    }
    return true;
  };

  const onSend = async () => {
    setLoading(true);

    const headers = {
      'x-api-key': process.env.REACT_APP_API_KEY,
    };

    const payload = {
      'email': formVals.newEmail,
      'question_type': formVals.type,
      'title': formVals.title,
      'message': formVals.body,
    };
    try {
      const tmp = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/support/ticket`,
        headers,
        data: payload,
      });
      if (tmp && tmp.status && tmp.status === 200) {
        setAlert({
          open: true,
          message: 'Thank you for reaching out! We will respond via email within one business day, Monday – Friday 8:00am – 5:00pm ET',
          kind: 'success',
        });
      }
    } catch {
      setAlert({
        open: true,
        message: 'Sending help request failed, please refresh and try again',
        kind: 'error',
      });
      setLoading(false);
    }

    setLoading(false);
    setFormVal(initialVals);
  };

  return (
    <div className="help">
      <Label size="h2" bold center color="primary" >How can we help? </Label>
      <Spacer value={30} />

      <div className='help__flex'>
        <div className='panel1'>
          {
            FAQbody.map((el, index) => (
              <Accordion key={index} expanded={expanded === el.title} onChange={handleFAQChange(el.title)}>
                <AccordionSummary
                  expandIcon={<MdOutlineExpandMore />}
                >
                  <Label size="h4">
                    {formatFAQ(el.title)}
                  </Label>
                </AccordionSummary>
                {
                  el.body.map((elbody, bodyindex) => (
                    <AccordionDetails key={bodyindex}>
                      <div className="help__faq-text">
                        <p>
                          {formatFAQ(elbody)}
                        </p>
                      </div>
                    </AccordionDetails>
                  ))
                }
              </Accordion>
            ))
          }
        </div>
        <div className='panel2'>
          <Label size="h4" color="primary" className='help__title'> Contact Us </Label>
          <Spacer value={10} />
          {/* TODO: Actually connect email from user */}
          {/* {!selectUser && */}
          <div>
            <TextInput label="Email" name="newEmail" value={formVals.newEmail} onChange={handleChangeForm} />
          </div>

          <Dropdown
            value={formVals.type}
            onChange={handleChangeForm}
            label='Question Type'
            name='type'

          >
            {typeOps.map((op: string) => (
              <MenuItem value={op}>{op}</MenuItem>
            ))}
          </Dropdown>

          <TextInput label="Title" name="title" value={formVals.title} onChange={handleChangeForm} />

          <TextAreaInput
            label="Message" name="body" value={formVals.body} onChange={handleChangeBody}
          />

          <Spacer value={30} />
          <div className='btn-row'>
            <Button disabled={validate()} onClick={onSend}>Send</Button>
          </div>


        </div>
      </div>

    </div>
  );
};

export default Help;
