import React from 'react';
// import classnames from 'classnames';
import './dropdown.scss';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Label from '../Label/Label';

type Props = {
  children?: any;
  value: string | number;
  name?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  disabled?: boolean;
  onChange: (value: any) => void;
};


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 5,
    position: 'relative',
    margin: '1em .2em',
    backgroundColor: 'white',
    border: '.5px solid rgba(90, 90, 90, 0.1)',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.08)',
    fontSize: '0.875em',
    // padding: '4px 26px 4px 12px',
    padding: '1em .75em',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Raleway',
    ].join(','),
    '&:focus': {
      borderRadius: 2,
      borderColor: 'rgba(90, 91, 91, 0.1)',
      boxShadow: '0 5px 15px rgba(0,0,0, / 8%)',
    },
  },
}));

const Dropdown = (props: Props) => {
  const { value, onChange, id, children, name, invalid, disabled, placeholder, label } = props;
  return (
    <FormControl className='dropdown' >
      {label &&
        <Label size='h5' weight={600} color='primary'>
          {label}
        </Label>
      }
      <Select
        labelId='demo-customized-select-label'
        id={id}
        value={value}
        name={name}
        error={invalid}
        disabled={disabled}
        onChange={onChange}
        input={<BootstrapInput />}
        label={placeholder}
      >
        {children}
      </Select>
    </FormControl>
  );
};

Dropdown.defaultProps = {
  children: <MenuItem value=''><em>No Options</em></MenuItem>,
  name: 'Dropdown',
  placeholder: 'Selet',
  invalid: false,
  disabled: false,
  id: 'x',
  label: '',
};

export default Dropdown;