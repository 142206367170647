import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Label, Spacer, Button, Modal, Alert } from '../../atoms';
import './epilepsy-surgery.scss';
import { PatientT, SurgeryT } from '../../../types/types';
import {
  useGetSurgeryRecordsByPatientQuery, useCreateSurgeryRecordMutation, useUpdateSurgeryRecordMutation,
} from '../../../api/builders/epilepsy.api';
import SurgeryForm from './SurgeryForm';

export interface EpilepsySurgeryProps {
  className?: string,
  patient: PatientT,
}

const sortByDate = (data: any) => {
  const tmp = JSON.parse(JSON.stringify(data));
  let test2 = [];
  try {
    test2 = tmp.sort((a: any, b: any) => a.dateOfSurgery.localeCompare(b.dateOfSurgery));
    return test2;
  } catch (e) {
    console.error('SURGERY SORT ERR', e);
    return test2;
  }
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}



const EpilepsySurgery = (props: EpilepsySurgeryProps): JSX.Element => {
  const { patient } = props;
  const { data: surgeries, isLoading } = useGetSurgeryRecordsByPatientQuery(patient?.id ?? skipToken);
  const [addSurgery, { isLoading: isAddingSurgery, isError: isErrorAdding, isSuccess: isSuccessAdding, error: addError }] = useCreateSurgeryRecordMutation();
  const [editSurgery, { isLoading: isEditingSurgery, isError: isEditingError, isSuccess: isEditingSuccess, error: editError }] = useUpdateSurgeryRecordMutation();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);
  const [current, setCurrent] = useState<SurgeryT | null>(null);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  const openSurgury = (entry: any) => {
    setShowEditForm(true);
    setCurrent(entry);
  };

  const handleCloseEditSurgery = () => {
    setShowEditForm(false);
    setCurrent(null);
  };

  const handleCloseNewSurgery = () => {
    setShowNewForm(false);
  };

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = addError as ApiError;
      setAlert({
        open: true,
        message: `Error Adding Surgery: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isEditingSuccess) {
      setAlert({
        open: true,
        message: 'Success Editing Surgery',
        kind: 'success',
      });
      handleCloseEditSurgery();
    }
  }, [isEditingSuccess]);


  React.useEffect(() => {
    if (isEditingError) {
      const apiError = editError as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Surgery: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isEditingError]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding Surgery',
        kind: 'success',
      });
      handleCloseNewSurgery();
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  const handleAddNewSurgery = (surgeryVals: any) => {
    const payload = {
      SurgeryType: surgeryVals.type,
      surgeryDetails: JSON.stringify(surgeryVals),
      dateOfSurgery: surgeryVals.date,
      visitDate: surgeryVals.date,
      patientId: patient.id,
    };
    addSurgery(payload);
    // closeModal();
  };


  const handleEditSurgery = (surgeryVals: any) => {
    if (current && current.id) {
      const payload = {
        SurgeryType: surgeryVals.type,
        surgeryDetails: JSON.stringify(surgeryVals),
        dateOfSurgery: surgeryVals.date,
        visitDate: surgeryVals.date,
        patientId: patient.id,
        surgeryRecordId: current?.id,
      };
      editSurgery(payload);
    }
    // closeModal();
  };




  return (
    <div className='epilepsy-surgery'>
      <Tile>
        <>
          <Label size='h4' color='ghost'>Epilepsy Surgeries</Label>
          <Spacer value={10} />
          {
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus
            surgeries && sortByDate(surgeries).map((entry: any) => <div role='button' tabIndex={-1} key={entry.id} onClick={() => openSurgury(entry)}>
              <Label size="h6" bold uppercase>{entry.SurgeryType}</Label>
              <div className="surgery-card__row">
                <Label size="h6">{entry.dateOfSurgery}</Label>
              </div>
              <Spacer value={10} />
            </div>)
          }
          <Spacer value={20} />
          <div className="super-centered" ><Button onClick={() => setShowNewForm(true)}>+ Add New</Button></div>
        </>
      </Tile>

      <Modal open={showNewForm} onClose={handleCloseNewSurgery} title='Add New Surgery'>
        <SurgeryForm
          handleSubmit={handleAddNewSurgery}
          loading={isAddingSurgery}
          editSurgery={null}
        />
      </Modal>

      <Modal open={showEditForm} onClose={handleCloseEditSurgery} title='Edit Surgery'>
        <SurgeryForm
          handleSubmit={handleEditSurgery}
          loading={isEditingSurgery}
          editSurgery={current}
        />
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

EpilepsySurgery.defaultProps = {
  className: '',
};

EpilepsySurgery.displayName = 'EpilepsySurgery';
export default EpilepsySurgery;
