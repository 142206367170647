/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import createUsernameFromEmail from '../../utils/fns/createUsernameFromEmail';

export type Credentials = {
  username: string,
  password: string,
};

export type SignUpCred = {
  email: string,
  password: string,
  firstname: string,
  surname: string,
};

export type ConfirmState = {
  email: string,
  code: string,
};

interface RejectedAction extends Action {
  error: Error
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected');
}
type InitialStateType = { user: any | null, status: string | null, error: any | null, token: string | null, groups: string[] | null, challenge: string | null };

const initialState: InitialStateType = { user: null, status: 'idle', error: null, token: null, groups: null, challenge: null };

export const fetchUser = createAsyncThunk('authentication/fetchUser', async () => {
  const user = await Auth.currentAuthenticatedUser();
  const { accessToken } = user.signInUserSession;

  return accessToken;
});

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ username, password }: Credentials, thunkAPI) => {
    try {
      const user = await Auth.signIn(username.toLowerCase(), password);

      if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return thunkAPI.rejectWithValue(user);
      }

      const { accessToken } = user.signInUserSession;
      // const groups = accessToken.payload['cognito:groups'] || [];

      // if (!groups.includes('TESTING')) {
      //   return thunkAPI.rejectWithValue('You do not have access to this feature');
      // }

      return accessToken;
    } catch (e) {
      console.error('error', e);
      throw e;
    }
  },
);
export const authenticateUser = createAsyncThunk(
  'auth/login',
  async ({ username, password }: Credentials, thunkAPI) => {
    try {
      const user = await Auth.signIn(username.toLowerCase(), password);

      if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return thunkAPI.rejectWithValue(user);
      }

      const { accessToken } = user.signInUserSession;

      return accessToken;
    } catch (e) {
      console.error('error', e);
      throw e;
    }
  },
);

export const signUpUser = createAsyncThunk('auth/signUpUser', async ({ email, password, firstname, surname }: SignUpCred) => {
  try {
    const usernameTmp = createUsernameFromEmail(email);
    const response = await Auth.signUp({
      username: usernameTmp,
      password,
      attributes: {
        email,
        name: `${firstname} ${surname}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
});

export const confirmSignUp = createAsyncThunk(
  'auth/confirmSignUpUser',
  async ({ email, code }: ConfirmState) => {
    try {
      const usernameTmp = createUsernameFromEmail(email);
      await Auth.confirmSignUp(usernameTmp, code);
      return 'ConfirmSignUpSuccess';
    } catch (error) {
      throw error;
    }
  },
);


export const logout = createAsyncThunk('authentication/logout', async () => {
  // console.info('LOGOUT');
  await Auth.signOut();
});

const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setUser: (state, { payload: user }) => ({ ...state, user }),
  },
  extraReducers: (builder) => {
    builder

      .addCase(loginUser.fulfilled, (state, { payload: token }) =>
      // Add user to the state array
        ({
          ...state,
          token: token.jwtToken,
          groups: token.payload['cognito:groups'] || [],
          user: token.payload.sub,
          status: 'succeeded',
          error: null,
        }),
      )
      .addCase(loginUser.rejected, (state, action: any) => {
        console.error('rejected');
        return ({
          ...state,
          token: null,
          status: 'failed',
          error: action?.payload?.challengeName === 'NEW_PASSWORD_REQUIRED' ? 'NEW_PASSWORD_REQUIRED' : action?.error?.message,
          challenge: action?.payload?.challengeParam,
        });
      },
      )
      .addCase(loginUser.pending, (state, action) =>
      // Add user to the state array
        ({
          ...state,
          status: 'loading',
          error: null,
        }),
      )
      .addCase(signUpUser.fulfilled, (state) => ({
        ...state,
        status: 'signUpSuccess',
        error: null,
      }))
      .addCase(signUpUser.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message || 'SignUp failed',
      }))
      .addCase(confirmSignUp.fulfilled, (state) => ({
        ...state,
        status: 'confirmSignUpSuccess',
        error: null,
      }))
      .addCase(confirmSignUp.rejected, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.error.message || 'ConfirmSignUp failed',
      }))
      .addCase(logout.fulfilled, () => ({ ...initialState, status: 'succeeded' }))
      .addCase(fetchUser.pending, (state: any) => ({
        ...state,
        status: 'loading',
        error: null,
      }))
      .addCase(fetchUser.fulfilled, (state, { payload: token }) => ({
        ...state,
        token: token.jwtToken,
        groups: token.payload['cognito:groups'] || [],
        user: token.payload.username,
        status: 'succeeded',
      }))
      .addCase(fetchUser.rejected, (state) => ({
        ...state,
        token: null,
        status: 'failed',
      }));
      
  },
});

export const { setUser } = slice.actions;

const authenticationReducer = slice.reducer;
export default authenticationReducer;

export const selectUser = (state: any) => state.authentication.user;
export const selectAuth = (state: any) => state.authentication;