import trim from './trim';


const getSZstring = (classTMP: string) => {
  if (!classTMP) {
    return 'Unknown';
  }
  const newClass = classTMP.replace(/\s/g, '').toUpperCase();

  switch (newClass) {
    case 'EPILEPTICFOCALONSETAWARE':
      return 'Epi FAS';
    case 'EPILEPTICFOCALONSETIMPAIREDAWARENESS':
      return 'Epi FIAS';
    case 'EPILEPTICFOCALONSETFOCALTOGENTONICCLONIC':
      return 'Epi FBTCS';
    case 'EPILEPTICGENERALIZEDONSETABSENCE':
      return 'Epi Gen Abs';
    case 'EPILEPTICGENERALIZEDONSETMOTOR':
      return 'Epi Gen Motor';
    case 'EPILEPTICGENERALIZEDONSETEPILEPTICSPASM':
      return 'Epi Gen Spasm';
    case 'EPILEPTICGENERALIZEDONSETTONIC-CLONIC':
      return 'Epi Gen TC';
    case 'EPILEPTICUNKNOWNAWARE':
      return 'EPILEPTIC UNKNOWN ONSET AWARE';
    case 'EPILEPTICUNKNOWNIMPAIREDAWARENESS':
      return 'EPILEPTIC UNKNOWN ONSET IMPAIRED AWARE';
    case 'EPILEPTICUNKNOWNTONIC-CLONIC':
      return 'Epi UTCS';
    case 'EPILEPTICUNKNOWNMOTOR':
      return 'Epi UMS';
    case 'EPILEPTICUNKNOWNNONMOTOR':
      return 'Epi UNMS';
    case 'NONEPILEPTICPSYCHOGENIC-VEEGCONFIRMED':
      return 'VEEG Conf PNES';
    case 'NONEPILEPTICPSYCHOGENIC-NOTVEEGCONFIRMED':
      return 'PNES';
    case 'NONEPILEPTICSLEEP':
      return 'Sleep = Sleep d/o';
    case 'NONEPILEPTICSYNCOPE':
      return 'Sync = Syncope';
    case 'NONEPILEPTICUNKNOWN':
      return 'Non Epi Unclassified';
    case 'NONEPILEPTICBEHAVIORALEVENT':
      return 'Behavioral';
    case 'NONEPILEPTICMIGRAINE':
      return 'Migraine';
    case 'NONEPILEPTICOTHER':
      return 'Non Epi Other';
    case 'UNKNOWN':
      return 'Dx Unknown';
    default:
      return trim(classTMP, 15);
  }
};


export default getSZstring;