import React, { useEffect, useState } from 'react';
import './smart-viewer.scss';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDispatch } from 'react-redux';
import { copyItem } from '../../../store/clipboard';
import DicomViewer from '../DicomViewer/DicomViewer';
import DicomViewer2 from '../DicomViewer2/DicomViewer2';
import { useGetAllVisitImagesQuery, useGetColdFileArrayQuery } from '../../../api/builders/participantFiles.api';
import ScanCard from '../ScanCard/ScanCard';
import { Label } from '../../atoms';

export interface SmartViewerProps {
  id: string;
  item?: any;
  visitId?: string;
  className?: string;
}

export interface ISerie {
  id: string | undefined;
}

export interface IStack {
  currentImageIdIndex: number;
  currentImageIndex: number;
  imageIds: string[];
}

const initStack: IStack = {
  currentImageIdIndex: 0,
  currentImageIndex: 0,
  imageIds: [],
};

const SmartViewer = ({ id, className, item, visitId }: SmartViewerProps): JSX.Element => {
  const [currentSerie, setCurrentSerie] = useState<ISerie | null>(null);
  const [currentStack, setCurrentStack] = useState(initStack);
  const [currentVisitId, setCurrentVisitId] = useState<any>();
  const dispatch = useDispatch();

  const series = useGetAllVisitImagesQuery(currentVisitId || skipToken);

  const coldfiles = useGetColdFileArrayQuery({ id: currentSerie ? currentSerie.id : skipToken });

  useEffect(() => {
    if (series && Array.isArray(series?.data) && series?.data.length > 0 && Array.isArray(series?.data[0].ScanSeries)) {
      setCurrentSerie(series?.data[0].ScanSeries[0]);
    }
  }, [series]);

  const handleClick = (_item: any) => {
    try {
      const serie = _item?.ScanSeries[0];
      setCurrentSerie(serie);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => setCurrentVisitId(visitId), [visitId]);

  useEffect(() => {
    if (coldfiles && coldfiles?.data && coldfiles?.data?.Keys) {
      const midFrame = Math.floor(coldfiles.data.Keys.length / 2);
      const stack = {
        currentImageIdIndex: midFrame,
        currentImageIndex: midFrame,
        imageIds: coldfiles.data.Keys.map((file: any) => `wadouri:${file}`),
      };
      setCurrentStack(stack);
    }
  }, [coldfiles.data]);

  const captureMeasurement = (measurement: any) => {
    dispatch(copyItem({ meta: measurement, userId: '0', fileUrl: 'none' }));

  };

  return (
    <div key={`SmartViewer_${id}`} className={`smart-viewer ${className}`}>
      <div className="top-part">
        {currentSerie ? <DicomViewer className='smart-viewer-v' 
        onCopyMeasurement={captureMeasurement} viewerId="smart-crf-viewer" prefix={`crf-${item?.surveyId}`} series={currentSerie} />
          :
          <div className='w-100 h-100 super-centered'>
            <Label size='h6' color='ghost'>No Images Available</Label>
          </div>
        }
      </div>
      <div className="bottom-part">
        <div className="panel">
          <div className="item-list">
            {series?.data?.map((serie: any) => {
              const a = 1;
              return (<ScanCard scan={serie} onClick={handleClick} />);
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

SmartViewer.defaultProps = {
  className: '',
  item: null,
  visitId: null,
};

SmartViewer.displayName = 'SmartViewer';
const MemoizedSmartViewer = React.memo(SmartViewer);

export default MemoizedSmartViewer;
