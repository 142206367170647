import React, { useState } from 'react';
import { GrFormAdd } from 'react-icons/gr';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Alert, Modal, DatePicker, Spacer, Button } from '../../atoms';
import './session-browser.scss';
import { useGetSurveysQuery, useGetSessionStatsByIdQuery, useCreateUsersurveyMutation } from '../../../api/builders/patientSessions.api';
import SessionSurveyItem from '../SessionSurveyItem/SessionSurveyItem';

export interface SessionBrowserProps {
  className?: string,
  session: any,
  selectedSurvey: any,
  onClick: (item: any) => void,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const SessionBrowser = (props: SessionBrowserProps): JSX.Element => {
  const { session, onClick, selectedSurvey } = props;
  const { data: surveyTemplates } = useGetSurveysQuery(' ');
  const { data: sessionDeets } = useGetSessionStatsByIdQuery(session?.id ?? skipToken);
  const [addSurvey, { isLoading: isAdding, isError: isErrorAdding, error: Adderror, isSuccess: isSuccessAdding }] = useCreateUsersurveyMutation();
  const [addModal, setAddModal] = useState(false);
  const [surveyToAdd, setsurveyToAdd] = useState<any>(null);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = Adderror as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Session: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding New Session',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);


  const handlCloseModal = () => {
    setAddModal(false);
    setsurveyToAdd(null);
  };

  const handleAddSurvey = async () => {
    try {
      const payload = {
        surveyId: surveyToAdd.id,
        sessionId: session.id,
      };
      await addSurvey(payload);
      handlCloseModal();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="card">

      {Array.isArray(sessionDeets) && sessionDeets.length > 0 && Array.isArray(sessionDeets[0].usersurvey) && sessionDeets[0].usersurvey.length > 0 ? sessionDeets[0].usersurvey.map((survey: any) => (
        <SessionSurveyItem isActive={selectedSurvey ? survey.id === selectedSurvey.id : false} survey={survey} onClick={onClick} />
      ))
        :
        <div >
          <Spacer value={30} />
          <Label size='h4' center color='ghost'>Nothing To Show.</Label>
          <Label size='h4' center color='ghost'>Please Add A Survey.</Label>
          <Spacer value={30} />
        </div>
      }

      <button className='session-browser__add-btn' type='button' onClick={() => setAddModal(true)}>
        <GrFormAdd size={20} className='session-browser__icon' />
      </button>
      <Spacer value={30} />
      <Spacer value={30} />

      <Modal title='Add Survey to Session' open={addModal} onClose={handlCloseModal}>
        <div>
          <div className='session-browser__surv-list'>
            {Array.isArray(surveyTemplates) && surveyTemplates.length > 0 && surveyTemplates.map((survey: any) => (
              <div
                className={surveyToAdd?.id === survey.id ? 'session-browser__survey-active' : 'session-browser__survey'}
                key={survey.id}
                role='button'
                tabIndex={-1}
                onClick={() => setsurveyToAdd(survey)}
              >
                <Label size='h5'>{survey.surveyLabel}</Label>
                <Spacer value={10} />
                <div className='row__apart'>
                  <Label size='subtext'>
                    Number of Questions: {JSON.parse(survey.questions).length}
                  </Label>
                  <Label size='subtext'>
                    Estimated Time: {survey.timeToComplete}
                  </Label>
                </div>
                <Spacer value={5} />
                <Label size='subtext'>
                  Instructions:
                </Label>
                <Label size='subtext'>
                  {survey.instructions}
                </Label>
              </div>
            ))}
          </div>
          <Spacer value={30} />
          <div className='btn-row'>
            <Button onClick={handlCloseModal} kind='danger'>Cancel</Button>
            <Button onClick={handleAddSurvey} >Add to Session</Button>
          </div>
        </div>
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

SessionBrowser.defaultProps = {
  className: '',
};

SessionBrowser.displayName = 'SessionBrowser';
export default SessionBrowser;
