/* eslint-disable no-plusplus */
import React from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { BiTrash, BiRefresh } from 'react-icons/bi';
import { GrFormAdd } from 'react-icons/gr';
import { Label, Button, Modal, Alert } from '../../atoms';
import { metApi, useRemoveTumorMutation, useAddMETMutation } from '../../../api/builders/met.api';
import './met-table.scss';
import DeleteVerification from '../DeleteVerification/DeleteVerification';
import AddMet from '../AddMet/AddMet';
import MetImageTD from '../MetImageTD/MetImageTD';
import { AlertState, MetT, ApiError } from '../../../types/types';


export interface MetTableProps {
  itemId: string | undefined,
  data: MetT[],
  participant: string | undefined,
  acl: string,
  onChangeItem: (item: MetT, indx: number) => void,
  visitId: string | undefined,
  role: string,
  path: string | undefined,
  canUpload: boolean,
}



const MetTable = (props: MetTableProps): JSX.Element => {
  const { onChangeItem, itemId, acl, participant, data, visitId, role, path, canUpload } = props;
  const [itemToDelete, setItemToDelete] = React.useState<MetT | null>(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [addNewModal, setAddNewModal] = React.useState(false);
  const [deleteTumor, { isLoading: isDeleting, isError: isErrorDel, isSuccess: isisSuccessDel, error: delError }] = useRemoveTumorMutation();
  const { refetch: refetchTumors } =
    metApi.endpoints.getparticipantTumors.useQuerySubscription({ participantId: participant });
  const [addMET, { isLoading: isAddingMet, isError: isErrorAdding }] = useAddMETMutation();

  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'success',
  });

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  React.useEffect(() => {
    if (isErrorDel) {
      const apiError = delError as ApiError;
      setAlert({
        open: true,
        message: `Error Deleting Image: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorDel]);

  React.useEffect(() => {
    if (isisSuccessDel) {
      setAlert({
        open: true,
        message: 'Success Deleting image',
        kind: 'success',
      });
    }
  }, [isisSuccessDel]);



  const openDeleteMetModal = (item: MetT) => {
    setItemToDelete(item);
    // setOpenDelete(true);
  };

  const openAddNewMet = () => {
    const label = `MET-${data.length + 1}`;
    setAddNewModal(true);
  };

  const closeAddModal = () => {
    setAddNewModal(false);
  };


  const closeDeleteMetModal = () => {
    setOpenDelete(false);
    setItemToDelete(null);
  };

  const deleteSelectedTumor = async (item: MetT) => {
    try {
      await deleteTumor(item.id);
      setOpenDelete(false);
    } catch (e) {
      console.error(e);
    }
  };




  const renderRow = (item: MetT, index: number) => {
    const hasTumorAssessments = item.tumorassessments && item.tumorassessments.length > 0;
    const findMatch = hasTumorAssessments
      ? item.tumorassessments.find((it) => it.protocolItemId === itemId)
      : null;

    return (
      <tr className='met-manager__met-item-wrapper' key={item.id} role="button" tabIndex={-1}>
        <td className='met-manager__table-td'><Label size='h6'>{item.tumorLabel || <span>-</span>}</Label></td>
        <td className='met-manager__table-td'><Label size='h6'>{item.lobe || <span>-</span>}</Label></td>
        <td className='met-manager__table-td'><Label size='h6'>{item.laterality || <span>-</span>}</Label></td>
        <td className='met-manager__coords-td'>
          <Label center size='subtext'>RL / AP / SI</Label>
          <span>
            {item.RLCoord || <span>-</span>} / {item.APCoord || <span>-</span>} / {item.SICoord || <span>-</span>}
          </span>
        </td>
        <td className='met-manager__coords-td'>
          <Label center size='subtext'>Longest / Shortest</Label>
          <span>
            {findMatch?.diameterAxial || <span>-</span>} / {findMatch?.diameterCoronal || <span>-</span>}
          </span>
        </td>
        <MetImageTD met={item} refetchTumors={refetchTumors} path={path} canEdit={canUpload} />
        <td className='met-manager__table-td'>
          <AiFillEdit className="met-manager__table-open" size={16} onClick={() => onChangeItem(item, index)} />
          {acl === 'admin' && <BiTrash className="met-manager__table-open" size={16} onClick={() => openDeleteMetModal(item)} />}
        </td>
      </tr>
    );
  };


  const sort = (items: MetT[]) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => a.tumorLabel.localeCompare(b.tumorLabel));
      return test;
    } catch {
      console.error('failed to sort MET data');
      return items;
    }
  };

  return (
    <div className="card">
      <div className='border-heading'>
        <div className='d-flex-row'>
          <Label size='h4' weight={600}>Mets</Label>
          <button type='button' className='icon-btn' aria-label='refetch' onClick={refetchTumors}>
            <BiRefresh />
          </button>
        </div>
        {role === 'SITE COORDINATOR' && <Button kind='ghost' icon={GrFormAdd} onClick={openAddNewMet} />}
      </div>

      <table className='met-manager__table'>
        <thead className='met-manager__table-thead'>
          <tr>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>MET</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Lobe</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Laterality</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Coordinates</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Dimensions</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Images</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Actions</Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.length > 0 && sort(data).map((item: MetT, index) => (
            renderRow(item, index)
          ),
          )}
        </tbody>
      </table>

      <Modal size="sm" open={openDelete} onClose={closeDeleteMetModal} title="Delete Study">
        <DeleteVerification
          loading={isDeleting}
          item={itemToDelete}
          name='Met'
          onCancel={closeDeleteMetModal}
          onDelete={deleteSelectedTumor}
        />
      </Modal>

      <Modal
        open={addNewModal}
        onClose={closeAddModal}
        title='Add New MET'
      >
        <AddMet
          label={`MET-${Array.isArray(data) ? (data.length + 1) : 1}`}
          isAddingMet={isAddingMet}
          addMET={addMET}
          itemId={itemId}
          participant={participant}
          onClose={closeAddModal}
          visitId={visitId}
        />
      </Modal>


      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

MetTable.defaultProps = {
};

MetTable.displayName = 'MetTable';
export default MetTable;
