import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Button, DatePicker, Spinner, Modal, Spacer, Dropdown } from '../../atoms';
import './add-patient-modal.scss';
import TextInput from '../TextInput/TextInput';
import { usePatientSearchQuery } from '../../../api/builders/registries.api';

export interface AddPatientModalProps {
  className?: string,
  open: boolean,
  onClose: () => void,
  handleAdd: (pat: any) => void,
  registryId: string,
}

interface CsvRow {
  [key: string]: string;
}


const AddPatientModal = (props: AddPatientModalProps): JSX.Element => {
  const { open, onClose, handleAdd, registryId } = props;
  const [payload, setPayload] = React.useState<any>(null);
  const [searchVal, setSearchVal] = React.useState('');
  const [screen, setScreen] = React.useState('search');
  const { data: results, isLoading: isLoadingData, isFetching: isFetchingData } = usePatientSearchQuery(payload ?? skipToken);
  const [file, setFile] = React.useState();
  const [array, setArray] = React.useState<CsvRow[]>([]);
  const [upload, setUpload] = React.useState<CsvRow[]>([]);
  const [uploadCSV, setUploadCSV] = React.useState<boolean>(false);


  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (csvString: string): CsvRow[] => {
    try {
      const csvHeader = csvString.slice(0, csvString.indexOf('\n')).split(',');
      const csvRows = csvString.slice(csvString.indexOf('\n') + 1).split('\n');

      const arrayTmp = csvRows.map((row) => {
        const sanitizedRow = row.replace('\r', '');
        const values = sanitizedRow.split(',');

        const obj = csvHeader.reduce((object: CsvRow, header, index) => {
          const sanitizedHeader = header.replace('\r', '');
          // eslint-disable-next-line no-param-reassign
          object[sanitizedHeader] = values[index];
          return object;
        }, {} as CsvRow);
        return obj;
      });
      return arrayTmp;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const handleOnUpload = (readFile: File | null) => {
    if (!readFile) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      try {
        const csvOutput = event.target?.result as string;
        const tmp = csvFileToArray(csvOutput);
        if (tmp && tmp.length > 100) {
          tmp.length = 100;
        }
        setArray(tmp);

        const tmp2 = csvFileToArray(csvOutput);
        const tmp3 = tmp2.slice(100);
        setUpload(tmp3);
      } catch (e) {
        console.error(e);
      }
    };
    fileReader.readAsText(readFile);
    setUploadCSV(true);
    setScreen('batch');
  };


  const handleSearchValChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(event.target.value);
  };

  const handleSearch = () => {
    const pl = {
      query: `mrn=${searchVal}`,
      pageSize: 10,
      orderBy: 'recent',
      pageCurrent: 1,
      id: registryId,
    };
    setPayload(pl);
    setScreen('results');
  };


  const handleClose = () => {
    onClose();
    setPayload(null);
    setSearchVal('');
    setScreen('search');
  };

  const handleAddpat = (pat: any) => {
    handleAdd(pat);
    handleClose();
  };


  const validateVals = () => {
    const total = array.length;
    let valid = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < array.length; i++) {
      if (
        array[i].mrn &&
        array[i].mrn.trim() !== '' &&
        array[i].firstname &&
        array[i].firstname.trim() !== '' &&
        array[i].lastname &&
        array[i].lastname.trim() !== '' &&
        array[i].dob &&
        array[i].dob.trim() !== ''
      ) {
        valid += 1;
      }
    }
    if (total === valid) {
      return false;
    }
    return true;
  };


  const handleValChange = (e: React.ChangeEvent<HTMLInputElement>, item: CsvRow, i: number) => {
    const newVal = e.target.value;
    const oldInputState = array.find((row) => row.mrn === item.mrn);
    const newDoc = [...array];
    newDoc[i][e.target.name] = newVal;
    setArray(newDoc);
  };

  const handleDropdownChange = (e: any, item: CsvRow, i: number) => {
    const newVal = e.target.value;
    const oldInputState = array.find((row) => row.mrn === item.mrn);
    const newDoc = [...array];
    newDoc[i][e.target.name] = newVal;
    setArray(newDoc);
  };

  const handleValDateChange = (e: Date, item: CsvRow, i: number) => {
    console.log('e', e);
    const newVals: CsvRow[] = [
      {
        ...item,
        dob: moment(e).format('MM-DD-YYYY'),
      },
    ];
    const arr = [...array];
    const newArr = arr.map((p, ii) => newVals.find(() => i === ii) || p);
    setArray(newArr);
  };


  return (
    <Modal title='Add Patient' size={screen === 'batch' ? 'lg' : 'sm'} open={open} onClose={handleClose}>
      <>
        {screen === 'search' &&
          <div >
            <Label size='h4' weight={200}>Search for a Patient</Label>
            <TextInput
              value={searchVal}
              onChange={handleSearchValChange}
              placeholder='Search for MRN'
            />
            <Spacer value={10} />
            <div className='btn-row'>
              <Button onClick={handleSearch}>Search</Button>
            </div>
            <Spacer value={30} />
            <Label size='h4' weight={200}>Upload CSV</Label>
            <input id='csvFileInput' className='csv-input' type='file' accept='.csv' onChange={handleOnChange} />
            <Spacer value={10} />
            <div className='_center _make-row'>
              {file && <Button outline disabled={!file} onClick={() => handleOnUpload(file)}>Upload CSV</Button>}
            </div>
          </div>
        }
        {screen === 'results' &&
          <div >
            {isLoadingData || isFetchingData ?
              <div className='add-patient__spinner'>
                <Spinner />
              </div>
              :
              <div>
                {(results && Array.isArray(results.items) && results.items.length > 0) ?
                  <div>
                    <Label size='h4' weight={200}>Patient(s) Found: </Label>
                    <Spacer value={10} />
                    {results.items.map((pat: any) => (
                      <div className='add-patient__list-item'>
                        <div className='w-100 row__apart'>
                          <Label weight={200} size='h5'>{pat.mrn}</Label>
                          <Label weight={200} size='h5'>{pat.PatientName}</Label>
                        </div>
                        <Spacer value={10} />
                        <Button onClick={() => handleAddpat(pat)}>+ Add Patient</Button>
                      </div>
                    ))}
                  </div>
                  :
                  <div className='add-patient__spinner'>
                    <Label size='h4' center weight={200}>Nothing Found </Label>
                    <Spacer value={10} />
                    <Button onClick={() => setScreen('search')}>Back to Search</Button>
                  </div>
                }
              </div>
            }
          </div>
        }

        {screen === 'batch' &&
          <div>
            <div>
              <Grid container spacing={2} >
                <Grid sm={2}>
                  <Label size="h5" center uppercase color='ghost'>Mrn</Label>
                </Grid>
                <Grid sm={2} >
                  <Label size="h5" center uppercase color='ghost'>first name</Label>
                </Grid>
                <Grid sm={2} >
                  <Label size="h5" center uppercase color='ghost'>last name</Label>
                </Grid>
                <Grid sm={3} >
                  <Label size="h5" center uppercase color='ghost'>DOB</Label>
                </Grid>
                <Grid sm={3} >
                  <Label size="h5" center uppercase color='ghost'>Gender</Label>
                </Grid>
              </Grid>
              {
                array.map((el, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid container spacing={2} key={i}>
                    <Grid sm={2}>
                      <TextInput invalid={!array[i].mrn || array[i].mrn.length < 1} name='mrn' value={el.mrn} onChange={(e) => handleValChange(e, el, i)} />
                    </Grid>
                    <Grid sm={2} >
                      <TextInput invalid={!array[i].firstname || array[i].firstname.length < 1} name="firstname" value={el.firstname} onChange={(e) => handleValChange(e, el, i)} />
                    </Grid>
                    <Grid sm={2} >
                      <TextInput invalid={!array[i].lastname || array[i].lastname.length < 1} name="lastname" value={el.lastname} onChange={(e) => handleValChange(e, el, i)} />
                    </Grid>
                    <Grid sm={3} >
                      <DatePicker label='' invalid={!array[i].dob || array[i].dob.length < 1} id="dob" value={el.dob} onChange={(e) => handleValDateChange(e, el, i)} />
                    </Grid>
                    <Grid sm={3} >
                      <Dropdown invalid={!array[i].gender || array[i].gender !== ('Male' || 'Female')} name="gender" id={`item${i}`} value={el.gender} onChange={(e) => handleDropdownChange(e, el, i)} >
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                      </Dropdown>
                    </Grid>
                  </Grid>))
              }
            </div>
            <Spacer value={20} />
            <Label size='text' center> {`${array.length} Patient(s) to add. Would you like to continue?`} </Label>
            <Spacer value={15} />
            <div className='_make-row _center'>
              <Button outline disabled={validateVals()}>
                Yes, Add to Clinic
              </Button>
              <Button outline kind='danger'>
                No, Go Back
              </Button>
            </div>
          </div>

        }
      </>
    </Modal>
  );
};

AddPatientModal.defaultProps = {
  className: '',
};

AddPatientModal.displayName = 'AddPatientModal';
export default AddPatientModal;
