import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsCalendar2Week, BsImages, BsFillPersonFill } from 'react-icons/bs';
import { RiSurveyLine } from 'react-icons/ri';
import { AiFillDashboard } from 'react-icons/ai';
import './patient-router.scss';
import { Label } from '../../atoms';
import PatientInfoPopper from '../PatientInfoPopper/PatientInfoPopper';

export interface PatientRouterProps {
  className?: string,
  activePage: string,
  patient: any,
  registry: any,
}

const PatientRouter = (props: PatientRouterProps): JSX.Element => {
  const { activePage, patient, registry } = props;
  const navigate = useNavigate();

  const handleChangeTab = (newTab: string) => {
    navigate(`/private/registries/registry/patient/${newTab}`, { state: { patient, registry } });
  };

  return (
    <div className="patient-router">
      <div className="patient-router__nav">
        <button type='button' onClick={() => handleChangeTab('overview')}
          className={`patient-router__tab ${activePage === 'overview' ? 'active' : ''}`}>
          <BsFillPersonFill className={`patient-router__icon ${activePage === 'overview' ? 'active' : ''}`} />
          <Label size='h5' uppercase color={activePage === 'overview' ? 'primary' : 'default'}>Patient Overview</Label>
        </button>
        {/* <button type='button' onClick={() => handleChangeTab('carepathway')}
          className={`patient-router__tab ${activePage === 'carepathway' ? 'active' : ''}`}>
          <BsCalendar2Week className={`patient-router__icon ${activePage === 'carepathway' ? 'active' : ''}`} />
          <Label size='h5' uppercase color={activePage === 'carepathway' ? 'primary' : 'default'}>Care Pathway</Label>
        </button> */}
        <button type='button' onClick={() => handleChangeTab('assessments')} className={`patient-router__tab ${activePage === 'assessments' ? 'active' : ''}`} >
          <RiSurveyLine className={`patient-router__icon ${activePage === 'assessments' ? 'active' : ''}`} />
          <Label size='h5' uppercase color={activePage === 'assessments' ? 'primary' : 'default'}>Assessments</Label>
        </button>
        <button type='button' onClick={() => handleChangeTab('scans')} className={`patient-router__tab ${activePage === 'scans' ? 'active' : ''}`} >
          <BsImages className={`patient-router__icon ${activePage === 'scans' ? 'active' : ''}`} />
          <Label size='h5' uppercase color={activePage === 'scans' ? 'primary' : 'default'}>Scans</Label>
        </button>
        <button type='button' onClick={() => handleChangeTab('dashboard')} className={`patient-router__tab ${activePage === 'dashboard' ? 'active' : ''}`} >
          <AiFillDashboard className={`patient-router__icon ${activePage === 'dashboard' ? 'active' : ''}`} />
          <Label size='h5' uppercase color={activePage === 'dashboard' ? 'primary' : 'default'}>Dashboard</Label>
        </button>
      </div>

      <PatientInfoPopper patient={patient} />
    </div>
  );
};

PatientRouter.defaultProps = {
  className: '',
};

PatientRouter.displayName = 'PatientRouter';
export default PatientRouter;

