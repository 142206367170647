/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import { Label, Spacer } from '../../../components/atoms';
import { FAQbody } from '../../../utils/FaqBody';
import formatFAQ from '../../../utils/fns/formatFAQ';



const FAQ = () => {
  const printableAreaRef = useRef<HTMLDivElement>(null);

  // const formatText = (text: string): JSX.Element[] => text.split('®').map((part, i, array) =>
  //   i === array.length - 1 ? <React.Fragment key={i}>{part}</React.Fragment> : (
  //     <React.Fragment key={i}>
  //       {part}<sup>®</sup>
  //     </React.Fragment>
  //   ),
  // );


  const formatText = (text: string): JSX.Element[] => {
    let inSubscript = false;
    let inSuperscript = false;

    const parts = text.split(/(<sub>|<\/sub>|<sup>|<\/sup>)/);

    return parts.map((part, i) => {
      if (!part) {
        return null; // Skip null parts
      } else if (part === '<sub>') {
        inSubscript = true; // Enable subscript
        return null;
      } else if (part === '</sub>') {
        inSubscript = false; // Disable subscript
        return null;
      } else if (part === '<sup>') {
        inSuperscript = true; // Enable superscript
        return null;
      } else if (part === '</sup>') {
        inSuperscript = false; // Disable superscript
        return null;
      } else {
        return (
          <React.Fragment key={i}>
            {inSubscript ? <sub>{part}</sub> : inSuperscript ? <sup>{part}</sup> : part}
          </React.Fragment>
        );
      }
    }).filter(Boolean) as JSX.Element[];
  };



  return (
    <div className="faq" ref={printableAreaRef}>
      <Label size="h3" bold center color="primary" > Frequently Asked Questions </Label>
      {/* <Button onClick={handlePrintClick}>Print</Button> */}
      <Spacer value={30} />
      {FAQbody.map((el, index) => (
        <div key={index}>
          <Label size="h4" bold>
            {formatFAQ(el.title)}
          </Label>
          <Spacer value={20} />
          {el.body.map((bodyel, bodyindex) => (
            <div key={bodyindex}>
              <Label size="h6">
                {formatFAQ(bodyel)}
              </Label>
              <Spacer value={20} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FAQ;