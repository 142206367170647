import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import { Button, Label, Dropdown, Checkbox, Spacer, Alert } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import { VisitT } from '../../../types/types';
import './edit-medication-form.scss';
import { epilepsyMeds, medFrequencyOps } from '../../../utils/parameters';
import { useUpdateMedicationRecordMutation } from '../../../api/builders/epilepsy.api';


export interface EditMedicationFormProps {
  med: any,
  className?: string,
  visit: VisitT | null,
  handleCloseMedForm: () => void,
  patient: any,
}


const initialMedForm = {
  substance: '',
  amount: 0,
  frequency: 'Once Daily',
  notes: '',
  date_started: '',
  date_stopped: '',
  sideEffects: '',
  BNO: false,
  LA: false,
  date: new Date(),
  active: true,
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}



const EditMedicationForm = (props: EditMedicationFormProps): JSX.Element => {
  const { med, visit, patient, handleCloseMedForm } = props;
  const [medValues, setMedValues] = React.useState(initialMedForm);
  const [calculatdDailyDose, setCalculatedDailyDose] = React.useState(0);
  const [TDADescription, setTDADescription] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const [editMed, { isLoading, isSuccess, isError, error }] = useUpdateMedicationRecordMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const reset = () => {
    setMedValues(initialMedForm);
    setCalculatedDailyDose(0);
    setTDADescription('');
    setDisabled(false);
  };

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Substance: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);


  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Editing Substance',
        kind: 'success',
      });
      reset();
      handleCloseMedForm();
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  useEffect(() => {
    if (med?.active === false) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    if (med?.history && med?.history?.length > 0) {
      const tmp = JSON.parse(med.history);
      const index = tmp.findIndex((el: any) => el.date === visit?.date);
      if (index !== -1) {
        setMedValues({
          ...medValues,
          substance: tmp[index].substance ? tmp[index].substance : '',
          amount: tmp[index].amount ? tmp[index].amount : '',
          frequency: tmp[index].frequency ? tmp[index].frequency : '',
          notes: tmp[index].notes ? tmp[index].notes : '',
          date_started: tmp[index].date_started ? tmp[index].date_started : '',
          date_stopped: tmp[index].date_stopped ? tmp[index].date_stopped : '',
          sideEffects: tmp[index].sideEffects ? tmp[index].sideEffects : '',
          BNO: tmp[index].BNO ? tmp[index].BNO : false,
          // compliance: tmp[index].compliance ? tmp[index].compliance : '',
          active: tmp[index]?.active ? tmp[index].active : true,
          LA: tmp[index]?.LA ? tmp[index].LA : false,
        });

        if (tmp[index].active === false) {
          setDisabled(true);
        }
        setTDADescription(tmp[index].TDADescription ? tmp[index].TDADescription : '');

      } else if (tmp) {
        const idx = tmp.length > 1 ? tmp.length - 1 : 0;
        setMedValues({
          ...medValues,
          substance: tmp[idx].substance ? tmp[idx].substance : '',
          amount: tmp[idx].amount ? tmp[idx].amount : '',
          frequency: tmp[idx].frequency ? tmp[idx].frequency : '',
          notes: tmp[idx].notes ? tmp[idx].notes : '',
          date_started: tmp[idx].date_started ? tmp[idx].date_started : '',
          date_stopped: tmp[idx].date_stopped ? tmp[idx].date_stopped : '',
          // compliance: tmp[idx].compliance ? tmp[idx].compliance : '',
          sideEffects: tmp[idx].sideEffects ? tmp[idx].sideEffects : '',
          date: tmp[idx].date ? tmp[idx].date : '',
          BNO: tmp[idx].BNO ? tmp[idx].BNO : false,
          active: med?.active ? med.active : true,
          LA: tmp[idx]?.LA ? tmp[idx].LA : false,
        });

        setTDADescription(tmp[idx].TDADescription ? tmp[idx].TDADescription : '');
      } else {
        setMedValues({
          ...medValues,
          substance: med?.substance ? med?.substance : '',
          date_started: med?.date_started ? med?.date_started : '',
          date_stopped: med?.date_stopped ? med?.date_stopped : '',
          // compliance: med.compliance ? med.compliance : '',
          amount: med?.amount ? med?.amount : '',
          frequency: med?.frequency ? med?.frequency : '',
          sideEffects: med?.sideEffects ? med?.sideEffects : '',
          BNO: med?.BNO ? med.BNO : false,
          notes: med?.notes ? med.notes : '',
          active: med?.active ? med.active : true,
          LA: med?.LA ? med.LA : true,
          date: new Date(),
        });
        setTDADescription(med?.TDADescription ? med.TDADescription : '');
      }
    } else {
      setMedValues({
        date: new Date(),
        substance: med?.substance ? med?.substance : '',
        date_started: med?.date_started ? med?.date_started : '',
        date_stopped: med?.date_stopped ? med?.date_stopped : '',
        amount: med?.amount ? med?.amount : '',
        frequency: med?.frequency ? med?.frequency : '',
        sideEffects: med?.sideEffects ? med?.sideEffects : '',
        BNO: med?.BNO ? med.BNO : false,
        // compliance: med?.compliance ? med?.compliance : '',
        LA: med?.LA ? med.LA : false,
        notes: med?.notes ? med.notes : '',
        active: med?.active ? med.active : true,
      });
      setTDADescription(med?.TDADescription ? med.TDADescription : '');
    }
  }, [med]);

  useEffect(() => {
    if (medValues.frequency === 'Daily') {
      setCalculatedDailyDose(medValues.amount);
      setTDADescription('');
    } else if (medValues.frequency === 'BID') {
      setCalculatedDailyDose(medValues.amount * 2);
      setTDADescription('');
    } else if (medValues.frequency === 'TID') {
      setCalculatedDailyDose(medValues.amount * 3);
      setTDADescription('');
    } else if (medValues.frequency === 'QID') {
      setCalculatedDailyDose(medValues.amount * 4);
      setTDADescription('');
    } else if (medValues.frequency === '5x') {
      setCalculatedDailyDose(medValues.amount * 5);
      setTDADescription('');
    } else if (medValues.frequency === '6x') {
      setCalculatedDailyDose(medValues.amount * 6);
      setTDADescription('');
    } else if (medValues.frequency === 'PRN') {
      setCalculatedDailyDose(0);
      setTDADescription('');
    } else if (medValues.frequency === 'Total Daily Amount') {
      setCalculatedDailyDose(medValues.amount);
    }
  }, [medValues]);


  const handleInputChangeMeds = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setMedValues({
      ...medValues,
      [name]: value,
    });
  };

  const handleAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setMedValues({
      ...medValues,
      [name]: value,
    });
  };


  const handleBNOchange = () => {
    setMedValues({
      ...medValues,
      BNO: !medValues.BNO,
    });
  };


  const handlesetTDADescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTDADescription(e.target.value);
  };

  const handleLAchange = () => {
    setMedValues({
      ...medValues,
      LA: !medValues.LA,
    });
  };

  const handleActivechange = () => {
    setMedValues({
      ...medValues,
      active: !medValues.active,
    });
    setDisabled(!disabled);
  };


  const submit = async () => {

    const newMedPl = {
      substance: medValues.substance,
      date_started: medValues.date_started,
      date_stopped: !disabled ? new Date() : '',
      amount: medValues.amount,
      frequency: medValues.frequency,
      sideEffects: medValues.sideEffects,
      date: visit?.date,
      BNO: medValues.BNO,
      // compliance: medValues.compliance,
      notes: medValues.notes,
      active: !disabled,
      LA: medValues.LA,
      TDADescription,
    };

    const tmp = med?.history ? JSON.parse(med.history) : [];
    const index = tmp.findIndex((el: any) => el.date === visit?.date);
    const editdata = [...tmp];
    if (index !== -1) {
      editdata[index] = newMedPl;
    } else {
      editdata.push(newMedPl);
    }

    const payload = {
      medicationId: med.id,
      history: JSON.stringify(editdata),
      patientId: patient.id,
      substance: medValues.substance,
      // compliance: medValues.compliance,
      date_started: medValues.date_started,
      date_stopped: !disabled ? new Date() : '',
      amount: medValues.amount,
      frequency: medValues.frequency,
      sideEffects: medValues.sideEffects,
      TDADescription,
      BNO: medValues.BNO,
      LA: medValues.LA,
      active: !disabled,
      notes: medValues.notes,
      createdon: med.createdon,
      medicationRecordId: med.id,
    };
    editMed(payload);
  };



  return (
    <div className='card'>
      {visit && (
        <div>
          <Label size="h6" uppercase>
            Visit Date:
            {moment(visit.date).format('L')}
          </Label>
          <Spacer value={15} />
        </div>
      )
      }

      <Label size="h6" uppercase>
        {med?.substance ? med.substance : ''}:
      </Label>
      <span className='chechbox-wrapper'>
        <Checkbox checked={!disabled} onChange={handleActivechange} label="Long Acting" />
        <Label size="subtext" >Active</Label>
      </span>
      <Spacer value={15} />

      {disabled ?
        <div>
          <Label size="h6"> Last Date: {moment(medValues.date).format('L')} </Label>
          <Spacer value={5} />
          <Label size="h6" > Dose: {medValues.amount} mg {medValues.frequency} </Label>
          <Spacer value={5} />
          <Label size="h6"> Side Effects: {medValues.sideEffects} </Label>
          <Spacer value={5} />
          <Label size="h6"> BNO: {medValues.BNO.toString()} </Label>
          <Spacer value={5} />
          <Label size="h6"> Notes: {medValues.notes} </Label>
        </div >
        :
        <>
          <div className='row'>
            <span className='col-30'>
              <Label size="subtext" >Amount in mg</Label>
              <TextInput
                name="amount"
                id="amount"
                type="number"
                value={medValues.amount}
                placeholder="ex. '20mg'"
                onChange={handleInputChangeMeds}
              />
            </span>


            <span className='col-30'>
              <Label size="subtext">Frequency</Label>
              <Dropdown
                name="frequency"
                label=""
                value={medValues.frequency}
                onChange={handleInputChangeMeds}
              >
                {medFrequencyOps.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Dropdown>
            </span>


            <span className='col-30'>
              <Label size="subtext" >Side Effects</Label>
              <Dropdown
                name="sideEffects"
                label=""
                value={medValues.sideEffects}
                onChange={handleInputChangeMeds}
              >
                {['None', 'Mild', 'Significant'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Dropdown>
            </span>


          </div>



          {medValues.frequency === 'Total Daily Amount' &&

            <div>
              <Label size="subtext" >Daily Regimen:</Label>
              <TextInput
                name="TDADescription"
                id="TDADescription"
                value={TDADescription}
                placeholder="Please Describe Daily Regimen"
                onChange={handlesetTDADescriptionChange}
              />
            </div>}

          <div className="row">
            <span className='chechbox-wrapper'>

              <Checkbox checked={medValues.BNO} onChange={handleBNOchange} label="Brand Name Only" />
              <Label size="subtext" >BNO</Label>
            </span>


            <span className='chechbox-wrapper'>
              <Checkbox checked={medValues.LA} onChange={handleLAchange} label="Long Acting" />
              <Label size="subtext" >LA</Label>
            </span>
          </div>


          <Label size="subtext">Notes:</Label>
          <TextAreaInput
            name="notes"
            id="notes"
            value={medValues.notes}
            onChange={handleAreaChange}
          />


          <Label size="subtext">
            {`Calculated Daily Dose: ${calculatdDailyDose}`}
          </Label>

        </>}
      <Spacer value={15} />
      <div className="btn-row">
        <Button loading={isLoading} disabled={isLoading} onClick={submit}>Submit</Button>
      </div>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div >
  );
};

EditMedicationForm.defaultProps = {
  className: '',
};

EditMedicationForm.displayName = 'EditMedicationForm';
export default EditMedicationForm;
