/* eslint-disable no-underscore-dangle */
import React from 'react';
import Popover from '@mui/material/Popover';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { HiOutlineInformationCircle, HiInformationCircle } from 'react-icons/hi';
import './patient-info-popper.scss';
import { useGetPatientEditableQuery, useUpdatePatientInfoMutation } from '../../../api/builders/patient.api';
import { Modal, Button, Spacer, Alert } from '../../atoms';
import TextInput from '../TextInput/TextInput';

export interface PatientInfoPopperProps {
  className?: string,
  patient: any,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const PatientInfoPopper = (props: PatientInfoPopperProps): JSX.Element => {
  const { patient } = props;
  const { data } = useGetPatientEditableQuery(patient?.id ?? skipToken);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [updateModal, setUpdateModal] = React.useState(false);
  const [editedPatient, setEditedPatient] = React.useState({ ...patient });
  const [update, { isLoading: isEditing, isError: isErrorEditing, isSuccess: isSuccessEditing, error: editError }] = useUpdatePatientInfoMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isErrorEditing) {
      const apiError = editError as ApiError;
      setAlert({
        open: true,
        message: `Error Updating Patient Info: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorEditing]);

  React.useEffect(() => {
    if (isSuccessEditing) {
      setAlert({
        open: true,
        message: 'Success Updating Patient Info!',
        kind: 'success',
      });
    }
  }, [isSuccessEditing]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSubmitEdits = async () => {
    try {
      const payload = {
        aupatientIdditId: patient.id,
        body: {
          email: editedPatient.email,
          phoneNumber: editedPatient.phoneNumber ?? '',
        },
      };
      await update(payload);
      setUpdateModal(false);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <div>
      <button type='button' onClick={handleClick} className='patient-info-popper__btn'>
        {open ?
          <HiInformationCircle className='patient-info-popper__icon-active' size={20} />
          :
          <HiOutlineInformationCircle size={20} />
        }
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {Array.isArray(data) && <div className='patient-info-popper__body'>
          <li className='poppover-li'>
            Name: <span className='patient-info'> {data[0].firstname} {data[0].lastname}{' '}</span>
          </li>
          <li className='poppover-li'>
            Gender: <span className='patient-info'>{data[0].PatientSex}</span>
          </li>
          <li className='poppover-li'>
            MRN: <span className='patient-info'>{data[0].mrn}</span>
          </li>
          <li className='poppover-li'>
            DOB:{' '}
            <span className='patient-info'>
              {(data[0]._PatientBirthDate && typeof data[0].PatientBirthDate === 'string') ? moment(data[0].PatientBirthDate).format('L') : 'N/A'}
            </span>
          </li>
          <li className='poppover-li'>
            Email: <span className='patient-info'>{data[0].email !== 'null' ? data[0].email : 'N/A'}</span>
          </li>
          <li className='poppover-li'>
            Phone Number:{' '}
            <span className='patient-info'>{data[0].phoneNumber !== 'null' ? data[0].phoneNumber : 'N/A'}</span>
          </li>
          <Spacer value={20} />
          <div className='btn-row'>
            <Button onClick={() => setUpdateModal(true)}>Update</Button>
          </div>
        </div>}
      </Popover>

      <Modal title='Update Patient Information' open={updateModal} onClose={() => setUpdateModal(false)} >
        <div>
          <TextInput
            label='Email'
            value={editedPatient.email}
            onChange={(e) => setEditedPatient({ ...editedPatient, email: e.target.value })}
          />
          <TextInput
            label='Phone Number'
            value={editedPatient.phoneNumber}
            onChange={(e) => setEditedPatient({ ...editedPatient, phoneNumber: e.target.value })}
          />
          <Spacer value={15} />
          <div className='btn-row'>
            <Button onClick={() => setUpdateModal(false)}>Cancel</Button>
            <Button loading={isEditing} onClick={handleSubmitEdits}>Save Chagnes</Button>
          </div>
        </div>
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

PatientInfoPopper.defaultProps = {
  className: '',
};

PatientInfoPopper.displayName = 'PatientInfoPopper';
export default PatientInfoPopper;
