import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import './protocol-survey.scss';
import computeScore from '../../../utils/scoring/FormulaParser';
import { useGetUsersurveyStatsByIdQuery, useEditSurveyMutation, useEditT1Mutation } from '../../../api/builders/surveys.api';
import DocumentsDrive from '../DocumentsDrive/DocumentsDrive';
import Survey from '../Survey/Survey';
import ProtocolPrintForm from '../ProtocolPrintForm/ProtocolPrintForm';
import { Spacer, Button, Modal, Alert } from '../../atoms';
import SurveyResponseTable from '../SurveyResponseTable/SurveyResponseTable';
import { useEditProtocolMutation } from '../../../api/builders/participantProtocol.api';

export interface ProtocolSurveyProps {
  protocolItem: any,
  readOnly?: boolean,
  participant: string | undefined,
  info: any,
  path: string | undefined,
  acl: string,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const ProtocolSurvey = (props: ProtocolSurveyProps): JSX.Element => {
  const { protocolItem, readOnly, participant, info, path, acl } = props;
  const { data } = useGetUsersurveyStatsByIdQuery(protocolItem?.id ?? skipToken);
  const [editSurvey] = useEditT1Mutation();
  const [editing, setEditing] = useState(false);
  const [questionsToTake, setQuestionsToTake] = useState([]);
  const [changeStatus, { isLoading: isEdititng, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useEditProtocolMutation();
  const [printModal, setPrintModal] = useState(false);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Saving Survey: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Saving Survey',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  useEffect(() => {
    try {
      if (Array.isArray(data) && data[0].questions) {
        const surveyQuestions = JSON.parse(data[0].questions);
        const sorted = surveyQuestions.sort((a: any, b: any) => a.order - b.order);
        setQuestionsToTake(sorted);
        if (data[0].status === 'pending' || data[0].status === 'not started' || data[0].status === 'started') {
          setEditing(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [data, protocolItem]);

  const handleChangeStatus = () => {
    try {
      const pL = {
        id: participant ?? 'n/a',
        body: {
          itemId: protocolItem.id,
          itemStatus: 'pending review',
        },
      };
      changeStatus(pL);
    } catch {
      console.error('er');
    }
  };

  const handleSubmitSurvey = () => {
    try {
      const calcScore = computeScore({
        ...data[0],
        questions: JSON.stringify(questionsToTake),
      })[0];
      const payload = {
        id: data[0].id,
        body: {
          status: 'completed',
          questions: JSON.stringify(questionsToTake),
          score: calcScore !== 'undefined' ? JSON.stringify(calcScore) : '',
        },
      };
      editSurvey(payload);
      handleChangeStatus();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSaveProgress = () => {
    try {
      const calcScore = computeScore({
        ...data[0],
        questions: JSON.stringify(questionsToTake),
      })[0];
      const payload = {
        id: data[0].id,
        body: {
          status: 'started',
          questions: JSON.stringify(questionsToTake),
          score: calcScore !== 'undefined' ? JSON.stringify(calcScore) : '',
        },
      };
      editSurvey(payload);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="card">
      {readOnly ?
        <div>
          <SurveyResponseTable
            questions={questionsToTake}
          />
          {acl === 'admin' && <DocumentsDrive protocolId={protocolItem.id} />} </div>
        :
        <div>
          {Array.isArray(data) &&
            <div>
              {editing ?
                <Survey
                  userSurvey={questionsToTake}
                  setUserSurvey={setQuestionsToTake}
                  onSubmit={() => setPrintModal(true)}
                  saveProgress={handleSaveProgress}
                  viewAll
                  loading={isEdititng}
                />
                :
                <div>
                  <SurveyResponseTable
                    questions={questionsToTake}
                  />
                  <Spacer value={20} />
                  {protocolItem.status !== 'accepted' && <Button onClick={() => setEditing(true)}>Edit</Button>}
                </div>
              }
            </div>
          }
        </div>
      }
      <Modal title='Print' size='lg' open={printModal} onClose={() => setPrintModal(false)}>
        <ProtocolPrintForm
          protocolItem={protocolItem}
          info={info}
          path={path}
          submitSurvey={handleSubmitSurvey}
        >
          <SurveyResponseTable
            questions={questionsToTake}
          />
        </ProtocolPrintForm>
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

ProtocolSurvey.defaultProps = {
  readOnly: false,
};

ProtocolSurvey.displayName = 'ProtocolSurvey';
export default ProtocolSurvey;
