/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { Button, Label, Dropdown, Checkbox, Spacer } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import './new-med-form.scss';
import { useCreateMedicationRecordMutation } from '../../../api/builders/epilepsy.api';
import { epilepsyMeds, medFrequencyOps } from '../../../utils/parameters';

export interface NewMedFormProps {
  className?: string,
  meds: any
  patient: any,
  addMed: (pl: any) => void,
  isLoading: boolean,
}



const initialMedForm = {
  substance: '',
  amount: 0,
  frequency: 'Once Daily',
  notes: '',
  date_started: '',
  date_stopped: '',
  sideEffects: '',
  BNO: false,
  LA: false,
};


const NewMedForm = (props: NewMedFormProps): JSX.Element => {
  const { meds, patient, addMed, isLoading } = props;
  const [medValues, setMedValues] = useState(initialMedForm);
  const [calculatdDailyDose, setCalculatedDailyDose] = useState(0);
  const [TDADescription, setTDADescription] = useState('');
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
  });


  useEffect(() => {
    if (medValues.frequency === 'Daily') {
      setCalculatedDailyDose(medValues.amount);
      setTDADescription('');
    } else if (medValues.frequency === 'BID') {
      setCalculatedDailyDose(medValues.amount * 2);
      setTDADescription('');
    } else if (medValues.frequency === 'TID') {
      setCalculatedDailyDose(medValues.amount * 3);
      setTDADescription('');
    } else if (medValues.frequency === 'QID') {
      setCalculatedDailyDose(medValues.amount * 4);
      setTDADescription('');
    } else if (medValues.frequency === '5x') {
      setCalculatedDailyDose(medValues.amount * 5);
      setTDADescription('');
    } else if (medValues.frequency === '6x') {
      setCalculatedDailyDose(medValues.amount * 6);
      setTDADescription('');
    } else if (medValues.frequency === 'PRN') {
      setCalculatedDailyDose(0);
      setTDADescription('');
    } else if (medValues.frequency === 'Total Daily Amount') {
      setCalculatedDailyDose(medValues.amount);
    }
  }, [medValues]);

  const handleInputChangeMeds = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setMedValues({
      ...medValues,
      [name]: value,
    });
  };

  const handleAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setMedValues({
      ...medValues,
      [name]: value,
    });
  };


  const handleBNOchange = () => {
    setMedValues({
      ...medValues,
      BNO: !medValues.BNO,
    });
  };

  const handleSubstanceSelect = (option: string) => {
    const tmp = { substance: option };
    setMedValues({ ...medValues, ...tmp });
  };

  const handlesetTDADescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTDADescription(e.target.value);
  };

  const handleLAchange = () => {
    setMedValues({
      ...medValues,
      LA: !medValues.LA,
    });
  };

  const closeModal = () => {
    setMedValues(initialMedForm);
    setCalculatedDailyDose(0);
    setTDADescription('');
  };

  const isMedicationUnique = (substance: string, existingMeds: any) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < existingMeds.length; i++) {
      if (existingMeds[i].substance === substance) {
        return false; // Substance already exists, not unique
      }
    }

    return true; // Substance is unique
  };



  const submit = async () => {
    try {
      if (Array.isArray(meds) && meds.length > 0) {
        if (isMedicationUnique(medValues.substance, meds)) {
          const tmp = {
            history: '',
            patientId: patient.id,
            substance: medValues.substance,
            date_started: medValues.date_started,
            date_stopped: medValues.date_stopped,
            amount: medValues.amount,
            frequency: medValues.frequency,
            sideEffects: medValues.sideEffects,
            BNO: medValues.BNO,
            LA: medValues.LA,
            notes: medValues.notes,
            TDADescription,
            active: true,
          };
          await addMed(tmp);
          closeModal();
        } else {
          setAlert({
            open: true,
            message: `Medication with substance "${medValues.substance}" already exists.`,
          });
        }
      } else {
        const tmp = {
          history: '',
          patientId: patient.id,
          substance: medValues.substance,
          date_started: medValues.date_started,
          date_stopped: medValues.date_stopped,
          amount: medValues.amount,
          frequency: medValues.frequency,
          sideEffects: medValues.sideEffects,
          BNO: medValues.BNO,
          LA: medValues.LA,
          notes: medValues.notes,
          TDADescription,
          active: true,
        };
        await addMed(tmp);
        closeModal();
      }
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <div className="new-med-form">

      <Label size="subtext" >Substance</Label>
      <Autocomplete
        id="substance"
        options={epilepsyMeds}
        inputValue={medValues.substance}
        freeSolo
        value={medValues.substance}
        onInputChange={(event, newInputValue) => {
          handleSubstanceSelect(newInputValue);
        }}
        onChange={(event: any, newValue: string | null) => {
          handleSubstanceSelect(newValue ?? '');
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input className="custom-auto-input" type="text" {...params.inputProps} />
          </div>
        )}
      />

      <div className='row'>

        <span className='col-30'>
          <Label size="subtext" >Amount in mg</Label>
          <TextInput
            name="amount"
            id="amount"
            type="number"
            value={medValues.amount}
            placeholder="ex. '20mg'"
            onChange={handleInputChangeMeds}
          />
        </span>


        <span className='col-30'>
          <Label size="subtext">Frequency</Label>
          <Dropdown
            name="frequency"
            label=""
            value={medValues.frequency}
            onChange={handleInputChangeMeds}
          >
            {medFrequencyOps.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Dropdown>
        </span>


        <span className='col-30'>
          <Label size="subtext" >Side Effects</Label>
          <Dropdown
            name="sideEffects"
            label=""
            value={medValues.sideEffects}
            onChange={handleInputChangeMeds}
          >
            {['None', 'Mild', 'Significant'].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Dropdown>
        </span>


      </div>



      {medValues.frequency === 'Total Daily Amount' &&

        <div>
          <Label size="subtext" >Daily Regimen:</Label>
          <TextInput
            name="TDADescription"
            id="TDADescription"
            value={TDADescription}
            placeholder="Please Describe Daily Regimen"
            onChange={handlesetTDADescriptionChange}
          />
        </div>}

      <div className="row">
        <span className='chechbox-wrapper'>

          <Checkbox checked={medValues.BNO} onChange={handleBNOchange} label="Brand Name Only" />
          <Label size="subtext" >BNO</Label>
        </span>


        <span className='chechbox-wrapper'>
          <Checkbox checked={medValues.LA} onChange={handleLAchange} label="Long Acting" />
          <Label size="subtext" >LA</Label>
        </span>
      </div>


      <Label size="subtext">Notes:</Label>
      <TextAreaInput
        name="notes"
        id="notes"
        value={medValues.notes}
        onChange={handleAreaChange}
      />


      <Label size="subtext">
        {`Calculated Daily Dose: ${calculatdDailyDose}`}
      </Label>

      <div className="text-center">
        <Button disabled={isLoading} onClick={submit}>Submit</Button>
      </div>
      <Spacer value={10} />
      {alert.open && <Label size='h6' color="danger" center>{alert.message}</Label>}

    </div >
  );
};

NewMedForm.defaultProps = {
  className: '',
};

NewMedForm.displayName = 'NewMedForm';
export default NewMedForm;
