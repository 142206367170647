import React from 'react';
import { Label, Spacer, Button } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';

export interface NameProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleNextStep: () => void,
}

const Name = (props: NameProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleNextStep } = props;


  const validate = () => {
    if (
      state.firstname &&
			state.firstname.trim() !== '' &&
			state.surname &&
			state.surname.trim() !== '' &&
			state.lastNameAtBirth &&
			state.lastNameAtBirth.trim() !== '' 
    ) {
      return false;
    } 
    return true;
  };

  return (
    <div>
      <Label size="h3" bold color="primary" center>What is your name?</Label>
      <Spacer value={20} />

      <TextInput
        id="firstname"
        label="First Name"
        type=""
        value={state.firstname}
        placeholder="First Name (No nicknames)"
        onChange={handleChange}
      />
      <Spacer value={10} />
      <TextInput
        id="surname"
        label="Last Name"
        type=""
        value={state.surname}
        placeholder="Last Name"
        onChange={handleChange}
      />
      <Spacer value={10} />
      <TextInput
        id="lastNameAtBirth"
        label="Last Name at Birth"
        type=""
        value={state.lastNameAtBirth}
        placeholder="Last Name at Birth"
        onChange={handleChange}
      />

      <Spacer value={20} />
      <div className='btn-row'>
        <Button disabled>Previous</Button>
        <Button onClick={handleNextStep} disabled={validate()}>Next</Button>
      </div>
    </div>
  );
};

Name.defaultProps = {
};

Name.displayName = 'Name';
export default Name;
