import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Label, Logo, Spacer, Checkbox, Modal, Alert } from '../../../components/atoms';
import { PhoneInput, TextInput } from '../../../components/molecules';
import { Credentials, selectAuth, signUpUser, logout } from '../../../store/authentication/slice';
import api from '../../../api/api';
import { AlertState } from '../../../types/types';
import TermsBody from '../../../utils/TermsBody';


const CreateAccount = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    firstname: '',
    lastname: '',
    agree: false,
    status: '',
  });
  const [modal, setModal] = useState(false);
  const [helperText, setHelperText] = React.useState(false);

  const showHelper = () => {
    setHelperText(true);
  };

  const hideHelper = () => {
    setHelperText(false);
  };


  const { status, error } = useSelector(selectAuth);

  React.useEffect(() => {
    if (status === 'signUpSuccess') {
      navigate('/verify', { state: { user: state } });
    }
  }, [status]);


  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'error',
  });

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'error',
    });
  };

  React.useEffect(() => {
    if (error) {
      setAlert({
        open: true,
        message: `Error Creating Account: ${error}`,
        kind: 'error',
      });
      setState((prev) => ({ ...prev, 'status': '' }));
    }
  }, [error]);


  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('e', e.target.value);
    setState((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const onCheck = () => {
    setState((prev) => ({ ...prev, agree: !state.agree }));
  };


  useEffect(() => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
  }, []);

  const onSubmit = async () => {
    if (state.password !== state.passwordConfirm) {
      setAlert({
        open: true,
        message: 'Passwords to not match',
        kind: 'error',
      });
      return;
    }
    setState((prev) => ({ ...prev, 'status': 'loading' }));
    const pl = {
      email: state.email,
      password: state.password,
      firstname: state.firstname,
      surname: state.lastname,
    };
    await dispatch(signUpUser(pl));
  };


  const validate = () => {
    if (
      state.email &&
      state.email.trim() !== '' &&
      state.password &&
      state.password.trim() !== '' &&
      state.passwordConfirm &&
      state.passwordConfirm.trim() !== '' &&
      state.agree
    ) {
      return false;
    }
    return true;
  };


  return (
    <div className="hsg-login">
      <Spacer value={30} />
      <Logo size='75px' type='small' />
      <Label center size='h1' color='primary'>Create an Account</Label>
      <div className='hsg-login__form-wrapper'>
        <Spacer value={10} />
        <TextInput
          id="email"
          type="Email"
          autocomplete="Email"
          value={state.email}
          placeholder='Email'
          label='Email'
          onChange={onChange}
        />
        <Spacer value={10} />
        <TextInput
          id="password"
          type="password"
          value={state.password}
          autocomplete="current-password"
          placeholder='Password'
          label='Password'
          onChange={onChange}
          onFocus={showHelper}
          onfocusout={hideHelper}
        />
        {helperText &&
          <div>
            <Label size="subtext" color="danger">
              Password must be at least 8 characters
            </Label>
            <Label size="subtext" color="danger">
              Password must contain at least 1 uppercase letter & at least  1 lowercase letter
            </Label>
            <Label size="subtext" color="danger">
              Password must contain at least 1 special character & at least 1 number
            </Label>
          </div>
        }
        <Spacer value={10} />

        <TextInput
          id="passwordConfirm"
          type="password"
          value={state.passwordConfirm}
          placeholder='Confirm Password'
          label='Confirm Password'
          onChange={onChange}
        />
        <Spacer value={10} />

        <PhoneInput
          id="phone"
          value={state.phone}
          placeholder='Phone'
          label='Phone'
          onChange={onChange}
        />
        <Spacer value={10} />

        <TextInput
          id="firstname"
          autocomplete="given-name"
          value={state.firstname}
          placeholder='First Name'
          label='First Name'
          onChange={onChange}
        />
        <Spacer value={10} />

        <TextInput
          id="lastname"
          autocomplete="family-name"
          value={state.lastname}
          placeholder='Last Name'
          label='Last Name'
          onChange={onChange}
        />
        <Spacer value={10} />

        <span className='hsg-login__check-wrapper'>
          <Checkbox checked={state.agree} onChange={onCheck} />
          <Label size="h4" weight={200} >
            I HAVE READ AND AGREE TO THE
            <button className='hsg-login__text-btn' type='button' onClick={() => setModal(true)}>
              TERMS AND CONDITIONS
            </button>
          </Label>
        </span>
        <Spacer value={20} />
        <div className='btn-row'>
          <Button loading={state.status === 'loading'} disabled={validate()} onClick={onSubmit} >Submit</Button>
        </div>
      </div>

      <Modal title='' open={modal} onClose={() => setModal(false)}>
        <div>
          <TermsBody />
          <Spacer value={30} />
          <div className="super-centered">
            <Button onClick={() => setModal(false)}>Close</Button>
          </div>
        </div>
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

export default CreateAccount;
