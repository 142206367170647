/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
import React from 'react';

const formatFAQ = (text: string): JSX.Element[] => {
  let inSubscript = false;
  let inSuperscript = false;

  const parts = text.split(/(<sub>|<\/sub>|<sup>|<\/sup>)/);

  return parts.map((part, i) => {
    if (!part) {
      return null; // Skip null parts
    } else if (part === '<sub>') {
      inSubscript = true; // Enable subscript
      return null;
    } else if (part === '</sub>') {
      inSubscript = false; // Disable subscript
      return null;
    } else if (part === '<sup>') {
      inSuperscript = true; // Enable superscript
      return null;
    } else if (part === '</sup>') {
      inSuperscript = false; // Disable superscript
      return null;
    } else {
      return (
        <React.Fragment key={i}>
          {inSubscript ? <sub>{part}</sub> : inSuperscript ? <sup>{part}</sup> : part}
        </React.Fragment>
      );
    }
  }).filter(Boolean) as JSX.Element[];
};


export default formatFAQ;