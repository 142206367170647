import React from 'react';
// import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { BsTrash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { Tile, Spacer, Label, Modal, Checkbox } from '../../atoms';
import './patient-item.scss';
import { selectAuth } from '../../../store/authentication/slice';
import DeleteVerification from '../DeleteVerification/DeleteVerification';
import { useDeletePatientMutation } from '../../../api/builders/patients.api';

export interface PatientItemProps {
  id: string,
  editable?: boolean,
  mrn: string,
  firstname: string,
  lastname: string,
  gender: string,
  dob: number,
  cohorts: string[] | [],
  checked: boolean,
  handleCheck: (id: string) => void,
  patient: any,
  cohortId?: string,
  cohort?: string,
}

const PatientItem = (props: PatientItemProps): JSX.Element => {
  const { id, editable, mrn, firstname, lastname, dob, cohorts, checked, handleCheck, gender, patient, cohortId, cohort } = props;
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deletePatient, { isLoading }] = useDeletePatientMutation();
  const { groups } = useSelector(selectAuth);

  const closeDeleteMetModal = () => {
    setOpenDelete(false);
  };
  const openDeleteMetModal = () => {
    setOpenDelete(true);
  };


  const getDob = (date: number) => {
    const birthDate = new Date(date * 1000);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    return age;
  };

  const onOpen = () => {
    navigate('/private/cohorts/item/patient', { state: { patient, secondary: false, cohort } });
  };

  const handleDelete = () => {
    try {
      const payload = {
        id,
        body: {
          cohortId,
          mrn,
        },
      };
      deletePatient(payload);
    } catch (e) {
      console.error('err deleting patient', e);
    }
  };


  return (
    <div key={`PatientItem_${id}`} className="patient-item">
      <Tile>
        <div className='patient-item__body' role='button' tabIndex={-1} >
          {editable ?
            <Grid container spacing={2}>
              <Grid xs={2} >
                <Checkbox label='' checked={checked} onChange={() => handleCheck(id)} />
              </Grid>
              <Grid xs={3} >
                <Label size="h5" center>{mrn}</Label>
              </Grid>
              <Grid xs={3} >
                <Label size="h5" center>{firstname} {lastname}</Label>
              </Grid>
              <Grid xs={2} >
                <Label size="h5" center>{getDob(dob)}</Label>
              </Grid>
              <Grid xs={2} >
                <Label size="h5" center>{gender}</Label>
              </Grid>
            </Grid>
            :
            <Grid container spacing={2}>
              <Grid xs={3} onClick={onOpen} >
                <Label size="h5" center>{mrn}</Label>
              </Grid>
              <Grid xs={3} onClick={onOpen} >
                <Label size="h5" center>{firstname} {lastname}</Label>
              </Grid>
              <Grid xs={2} onClick={onOpen}>
                <Label size="h5" center>{getDob(dob)}</Label>
              </Grid>
              <Grid xs={3} onClick={onOpen} >
                <Label size="h5" center>{gender}</Label>
              </Grid>
              <Grid xs={1} >
                <div className='super-centered'>
                  {groups.includes('ADMIN') &&
                    <span className='cohort-item__trash-wrapper'>
                      <button type='button' className='icon-btn' onClick={openDeleteMetModal} aria-label='refetch'><BsTrash /></button>
                    </span>
                  }
                </div>
              </Grid>
            </Grid>
          }
        </div>

      </Tile>
      <Modal size="sm" open={openDelete} onClose={closeDeleteMetModal} title="Delete Patient">
        <DeleteVerification
          loading={isLoading}
          item={patient}
          name='Patient'
          onCancel={closeDeleteMetModal}
          onDelete={handleDelete}
        />
      </Modal>
    </div>
  );
};

PatientItem.defaultProps = {
  editable: true,
  cohortId: 'cohortid',
  cohort: 'cohort',
};

PatientItem.displayName = 'PatientItem';
export default PatientItem;
