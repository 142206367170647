import React, { useState } from 'react';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { Button, Label, Logo, Spacer } from '../../../components/atoms';
import { CreateProfile } from '../../../components/molecules';


const CreateProfilePg = () => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <div className="create-profile">
      {openForm ?
        <CreateProfile />
        :
        <div className='create-profile__tile'>
          <Logo size='75px' type='small' />
          <div>
            <Spacer value={30} />
            <Label center size='h1' color='primary'>Thank you for registering!</Label>
            <Spacer value={30} />
            <div className='btn-row'>
              <Button size='wide' kind='secondary' outline onClick={() => setOpenForm(true)} >
                Create Your Profile
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default withAuthenticator(CreateProfilePg);
