/* eslint-disable no-restricted-globals */
// patientPathwayUtils.ts

export interface Step {
  completionDate: string | null;
  scheduledDate: string | null;
  isFirst: boolean;
  isLast: boolean;
  order: number;
  patientStatus: string | null;
  stepLabel: string;
  description: string;
  type: string;
  startTime: number; // assuming startTime is a timestamp
  // ... include other properties as needed
}
  
export interface CarePathway {
  steps: Step[];
  startDate: number;
  name: string,
  description: string,
  // ... include other properties as needed
}
  

export interface PathwayAnalysis {
  totalSteps: number;
  completedStepsCount: number;
  progress: number;
  firstStepStartDate: string;
  nextStep: Step | null;
  nextStepStatus: string;
  name: string; // Care pathway name
  description: string; // Care pathway description
  weeksSinceStart: string;
  //   firstStepStartDate: string; // Changed to string to handle not started message
  //   weeksSinceStart: number | string; // Changed to string to handle not started message
  //   currentStep: Step | null;
  //   nextStep: Step | null;
  //   nextStepStatus: string;

}
export function calculateWeeksBetweenDates(startDate: Date, endDate: Date): string {
  const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
  const timeDiff = endDate.getTime() - startDate.getTime();
    
  if (isNaN(timeDiff)) {
    return '--'; // Return placeholder if time difference is NaN
  }
    
  const weeks = Math.floor(timeDiff / millisecondsPerWeek);
  return `${weeks.toString() } weeks`; // Return weeks as string
}
  
  
export function analyzePatientPathway(patientCarePathway: CarePathway): PathwayAnalysis {
  const totalSteps = patientCarePathway.steps.length;
  const completedSteps = patientCarePathway.steps.filter(step => step.patientStatus === 'COMPLETED');
  const completedStepsCount = completedSteps.length;
  const progress = (completedStepsCount / totalSteps) * 100;


  // Determine the start date of the first step or indicate not started
  // Find the first step
  const firstStep = patientCarePathway.steps.find(step => step.isFirst);
  const today = new Date();

  let firstStepStartDate: string;
  let weeksSinceStart = '--';  // Placeholder for not started

  if (firstStep) {
    if (firstStep.patientStatus === 'COMPLETED' && firstStep.completionDate) {
    // Ensure that the completionDate is not null and is a valid date
      firstStepStartDate = new Date(firstStep.completionDate).toLocaleDateString();
      const startDate = new Date(firstStep.completionDate);
      weeksSinceStart = calculateWeeksBetweenDates(startDate, today);
    } else if (firstStep.patientStatus !== 'COMPLETED' && firstStep.scheduledDate) {
    // Ensure that the scheduledDate is not null and is a valid date
      const startDate = new Date(firstStep.scheduledDate);
      weeksSinceStart = calculateWeeksBetweenDates(startDate, today);
      firstStepStartDate = startDate.toLocaleDateString();

    } else {
    // If neither date is available or they are null, the pathway has not started
      firstStepStartDate = 'Not started';
    }
  } else {
  // If there is no first step marked, consider the pathway as not started
    firstStepStartDate = 'Not started';
  }

  // If dates could be null resulting in "Invalid Date", check if the dates are valid
  if (firstStepStartDate === 'Invalid Date') {
    firstStepStartDate = 'Not started';
  }

  // Find the next step (the first non-completed step)
  const nextStep = patientCarePathway.steps.find(step => step.patientStatus !== 'COMPLETED');

  const nextStepLabel = nextStep ? nextStep.stepLabel : '';
  const nextStepType = nextStep ? nextStep.type : '';
  // Find the next step (the first non-completed step)


  // Determine the status of the next step, including if it's scheduled or not
  let nextStepStatus = '';
  if (nextStep) {
    if (nextStep.patientStatus && nextStep.patientStatus !== 'null') {
    // If there is a valid status that is not the string "null"
      nextStepStatus = nextStep.patientStatus;
    } else {
    // If the status is "null" (either as a string or null value), consider the step as not started
      nextStepStatus = '';
      // Append scheduled status
      nextStepStatus += (nextStep.scheduledDate && nextStep.scheduledDate !== 'null' )
        ? `Scheduled (${nextStep.scheduledDate})`
        : 'Not scheduled';

    }
  } else {
    nextStepStatus = 'Pathway complete'; // If there are no more steps, the pathway is complete
  }


  // Initialize analysis result with default values
  const analysis: PathwayAnalysis = {
    name: patientCarePathway.name,
    description: patientCarePathway.description,
    totalSteps: 0,
    completedStepsCount,
    progress,
    firstStepStartDate,
    weeksSinceStart,
    nextStep: nextStep || null,
    nextStepStatus,
  };

  const now = new Date();

  
  return analysis;
}