/* eslint-disable react/no-array-index-key */
import React from 'react';
import { DatePicker, Label, Spacer, Button } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';
import SimpleSelect from '../../SimpleSelect/SimpleSelect';

export interface HowHearProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleNextStep: () => void,
}

const HowHear = (props: HowHearProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleNextStep } = props;

  const answerQuestion = (value: any) => {
    let tmp: any[] = [];
    if (props?.state?.hear[0] === value) {
      tmp = [];
    } else {
      tmp = [value];
    }
    handleChange((prev: any) => ({ ...prev, hear: tmp }));
  };

  const options = [
    { goto: 'next', label: 'Social media', order: 1, value: 'Social media' },
    { goto: 'next', label: 'Research site staff', order: 2, value: 'Research site staff' },
    { goto: 'next', label: 'Doctor/Nurse', order: 3, value: 'Doctor/Nurse' },
    { goto: 'next', label: 'Support group', order: 4, value: 'Support group' },
    { goto: 'next', label: 'Friend', order: 5, value: 'Friend' },
    { goto: 'next', label: 'Other', order: 6, value: 'Other' },
  ];

  const validate = () => {
    if (
      state.hear[0] &&
			state.hear[0].trim() !== ''
    ) {
      return false;
    } 
    return true;
		
  };

  return (
    <div>
      <Label size="h3" bold color="primary" center>
        How did you hear about myHDstory?
      </Label>
      <Spacer value={20} />
      {options.map((op, i) => (
        <SimpleSelect
          label={op.label}
          onSelect={answerQuestion}
          answer={state.hear}
          withCheck
          multi={false}
          key={i}
        />
      ))}

      <Spacer value={20} />
      <div className='btn-row'>
        <Button onClick={handlePrevStep}>Previous</Button>
        <Button onClick={handleNextStep} disabled={validate()}>Next</Button>
      </div>
    </div>
  );
};

HowHear.defaultProps = {
};

HowHear.displayName = 'HowHear';
export default HowHear;
