import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import Grid from '@mui/material/Unstable_Grid2';
import { VisitT } from '../../../types/types';
import { Label, Spacer, IconButton, DatePicker, Checkbox, Dropdown, Button, Alert } from '../../atoms';
import './seizure-frequency-form.scss';
import SeizureTypeForm from '../SeizureTypeForm/SeizureTypeForm';
import TextInput from '../TextInput/TextInput';
import { useUpdateSeizureRecordMutation } from '../../../api/builders/epilepsy.api';

export interface SeizureFrequencyFormProps {
  visits: VisitT[] | [],
  className?: string,
  visit: VisitT | null,
  openSeizure: any,
  patient: any,
  onClose: () => void,
}

const initialSeizureFreqVals = {
  numOfSeizures: 0,
  notes: '',
  timeUnit: 'Month',
  date: new Date(),
};


const freqOps1 = [
  { value: 'Year', label: 'Per Year' },
  { value: 'Week', label: 'Per Week' },
  { value: 'Month', label: 'Per Month' },
  { value: 'Day', label: 'Per Day' },
  { value: 'Since last visit', label: 'Total since last visit' },
];

const freqOps2 = [
  { value: 'Year', label: 'Per Year' },
  { value: 'Week', label: 'Per Week' },
  { value: 'Month', label: 'Per Month' },
  { value: 'Day', label: 'Per Day' },
];


const initialSeizure = {
  patientLabel: '',
  classification: '',
  onset: '',
  awareness: '',
  yearOfOnset: '',
  description: '',
  dateOfLastKnownSeizure: new Date(),
  count: 0,
  other: '',
  historyOfSeizureCount: '',
  NonEpilepticOption: '',
  active: true,
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const mergeSeizureValues = (initial: any, incoming: any) => ({ ...initial, ...incoming });

const SeizureFrequencyForm = (props: SeizureFrequencyFormProps): JSX.Element => {
  const { visits, visit, openSeizure, patient, onClose } = props;
  const [checked, setChecked] = useState(false);
  const [seizureVals, setSeizureVals] = useState(mergeSeizureValues(initialSeizure, openSeizure));
  const [seizureFreqVals, setSeizureFreqVals] = useState(initialSeizureFreqVals);
  const [calculatedSeizure, setCalculatedSeizure] = useState<string | number>(0);
  const [editSeizure, { isLoading, isSuccess, isError, error }] = useUpdateSeizureRecordMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const reset = () => {
    setSeizureFreqVals(initialSeizureFreqVals);
    setSeizureVals(initialSeizure);
    setCalculatedSeizure(0);
    setChecked(false);
  };

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Substance: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Editing Substance',
        kind: 'success',
      });
      reset();
      onClose();
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };



  useEffect(() => {
    if (openSeizure?.historyOfSeizureCount?.length > 0 && visit) {
      const tmp = JSON.parse(openSeizure.historyOfSeizureCount);
      const filtered = tmp.filter((el: any) => el.visitDate[0] === visit.date[0])[0];
      if (filtered) {
        setSeizureFreqVals({
          ...seizureFreqVals,
          numOfSeizures: filtered.count,
          timeUnit: filtered.timeUnit,
          date: filtered.dateOfLast,
          notes: filtered.notes ? filtered.notes : '',
        });
        if (filtered.uknownNumber || filtered.UknownNumber) {
          setChecked(true);
        }
      } else if (tmp) {
        const indx = tmp.length > 1 ? tmp.length - 1 : 0;
        const filtered2 = tmp[indx];
        if (filtered2) {
          setSeizureFreqVals({
            ...seizureFreqVals,
            numOfSeizures: filtered2.count,
            timeUnit: filtered2.timeUnit,
            date: filtered2.dateOfLast,
            notes: filtered2.notes ? filtered2.notes : '',
          });
          if (filtered2.uknownNumber || filtered2.UknownNumber) {
            setChecked(true);
          }
        }
      } else {
        setSeizureFreqVals(initialSeizureFreqVals);
      }
    } else if (openSeizure) {
      setSeizureFreqVals(initialSeizureFreqVals);
    }
  }, [openSeizure]);

  useEffect(() => {
    if (seizureFreqVals.timeUnit === 'Day') {
      setCalculatedSeizure(seizureFreqVals.numOfSeizures * 30);
    } else if (seizureFreqVals.timeUnit === 'Month') {
      const tmp = seizureFreqVals.numOfSeizures;
      setCalculatedSeizure(tmp);
    } else if (seizureFreqVals.timeUnit === 'Week') {
      const tmp = seizureFreqVals.numOfSeizures * 4;
      setCalculatedSeizure(tmp);
    } else if (seizureFreqVals.timeUnit === 'Year') {
      const tmp = (seizureFreqVals.numOfSeizures / 12).toFixed(2);
      setCalculatedSeizure(tmp);
    } else if (seizureFreqVals.timeUnit === 'Since last visit') {
      let num = 0;
      if (visits?.length > 1 && visit) {
        const idx = visits.findIndex(el => el.date[0] === visit?.date);
        const previous = visits[idx - 1];
        if (previous && previous !== undefined) {
          const date1 = new Date(visit?.date);
          const date2 = new Date(previous.date);
          const differenceInTime = date1.getTime() - date2.getTime();
          const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
          num = differenceInDays / 30;
        }
      }
      if (num !== 0) {
        const tmp = (seizureFreqVals.numOfSeizures / num).toFixed(2);
        setCalculatedSeizure(tmp);
      } else {
        setCalculatedSeizure(0);
      }
    }
  }, [seizureFreqVals]);


  // TEST 
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };


  const handleFrequencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeizureFreqVals({
      ...seizureFreqVals,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheck = () => {
    // if (!seizureFreqVals.unknownNumber) {
    setSeizureFreqVals({
      ...seizureFreqVals,
      numOfSeizures: 0,
      timeUnit: ',',
    });
    // }
    setChecked(!checked);
    setCalculatedSeizure('>0');
  };


  const submit = () => {
    let hx: any[] = [];

    if (seizureVals.historyOfSeizureCount && seizureVals.historyOfSeizureCount.length > 0) {
      const tmp = JSON.parse(seizureVals.historyOfSeizureCount);
      const index = tmp.findIndex((el: any) => el.visitDate === visit?.date);
      const editdata = [...tmp];

      if (index !== -1) {
        editdata[index] = {
          visitDate: visit?.date,
          count: seizureFreqVals.numOfSeizures,
          timeUnit: seizureFreqVals.timeUnit,
          dateOfLast: seizureFreqVals.date,
          unknownNumber: checked,
          notes: seizureFreqVals.notes,
        };
      } else {
        editdata.push({
          visitDate: visit?.date,
          count: seizureFreqVals.numOfSeizures,
          timeUnit: seizureFreqVals.timeUnit,
          dateOfLast: seizureFreqVals.date,
          notes: seizureFreqVals.notes,
          unknownNumber: checked,
        });
      }
      hx = editdata;
    } else {
      hx = [
        {
          visitDate: visit?.date,
          count: seizureFreqVals.numOfSeizures,
          timeUnit: seizureFreqVals.timeUnit,
          dateOfLast: seizureFreqVals.date,
          unknownNumber: checked,
          notes: seizureFreqVals.notes,
        },
      ];
    }

    let field = '';

    if (seizureVals.NonEpilepticOption === 'Other') {
      field = seizureVals.other;
    } else {
      field = seizureVals.NonEpilepticOption;
    }

    const payload = {
      seizureRecordId: openSeizure.id,
      patientId: patient?.id,
      patientLabel: seizureVals.patientLabel,
      classification: seizureVals.classification,
      onset: seizureVals.onset,
      createdon: openSeizure.createdon,
      awareness: seizureVals.awareness,
      field,
      yearOfOnset: seizureVals.yearOfOnset,
      description: seizureVals.description,
      dateOfLastKnownSeizure: seizureVals.dateOfLastKnownSeizure,
      count: seizureFreqVals.numOfSeizures,
      active: seizureVals.active,
      historyOfSeizureCount: JSON.stringify(hx),
    };

    editSeizure(payload);
    // onClose();
  };


  return (
    <div className='card'>
      <Label size="h6" uppercase>
        Visit Date: {visit && moment(visit.date[0]).format('L')}
      </Label>
      <Spacer value={20} />

      <div className='sz-info-toggle'>
        <Label size="h6" uppercase> Seizure Information:</Label>
        <IconButton onClick={toggleCollapse} customIcon={collapsed ? <MdExpandMore /> : <MdExpandLess />} />
      </div>
      <div className={`my-form ${collapsed ? 'collapsed' : ''}`}>
        <div>
          <Spacer value={10} />
          <SeizureTypeForm
            seizureVals={seizureVals}
            setSeizureVals={setSeizureVals}
          />
        </div>
      </div>
      <Spacer value={20} />

      {seizureVals.active && <div>
        <Label size="h6" uppercase >
          Seizure count update since Last visit:
        </Label>
        <Spacer value={20} />

        <Grid container spacing={2} >
          <Grid xs={4} >
            <Label size="subtext" >Seizure count</Label>
            <TextInput
              name="numOfSeizures"
              id="numOfSeizures"
              type="number"
              value={seizureFreqVals.numOfSeizures}
              onChange={handleFrequencyChange}
              disabled={checked}
            />
          </Grid>
          <Grid xs={4} >
            <Label size="subtext">
              Unit of time
            </Label>

            <Dropdown
              name="timeUnit"
              label=""
              value={seizureFreqVals.timeUnit}
              disabled={checked}
              onChange={handleFrequencyChange}
            >
              {(Array.isArray(visits) && visits.length > 1
                ? freqOps1
                : freqOps2
              ).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Dropdown>

          </Grid>
          <Grid xs={4} >
            <Label size="subtext" >
              Date of last known seizure
            </Label>
            <DatePicker
              id="date"
              label=''
              value={moment(seizureFreqVals.date).format('L')}
              onChange={(val) => setSeizureFreqVals({
                ...seizureFreqVals,
                date: val.$d,
              })}
            />
          </Grid>

          <Grid xs={12} >
            <div className='checkbox-wrapper'>
              <Checkbox
                checked={checked}
                onChange={handleChangeCheck}
                label=" having seizures but we dont know how many"
              />
              <Label size='subtext'>
                having seizures but we dont know how many
              </Label>
            </div>
          </Grid>

          <Grid xs={12} >
            <Label size="subtext">Frequency Notes</Label>
            <TextInput
              name="notes"
              id="notes"
              value={seizureFreqVals.notes}
              onChange={handleFrequencyChange}
            />
          </Grid>


        </Grid >

        <Spacer value={20} />
        <Label size="subtext">
          {`Calculated per month seizure count: ${calculatedSeizure}`}
        </Label>

      </div>}

      <Spacer value={20} />
      <div className="text-center">
        <Button onClick={submit} loading={isLoading}>Submit</Button>
      </div>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />


    </div>
  );
};

SeizureFrequencyForm.defaultProps = {
  className: '',
};

SeizureFrequencyForm.displayName = 'SeizureFrequencyForm';
export default SeizureFrequencyForm;
