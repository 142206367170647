import QueryString from 'qs';
import api from '../api';

export const patientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPatientEditable: builder.query({
      query: (patientId) => `patient/editable/${patientId}`,
      providesTags: ['PATIENT'],
      transformResponse: (response: any) => response.items,
    }),
    updatePatientInfo: builder.mutation({
      query: (payload) => ({ url: `/patient/${payload.aupatientIdditId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['PATIENT'],
    }),
    createPatientcarepathway: builder.mutation({
      query: (payload) => ({ url: '/patientcarepathway/', method: 'POST', body: payload }),
      invalidatesTags: ['PATIENTPATHWAYS'],
    }),
    getPatientPathways: builder.query({
      query: (patientId) => `/patientcarepathway/${patientId}`,
      transformResponse: (response: any) => response.items,
      providesTags: ['PATIENTPATHWAYS'],
    }),
    updatePatientcarepathway: builder.mutation({
      query: (payload) => ({ url: `/patientcarepathway/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['PATIENTPATHWAYS'],
    }),
  }),
});

export const {  
  usePrefetch,
  useGetPatientEditableQuery,
  useUpdatePatientInfoMutation,
  useCreatePatientcarepathwayMutation,
  useGetPatientPathwaysQuery,
  useUpdatePatientcarepathwayMutation,
} = patientApi;
