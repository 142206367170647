import React, { useContext } from 'react';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import { NavLink, useNavigate } from 'react-router-dom';
import './page-footer.scss';
import version from '../../../version.json';
import { SurveyContext } from '../../../utils/SurveyInProgress/surveyInProgress';
import { Label, Modal, Spacer, Button } from '../../atoms';

const PageFooter = (props: any) => {
  const navigate = useNavigate();
  const { className: parentClassName } = props;
  const footerClassName = `page-footer ${parentClassName || ''}`;
  const { surveyInProgress, setSurveyInProgress } = useContext(SurveyContext);
  const [route, newRoute] = React.useState('');
  const [popup, setPopup] = React.useState(false);



  const confirmNaviagte = () => {
    setPopup(false);
    setSurveyInProgress(false);
    navigate(route);
  };

  const handleLinkClick = (toGo: any) => {
    if (surveyInProgress) {
      newRoute(toGo);
      setPopup(true);
    } else {
      navigate(toGo);
    }
    // setBreadcrumbs([]);
  };

  const handleCancelNav = () => {
    newRoute('');
    setPopup(false);
  };



  return (
    <footer className={footerClassName}>
      <div className="page-footer__container">
        <div className="page-footer__text">
          V {version.version}
        </div>
        <div className="page-footer__text">
          myHDstory<sup>&reg;</sup>
        </div>
        <div className="page-footer__nav">
          {/* <NavLink to='/privacy'>Privacy</NavLink>
          <NavLink to='/Terms'>Terms</NavLink>
          <NavLink to='/faq'>FAQ</NavLink>
          <NavLink to='/help'>Contact Us</NavLink> */}

          <button onClick={() => handleLinkClick('/privacy')} className='page-footer__link' type='button'>
            Privacy
          </button>
          <button onClick={() => handleLinkClick('/Terms')} className='page-footer__link' type='button'>
          Terms
          </button>
          <button onClick={() => handleLinkClick('/faq')} className='page-footer__link' type='button'>
            FAQ
          </button>
          <button onClick={() => handleLinkClick('/help')} className='page-footer__link' type='button'>
            HELP
          </button>
        </div>
      </div>

      <Modal open={popup} onClose={handleCancelNav} title='Confirm Exit' size='sm'>
        <div>
          <Label size="h4">Are you sure you want to exit this survey?</Label>
          <Spacer value={20} />
          <Label size="h4">Your progress so far will not be saved.</Label>
          <Spacer value={30} />
          <div className="btn-row">
            <Button onClick={handleCancelNav} kind="ghost">No I don&apos;t want to exit</Button>
            <Button onClick={confirmNaviagte} >Yes</Button>
          </div>
        </div>
      </Modal>

    </footer>);
};

export default PageFooter;
