/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import moment from 'moment';
import { Tile, Label, Spacer, Button, Modal } from '../../../atoms';
import { VisitT } from '../../../../types/types';
import sortVisits from '../../../../utils/fns/sortVisits';
import { PROD } from '../../../../utils/parameters';
import SurveyResponseTable from '../../SurveyResponseTable/SurveyResponseTable';

export interface LifeEventsProps {
  visits: VisitT[] | [],
  patient: any,
  patientSurveys: any,
}


const LifeEvents = (props: LifeEventsProps): JSX.Element => {
  const { visits, patientSurveys, patient } = props;
  const preVisitSurveys = patientSurveys.PREVISITALL;
  const [preVistModal, setPreVisitModal] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState<any>(null);

  const openPreVist = (survey: any) => {
    if (Array.isArray(survey) && survey.length > 0) {
      setPreVisitModal(true);
      setCurrentSurvey(survey[0]);
    }
  };

  const renderDrivingTd = (index: number, questionId: string, question2Id: string, visit: VisitT) => {
    // console.log("visit", visit, preVisitSurveys)
    if (Array.isArray(preVisitSurveys) && preVisitSurveys.length > 0) {
      try {
        let answer = '';
        const survey = preVisitSurveys.filter(s => s.sessionId === visit.id);
        if (survey.length > 0 && survey[0].status === 'completed') {
          const qs = JSON.parse(survey[0].questions);
          const filtered = qs.filter((el: any) => el.id === questionId);
          for (let i = 0; i < filtered[0].answer.length; i++) {
            answer = filtered[0].answer[i].label;
          }
        }
        let answer2 = '';
        const survey2 = preVisitSurveys.filter(s => s.sessionId === visit.id);
        if (survey2.length > 0 && survey2[0].status === 'completed') {
          const qs2 = JSON.parse(survey2[0].questions);
          const filtered = qs2.filter((el: any) => el.id === question2Id);
          for (let i = 0; i < filtered[0].answer.length; i++) {
            answer2 = filtered[0].answer[i].label;
          }
        }
        return <td key={index}  >{answer !== '' ? `${answer} / ${answer2}` : ''}</td>;
      } catch (e) {
        console.error('"renderStatusTd error"', e);
      }
    }
    return <td key={index} />;
  };

  const renderStatusTd = (index: number, questionId: string, visit: VisitT) => {
    if (Array.isArray(preVisitSurveys) && preVisitSurveys.length > 0) {
      try {
        let answer = '';
        const survey = preVisitSurveys.filter(s => s.sessionId === visit.id);
        if (survey.length > 0 && survey[0].status === 'completed') {
          const qs = JSON.parse(survey[0].questions);
          const filtered = qs.filter((el: any) => el.id === questionId);
          for (let i = 0; i < filtered[0].answer.length; i++) {
            answer = filtered[0].answer[i].label;
          }
        }
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        return <td key={index} onClick={() => openPreVist(survey)} >{answer}</td>;
      } catch (e) {
        console.error('"renderStatusTd error"', e);
      }
    }
    return <td key={index} />;
  };


  return (
    <>
      <tr>
        <th> <span className="seizure-th">Driving / License</span> </th>
        {
          sortVisits(visits).map((visit, index) => renderDrivingTd(index, PROD.hhcActivelyDrives, PROD.hhcDriversLicense, visit))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">Working</span> </th>
        {
          sortVisits(visits).map((visit, index) => renderStatusTd(index, PROD.hhcWorking, visit))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">Living</span> </th>
        {
          sortVisits(visits).map((visit, index) => renderStatusTd(index, PROD.hhcLivingSituation, visit))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">Insurance</span> </th>
        {
          sortVisits(visits).map((visit, index) => renderStatusTd(index, '7ba353fd-15b3-463c-b7d9-d25ab1bebd66', visit))
        }
      </tr>
      <tr>
        <th> <span className="seizure-th">Life events</span> </th>
        {
          sortVisits(visits).map((visit, index) => renderStatusTd(index, '6f9cd66e-675a-4512-8b12-2b699b93fc25', visit))
        }
      </tr>
      <Modal
        open={preVistModal}
        onClose={() => setPreVisitModal(false)}
        title="Pre Visit Survey Questionairre"
        size="md"
      >
        <div className="modal-body">
          {currentSurvey?.questions && preVistModal ?
            <SurveyResponseTable
              questions={JSON.parse(currentSurvey.questions)}
              survey={currentSurvey}
            /> :
            <div>Error Laoding Survey</div>
          }
        </div>
      </Modal >
    </>
  );
};

LifeEvents.defaultProps = {
};

LifeEvents.displayName = 'LifeEvents';
export default LifeEvents;
