import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';


const prepareHeaders = async (headers: Headers, { getState }: { getState: () => unknown }) => {
  try {
    let token = 'none';
    try {
      const tokens = await Auth.currentSession();
      token = tokens?.getIdToken().getJwtToken();
      // console.log('token', tokens);
    } catch (session_error) {
      console.error('no active session');
    }
    if (token && token !== 'none') {
      headers.set('Authorization', token);
    }
  } catch (error) {
    console.error('Error fetching auth session:', error);
  }

  if (process.env.REACT_APP_API_KEY) {
    headers.set('x-api-key', process.env.REACT_APP_API_KEY);
  } else {
    console.error('API_KEY is not defined in environment variables');
  }

  // console.log('Headers:', headers.get('Authorization'));
  return headers;
};


const api = createApi({
  baseQuery: retry(fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL_OLD,
    prepareHeaders,
  }), {
    maxRetries: 5,
  }),
  tagTypes: ['PATIENTS', 'PARTICIPANT_REQS', 'REQUIREMENT_IMAGES', 'TEMPLATES', 'TESTS', 'PARTICIPANT', 'STUDIES', 'STUDY', 'SITES', 'SITE', 'PARTICIPANTS', 'TUMORS', 'PROTOCOL', 'ACTIVITIES', 'CHATS', 'STUDYUSERS', 'PROTOCOLLOGS', 'IMG_ANALYSIS', 'SURVEY', 'REQUIREMENT_DOCUMENTS', 'REGISTRIES', 'STUDYSTATS', 'HIVES', 'HIVE', 'AUDITS', 'AUDIT', 'OPENCOHORT', 'COHORTS', 'PATIENTS', 'PATIENTFILES', 'LOGS', 'AUDITIMG', 'REGISTRY', 'CARETEMPLATES', 'OPENPATIENT', 'CONFERENCE', 'CONFERENCS', 'CONFERENCEPATS', 'PATIENTSESSIONS', 'SURVEYTEMPLATES', 'PATIENTSCANS', 'PATIENT', 'EPILEPSY', 'PATIENTPATHWAYS', 'USERSURVEYS', 'USER'],
  endpoints: (builder) => ({
    createPUTSignedUrl: builder.mutation({
      query: (s3_path) => ({ url: '/files/binder/', method: 'POST', body: s3_path }),
      invalidatesTags: ['REQUIREMENT_IMAGES', 'REQUIREMENT_DOCUMENTS'],
    }),
    createSignedUrl: builder.mutation({ query: (s3_path) => ({ url: '/files/drive/', method: 'POST', body: s3_path }) }),
  }),
});

export const { useCreatePUTSignedUrlMutation, useCreateSignedUrlMutation } = api;

export default api;
