import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPatientEMRQuery } from '../../../api/builders/registries.api';
import { Tile, Label, Spacer, Alert, Spinner } from '../../atoms';
import './patient-emr-data.scss';

export interface PatientEMRDataProps {
  id: string,
  className?: string,
}

const PatientEMRData = (props: PatientEMRDataProps): JSX.Element => {
  const { id } = props;
  // eslint-disable-next-line no-nested-ternary
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetPatientEMRQuery(id ?? skipToken);

  const dragStart = (event: any, item: any) => {
    try {
      event.dataTransfer.setData('ehrData', JSON.stringify(item));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Tile className='patient-care-pathway__tile' >
      <div>
        <Label uppercase size="h3" weight={600}>
          EHR Events
        </Label>
        <Spacer value={10} />
        {isLoadingData || isFetchingData &&
          <div className='super-centered'>
            <Spinner />
          </div>
        }
        {Array.isArray(data) && data.length > 0 &&
          <div>
            {data.map((item: any) => (
              <div
                key={item.id}
                className='emr-item'
                draggable
                onDragStart={(e) => dragStart(e, item)}
              >
                <span className='emr-item__header'>
                  <Label uppercase size='h5' weight={600} >
                    {item.visitLabel}
                  </Label>
                  <Label uppercase size='h6' weight={600} >
                    {item.visitDate}{' '}{item.visitTime}
                  </Label>
                </span>
                <Spacer value={10} />
                <Label uppercase size='h6' weight={400} >
                  {item.attendingDoctorFirstname},{' '}{item.attendingDoctorLastname}
                </Label>
                <Spacer value={10} />
                <Label uppercase size='h6' weight={400} color='primary'>
                  {item.status}
                </Label>
              </div>
            ))}
          </div>
        }
      </div>
    </Tile>
  );
};

PatientEMRData.defaultProps = {
  className: '',
};

PatientEMRData.displayName = 'PatientEMRData';
export default PatientEMRData;
