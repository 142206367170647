/* eslint-disable no-else-return */
import React from 'react';
import { FaRegClock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './user-survey-item.scss';
import { SurveyNewT } from '../../../types/hsgTypes';
import { Button, Label, Spacer, Tile } from '../../atoms';

export interface UserSurveyItemProps {
  className?: string,
  survey: SurveyNewT
  disabled: boolean,
}

const UserSurveyItem = (props: UserSurveyItemProps): JSX.Element => {
  const { survey, disabled } = props;
  const navigate = useNavigate();

  const openSurvey = () => {
    navigate('/survey', { state: { survey } });
  };


  const calculateUrgency = (expirationDate: string | number | Date): number | string => {
    if (!expirationDate) return 'null'; // No expiration date provided
    const now = new Date();
    const expiry = new Date(expirationDate);
    // const diffInDays = Math.ceil((expiry - now) / (1000 * 60 * 60 * 24));
    const diffInDays = Math.ceil((expiry.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    if (diffInDays < 0) {
      return 'expired';
    }
    if (diffInDays === 0) {
      return 'Expires Today';
    }
    if (diffInDays <= 3) {
      return diffInDays; // Urgent
    }
    if (diffInDays <= 7) {
      return diffInDays;
    }
    return diffInDays; // Urgent
  };

  const renderFooter = (status: string, expireOn: string | number | Date) => {
    const expiration = calculateUrgency(expireOn);
    // console.log('expiration', expireOn);
    const s = status.toLocaleLowerCase();

    if (expiration === 'expired') {
      return (
        <Label italic color="ghost" >Expired</Label>
      );
    } else if (s === 'complete' || s === 'completed') {
      return (
        <Label italic color="success" >{status}</Label>
      );
    } else if (s === 'pending' && !expiration && expiration !== 'expired') {
      return (
        <div className='d-flex'>
          <Button disabled={!disabled} onClick={openSurvey}>Go</Button>
        </div>
      );
    } else if (s === 'pending' && expiration === 'Expires Today') {
      return (
        <div className='user-survey-item__footer'>
          <Button disabled={!disabled} onClick={openSurvey}>Go</Button>
          <Label italic color="danger" >{expiration}</Label>
        </div>
      );
    } else if (s === 'pending' && typeof expiration === 'number' && expiration <= 7) {
      return (
        <div className='user-survey-item__footer'>
          <Button disabled={!disabled} onClick={openSurvey}>Go</Button>
          <Label italic color="warning" >Expires in {expiration} days </Label>
        </div>
      );
    } else if (s === 'pending' && typeof expiration === 'number' && expiration > 7) {
      return (
        <div className='user-survey-item__footer'>
          <Button disabled={!disabled} onClick={openSurvey}>Go</Button>
          <Label italic color="ghost" >Expires in {expiration} days </Label>
        </div>
      );
    }

    return (
      <Label italic color="ghost" >{status}</Label>
    );
  };


  return (
    <Tile className='user-survey-item' >
      <div >
        <div className="user-survey-item__header" >
          <Label size="h4" bold color="ghost">{survey.surveyLabel}</Label>
          <span className="d-flex">
            <FaRegClock className="user-survey-item__subtext" />
            <Spacer value={5} />
            <Label size='h6'>
              About {survey.timeToComplete ? survey.timeToComplete : '5 minutes'}
            </Label>
          </span>
        </div>

        {/* <Button onClick={openSurvey}>Go</Button> */}

        <Spacer value={20} />

        <Label color="ghost" >{survey.description}</Label>

        <Spacer value={20} />
        {/* {survey.status !== 'pending' ?
          <Label italic color="ghost" >{survey.status}</Label>
          :
          <div className='d-flex'>
            <Button disabled={!disabled} onClick={openSurvey}>Go</Button>
          </div>
        } */}


        {renderFooter(survey.status, survey.expiredAt)}
        <Spacer value={10} />
      </div>
    </Tile>
  );
};

UserSurveyItem.defaultProps = {
  className: '',
};

UserSurveyItem.displayName = 'UserSurveyItem';
export default UserSurveyItem;
