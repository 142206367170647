/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
import { Tile, Label, Text, Button, Spacer } from '../../atoms';
import './survey-response-table.scss';
import { SurveyT } from '../../../types/types';

export interface SurveyResponseTableProps {
  questions: any,
  survey?: SurveyT | null,
  noScore?: boolean,
}

const SurveyResponseTable = (props: SurveyResponseTableProps): JSX.Element => {
  const { questions, survey, noScore } = props;


  const renderResponse = (q: any) => {
    try {
      if (q && q.answer && q.answer.length > 0) {
        const nLabel = q.answer[0].label;
        if (q.options && typeof nLabel === 'string') {
          return nLabel;
        }
        if (q.options && typeof JSON.parse(nLabel) === 'object') {
          return JSON.stringify({ side: nLabel.side, area: nLabel.area });
        }
        return nLabel;
      }
      return 'N/A';
    } catch (err) {
      // console.error(err);
      return 'N/A';
    }
  };


  const renderQuestionScore = (arr: any, item: any) => {
    try {
      if (arr.length === 0) {
        return 'N/A';
      }
      if (item && item.type) {
        const { type } = item;
        if (
          type === 'TEXT' ||
          type === 'MULTILINETEXT' ||
          type === 'DATETIME' ||
          type === 'DATE'
        ) {
          return 'N/A';
        }
      }
      if (Number.isNaN(Number(arr[0].value))) {
        return arr[0].value;
      }
      if (!Number.isNaN(Number(arr[0].value))) {
        if (arr && arr.length > 1) {
          const score: any[] = [];
          arr.map((a: any) => score.push(`${a.label}: ${a.value} `));
          return score;
        }
        return arr[0].value;
      }
    } catch (err) {
      // console.error(err);
      return 'N/A';
    }
    return 'N/A';
  };


  const renderSurveyScores = () => {
    if (survey) {

      const isSurveyComplete = survey?.status === 'completed';
      if (!isSurveyComplete) {
        return null; // Survey is not complete, do not render scores
      }

      try {
        const parsedScores = JSON.parse(survey.score);
        if (parsedScores) {
          return (
            <div >
              {parsedScores.map((score: any, index: number) => (
                <span key={index}>
                  <Label size="h6" uppercase>{score.label}: {parseFloat(score.score).toFixed(2)} / {score.totalScore} </Label>
                  <Spacer value={5} />
                </span>
              ))}
            </div>
          );
        }
      } catch (error) {
        console.error('Error parsing survey scores:', error);
        return <div>Error parsing survey scores.</div>;
      }
    }
    return <div />;
  };

  return (
    <div className="card">
      {survey && survey.status === 'completed' &&
        <span>
          <Label size="h6" uppercase>Completed on: {moment(survey.updatedAt).format('L')}</Label>
        </span>
      }
      <Spacer value={5} />
      {survey && renderSurveyScores()}
      <table className="response-table">
        <thead >
          <tr>
            <th>
              <Label size="h6" color='white' uppercase>Question</Label>
            </th>
            <th>
              <Label size="h6"  color='white' uppercase>Response</Label>
            </th>
            {!noScore && <th>
              <Label size="h6"  color='white' uppercase>Score</Label>
            </th>}
          </tr>
        </thead>
        <tbody>
          {questions.map((item: any) => (
            <tr className="response-table__row" key={item.id}>
              <td>
                <Label size="h6">{item.label}</Label>
              </td>
              <td>
                <Label size="h6">{renderResponse(item)}</Label>
              </td>
              {!noScore &&
                <td>
                  <Label size="h6">{renderQuestionScore(item.answer, item)}</Label>
                </td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SurveyResponseTable.defaultProps = {
  survey: null,
  noScore: false,
};

SurveyResponseTable.displayName = 'SurveyResponseTable';
export default SurveyResponseTable;
