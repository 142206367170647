/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { RiStopFill } from 'react-icons/ri';
import { BsFillMicFill } from 'react-icons/bs';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Tile, Label, Button, Spacer } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import './question-types.scss';


export interface TextProps {
  answer: any,
  onAnswer: (vals: any) => void,
  detect?: boolean,
  setListeningActive: (pl: boolean) => void,
  listeningActive: boolean,
  question: any,
}

const Text = (props: TextProps): JSX.Element => {
  const { answer, onAnswer, detect, setListeningActive, listeningActive, question } = props;
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [value, setValue] = useState('');
  const [state, setState] = useState({
    wordCount: 0,
    charCount: 0,
    detected: false,
  });
  const [invalid, setInvalid] = useState(false);

  const countWords = (count: string) => {
    if (count.length === 0) {
      return 0;
    }
    let countN = '';
    countN = count.replace(/(^\s*)|(\s*$)/gi, '');
    countN = count.replace(/[ ]{2,}/gi, ' ');
    countN = count.replace(/\n /, '\n');
    return countN.split(' ').length;
  };


  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      resetTranscript();
      setValue(answer[0].value);

      if (state.wordCount === 0 && answer[0].value) {
        const count = answer[0].value;
        setState({
          wordCount: countWords(count),
          charCount: count.length,
          detected: false,
        });

        if (countWords(count) > 300) {
          setInvalid(true);
        }
      }
    }
  }, [question.id]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const txt = event.target.value;

    if (countWords(txt) < 300) {

      if (detect) {
        const searchString = txt.toLowerCase();
        const searchTokens = ['suicide', 'kill'];
        const searchRegex = new RegExp(searchTokens.join('|'), 'g');
        const numOfMatches = searchString.match(searchRegex)?.length;
        setState({
          wordCount: countWords(txt),
          charCount: txt.length,
          detected: numOfMatches ? numOfMatches > 0 : false,
        });
      } else {
        setState({
          wordCount: countWords(txt),
          charCount: txt.length,
          detected: false,
        });
      }

      setValue(event.target.value);
      setInvalid(false);
      onAnswer([{ 'value': event.target.value, 'label': event.target.value }]);
    } else {
      setInvalid(true);
    }
  };

  const startRec = () => {
    SpeechRecognition.startListening({ continuous: true });
    setListeningActive(true);
  };

  const stopRec = () => {
    SpeechRecognition.stopListening();
    setListeningActive(false);
    resetTranscript();
  };


  useEffect(
    () => {
      if (listeningActive) {

        const count = transcript;
        setState({
          wordCount: countWords(count),
          charCount: count.length,
          detected: false,
        });

        if (countWords(count) < 300) {
          setValue(transcript);
          onAnswer?.([{ 'value': transcript, 'label': transcript }]);
          setInvalid(false);
        } else {
          setInvalid(true);
        }

        // setValue(transcript)
      }
    }, 
    [transcript],
  );

  useEffect(
    () => {
      resetTranscript();
    },
    [],
  );


  return (
    <div className='question-types_text'>

      <Spacer value={15} />
      {state?.detected === true &&
        <Label color='danger' center size="h6">Need help? +1-800-273-TALK (8255) </Label>}
      <Spacer value={5} />

      <TextAreaInput
        value={value}
        onChange={onChange}
        rows={4}
        placeholder='Type here or press the record button to speak your answer. Please press stop when you are finished speaking.'
      />

      <div className='d-flex-row space-btw '>
        <Label color={invalid ? 'danger' : 'default'} size='subtext'>Word Count: {state.wordCount} / 300</Label>
      </div>

      <Spacer value={10} />
      {!SpeechRecognition.browserSupportsSpeechRecognition() ?
        <p> Speech recognition not compatible with your browser </p>
        : <div className="question__text__listening-container">
          {listeningActive ?
            <Button className="question__microphone" kind="primary" onClick={stopRec}>  <RiStopFill size={25} /> </Button>
            :
            <Button className="question__microphone" kind="primary" outline onClick={startRec}>  <BsFillMicFill size={25} /></Button>
          }
        </div>
      }

    </div>
  );
};

Text.defaultProps = {
  detect: false,
};

Text.displayName = 'Text';
export default Text;
