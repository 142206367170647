import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';
import { UserStudyItem, UserSurveyItem, VideoPlayer } from '../../../components/molecules';
import { useGetStudiesQuery, useGetSurveys2Query, useGetAllStudiesQuery, useGetUserProfileQuery } from '../../../api/builders/hsg.api';
import { StudyT, SurveyT } from '../../../types/hsgTypes';
import { Button, Label, Spacer, Spinner, Modal } from '../../../components/atoms';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';


const Research = () => {
  const navigate = useNavigate();
  const [watch, setWatch] = useState(false);
  const { data: studies, isLoading: isLoadingSudies } = useGetStudiesQuery(' ');
  // const { data: surveys } = useGetSurveys2Query(' ');
  const { data: userProfile, isLoading: isLoadingProfile } = useGetUserProfileQuery(' ');


  const isSurveyClickable = (currentSurvey: SurveyT, sortedSurveys: SurveyT[]): boolean => {
    // Filter out completed surveys and find the lowest order among pending surveys
    const pendingSurveys = sortedSurveys.filter(survey => survey.status[0] === 'pending');
    const lowestPendingOrder = pendingSurveys.length > 0 ?
      Math.min(...pendingSurveys.map(survey => parseInt(survey.order[0], 10))) : 1;

    // Check if the current survey's order is the lowest pending order
    // and if the current survey is the first survey in the list
    return (
      parseInt(currentSurvey.order[0], 10) === lowestPendingOrder
    );
  };



  const copyAndSortSurveys = (array: SurveyT[]): SurveyT[] => {
    // Deep copy the array
    const copiedArray = JSON.parse(JSON.stringify(array));

    // Sort the copied array
    copiedArray.sort((a: SurveyT, b: SurveyT) => parseInt(a.order[0], 10) - parseInt(b.order[0], 10));

    return copiedArray;
  };

  const deepCopyAndSortStudies = (array: StudyT[]): StudyT[] => {
    // Deep copy the array
    const copiedArray = JSON.parse(JSON.stringify(array));

    // Sort the copied array
    copiedArray.sort((a: SurveyT, b: SurveyT) => {
      // Check if either item has status !== 'closed'
      const statusA = a.status[0];
      const statusB = b.status[0];

      if (statusA !== 'closed' && statusB === 'closed') {
        return -1; // a should come before b
      } if (statusA === 'closed' && statusB !== 'closed') {
        return 1; // b should come before a
      }
      return 0; // No priority based on status, maintain original order

    });

    return copiedArray;
  };




  const handleGoToCreate = () => {
    navigate('/create-profile');
  };

  useEffect(() => {
    // console.log('userProfile', userProfile);
    if (userProfile && Array.isArray(userProfile) && (userProfile.length === 0 || !userProfile[0].country)) {
      handleGoToCreate();
    }
  }, [userProfile]);



  if (isLoadingSudies) {
    return (
      <div className='research__empty'>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="research">
      <div className="research__header">
        <span>
          <Label size="h1" weight={200} color="primary"  >
            Discover Trials
          </Label>
          <Label size="h1" weight={200} color="primary"  >
            & Participate in Research
          </Label>
        </span>
        <span className='aside'>
          <Button onClick={() => setWatch(true)}>Watch Video <FaArrowRightLong color='white' className='research__icon' /></Button>
        </span>
      </div>
      {isLoadingSudies ?
        < div className="research__body super-centered">
          <Spacer value={30} />
          <Spacer value={30} />
          <Spinner />
        </div>
        :
        <div className="research__body">
          {studies?.length > 0 && deepCopyAndSortStudies(studies).map((trial: StudyT) => (
            <UserStudyItem trial={trial} key={trial.id} />
          ))}
        </div>
      }

      <Modal open={watch} onClose={() => setWatch(false)} title="Getting Started Guide">
        <VideoPlayer url='https://myhdstory.s3.amazonaws.com/assets/media/myHDstudy+Platform+Overview_Web_high+res.mp4' />
      </Modal>


      {/* <aside className='panel1'>
        <Label size="h4" color="primary" >Studies For You</Label>
        {studies?.length > 0 && studies.map((trial: StudyT) => (
          <UserStudyItem trial={trial} key={trial.id} />
        ))}
      </aside>
      <div className='panel2'>
        {Array.isArray(surveys) && surveys.length > 0 ?
          <div>
            <div className="research__surveyheader">
              <Label size="h4" color="primary">Your Surveys</Label>
              <Spacer value={5} />
              <Label italic color="primary" >Please select each survey in the order they appear.</Label>
            </div>
            {deepCopyAndSort(surveys).map((survey: SurveyT) => (
              <UserSurveyItem
                key={survey.id}
                survey={survey}
                disabled={isClickable(survey, deepCopyAndSort(surveys))} // Pass whether the survey is clickable
              />
            ))}
          </div>
          :
          <div>
            <Label italic color="primary" center >You have no surveys left to complete! Enroll in a new trial to see more surveys</Label>
          </div>
        }
      </div> */}
    </div >
  );

};

export default withAuthenticator(Research);
