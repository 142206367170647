import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { IconButton, Label, Modal, Alert, Spinner, Button, Spacer } from '../../atoms';
import './conference-info-column.scss';
import { useGetConferenceStatsByIdQuery, useAddPatientToConferenceMutation, conferencesApi } from '../../../api/builders/conference.api';
import AddPatientModal from './AddPatientModal';

export interface ConferenceInfoColumnProps {
  className?: string,
  openConference: any,
  handleCloseConf: () => void,
  registry: any,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const ConferenceInfoColumn = (props: ConferenceInfoColumnProps): JSX.Element => {
  const { openConference, handleCloseConf, registry } = props;
  const navigate = useNavigate();
  const { data: info, isLoading: isLoadingConferences, isFetching: isFetchingConferences } = useGetConferenceStatsByIdQuery(openConference ? openConference.id : skipToken);
  const [AddPatient, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useAddPatientToConferenceMutation();
  const { refetch } =
    conferencesApi.endpoints.getConferenceStatsById.useQuerySubscription(openConference.id);
  const [isDragActive, setIsDragActive] = React.useState(false);
  const [addPatModal, setAddPatModal] = React.useState(false);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  const goToConference = () => {
    navigate('/private/registries/registry/conferences/conference', { state: { conference: openConference } });
  };

  React.useEffect(() => {
    refetch();
  }, [openConference]);

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Adding Patients to Conference: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding Patients to Conference',
        kind: 'success',
      });
      refetch();
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  // const handleRmPatient = (pat: any) => {
  //   REGISTRYTODO::: need api for removing patients from conference
  // };

  const handleDragEnter = (event: any) => {

    event.preventDefault();
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
    setIsDragActive(false);
  };

  // enables us to copy the file from our PC to the drop area
  const handleDragOver = (event: any) => {
    event.preventDefault();
    // event.dataTransfer.dropEffect === 'copy';
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
  };


  const handleAddPatient = (newPat: any) => {
    try {
      const payload = {
        patientId: newPat.id,
        conferenceId: openConference.id,
        patientcarepathwayId: newPat.patientcarepathwayId,
      };
      AddPatient(payload);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddOtherPat = (pat: any) => {
    const isInConference = info[0]?.patients?.some((patient: any) => patient.id === pat.id);
    if (!isInConference) {
      handleAddPatient(pat);
      // setPendingPats((prevState: any) => [...prevState, pat]);
    } else {
      setAlert({
        open: true,
        message: 'Patient is already in this Conference',
        kind: 'warning',
      });
    }
  };



  const handleDrop = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsDragActive(false);
      if (e.dataTransfer.items) {
        const newPatient = JSON.parse(e.dataTransfer.getData('patient'));
        if (newPatient) {
          // Check if the patient with the same ID already exists in the pendingPats array
          // const isPatientExists = pendingPats.some((patient: any) => patient.id === newPatient.id);

          // Check if the patient is already in the conference's patient list
          const isInConference = info[0]?.patients?.some((patient: any) => patient.id === newPatient.id);

          if (!isInConference) {
            handleAddPatient(newPatient);
          } else {
            setAlert({
              open: true,
              message: 'Patient is already in this Conference',
              kind: 'warning',
            });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };



  return (
    <div
      className='open-conference'
      onDrop={(event) => handleDrop(event)}
      onDragOver={(event) => handleDragOver(event)}
      onDragEnter={(event) => handleDragEnter(event)}
      onDragLeave={(event) => handleDragLeave(event)}
    >
      {/* <Button onClick={refetch}>refetch</Button> */}
      <div className='open-conference__header'>
        {/* <Label size='h4' weight={200} >{openConference && openConference.conferenceLabel}</Label> */}
        <Label size='h4' weight={200}  >{moment(openConference.conferenceDate).format('LLLL')}</Label>
        <IconButton icon='close' onClick={handleCloseConf} />
      </div>
      <Spacer value={10} />
      <Label size='h4' weight={200}  >{openConference?.type}</Label>
      <Label size='h4' weight={200}  >{openConference?.status}</Label>

      <Spacer value={20} />
      <div className='w-100 row__apart'>
        <Label size='h4' weight={200} >Cases:</Label>
      </div>
      {openConference.status !== 'Closed' ?
        <div className='open-conference__cases-list'>
          {info?.length > 0 && Array.isArray(info[0].patients) && info[0].patients.length > 0 &&
            <>
              {info[0].patients.map((item: any) => (
                <div className='open-conference__patient'>
                  <span>
                    <Label size='h6'>{item.firstname}, {item.lastname}</Label>
                    <Label size='h6'>{item.mrn}</Label>
                  </span>
                </div>
              ))}
            </>
          }
          {isAdding && <div className='super-centered'><Spinner /></div>}
        </div>
        :
        <div>
          {info && Array.isArray(info[0].patients) && info[0].patients.length > 0 &&
            <>
              {info[0].patients.map((item: any) => (
                <div className='open-conference__patient'>
                  <span>
                    <Label size='h6'>{item.firstname}, {item.lastname}</Label>
                    <Label size='h6'>{item.mrn}</Label>
                  </span>
                  {/* REGISTRYTODO::: ADD THIS BACK WHEN FUNC WORKSx */}
                  {/* <IconButton onClick={handleRm(item)} icon='close' /> */}
                </div>
              ))}
            </>
          }
        </div>
      }

      <Spacer value={20} />
      <div className='btn-row'>
        {/* {openConference.status !== 'Closed' && <Button onClick={handleSaveCases}>Save Changes</Button>} */}
        <Button onClick={goToConference}>Open Conference</Button>
      </div>
      <Spacer value={20} />
      <Spacer value={20} />

      <AddPatientModal handleAdd={handleAddOtherPat} open={addPatModal} onClose={() => setAddPatModal(false)} registryId={registry.id} />

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

ConferenceInfoColumn.defaultProps = {
  className: '',
};

ConferenceInfoColumn.displayName = 'ConferenceInfoColumn';
export default ConferenceInfoColumn;
