import api from '../api';


export const surveysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsersurveyStatsById: builder.query({
      query: (id) => ({ url: `/usersurvey/statsbyid/${id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['SURVEY'],
    }),
    editSurvey: builder.mutation({
      query: (payload) => ({ url: `/usersurvey/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['SURVEY'],
    }),
    editT1: builder.mutation({
      query: (payload) => {
        // Log the payload here
        console.log('Edit Session');
    
        // Return the request details
        return {
          url: `/usersurvey/${payload.id}`,
          method: 'PUT',
          body: payload.body,
        };
      },
      invalidatesTags: ['SURVEY'],
    }),
  }),
});

export const { usePrefetch, useGetUsersurveyStatsByIdQuery, useEditSurveyMutation, useEditT1Mutation } = surveysApi;
