/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import moment from 'moment';
import Grid from '@mui/material/Unstable_Grid2';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsExclamationCircle } from 'react-icons/bs';
import { Modal, Label, Alert, Spacer, Button, Checkbox, MultipleSelectDropdown, IconButton } from '../../../atoms';
import { VisitT } from '../../../../types/types';
import sortVisits from '../../../../utils/fns/sortVisits';
import { useGetDiagnosisRecordsByPatientQuery, useCreateDiagnosisRecordMutation, useUpdateDiagnosisRecordMutation } from '../../../../api/builders/epilepsy.api';
import TextInput from '../../TextInput/TextInput';
import '../epilepsy-table.scss';

export interface DiagnosisRowProps {
  visits: VisitT[] | [],
  patient: any,
  diagnosis: any,
}


const initialDx = {
  diagnostic_category: [] as string[],
  refractory: false,
  lesion: false,
  selectedLesionalOps: [] as string[],
  other: '',
  options: [] as string[],
  EEGConfirmed: false,
  freeText: '',
};

const lesionalOps = [
  'Prior infarct (ischemic)',
  'Prior hemorrhage',
  'Cavernous malformation',
  'Mesial temporal sclerosis',
  'Focal cortical dysplasia',
  'Heterotopias',
  'MRI lesion of unclear etiology',
  'Perinatal injury',
  'Prior global cerebral anoxia',
  'Tuberous sclerosis',
  'Neurofibromatosis',
  'MRI normal, other imaging focal',
  'Other',
  'Unknown',
  'Brain tumor',
  'CNS infection',
  'Dementia/cortical atrophy',
  'Suspected genetic etiology',
  'Traumatic brain injury',
  'Suspected autoimmune',
  'Congenital malformations',
  'Hypothalamic hamartoma',
  'Sturge Weber syndrome',
  'Other congenital malformations',
  'Arachnoid Cyst',
];

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const DiagnosisRow = (props: DiagnosisRowProps): JSX.Element => {
  const { visits, patient, diagnosis } = props;
  const [addPatientDx, { isLoading: isAdding, isSuccess: isSuccessAdding, isError: isErrorAdding, error: AddError }] = useCreateDiagnosisRecordMutation();
  const [editPatientDx, { isLoading, isSuccess, isError, error }] = useUpdateDiagnosisRecordMutation();
  const [visit, setVisit] = useState<VisitT | null>(null);
  const [showDiagnoisForm, setShowDiagnoisForm] = useState(false);
  const [dx, setDx] = React.useState(initialDx);
  const [ops, setOps] = React.useState([{
    lateralization: [] as string[],
    localization: [] as string[],
  }]);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  const closeDiagnosisModal = () => {
    setShowDiagnoisForm(false);
  };

  const reset = () => {
    setVisit(null);
    closeDiagnosisModal();
    setDx(initialDx);
    setOps(
      [{
        lateralization: [] as string[],
        localization: [] as string[],
      }],
    );
  };

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Diagnosis: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);


  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Editing Diagnosis',
        kind: 'success',
      });
      reset();
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = AddError as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Diagnosis: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);


  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Editing Diagnosis',
        kind: 'success',
      });
      reset();
    }
  }, [isSuccessAdding]);


  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };



  const openDiagnosisModal = (v: VisitT) => {
    setVisit(v);
    setShowDiagnoisForm(true);

    try {
      if (Array.isArray(diagnosis) && diagnosis[0].history && diagnosis[0].history.length > 0 && v) {
        const tmp = JSON.parse(diagnosis[0].history);
        const index = tmp.findIndex((el: any) => el.date === v.date);
        if (index !== -1) {
          const tmp2 = JSON.parse(tmp[index].localizationDetails);
          setDx({
            ...dx,
            diagnostic_category: JSON.parse(tmp[index].diagnostic_category),
            refractory: tmp[index].refractory,
            lesion: tmp[index].lesional,
            selectedLesionalOps: JSON.parse(tmp[index].lesional_options),
            other: tmp2?.other,
            EEGConfirmed: tmp[index]?.EEGConfirmed ?? false,
            freeText: tmp[index]?.freeText,
          });
          if (tmp2?.length > 0) {
            // setOps(tmp2?.localizationDetails);
            setOps(tmp2);
          }
        }
      }
    } catch (e) {
      console.error('Error loading diagnosis history', e);
    }
  };



  const renderDiagnosisTd = (index: number, v: VisitT) => {
    try {
      if (Array.isArray(diagnosis) && diagnosis[0]?.history && diagnosis[0]?.history.length > 0) {
        const tmp = JSON.parse(diagnosis[0].history);
        const entry = tmp.filter((el: any) => el.date === v.date)[0];
        const catagory = JSON.parse(entry.diagnostic_category);
        const filtered = catagory.filter((el: any) => el.toLowerCase() !== 'uknown');
        if (filtered) {
          return <td role='button' tabIndex={-1} key={index} onClick={() => openDiagnosisModal(v)}> {filtered.join(' & ')}</td>;
        }
      } else {
        return <td role='button' tabIndex={-1} key={index} onClick={() => openDiagnosisModal(v)}><BsExclamationCircle /></td>;
      }
      return <td role='button' tabIndex={-1} key={index} onClick={() => openDiagnosisModal(v)}><BsExclamationCircle /></td>;
    } catch (e) {
      return <td role='button' tabIndex={-1} key={index} onClick={() => openDiagnosisModal(v)}><BsExclamationCircle /></td>;
    }
  };


  // options
  const addOp = () => {
    const tmpOPS = [...ops];
    tmpOPS.push({
      lateralization: [''],
      localization: [''],
    });
    setOps(tmpOPS);
  };

  const removeOp = (index: number) => {
    const tmpRM = [...ops];
    tmpRM.splice(index, 1);
    setOps(tmpRM);
  };

  // ON CHANGE VALS FSN
  const handleLesionalOpsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setDx({
      ...dx,
      selectedLesionalOps: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handleLesionChange = () => {
    setDx({
      ...dx,
      lesion: !dx.lesion,
    });
  };

  const handleChangeOpLat = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedOps = [...ops];
    if (Array.isArray(e.target.value)) {
      updatedOps[index].lateralization = e.target.value;
      setOps(updatedOps);
    }
  };

  const handleChangeOpLoc = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedOps = [...ops];
    if (Array.isArray(e.target.value)) {
      updatedOps[index].localization = e.target.value;
      setOps(updatedOps);
    }
  };




  const handleDCchange = (val: string) => {
    let tmp: string[] = [];
    if (Array.isArray(dx.diagnostic_category) && dx.diagnostic_category.includes(val)) {
      tmp = dx.diagnostic_category.filter((el) => el !== val);
      if (val === 'PSYCHOGENIC') {
        setDx({
          ...dx,
          EEGConfirmed: false,
        });
      }
      if (val === 'OTHER') {
        setDx({
          ...dx,
          freeText: '',
        });
      }
    } else {
      tmp = dx.diagnostic_category;
      tmp.push(val);
    }
    setDx((prev) => ({ ...prev, diagnostic_category: tmp }));
  };

  const isChecked = (val: string) => Array.isArray(dx.diagnostic_category) && dx.diagnostic_category.includes(val);

  const handleDiagnosisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDx((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleEEGConfirmed = () => {
    setDx({
      ...dx,
      EEGConfirmed: !dx.EEGConfirmed,
    });
  };



  // yee haw
  const submitChanges = () => {
    try {
      const newdx = {
        diagnostic_category: JSON.stringify(dx.diagnostic_category),
        lesional: dx.lesion,
        lesional_options: JSON.stringify(dx.selectedLesionalOps),
        refractory: dx.refractory,
        date: visit?.date,
        other: dx.other,
        localizationDetails: JSON.stringify(ops),
        EEGConfirmed: dx.EEGConfirmed,
      };

      if (Array.isArray(diagnosis) && diagnosis[0]?.history && diagnosis[0]?.history.length > 0) {
        const tmp = JSON.parse(diagnosis[0].history);
        const index = tmp.findIndex((el: any) => el.date === visit?.date);
        const editdata = [...tmp];
        if (index !== -1) {
          editdata[index] = newdx;
        } else {
          editdata.push(newdx);
        }

        const payload = {
          ...newdx,
          history: JSON.stringify(editdata),
          diagnosisRecordId: diagnosis[0].id,
          patientId: patient.id,
        };
        // TO DO EDIT PL
        editPatientDx(payload);
      } else {
        const newDxPl = {
          ...newdx,
          history: JSON.stringify([newdx]),
          patientId: patient.id,
        };
        addPatientDx(newDxPl);
      }
    } catch {
      console.error('err saving dx');
    }
  };


  return (
    <>
      <tr>
        <th> <span className="seizure-th"> Diagnosis</span> </th>
        {
          sortVisits(visits).map((v, index) => renderDiagnosisTd(index, v))
        }
      </tr>

      <Modal open={showDiagnoisForm} onClose={closeDiagnosisModal} title='Edit Diagnosis'>
        <div>
          <Grid container spacing={2} >

            <Grid xs={12} >
              <Label size='h6'>
                Diagnostic Category (Select All That Apply):
              </Label>
            </Grid>
            <Grid xs={4}>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('FOCAL')} onChange={() => handleDCchange('FOCAL')} />
                <Label size="subtext" color="primary">FOCAL EPILEPSY</Label>
              </span>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('GENERALIZED')} onChange={() => handleDCchange('GENERALIZED')} />
                <Label size="subtext" color="primary">GENERALIZED EPILEPSY</Label>
              </span>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('GENERALIZED IDIOPATHIC')} onChange={() => handleDCchange('GENERALIZED IDIOPATHIC')} />
                <Label size="subtext" color="primary">GENERALIZED IDIOPATHIC</Label>
              </span>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('GENERALIZED SYMPTOMATIC')} onChange={() => handleDCchange('GENERALIZED SYMPTOMATIC')} />
                <Label size="subtext" color="primary">GENERALIZED SYMPTOMATIC</Label>
              </span>
            </Grid>
            <Grid xs={4}>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('MIXED')} onChange={() => handleDCchange('MIXED')} />
                <Label size="subtext" color="primary">EPILEPSY WITH MIXED FOC/GEN FEATURES</Label>
              </span>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('UNKNOWNEP')} onChange={() => handleDCchange('UNKNOWNEP')} />
                <Label size="subtext" color="primary">EPILEPSY OF UNKNOWN ONSET</Label>
              </span>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('UNKNOWN')} onChange={() => handleDCchange('UNKNOWN')} />
                <Label size="subtext" color="primary">UNKNOWN DIAGNOSIS</Label>
              </span>
            </Grid>
            <Grid xs={4}>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('PSYCHOGENIC')} onChange={() => handleDCchange('PSYCHOGENIC')} />
                <Label size="subtext" color="primary">PSYCHOGENIC</Label>
              </span>
              {dx.diagnostic_category.includes('PSYCHOGENIC') && (
                <div className="indent">
                  <span className="diagnosis-row__checkbox">
                    <Checkbox checked={dx.EEGConfirmed} onChange={handleEEGConfirmed} />
                    <Label size="subtext" color="primary">EEG CONFIRMED</Label>
                  </span>
                </div>
              )}
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('SYNCOPE')} onChange={() => handleDCchange('SYNCOPE')} />
                <Label size="subtext" color="primary">SYNCOPE</Label>
              </span>
              <span className="diagnosis-row__checkbox">
                <Checkbox checked={isChecked('OTHER')} onChange={() => handleDCchange('OTHER')} />
                <Label size="subtext" color="primary">OTHER NON EPILEPTIC</Label>
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={2} >

            <Grid xs={6} >

              <Label size='h6'>Lesional:</Label>
              <span className="diagnosis-row__checkbox">
                <Checkbox
                  checked={dx.lesion}
                  onChange={handleLesionChange}
                  label='Lesional'
                />
                <Label size="subtext" color="primary">Lesional</Label>
              </span>
            </Grid>
            <Grid xs={6} >
              <Label size='h6' >Refractory:</Label>
              <span className="diagnosis-row__checkbox">
                <Checkbox
                  checked={dx.refractory}
                  onChange={() => setDx({
                    ...dx,
                    refractory: !dx.refractory,
                  })}
                  label='Refractory'
                />
                <Label size="subtext" color="primary">Refractory</Label>
              </span>
            </Grid>
            <Grid xs={6} >
              {dx.lesion &&
                <div>
                  <Label size='subtext' uppercase>Lesional Types:</Label>
                  <MultipleSelectDropdown
                    name='Lesionalops'
                    options={lesionalOps}
                    value={dx.selectedLesionalOps}
                    onChange={handleLesionalOpsChange}
                  />
                </div>
              }
            </Grid>
            <Grid xs={6} />
          </Grid >

          {(dx.diagnostic_category.includes('FOCAL') || dx.diagnostic_category.includes('MIXED')) &&
            <Grid container spacing={2} >
              <Grid xs={12} className='_make-row' >
                <Label size='h6'>Localization Details</Label>
                <IconButton icon='add' onClick={addOp} />
              </Grid>
            </Grid>
          }
          {(dx.diagnostic_category.includes('FOCAL') || dx.diagnostic_category.includes('MIXED')) && ops.length > 0 && ops.map((el, index) => (
            <Grid container key={index} spacing={2} className='localization-row'  >
              <Grid xs={5} >
                <Label size='subtext' uppercase>Lateralization:</Label>
                <MultipleSelectDropdown
                  name='Lesionalops'
                  options={['Left', 'Right', 'Bilateral', 'Unknown']}
                  value={ops[index].lateralization}
                  onChange={(selectedOptions: any) => handleChangeOpLat(selectedOptions, index)}
                />
              </Grid>
              <Grid xs={5} >
                <Label size='subtext' uppercase>Localization:</Label>
                <MultipleSelectDropdown
                  name='localization'
                  options={['Frontal', 'Insular', 'Temporal', 'Parietal', 'Occipital', 'Hemisphere', 'Unknown']}
                  value={ops[index].localization}
                  onChange={(selectedOptions) => handleChangeOpLoc(selectedOptions, index)}
                />
              </Grid>
              <Grid xs={2} className='trash-btn'>
                <IconButton icon='trash' onClick={() => removeOp(index)} />
              </Grid>
            </Grid>
          ))}

          {dx.diagnostic_category.includes('OTHER') &&
            <Grid container spacing={2}  >
              <Grid xs={10} className='trash-btn'>
                <Spacer value={15} />
                <Label size='subtext' uppercase>Describe Other: </Label>
                <TextInput
                  name='freeText'
                  id='freeText'
                  value={dx.freeText}
                  // placeholder='Please Describe'
                  onChange={handleDiagnosisChange}
                />
              </Grid>
            </Grid>
          }

          <Spacer value={20} />

          <div className='text-center'>
            <Button loading={isLoading || isAdding} onClick={submitChanges}>Submit</Button>
          </div>
        </div>
      </Modal >

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </>
  );
};

DiagnosisRow.defaultProps = {
};

DiagnosisRow.displayName = 'DiagnosisRow';
export default DiagnosisRow;
