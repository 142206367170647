/* eslint-disable react/require-default-props */
import React from 'react';
import classnames from 'classnames';
import './text-input.scss';
import { Label } from '../../atoms';


type Props = {
  kind?: 'primary' | 'secondary' | 'ghost' | 'invalid',
  disabled?: boolean,
  invalid?: boolean,
  label?: string | undefined,
  name?: string,
  value?: string | number,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (event: React.KeyboardEvent) => void,
  id?: string,
  placeholder?: string,
  type?: string,
  autocomplete?: string,
  error?: string,
  className?: string,
  onFocus?: any,
  onfocusout?: any,
};

const TextInput = ({ error,  kind, disabled, invalid, label, name, value, onChange, id, placeholder, type, autocomplete, className, onKeyDown, onFocus, onfocusout }: Props) => {
  const classes = {
    'text-input': true,
    [`text-input--${kind}`]: true,
    'text-input--disabled': disabled,
    'text-input--invalid': invalid,
    className: true,
  };

  return (
		<div className="text-input-wrapper">
			{label && <Label className="input__label" size="h5" weight={600}  color="primary" >{label}</Label>}
			<input
				id={id}
				aria-label={id}
				onChange={onChange}
				className={classnames(classes)}
				value={value}
				disabled={disabled}
				autoComplete={autocomplete}
				// defaultValue={props.defaultValue}
				type={type}
				placeholder={placeholder}
				name={name}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
				onBlur={onfocusout}
			/>
      {error && <div className="error">{error}</div>}
		</div>
  );
};

TextInput.defaultProps = {
  id:'randomidhere',
  kind: 'grey',
  disabled: false,
  invalid: false,
  type: 'text',
  name: '',
  value:'',
  onChange:()=>{},
  onfocusout:()=>{},
  onFocus:()=>{},
  onKeyDown:()=>{},
  placeholder:null,
  autocomplete:'off',
  error:'',
  className: 'text-input',
};

export default TextInput;
