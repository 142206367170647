import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './question-types.scss';
import { Label, Spacer } from '../../atoms';
import YearPicker from '../YearPicker/YearPicker';

export interface DateProps {
  className?: string,
  answer: any,
  onAnswer: (vals: any) => void,
}

const Date = (props: DateProps): JSX.Element => {
  const { answer, onAnswer } = props;
  const [value, setValue] = useState<any>();

  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      setValue(answer[0].value);

    }
  }, [answer]);


  const onChange = (val: string) => {
    if (val === 'invalidValue') {
      onAnswer([{ value: val, label: val }]);
    } else {
      const valu = moment(val).format('MM/DD/YYYY');
      setValue(valu);
      // props.onAnswer?.(value, value);
      onAnswer([{ value, label: value }]);
    }

  };



  return (
    <div className='question-types_text'>
      <Spacer value={20} />
      <YearPicker
        pastOnly
        value={value}
        onAnswer={onChange}
      />
    </div>
  );
};

Date.defaultProps = {
  className: '',
};

Date.displayName = 'Date';
export default Date;
