/* eslint-disable import/prefer-default-export */
import { createContext } from 'react';

export interface SurveyContextType {
  surveyInProgress: boolean;
  setSurveyInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SurveyContext = createContext<SurveyContextType>({
  surveyInProgress: false,
  setSurveyInProgress: () => { },
});
