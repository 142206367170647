/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './logo.scss';
import HDstoryLogoLg from './HDstoryLogoLg-01.png';
import HDstoryLogoR from './myHDstory_Registered.png';
import HDLogoEl from './HDelement.png';
import Simple from './myhdlogo_simple.png';


type Props = {
  size: number | string;
  type?: string;
};

const Logo = ({ size, type }: Props) => {
  const navigate = useNavigate();

  const reRoute = () => {
    navigate('/');
  };

  const logoType = (kind: string) => {
    switch (kind) {
      case 'expanded':
        return HDstoryLogoLg;
      case 'registered':
        return HDstoryLogoR;
      case 'small':
        return HDLogoEl;
      default:
        return Simple;
    }
  };

  return (
    <img role='button' onClick={reRoute} id="hd-logo" src={logoType(type ?? 'defualt')} height="auto" width={size} alt="My HD Story Logo" />
  );
};

Logo.defaultProps = {
  type: 'defualt',
};

export default Logo;
