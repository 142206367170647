/* eslint-disable consistent-return */
import html2pdf from 'html2pdf.js';
import { StudyT } from '../types/hsgTypes';

export type ConsentTextItem = {
  title: string;
  body: string;
};

export const isSpecialSection = (item: { title: any; body: any; }): number => {
  const keywords = ['Sponsor', 'Protocol', 'Principal Investigator', 'Telephone', 'Address', 'Contact'];
  const consentKeyword = 'Consent'; // Keyword for checking the body
  const thankKeyword = 'Thank'; // Keyword for checking the body

  // Check if the title contains any of the keywords
  const titleMatch = keywords.some(keyword => item.title.includes(keyword));
  if (titleMatch) return 1;

  // Check if the title or body contains the consentKeyword
  const consentMatch = item.title.includes(consentKeyword) || item.body.includes(consentKeyword);
  if (consentMatch) return 3;

  // Check if the body starts with the thankKeyword
  const bodyMatch = item.body.startsWith(thankKeyword);
  return bodyMatch ? 2 : 0;
};


export const formatConsentToHTML = (textItems: ConsentTextItem[]): string => {
  const formatItem = (item: ConsentTextItem): string => {
    const startsWithBulletOrNumber = /^(\d+\.)|(•)/.test(item.body);
    // const startsWithBulletOrNumber = /^(\d+\.)|(\•)/.test(item.body);

    const sectionType = isSpecialSection(item);
    switch (sectionType) {
      case 1: // Special Section
        return `
          <div style="background-color: #f0f0f0; padding-left: 20px; padding-right: 20px; margin-top: 0px;">
            ${item.title ? `<h2 style="display: inline; color: #333; margin-right: 10px;">${item.title}</h2>` : ''}
            ${item.body ? `<p style="display: inline;">${item.body}</p>` : ''}
          </div>
        `;
      case 2: // Thank Section
        return `
          <div style=" padding-left: 20px; padding-right: 20px; margin-top: 15px; padding-top: 15px; border-top: 2px solid #dddddd;">
            ${item.title ? `<h2 style="color: #333;">${item.title}</h2>` : ''}
            ${item.body ? `<p style="color: #333;">${item.body}</p>` : ''}
          </div>
        `;
      case 3: // Consent Section
        return `
          <div style="page-break-before: always; background-color: #f0f0f0; padding-left: 20px; padding-right: 20px; margin-top: 5px;">
            ${item.title ? `<h2>${item.title}</h2>` : ''}
            ${item.body ? `<p style="padding-left: ${startsWithBulletOrNumber ? '20px' : '0px'}; padding-right: ${startsWithBulletOrNumber ? '20px' : '0px'};">${item.body}</p>` : ''}
            <div class="signature-section" style="margin-top: 20px;">
            <p>By signing below, I confirm that I have read and fully understand the contents of this consent form and voluntarily agree to participate.</p>
            
            <!-- Include witness fields if necessary -->
          </div>
          </div>
        `;
      default: // General Section
        return `
          <div style=" padding-left: 20px; padding-right: 20px; margin-top: 5px;">
            ${item.title ? `<h2>${item.title}</h2>` : ''}
            ${item.body ? `<p style="padding-left: ${startsWithBulletOrNumber ? '20px' : '0px'}; padding-right: ${startsWithBulletOrNumber ? '20px' : '0px'};">${item.body}</p>` : ''}
          </div>
        `;
    }
  };
  const formattedItems = textItems.map(formatItem).join('');

  return `
    <html>
      <head>
        <title>Consent Form</title>
        <style>
          body {
            font-family: 'Helvetica Neue', Arial, sans-serif;
            font-size: 14px;
            line-height: 1.6;
            color: #333333;
            // background-color: #ffffff;
            margin: 48px;
            text-align: justify; /* Justify all text in the body */
          }
          h1 {
            color: #000000;
            font-size: 24px;
            margin-bottom: 10px;
            border-bottom: 2px solid #dddddd;
            padding-bottom: 10px;
          }
          h2 {
            color: #333333;
            font-size: 18px;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          p {
            color: #333333;
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 10px;
          }
          a {
            color: #0066cc;
            text-decoration: none;
          }
          a:hover {
            text-decoration: underline;
          }
          .special-section {
            // background-color: #f9f9f9;
            border-left: 4px solid #0066cc;
            padding: 15px;
            margin-bottom: 20px;
          }
          .date-section {
            text-align: right;
            margin-top: 20px;
          }
          .signature-section {
            text-align: left;
            margin-top: 40px;
          }
          /* Add more styles as needed */
        </style>
      </head>
      <body>
        <header style=" padding: 5px; text-align: center;">
          <h1>Consent Form</h1>
        </header>
        ${formattedItems}
        <footer style=" padding: 5px; text-align: center;">
          <p>End of Consent Form</p>
        </footer>
      </body>
    </html>
  `;
};


export const onrenderPDFText = async (study: any, signatureData?: any) => {
  try {
    let parsedText: ConsentTextItem[];
    let choiceText: any | string | undefined;

    // if (Array.isArray(study.consentText)) {
    //   // parsedText = study.consentText;
    //   parsedText = study.consentText[0].map(text => ({ text }));

    // } else {
    try {
      // parsedText = JSON.parse(study.consentText);
      parsedText = study.consentText ? JSON.parse(study.consentText) : [];
    } catch (error) {
      console.error('Failed to parse text:', error);
      return null; // Or handle the error as needed
    }


    if (typeof study.consentCheckboxText === 'object' && study.consentCheckboxText !== null) {
      choiceText = study.consentCheckboxText;
    } else {
      try {
        choiceText = JSON.parse(study.consentCheckboxText!);
      } catch (error) {
        console.error('Failed to parse text:', error);
        return null; // Or handle the error as needed
      }
    }

    // parsedText = study.consentText ;//JSON.parse(study.consentText);
    const htmlContent = formatConsentToHTML(parsedText);
    const headerHtml = '';// `<h1>${study.title}</h1><div>powered by the Huntington Study Group (HSG)</div>`;



    let consentHtml = '';
    const footerHtml = '';

    let signatureHtml = `
            <div style="margin-top: 20px;">
                <label>Signature:</label><br />
                <div style="border-bottom: 1px solid #000; width: 300px; height: 40px;"></div>
            </div>
            <div style="margin-top: 10px;">
                <label>Print Name:</label><br />
                <div style="border-bottom: 1px solid #000; width: 300px; height: 20px;"></div>
            </div>
            <div style="margin-top: 10px; padding-bottom: 20px;">
                <label>Date:</label><br />
                <div style="border-bottom: 1px solid #000; width: 300px; height: 20px;"></div>
            </div>
        `;
    const formatDateWithTimezone = (dateString: string): string => {
      const date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: '2-digit', minute: '2-digit', second: '2-digit',
        timeZoneName: 'short',
      };
      return new Intl.DateTimeFormat('default', options).format(date);
    };

    if (choiceText) {
      const yesOptionAvailable = !!choiceText?.yes;
      if (yesOptionAvailable) {
        consentHtml = `
          <div style="margin-top: 20px;">
              <label>
                  <input type="checkbox" checked disabled /> ${choiceText.yes}
              </label><br />
          </div>
          <div style="margin-top: 20px;">
              <label>
                  <input type="checkbox" disabled /> ${choiceText.no}
              </label><br />
          </div>`;
      }


    }
    if (signatureData && signatureData.signature) {
      signatureHtml = `
      <div style="margin-top: 30px;">
      <label>I, ${signatureData.fullname || ''} have read the terms and hereby declare </label>
  </div>
              <div style="margin-top: 20px;">
              <label></label>${signatureData.consent}<br />
              <br />
              </div>
              <div style="margin-top: 20px;">
                  <label>Signature:</label><br />
                  <img src="${signatureData.signature}" alt="Signature" style="width: 200px; height: auto;"/>
              </div>
              <div style="margin-top: 10px;">
                  <label>Print Name: </label>${signatureData.fullname || ''}<br />
              </div>
              <div style="margin-top: 10px;">
                  <label>User Name: </label>${signatureData.username || ''}<br />
              </div>
              <div style="margin-top: 10px; padding-bottom: 20px;">
                  <label>Date:</label> ${formatDateWithTimezone(signatureData.signatureDate) || ''}<br />
                  
              </div>
          `;
    }

    let deviceInfoHtml = '';
    if (signatureData && signatureData.deviceInfo) {
      deviceInfoHtml = `
            <div style="margin-top: 20px; padding-bottom: 20px;">
                <h3>Digital sign</h3>
                <span>OS: ${signatureData.deviceInfo.os || 'N/A'}</span>
                <span>Version: ${signatureData.deviceInfo.version || 'N/A'}</span>
                <span>Device ID: ${signatureData.deviceInfo.deviceId || 'N/A'}</span>
                <span>Device Name: ${signatureData.deviceInfo.deviceName || 'N/A'}</span>
                <span>IP Address: ${signatureData.deviceInfo.ipAddress || 'N/A'}</span>
            </div>
        `;
    }
    const options = {
      html: headerHtml + htmlContent + consentHtml + signatureHtml + deviceInfoHtml + footerHtml,
      fileName: `${study.title}-consent-form-${signatureData ? 'signed' : 'clean'}`,
      directory: 'Documents',
    };

    const html = headerHtml + htmlContent + consentHtml + signatureHtml + deviceInfoHtml + footerHtml;

    const ops = {
      margin: [0.24, .5, .5, .5],
      filename: 'consent.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    // const file = await RNHTMLtoPDF.convert(options);

    const file = await html2pdf().from(html).set(ops).output('blob');



    return file;
  } catch (e) {
    console.error('Error', `An error occurred: ${e}`);
  }
};




/**
 * Uploads a consent form to a specified URL.
 * 
 * @param file The File object of the consent form.
 * @param url The URL to which the consent form should be uploaded.
 * @param uuid The unique identifier for the upload process.
 * @returns A Promise that resolves to the UUID if the upload is successful, or null in case of failure.
 */
// export const uploadConsentForm = async (
//   file: any,
//   url: string,
//   uuid: string,
//   mimeType = 'application/pdf',
// ): Promise<string | null> => {
//   try {
//     // Logger.info('------------------- Starting to upload Consent Form:', {uuid, url, file});
//     const { filePath } = file;
//     // Logger.info('------------------- filePath:', {filePath, mimeType});

//     // const mimeType = getMimeType(filePath);

//     const uploadSuccessful = await uploadFile(url, filePath, mimeType);

//     if (!uploadSuccessful) {
//       // Logger.error('========-------===-- Failed to upload the consent form:', uploadSuccessful);
//       throw new Error('Failed to upload the consent form');
//     } else {
//       console.info('========------------ Successfully uploaded Consent Form:', { uuid, file });
//     }


//     return uuid;
//   } catch (error) {
//     console.error('Error in uploadConsentForm:', error);
//     return null;
//   }
// };

export default { isSpecialSection, formatConsentToHTML, onrenderPDFText };


