import { VisitT } from '../../types/types';

const sortVisits = (visits: VisitT[]) => {
  try {
    const sorted = visits.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()); return sorted;
  } catch (e) {
    console.error(e);
    return visits;
  }
};

export default sortVisits;