import React, { useState, useEffect } from 'react';
import { Modal, Label, Spacer, Button } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import { useUpdateImgRatingMutation } from '../../../api/builders/participantFiles.api';
import './image-rating.scss';

export interface ImageRatingProps {
  series: any,
}

const labels = [
  'Very Poor - Lots of issues',
  'Poor - Several issues',
  'Average - Some minor issues',
  'Good - Few issues',
  'Excellent - No issues',
];

interface Rating {
  qc_score: number;
  qc_notes: string;
  qc_grainy: boolean;
  qc_movement: boolean;
  qc_distortion: boolean;
}
const initial: Rating = {
  qc_score: 0,
  qc_notes: '',
  qc_grainy: false,
  qc_movement: false,
  qc_distortion: false,
};

const ImageRating = (props: ImageRatingProps): JSX.Element => {
  const { series } = props;
  const [rating, setRating] = useState(initial);
  const [popup, setPopup] = useState(false);
  const [submitRating] = useUpdateImgRatingMutation();

  useEffect(() => {
    if (series) {
      setRating({
        qc_score: series.qc_score !== 'null' ? series.qc_score : 0,
        qc_notes: series.qc_notes !== 'null' ? series.qc_score : '',
        qc_grainy: series.qc_grainy,
        qc_movement: series.qc_movement,
        qc_distortion: series.qc_distortion,
      });
    }
  }, [series]);

  const getPayload = (st: any) => {
    const variable = 'filler';
    return st.replace(/\./g, '_');
  };

  const onRatingChange = (vals: any) => {
    const payload = {
      seriesId: getPayload(series.id),
      body: vals,
    };
    submitRating(payload);
    setPopup(false);
  };

  const handleRatingChange = (newRating: number) => {
    if (newRating !== 5) {
      setPopup(true);
      setRating((prev) => ({ ...prev, qc_score: newRating }));
    } else if (newRating === 5) {
      setRating({
        qc_score: 5,
        qc_notes: '',
        qc_grainy: false,
        qc_movement: false,
        qc_distortion: false,
      });
      if (!popup) {
        onRatingChange({
          qc_score: 5,
          qc_notes: '',
          qc_grainy: false,
          qc_movement: false,
          qc_distortion: false,
        });
      }
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRating({ ...rating, [event.target.name]: event.target.value });
  };

  const handleToggleChange = (toggle: keyof Rating) => {
    const updatedRating: Rating = {
      ...rating,
      [toggle]: !rating[toggle],
    };
    setRating(updatedRating);
  };

  const handleSubmit = () => {
    onRatingChange(rating);
  };

  const cancelRating = () => {
    setPopup(false);
    setRating(initial);
  };

  return (
    <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          className={star <= rating.qc_score ? 'star__filled' : 'star'}
          onClick={() => handleRatingChange(star)}
          role='button'
          tabIndex={-1}
        >
          &#9733;
        </span>
      ))}

      <Modal title='Rate Image Quality' open={popup} onClose={cancelRating}>
        <div>
          <div className='image-rating__header'>
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={star <= rating.qc_score ? 'star-lg__filled' : 'star-lg'}
                onClick={() => handleRatingChange(star)}
                role='button'
                tabIndex={-1}
              >
                &#9733;
              </span>
            ))}
          </div>
          <Label size='h4' center> {labels[rating.qc_score - 1]}</Label>
          <Spacer value={20} />
          {rating.qc_score !== 5 &&
            <div>
              <div className='btn-row'>
                <button type='button'
                  className={rating.qc_grainy ? 'image-rating__toggle-btn-active' : 'image-rating__toggle-btn'}
                  onClick={() => handleToggleChange('qc_grainy')}
                >
                  Grainy
                </button>
                <button type='button'
                  className={rating.qc_movement ? 'image-rating__toggle-btn-active' : 'image-rating__toggle-btn'}
                  onClick={() => handleToggleChange('qc_movement')}
                >
                  Movement
                </button>
                <button type='button'
                  className={rating.qc_distortion ? 'image-rating__toggle-btn-active' : 'image-rating__toggle-btn'}
                  onClick={() => handleToggleChange('qc_distortion')}
                >
                  Distortion
                </button>
              </div>
              <Spacer value={20} />
              <TextAreaInput
                value={rating.qc_notes}
                onChange={handleChangeInput}
                name='qc_notes'
                label='Why did you give the image this rating?'
              />
            </div>
          }
          <Spacer value={30} />
          <div className='btn-row'>
            <Button onClick={handleSubmit}>Submit</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ImageRating.defaultProps = {
};

ImageRating.displayName = 'ImageRating';
export default ImageRating;
