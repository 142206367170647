/* eslint-disable react/no-array-index-key */
import React from 'react';
import { DatePicker, Label, Spacer, Button } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';
import SimpleSelect from '../../SimpleSelect/SimpleSelect';

export interface MotherProps {
  state: any,
  handleChange: (e: any) => void,
  handlePrevStep: () => void,
  handleNextStep: () => void,
}

const Mother = (props: MotherProps): JSX.Element => {
  const { state, handleChange, handlePrevStep, handleNextStep } = props;

  const validate = () => {
    if (
      state.mother &&
      state.mother.trim() !== ''
    ) {
      return false;
    } 
    return true;
    
  };

  return (
    <div>
      <Label size="h3" bold color="primary" center>
        What is your Mother&apos;s Maiden last name?
      </Label>
      <Spacer value={20} />
      <TextInput
        id="mother"
        name="mother"
        label=""
        value={state.mother}
        placeholder=""
        onChange={handleChange}
      />

      <Spacer value={20} />
      <div className='btn-row'>
        <Button onClick={handlePrevStep}>Previous</Button>
        <Button onClick={handleNextStep} disabled={validate()}>Next</Button>
      </div>
    </div>
  );
};

Mother.defaultProps = {
};

Mother.displayName = 'Name';
export default Mother;
