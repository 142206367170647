import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import { Modal, Label, Spacer, Button, Dropdown, DatePicker, IconButton, MultipleSelectDropdown } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import './epilepsy-surgery.scss';
import { PatientT, SurgeryT } from '../../../types/types';
import { useGetSurgeryRecordsByPatientQuery } from '../../../api/builders/epilepsy.api';
import { ResectionPathologyOps, EpilepsySurgeryOps } from '../../../utils/parameters';

export interface SurgeryFormProps {
  className?: string,
  handleSubmit: (vals: any) => void,
  loading: boolean,
  editSurgery: SurgeryT | null,
}


const initialSurgeryVal = {
  type: '',
  date: moment(new Date()).format('L'),
  lobe: '',
  pathology: [],
  ResectionOps: [] as (string[]),
  localization: [
    {
      lateralization: '',
      localization: '',
    },
  ],
  FCDType: '',
  MMType: '',
  VMType: '',
  Tumor: '',
  VNSType: '',
  DBSType: '',
  RNType: '',
  DBSLocation: '',
  HemispherectomyLocation: '',
};


const SurgeryForm = (props: SurgeryFormProps): JSX.Element => {
  const { handleSubmit, loading, editSurgery } = props;
  const [surgeryVals, setSurgeryVals] = useState(initialSurgeryVal);



  useEffect(() => {
    if (editSurgery) {
      try {
        const {
          type,
          lobe,
          pathology,
          ResectionOps,
          localization,
          FCDType,
          MMType,
          VMType,
          Tumor,
          VNSType,
          DBSLocation,
          HemispherectomyLocation,
          DBSType,
          RNType,
        } = editSurgery.surgeryDetails[0];


        setSurgeryVals({
          type,
          date: editSurgery.dateOfSurgery,
          lobe,
          pathology: pathology ? JSON.parse(pathology) : [],
          ResectionOps: ResectionOps ? JSON.parse(ResectionOps) : '',
          localization: localization ? JSON.parse(localization) : [
            {
              lateralization: '',
              localization: '',
            },
          ],
          FCDType,
          MMType,
          VMType,
          Tumor,
          VNSType,
          DBSType,
          RNType,
          DBSLocation,
          HemispherectomyLocation,
        });

      } catch (e) {
        console.error(e);
      }
    }
  }, [editSurgery]);


  const closeModal = () => {
    setSurgeryVals(initialSurgeryVal);
    // onClose();
  };


  const handleResectionOpsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    setSurgeryVals({
      ...surgeryVals,
      ResectionOps: typeof value === 'string' ? value.split(',') : (value as string[]),
    });
  };

  const addOp = () => {
    const tmpOPS = [...surgeryVals.localization];
    tmpOPS.push({
      lateralization: '',
      localization: '',
    });
    setSurgeryVals({
      ...surgeryVals,
      localization: tmpOPS,
    });
  };

  const removeOp = (index: number) => {
    const tmpRm = [...surgeryVals.localization];
    tmpRm.splice(index, 1);
    setSurgeryVals({
      ...surgeryVals,
      localization: tmpRm,
    });
  };

  const handleChangeOpLat = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const tmpLAT = [...surgeryVals.localization];
    tmpLAT[index].lateralization = e.target.value;
    setSurgeryVals({
      ...surgeryVals,
      localization: tmpLAT,
    });
  };

  const handleChangeOpLoc = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const tmpLOC = [...surgeryVals.localization];
    tmpLOC[index].localization = e.target.value;
    setSurgeryVals({
      ...surgeryVals,
      localization: tmpLOC,
    });
  };

  const handleSurgeryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setSurgeryVals({
      ...surgeryVals,
      [event.target.name]: event.target.value,
    });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setSurgeryVals({
      ...surgeryVals,
      pathology: [],
      ResectionOps: [],
      FCDType: '',
      MMType: '',
      VMType: '',
      Tumor: '',
      VNSType: '',
      DBSType: '',
      RNType: '',
      DBSLocation: '',
      HemispherectomyLocation: '',
      localization: [{
        lateralization: '',
        localization: '',
      }],
      [event.target.name]: event.target.value,
    });
  };

  const handleSurgeryDateChange = (val: string) => {
    const value = moment(val).format('MM/DD/YYYY');
    setSurgeryVals({
      ...surgeryVals,
      date: value,
    });
  };



  const isResectionOrAblation = ['Resection', 'Ablation'].includes(surgeryVals.type);
  const isFCDTypeVisible = isResectionOrAblation && surgeryVals.ResectionOps.includes('Focal Cortical Dysplasia');
  const isMMTypeVisible = isResectionOrAblation && surgeryVals.ResectionOps.includes('Mild microdysgenesis');
  const isTumorVisible = isResectionOrAblation && surgeryVals.ResectionOps.includes('Tumor');
  const isVMTypeVisible = isResectionOrAblation && surgeryVals.ResectionOps.includes('Vascular malformation');
  const isVNS = surgeryVals.type === 'VNS - Vagus nerve stimulator';
  const isRNS = surgeryVals.type === 'RNS - Responsive neurostimulator';
  const isHemispherectomyOrVNS = ['Hemispherectomy', 'VNS - Vagus nerve stimulator'].includes(surgeryVals.type);
  const isDBS = surgeryVals.type === 'DBS - Deep Brain stimulator';


  const renderLocalizationDetails = () => (
    surgeryVals.localization.map((el: any, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid key={index} container spacing={0} >
        <Grid sm={5}>
          <Label size="subtext" uppercase>Lateralization:</Label>
          <Dropdown
            name="mid"
            value={surgeryVals.localization[index].lateralization}
            onChange={(e) => handleChangeOpLat(e, index)}
          >
            <MenuItem value="Left">Left</MenuItem>
            <MenuItem value="Right">Right</MenuItem>
            <MenuItem value="Bilateral">Bilateral</MenuItem>
            <MenuItem value="Unknown">Unknown</MenuItem>
          </Dropdown>
        </Grid>
        <Grid sm={5}>
          <Label size="subtext" uppercase>Localization:</Label>
          <Dropdown
            name="localization"
            value={surgeryVals.localization[index].localization}
            onChange={(e) => handleChangeOpLoc(e, index)}
          >
            <MenuItem value="Frontal">Frontal</MenuItem>
            <MenuItem value="Insular">Insular</MenuItem>
            <MenuItem value="Temporal">Temporal</MenuItem>
            <MenuItem value="Parietal">Parietal</MenuItem>
            <MenuItem value="Occipital">Occipital</MenuItem>
            <MenuItem value="Hemisphere">Hemisphere</MenuItem>
            <MenuItem value="Unknown">Unknown</MenuItem>
          </Dropdown>

        </Grid>
        <Grid sm={2}>
          <div className='h-100 d-flex'>
            <IconButton onClick={() => removeOp(index)} icon='trash' />
          </div>
        </Grid>
      </Grid>
    ))
  );

  return (
    <>
      <Spacer value={10} />
      <div className='surgery-form'>

        <Label size='subtext' uppercase>Date</Label>
        <DatePicker
          label=''
          value={surgeryVals.date}
          onChange={handleSurgeryDateChange}
        />

        <Spacer value={10} />
        <Label size='subtext' uppercase>Surgery Type</Label>
        <Dropdown
          name='type'
          value={surgeryVals.type}
          onChange={handleTypeChange}
        >
          {EpilepsySurgeryOps.map((option: string) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Dropdown>

        {(surgeryVals.type === 'Resection' || surgeryVals.type === 'Ablation' || surgeryVals.type === 'RNS -Responsive neurostimulator') && (
          <>
            <Spacer value={10} />
            <div className='d-flex'>
              <Label size='subtext'>Localization Details</Label>
              <IconButton icon='add' onClick={addOp} />
            </div>
          </>
        )}

        {(surgeryVals.type === 'Resection' || surgeryVals.type === 'Ablation' || surgeryVals.type === 'RNS - Responsive neurostimulator' ||
          surgeryVals.type === 'Stereotactic Radiosurgery (Gamma Knife)' || surgeryVals.type === 'Multiple Subpial transections' || surgeryVals.type === 'Arachnoid Cyst fenestration/ removal') &&
          surgeryVals.localization.length > 0 && renderLocalizationDetails()
        }
        {/* {renderTypeSpecificFields()} */}

        {isResectionOrAblation && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Lesion or Pathology:</Label>
            <MultipleSelectDropdown
              name='ResectionOps'
              options={ResectionPathologyOps}
              value={surgeryVals.ResectionOps}
              onChange={handleResectionOpsChange}
            />
          </div>
        )}
        {isFCDTypeVisible && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Focal Cortical Dysplasia Type</Label>
            <Dropdown
              name='FCDType'
              value={surgeryVals.FCDType}
              onChange={handleSurgeryChange}
            >
              <MenuItem value="Type 1a">Type 1a</MenuItem>
              <MenuItem value="Type 1a">Type 1b</MenuItem>
              <MenuItem value="Type 1a">Type 2a</MenuItem>
              <MenuItem value="Type 1a">Type 2b</MenuItem>
              <MenuItem value="Type 1a">Type 3</MenuItem>
            </Dropdown>
          </div>
        )}
        {isMMTypeVisible && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Mild microdysgenesis Type</Label>
            <Dropdown
              name='MMType'
              value={surgeryVals.MMType}
              onChange={handleSurgeryChange}
            >
              <MenuItem value="Type 1a">Type 1a</MenuItem>
              <MenuItem value="Type 1a">Type 2b</MenuItem>
            </Dropdown>
          </div>
        )}
        {isTumorVisible && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Describe Tumor</Label>
            <TextInput
              name='Tumor'
              id='Tumor'
              className='seizure-dropdown'
              value={surgeryVals.Tumor}
              onChange={handleSurgeryChange}
            />
          </div>
        )}
        {isVMTypeVisible && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Vascular malformation Type</Label>
            <Dropdown
              name='VMType'
              value={surgeryVals.VMType}
              onChange={handleSurgeryChange}
            >
              <MenuItem value="Cavernous malformation">Cavernous malformation</MenuItem>
              <MenuItem value="Arteriovenous malformation">Arteriovenous malformation</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Dropdown>
          </div>
        )}
        {isVNS && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Vagus nerve Stim Type</Label>
            <Dropdown
              name='VNSType'
              value={surgeryVals.VNSType}
              onChange={handleSurgeryChange}
            >
              <MenuItem value="First implant">First implant</MenuItem>
              <MenuItem value="Battery Change">Battery Change</MenuItem>
              <MenuItem value="Removal">Removal</MenuItem>
              <MenuItem value="Repair">Repair</MenuItem>
            </Dropdown>
          </div>
        )}
        {isRNS && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Responsive Procedure Type</Label>
            <Dropdown
              name='RNType'
              value={surgeryVals.RNType}
              onChange={handleSurgeryChange}
            >
              <MenuItem value="First implant">First implant</MenuItem>
              <MenuItem value="Battery Change">Battery Change</MenuItem>
              <MenuItem value="Removal">Removal</MenuItem>
              <MenuItem value="Repair">Repair</MenuItem>
            </Dropdown>
          </div>
        )}
        {isHemispherectomyOrVNS && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Location</Label>
            <Dropdown
              name='HemispherectomyLocation'
              value={surgeryVals.HemispherectomyLocation}
              onChange={handleSurgeryChange}
            >
              <MenuItem value="Left">Left</MenuItem>
              <MenuItem value="Right">Right</MenuItem>
            </Dropdown>
          </div>
        )}
        {isDBS && (
          <div>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Location</Label>
            <Dropdown
              name='DBSLocation'
              value={surgeryVals.DBSLocation}
              onChange={handleSurgeryChange}
            >
              <MenuItem value="Anterior Nucleus">Anterior Nucleus</MenuItem>
              <MenuItem value="Centromedian Nucleus">Centromedian Nucleus</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Dropdown>
            <Spacer value={10} />
            <Label size='subtext' uppercase>Deep Brain stimulator Type</Label>
            <Dropdown
              name='DBSType'
              value={surgeryVals.DBSType}
              onChange={handleSurgeryChange}
            >
              <MenuItem value="First implant">First implant</MenuItem>
              <MenuItem value="Battery Change">Battery Change</MenuItem>
              <MenuItem value="Removal">Removal</MenuItem>
              <MenuItem value="Repair">Repair</MenuItem>
            </Dropdown>
          </div>
        )}


      </div>

      <Spacer value={15} />
      <div className='text-center'>
        <Button loading={loading} onClick={() => handleSubmit(surgeryVals)}>Submit</Button>
      </div>
    </>
  );
};

SurgeryForm.defaultProps = {
  className: '',
};

SurgeryForm.displayName = 'SurgeryForm';
export default SurgeryForm;
