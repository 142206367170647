/* eslint-disable react/no-array-index-key */
import { Avatar, MenuItem } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '../../../api/builders/hsg.api';
import { Alert, Button, DatePicker, Dropdown, Label, Modal, Spacer, Spinner } from '../../../components/atoms';
import { SimpleSelect, TextInput } from '../../../components/molecules';
import Switch from '../../../components/molecules/Switch/Switch';
import { AlertState, ApiError } from '../../../types/types';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import stringAvatar from '../../../utils/fns/stringAvatar';
import CountryList from '../../../utils/countryList';

const initial = {
  firstname: '',
  lastname: '',
  lastnameAtBirth: '',
  gender: '',
  motherMaidenname: '',
  dob: '',
  country: '',
  zip: '',
  state: '',
  city: '',
  assistant: '',
  receiveEmail: false,
};

const getBool = (string: string | boolean) => {
  if (string === 'Yes') {
    return true;
  } if (string === 'No') {
    return false;
  } if (string === false) {
    return false;
  } if (string === true) {
    return true;
  }
  return false;

};

const options = [
  { goto: 'next', label: 'Male', order: 1, value: 'Male' },
  { goto: 'next', label: 'Female', order: 2, value: 'Female' },
  { goto: 'next', label: 'Other', order: 3, value: 'Other' },
];

const UserProfile = () => {
  const { data: userProfile } = useGetUserProfileQuery(' ');
  const [editableUser, setEditableUser] = useState(initial);
  const [genderModal, setGenderModal] = useState(false);
  const [update, { isLoading, isError, isSuccess, error }] = useUpdateUserProfileMutation();
  const [newGender, setNewGender] = useState({ gender: '', genderOther: '' });
  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'success',
  });


  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Updating Profile: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Updating Profile',
        kind: 'success',
      });
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  useEffect(() => {
    if (userProfile) {
      const info = {
        firstname: userProfile[0].firstname?.length > 0 ? userProfile[0].firstname[0] : '',
        lastname: userProfile[0].lastname?.length > 0 ? userProfile[0].lastname[0] : '',
        lastnameAtBirth: userProfile[0].lastnameAtBirth?.length > 0 ? userProfile[0].lastnameAtBirth[0] : '',
        dob: userProfile[0].dob?.length > 0 ? userProfile[0].dob[0] : '',
        gender: userProfile[0].gender?.length > 0 ? userProfile[0].gender[0] : '',
        motherMaidenname: userProfile[0].motherMaidenname?.length > 0 ? userProfile[0].motherMaidenname[0] : '',
        zip: userProfile[0].zip?.length > 0 ? userProfile[0].zip[0] : '',
        city: userProfile[0].city?.length > 0 ? userProfile[0].city[0] : '',
        state: userProfile[0].state?.length > 0 ? userProfile[0].state[0] : '',
        country: userProfile[0].country?.length > 0 ? userProfile[0].country[0] : '',
        receiveEmail: userProfile[0].receiveEmail?.length > 0 ? getBool(userProfile[0].receiveEmail[0]) : false,
        assistant: userProfile[0].assistant?.length > 0 ? userProfile[0].assistant[0] : '',
      };
      setEditableUser(info);
    }
  }, [userProfile]);

  const saveUpdates = () => {
    try {
      const pl = {
        ...editableUser,
        description: '',
        email: '',
        phoneNumber: '',
        avatarId: '',
      };
      update(pl);
    } catch (e) {
      console.error('ERR SAVING UPDATES', e);
    }
  };


  const onChangeUserProfile = (e: { target: { id: any; value: any; }; }) => {
    setEditableUser((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const onChangeUserProfileLocation = (e: any) => {
    setEditableUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDOBchange = (value: any) => {
    const val = moment(value.$d).format('MM/DD/YYYY');
    setEditableUser((prev) => ({ ...prev, dob: val.toString() }));
  };

  const onEmailChange = (e: { target: { checked: any; }; }) => {
    setEditableUser((prev) => ({ ...prev, receiveEmail: e.target.checked }));
  };


  const validate = () => {
    if (
      editableUser.firstname &&
      editableUser.firstname.trim() !== '' &&
      editableUser.lastname &&
      editableUser.lastname.trim() !== '' &&
      editableUser.dob &&
      editableUser.dob.trim() !== '' &&
      editableUser.zip &&
      editableUser.zip.trim() !== ''
    ) {
      return false;
    }
    return true;
  };

  const cancelGenderChange = () => {
    setGenderModal(false);
    // setNewGender({ gender: "", genderOther: "" })
  };

  const answerQuestion = (value: string) => {
    setNewGender((prev) => ({ ...prev, gender: value }));
  };

  const onGenderChange = (e: { target: { id: any; value: any; }; }) => {
    setNewGender((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };



  if (Array.isArray(userProfile) && userProfile.length > 0) {
    return (
      <div className="user-profile">
        <aside className="panel1">
          <div className="user-profile__user-tile">
            <>
              <Spacer value={15} />
              <div className="super-centered">
                <Avatar className="user-profile__avatar"  >{stringAvatar(`${userProfile[0].firstname} ${userProfile[0].lastname}`)}</Avatar>
              </div>
              <Spacer value={15} />
              <Label center size="h4" color="primary" >
                {`${userProfile[0].firstname} ${userProfile[0].lastname}`}
              </Label>
              <Spacer value={5} />
              {/* <Label size="h5" color="primary" center value={user?.email && (user.email).toLowerCase()} /> */}
              <Spacer value={20} />
            </>
          </div>
        </aside>
        <div className="panel2">
          <Label size="h4" color="primary"> Edit your Information </Label>
          <Spacer value={20} />

          <TextInput
            id="firstname"
            value={editableUser.firstname}
            label='First Name'
            onChange={onChangeUserProfile}
          />
          <Spacer value={10} />
          <TextInput
            id="lastname"
            value={editableUser.lastname}
            label='Last Name'
            onChange={onChangeUserProfile}
          />
          <Spacer value={10} />

          <TextInput
            id="lastnameAtBirth"
            value={editableUser.lastnameAtBirth}
            label='LAST NAME AT BIRTH'
            onChange={onChangeUserProfile}
          />
          <Spacer value={10} />

          <div role='button' tabIndex={-1} className="user-profile__gender-wrapper" onClick={() => setGenderModal(true)}>
            <TextInput
              id="gender"
              type=""
              value={editableUser.gender}
              label='SEX AT BIRTH'
              onChange={onChangeUserProfile}
              disabled
            />
            <Spacer value={10} />
          </div>

          <TextInput
            id="maidenname"
            type=""
            value={editableUser.motherMaidenname}
            label="MOTHER'S MAIDEN LAST NAME?"
            onChange={onChangeUserProfile}
          />
          <Spacer value={10} />

          <DatePicker
            id="dob-2"
            label='DATE OF BIRTH'
            value={editableUser.dob}
            onChange={handleDOBchange}
          />
          <Spacer value={10} />

          <TextInput
            id="zip"
            type=""
            value={editableUser.zip}
            label='ZIP CODE'
            onChange={onChangeUserProfile}
          />
          <Spacer value={10} />

          <Dropdown
            label='COUNTRY'
            disabled
            name="country"
            value={editableUser.country}
            onChange={onChangeUserProfileLocation}
          >
            {CountryList.map((countr: string) => (
              <MenuItem value={countr}>{countr}</MenuItem>
            ))}
          </Dropdown>

          <Spacer value={15} />

          <Label size="subtext" >
            RECEIVE EMAILS FROM MYHDSTORY
          </Label>

          <Switch
            checked={editableUser.receiveEmail}
            onChange={onEmailChange}
            label=''
          />
          <Spacer value={30} />

          <div className="super-centered">
            <Button disabled={validate()} onClick={saveUpdates} loading={isLoading}> SAVE CHANGES </Button>

          </div>

          <Spacer value={30} />
        </div>

        <Modal open={genderModal} onClose={cancelGenderChange} title="What is your Sex (sex at birth)?" size="sm">
          <div>
            <Spacer value={20} />

            {options.map((op, i) => (
              <SimpleSelect
                label={op.label}
                onSelect={answerQuestion}
                answer={newGender.gender}
                multi={false}
                key={i}
                withCheck
              />
            ))}

            {newGender.gender === 'Other' &&
              <TextInput
                id="genderOther"
                label="Specify:"
                value={newGender.genderOther}
                placeholder=""
                onChange={onGenderChange}
              />}
            <Spacer value={30} />
            <div className="btn-row">
              <Button outline kind="secondary" size="wide">
                Save Changes
              </Button>
            </div>
          </div>
        </Modal>

        <Alert
          severity={alert.kind}
          open={alert.open}
          onClose={resetAlert}
          message={alert.message}
        />

      </div>
    );
  }
  return (
    <div className='h-100 w-100 super-centerd'>
      <Spinner />
    </div>
  );

};

export default withAuthenticator(UserProfile);
