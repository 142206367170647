import QueryString from 'qs';
import api from '../api';

export const patientSessions = api.injectEndpoints({
  endpoints: (builder) => ({
    createSession: builder.mutation({
      query: (payload) => ({
        url: '/survey/session',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['PATIENTSESSIONS'],
    }),
    createSessionSurveyEmail: builder.mutation({
      query: (payload) => ({
        url: '/survey/session/email',
        method: 'POST',
        body: payload,
      }),
    }),
    createUsersurvey: builder.mutation({
      query: (payload) => ({
        url: '/usersurvey',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['PATIENTSESSIONS'],
    }),
    editSurvey: builder.mutation({
      query: (payload) => ({
        url: `/usersurvey/registry/${payload.id}`,
        method: 'PUT',
        body: payload.body,
      }),
      invalidatesTags: ['PATIENTSESSIONS'],
    }),
    // editSession: builder.mutation({
    //   query: (payload) => ({
    //     url: `/survey/session/${payload.id}`,
    //     method: 'PUT',
    //     body: payload.body,
    //   }),
    //   invalidatesTags: ['PATIENTSESSIONS'],
    // }),
    editSession: builder.mutation({
      query: (payload) => {
        // Log the payload here
        console.info('Edit Session Payload:', payload);
    
        // Return the request details
        return {
          url: `/survey/session/${payload.id}`,
          method: 'PUT',
          body: payload.body,
        };
      },
      invalidatesTags: ['PATIENTSESSIONS'],
    }),
    
    deleteSession: builder.mutation({
      query: (payload) => ({ url: `/survey/session/${payload.id}`, method: 'DELETE' }),
      invalidatesTags: ['PATIENTSESSIONS'],
    }),
    getSessions: builder.query({
      query: (patientId) => `/survey/session/${patientId}`,
      providesTags: ['PATIENTSESSIONS'],
      transformResponse: (response: any) => response.items,
    }),
    getSessionStatsById: builder.query({
      query: (sessionId) => `/survey/session/statsbyid/${sessionId}`,
      providesTags: ['PATIENTSESSIONS'],
      transformResponse: (response: any) => response.items,
    }),
    getSurveys: builder.query({
      query: () => '/survey', 
      transformResponse: (response: any) => response.items,
      providesTags: ['SURVEYTEMPLATES'],
    }),
  }),
});

export const {
  usePrefetch,
  useCreateSessionMutation,
  useGetSessionsQuery,
  useGetSurveysQuery,
  useGetSessionStatsByIdQuery,
  useCreateUsersurveyMutation,
  useEditSurveyMutation,
  useEditSessionMutation,
  useCreateSessionSurveyEmailMutation,
  useDeleteSessionMutation,
} = patientSessions;
