import React from 'react';
// import classnames from 'classnames';
import './multiple-select-dropdown.scss';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Label from '../Label/Label';

export interface MultipleSelectDropdownProps {
  value: string[];
  name?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  disabled?: boolean;
  options: string[],
  onChange: (value: any) => void;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 2,
    position: 'relative',
    margin: '0.5em .2em',
    backgroundColor: 'ffffff',
    border: '.5px solid rgba(90, 90, 90, 0.2)',
    fontSize: '0.875em',
    padding: '8px 26px 8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Raleway',
    ].join(','),
    '&:focus': {
      borderRadius: 2,
      borderColor: 'rgba(90, 90, 90, 0.2)',
      boxShadow: '0 5px 15px rgba(0,0,0, / 8%)',
    },
  },
}));


const MultipleSelectDropdown = (props: MultipleSelectDropdownProps) => {
  const { value, onChange, id, name, invalid, disabled, placeholder, label, options } = props;

  return (
    <FormControl className='dropdown' >
      {label &&
        <Label size='h5' weight={600} color='primary'>
          {label}
        </Label>
      }
      <Select
        labelId='demo-customized-select-label'
        id={id}
        multiple
        value={value}
        name={name}
        error={invalid}
        disabled={disabled}
        renderValue={(selected) => selected.join(', ')}
        onChange={onChange}
        input={<BootstrapInput />}
        label={placeholder}
      >
        {options.map((op) => (
          <MenuItem key={op} value={op}>
            <Checkbox checked={value.indexOf(op) > -1} />
            <ListItemText primary={op} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultipleSelectDropdown.defaultProps = {
  name: 'Dropdown',
  placeholder: 'Selet',
  invalid: false,
  disabled: false,
  id: 'x',
  label: '',
};

MultipleSelectDropdown.displayName = 'MultipleSelectDropdown';


export default MultipleSelectDropdown;
