/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import moment from 'moment';
import sparkMD5 from 'spark-md5';
import SignatureCanvas from 'react-signature-canvas';
import { AiFillPrinter } from 'react-icons/ai';
import './study-consent-form.scss';
import { Button, Label, Spacer, Checkbox } from '../../atoms';
import SimpleSelect from '../SimpleSelect/SimpleSelect';
import { useGenerateS3ConsentUrlMutation } from '../../../api/builders/hsg.api';
import { StudyT } from '../../../types/hsgTypes';
import { onrenderPDFText } from '../../../utils/consentUtils';

export interface StudyConsentFormProps {
  className?: string,
  body: any,
  user: string
  loading: boolean,
  onSubmit: (docId: string) => void,
  handleClose: () => void,
  study: StudyT,
}



// const formatText = (input: string[]) => {
//   const formattedText = input.map((line, index) => {
//     if (line.match(/^\/n\s*-\s*/)) {
//       // Bullet point
//       return (
//         <li key={index} style={{ marginLeft: '20px', listStyleType: 'disc' }}>
//           {line.replace(/^\/n\s*-\s*/, '')}
//         </li>
//       );
//     } if (line.match(/^\/n\s*\d+\.\s*/)) {
//       // Numbered list
//       return (
//         <li key={index} style={{ marginLeft: '20px', listStyleType: 'decimal' }}>
//           {line.replace(/^\/n\s*\d+\.\s*/, '')}
//         </li>
//       );
//     }
//     // Regular text
//     return <p key={index}>{line}</p>;

//   });

//   return formattedText;
// };



const formatText = (input: string) => {
  const lines = input.split('\n');
  const formattedText: JSX.Element[] = [];
  let currentList: JSX.Element[] = [];
  let listType: 'ul' | 'ol' | null = null;

  const flushCurrentList = () => {
    if (currentList.length > 0) {
      formattedText.push(
        listType === 'ul' ? <ul>{currentList}</ul> : <ol>{currentList}</ol>,
      );
      currentList = [];
      listType = null;
    }
  };

  lines.forEach((line, index) => {
    if (line.match(/^\/n\s*-\s*/)) {
      if (listType !== 'ul') {
        flushCurrentList();
        listType = 'ul';
      }
      currentList.push(
        <li key={index} style={{ marginLeft: '20px', listStyleType: 'disc' }}>
          {line.replace(/^\/n\s*-\s*/, '')}
        </li>,
      );
    } else if (line.match(/^\/n\s*\d+\.\s*/)) {
      if (listType !== 'ol') {
        flushCurrentList();
        listType = 'ol';
      }
      currentList.push(
        <li key={index} style={{ marginLeft: '20px', listStyleType: 'decimal' }}>
          {line.replace(/^\/n\s*\d+\.\s*/, '')}
        </li>,
      );
    } else {
      flushCurrentList();
      formattedText.push(<p key={index}>{line}</p>);
    }
  });

  // Flush any remaining list items
  flushCurrentList();

  return formattedText;
};


const StudyConsentForm = (props: StudyConsentFormProps): JSX.Element => {
  const { body, user, loading, onSubmit, handleClose, study } = props;
  const link = study.consentForm[0];
  const checkbox = JSON.parse(study.consentCheckboxText[0]);
  const componentRef = React.useRef(null);
  const sigCanvas = React.useRef<SignatureCanvas>(null);
  const [saveFile] = useGenerateS3ConsentUrlMutation();
  const [signatureData, setSig] = React.useState({
    signature: '',
    fullname: user,
    username: user,
    signatureDate: moment().format('LLL'),
    hashData: '',
    consent: '',
    signed: false,
  });


  const onAnswer = (value: any) => {
    setSig((prev) => ({ ...prev, consent: value }));
  };

  const finishSigning = () => {
    if (sigCanvas.current) {
      const signatureImage = sigCanvas.current.toDataURL();
      setSig((prev) => ({
        ...prev,
        signature: signatureImage,
        signed: true,
      }));
    }
  };

  const clearCanvas = () => {
    sigCanvas.current?.clear();
    setSig((prev) => ({ ...prev, signed: false }));
  };

  const validateButton = (): boolean => {
    const sigValidate = signatureData.signed;
    return !loading && signatureData.consent === checkbox.yes && sigValidate;
  };

  const uploadToS3 = async (url: string, file: any, hash: any): Promise<boolean> => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': 'application/pdf', 'Content-MD5': hash },
      });
      return response.ok;
    } catch (e2) {
      console.error('E', e2);
      return false;
    }
  };


  const hexToBase64 = (hexString: string) => {
    const matches = hexString.match(/.{1,2}/g);
    if (!matches) {
      throw new Error('Invalid hexadecimal string');
    }
    const hexBytes = matches.map((byte: string) => parseInt(byte, 16));
    const base64String = btoa(String.fromCharCode.apply(null, hexBytes));
    return base64String;
  };

  const generateMD5 = async (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const { result } = reader;
      if (result instanceof ArrayBuffer) {
        const spark = new sparkMD5.ArrayBuffer();
        spark.append(result);
        const hashHex = spark.end();
        const hashBase64 = hexToBase64(hashHex);
        resolve(hashBase64);
      } else {
        reject(new Error('Failed to read file as ArrayBuffer'));
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(blob);
  });


  const uploadPdfToS3 = async (blobPdf: Blob) => {

    const timestamp = Date.now();
    const filename = `${study.title}_${timestamp}_consent_form`;
    const file = new File([blobPdf], filename);
    const hash = await generateMD5(file);

    const payload = {
      'filename': filename,
      'method': 'upload',
      'studyId': study.id,
      'md5': hash,
    };

    const path = await saveFile(payload).unwrap();

    if (path && path?.url) {
      await uploadToS3(path?.url, file, hash);
      onSubmit(path.key);
      handleClose();
    }

  };
  const onUpload = async () => {
    try {
      const file = await onrenderPDFText(study, signatureData);

      if (file) {
        // const fileName = `${study.title}-consent-form-${signatureData ? 'signed' : 'clean'}.pdf`;
        // const objectUrl = window.URL.createObjectURL(file as Blob);
        // const downloadLink = document.createElement('a');
        // downloadLink.href = objectUrl;
        // downloadLink.download = fileName;
        // downloadLink.click();
        // window.URL.revokeObjectURL(objectUrl);
        uploadPdfToS3(file);
      }
    } catch (error) {
      // Handle any errors
      console.error('Error rendering PDF:', error);
    }
  };

  const onAgree = async () => {
    try {
      onUpload();
    } catch (e) {
      console.error('Failed to save', e);
    }
  };

  return (
    <div className="card">
      <span className="study-consent-form__title">
        <Label size="h3" color="primary" bold center >
          Study Information Sheet (Informed Consent Form)
        </Label>
      </span>
      <Spacer value={10} />

      <div  >
        <a href={link} className="study-consent-form__download-btn" target="_blank" rel="noreferrer" >
          <AiFillPrinter className="study-consent-form__download-icon" />
          Print Study Information Sheet
        </a>
      </div>


      <div className='study-consent-form' id='divToPrint' ref={componentRef}>
        {body && JSON.parse(body).map((section: any, index: number) => (
          <div key={index}>
            <p className="study-consent-form__section-title">{section.title}</p>
            <p className="study-consent-form__section-body">{formatText(section.body)}</p>
          </div>
        ))}

        <Spacer value={30} />

        <div className="consent-form__footer">
          <Label size="h4" color="ghost" center >
            I, {user} have read the terms and hereby declare
          </Label>

          <Spacer value={20} />

          <span className='study-consent-form__checkbox'>
            <Checkbox checked={signatureData.consent === checkbox.yes} onChange={() => onAnswer(checkbox.yes)} label={checkbox.yes} />
            <span className='study-consent-form__checkbox-text'>
              <Label size='h6' color='primary'>{checkbox.yes}</Label>
            </span>
          </span>
          <Spacer value={10} />
          <span className='study-consent-form__checkbox'>
            <Checkbox checked={signatureData.consent === checkbox.no} onChange={() => onAnswer(checkbox.no)} label={checkbox.no} />
            <span className='study-consent-form__checkbox-text'>
              <Label size='h6' color='primary'>{checkbox.no}</Label>
            </span>
          </span>

          <Spacer value={10} />
          <Label size="h4" color="ghost" center >
            On {moment().format('LLL')}
          </Label>
          <Spacer value={30} />

          <div className='protocol-print-form__signature-wrapper'>
            <Label size='h6'>Signature:</Label>
            <SignatureCanvas
              penColor='black'
              ref={sigCanvas}
              onEnd={finishSigning}
              maxWidth={1}
              canvasProps={{ width: 500, height: 150, className: 'protocol-print-form__signature' }}
            />
            <button type='button' className='sig-clear' onClick={clearCanvas} >
              <Label size='subtext'>Clear Signature</Label>
            </button>
          </div>
        </div>


        <div className='btn-row'>
          <Button loading={loading} disabled={!validateButton()} onClick={onAgree}>
            Confirm
          </Button>
        </div>

      </div>

    </div>
  );
};

StudyConsentForm.defaultProps = {
  className: '',
};

StudyConsentForm.displayName = 'StudyConsentForm';
export default StudyConsentForm;

